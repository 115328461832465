export class UserUnitsModel {
    public longLengths: string = 'ft';
    public shortLengths: string = 'in';
    public doglegSeverity: string = 'd-ft';
    public temperatureGradient: string = '∆°F/ft';
    public linearDensity: string = 'lb/ft';
    public temperature: string = '°F';
    public pressure: string = 'psi';
    public stressPressure: string = 'psi';
    public force: string = 'lbf';
    public speed: string = 'ft/s';
    public density: string = 'ppg (U.S.)';
    public solidsDensity: string = 'lbm/ft³';
    public standardFluidFlow: string = 'gpm';
    public hydrocarbonFlow: string = 'bbl/d';
    public volume: string = 'bbl';
    public area: string = 'in²';
    public thermalConductivity: string = 'BTU/h·ft·°F';
    public coefficientOfThermalExpansion: string = '1/°C';
    public gasInjectionRate: string = 'scfh';
    public massFlow: string = 'lb/h';
    public specificHeatCapacity: string = 'BTU/ft³·°F';
    public slurryFlow: string = `bbl/min`;
    public gasGradient: string = 'psi/ft';
    public enthalpy: string = 'BTU/lbm';
    public heat: string = 'BTU';
    public torque: string = 'lbf·ft';
  }