import { Units } from "src/app/core/services/unit-library";
import { GetFeetFromValue, GetPpgFromValue, GetPsiFromValue, GetValueFromPpg, GetValueFromPsi } from "./units.helper";

export function LinearInterpSingle(tvd, pressures, units, returnPressure?: boolean): number {
  let ppfg = pressures;
  tvd = GetFeetFromValue(tvd, units.longLengths);
  let densityUnit = Units.lib[units.density].symbol;

  if (units.longLengths != 'ft' || units.pressure != 'psi' || densityUnit != 'ppg') {
    ppfg.forEach(point => {
      point.trueVerticalDepth = GetFeetFromValue(point.trueVerticalDepth, units.longLengths);
      point.pressure = GetPsiFromValue(point.pressure, units.pressure);
      point.equivalentMudWeight = GetPpgFromValue(point.equivalentMudWeight, densityUnit);
    });
  }

  let xLowerIndex = ppfg.findIndex(x => x.trueVerticalDepth >= tvd) - 1;
  if (xLowerIndex == -1) {
    xLowerIndex = 0;
  }

  if (xLowerIndex == -2) {
    xLowerIndex = ppfg.length - 2;
  }

  const xUpper = ppfg[xLowerIndex + 1].trueVerticalDepth;
  const xLower = ppfg[xLowerIndex].trueVerticalDepth;

  let yUpper;
  let yLower;

  yUpper = ppfg[xLowerIndex + 1].equivalentMudWeight * 0.052 * tvd;
  yLower = ppfg[xLowerIndex].equivalentMudWeight * 0.052 * tvd;

  const pressure = (yLower + (yUpper - yLower) / (xUpper - xLower) * (tvd - xLower));

  if (returnPressure) {
    return GetValueFromPsi(pressure, units.pressure);
  }

  const emw = pressure / (tvd * 7.48051948051947 / 144);

  return GetValueFromPpg(emw, densityUnit);
}

export function LinearInterpSingleVal(xTarget: number, xValues: number[], yValues: number[]) {
  let xLowerIndex = xValues.findIndex(xValue => xValue >= xTarget) - 1;
  if (xLowerIndex < 0) xLowerIndex = 0;

  let xLower = xValues[xLowerIndex];

  if (Math.abs(xLower - xTarget) < 0.00001)
  {
      return yValues[xLowerIndex];
  }

  let xUpper = xValues[xLowerIndex + 1];
  let yUpper = yValues[xLowerIndex + 1];
  let yLower = yValues[xLowerIndex];

  return yLower + (yUpper - yLower) / (xUpper - xLower) * (xTarget - xLower);
}
