import { Component, Input, Output } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm, subformComponentProviders } from "ngx-sub-form";
import { Subject } from "rxjs";
import { UserProfile } from "./user-model";

@Component({
  selector: 'user-profile-form',
  styles: [
    `
    .error {
      color: red;
      font-size: 11px;
      height: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      user-select: none;
    }
    .field>* {
      display: block;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input {
        -moz-appearance: textfield;
        font-size: 12px;
        height: 30px;
        width: 100% !important;
    }

    :host ::ng-deep .p-fieldset-legend {
      font-size: 11px;
      padding: 6px;
      border-radius: 8px;
      user-select: none;
    }

    :host ::ng-deep .p-fieldset .p-fieldset-content {
      padding: 0;
      border-radius: 8px;
    }

    :host ::ng-deep .p-dropdown {
      height: 25px;
      width: 100%;

      .p-dropdown-label {
        padding-top: 3px;
      }
    }

    .checkboxClass {
        display: flex;

        input {
          width: 20px !important;
          height: 13px;
        }
    }
    `
  ],
  template: `
      <form [formGroup]="form.formGroup">
        <div class="form-container">
          <div class="field">
            <label>Email</label>
            <input type="email" pInputText [formControlName]="form.formControlNames.email" (blur)="emailOnBlur()" />
            <p class="error" *ngIf="!form.formGroup.controls.email.value && form.formGroup.controls.email.errors?.['required']">Email is required.</p>
            <p class="error" *ngIf="form.formGroup.controls.email.value && form.formGroup.controls.email.invalid">Email is invalid.</p>
          </div>
          <div class="field">
            <label>First Name</label>
            <input type="text" pInputText [formControlName]="form.formControlNames.firstName" />
            <p class="error" *ngIf="form.formGroup.controls.firstName.errors?.['required']">First Name is required.</p>
          </div>
          <div class="field">
            <label>Last Name</label>
            <input type="text" pInputText [formControlName]="form.formControlNames.lastName" />
            <p class="error" *ngIf="form.formGroup.controls.lastName.errors?.['required']">Last Name is required.</p>
          </div>
          <div class="field">
            <label>Organization</label>
            <p-dropdown [autoDisplayFirst]="false" appendTo="body" [options]="organizations" [formControlName]="form.formControlNames.organization"></p-dropdown>
            <p class="error" *ngIf="form.formGroup.controls.organization.errors?.['required']">Organization is required.</p>
          </div>
          <div class="field">
            <label>Job Title</label>
            <input type="text" pInputText [formControlName]="form.formControlNames.jobTitle" />
          </div>

          <p-fieldset legend="Global Admin Only" *ngIf="form.formGroup.controls.organization.value === 'Altus' && showAdminInputs">
            <div class="field">
              <label>Database</label>
              <p-dropdown appendTo="body" [options]="organizations" [formControlName]="form.formControlNames.database"></p-dropdown>
              <p class="error" *ngIf="form.formGroup.controls.database.errors?.['required']">Database is required.</p>
            </div>

            <div class="checkboxClass">
              <input
                type="checkbox"
                binary="true"
                [formControlName]="form.formControlNames.isGlobalAdmin"
              >
            <label> Global Admin</label>
          </div>
          </p-fieldset>
        </div>
      </form>
      <br/>
    `,
  providers: subformComponentProviders(UserProfileFormComponent)
})
export class UserProfileFormComponent {

  private input$: Subject<UserProfile | undefined> = new Subject();
  private _showAdminInputs: boolean;

  constructor() { }

  public organizations: Array<string> = ["Altus", "Bsee", "Baker", "Harbour", "BP", "Te", "Fervo", "Neptune", "Shell", "Oxy", "Cop"];

  @Input() set userProfile(userProfile: UserProfile | undefined) {
    this.input$.next(userProfile);
  }

  @Input() set showAdminInputs(value: boolean) {
    this._showAdminInputs = value;
  }
  get showAdminInputs(): boolean {
    return this._showAdminInputs;
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() userProfileUpdated: Subject<UserProfile> = new Subject();

  @Output() emailChanged: Subject<string> = new Subject();

  public emailOnBlur() {
    if (this.form.formGroup.controls.email.valid) {
      this.emailChanged.next(this.form.formGroup.controls.email.value)
    }
  }

  public form = createForm<UserProfile, UserProfile>(this, {
    formType: FormType.SUB,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userProfileUpdated,
    formControls: {
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      organization: new UntypedFormControl(null, Validators.required),
      jobTitle: new UntypedFormControl(null),
      database: new UntypedFormControl(null, Validators.required),
      isGlobalAdmin: new UntypedFormControl(false)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

}
