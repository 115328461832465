import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { FormationCementCatalog } from '../models/formations-cement-catalog.model';
import { FormationsService } from '../shared/services/formations-cement-catalog.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { saveAs } from 'file-saver';
import * as xlsx from 'xlsx';
import { Utilities } from 'src/app/core/services/utilities';
import { GetSolidsDensityFromLbmFt } from 'src/app/perivis/shared/helpers/units.helper';
import { StoreService, StorageKeys } from 'src/app/core/services/store.service';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';

@Component({
  selector: 'app-formations-cement-catalog',
  templateUrl: './formations-cement-catalog.component.html',
  styleUrls: ['./formations-cement-catalog.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class FormationsCementCatalogComponent implements OnInit, AfterViewInit, OnDestroy {
  private _items: Array<FormationCementCatalog>;
  private _subscriptions: Subscription;

  @Input() catalogType: string = '';
  @ViewChild("catalogTable") catalogTable;

  public columnDefs: Array<any>;
  public isLoading: boolean;
  public catalogForm: UntypedFormGroup;
  public youngsModulusUnit: string;
  public thermalCondUnit: string;
  public solidsDensityUnit: string;
  public thermalExpCoeffUnit: string;
  public specificHeatCapacityUnit: string;
  public tableHeightFormation: string;
  public tableHeightCement: string;

  get itemsCatalog(): UntypedFormArray {
    return this.catalogForm.get("items") as UntypedFormArray;
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  // State
  @Input()
  public componentId: string;

  constructor(
    private _messenger: MediatorService,
    private _confirmationService: ConfirmationService,
    private _catalogService: FormationsService,
    private _formBuilder: UntypedFormBuilder,
    private _store: StoreService,
    private _toaster: AppNotificationService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.catalogForm = this._formBuilder.group({
      items: this._formBuilder.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    let uu = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.youngsModulusUnit = uu.stressPressure;
    this.thermalCondUnit = uu.thermalConductivity;
    this.solidsDensityUnit = uu.solidsDensity;
    this.thermalExpCoeffUnit = Units.lib[uu.coefficientOfThermalExpansion].symbol;
    this.specificHeatCapacityUnit = Units.lib[uu.specificHeatCapacity].symbol;

    this.columnDefs = [
      { field: 'name', header: 'Name' },
      { field: 'density', header: `Density (${this.solidsDensityUnit})` },
      { field: 'specificHeatCapacity', header: `Specific Heat Capacity (${this.specificHeatCapacityUnit})` },
      { field: 'thermalConductivity', header: `Thermal Conductivity (${this.thermalCondUnit})` },
      { field: 'youngsModulus', header: `Young\'s Modulus (${this.youngsModulusUnit})` },
      { field: 'poissonsRatio', header: 'Poisson\'s Ratio' },
      { field: 'thermalExpansionCoefficient', header: `Thermal Exp. Coeff (${this.thermalExpCoeffUnit})` }
    ];

    this._catalogService.getFormationCement().subscribe({
      next: (items) => {
        this._items = items.filter(x => x.discriminator == this.catalogType);
        
        let defaultItem = this._items.find(x => x.name == 'Default');
        if (defaultItem) {
          this._items.splice(this._items.indexOf(defaultItem), 1);
          this._items.unshift(defaultItem);
        }

        this.populateFormData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });

    addEventListener("keydown", (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Formations Catalog") {
        this.tableHeightFormation = (e.itemHeight - 45) + 'px';
      }

      if (e.name == 'Hard Cement Catalog') {
        this.tableHeightCement = (e.itemHeight - 45) + 'px';
      }

      if (this.isLoading) {
        this.isLoading = false;
      }
    }));
  }

  public onAddItem(idx?: number): void {
    if (this.itemsCatalog.invalid) {
      return;
    }
    this.itemsCatalog.insert(idx || 0, this.newItem());
    this.catalogTable?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public onDeleteItem(idx: number): void {
    let itemId = this.itemsCatalog.controls[idx].get("id").value;
    let itemName = this.itemsCatalog.controls[idx].get("name").value;
    if (itemId.length < 36) {
      this.itemsCatalog.removeAt(idx);
    } else {
      let catalogType = this.catalogType == 'formation' ? ' formation?' : ' hard cement?'
      if (itemName == 'Default') {
        this._toaster.showError(`Default ${this.catalogType} cannot be deleted.`);
        return;
      }
      this._confirmationService.confirm({
        message: `Are you sure that you want to delete the selected "${itemName}"` + catalogType,
        accept: async () => {
          if (itemId.length > 24) { // it's been saved to the db, so delete through API
            await lastValueFrom(this._catalogService.deleteItem(itemId));
          }
          this.itemsCatalog.removeAt(idx);
        }
      });
    }
  }

  private async handleSaveCatalogRow(v: any, catalogRecord: UntypedFormGroup): Promise<void> {
    if (catalogRecord.valid && !this.isLoading) {
      let name = v.name;
      let existingGrade = this._items.find(x => x.name == name && x.id != v.id);
      if (existingGrade) {
        this._toaster.showError(`Item with name "${name}" already exists.`);
        return;
      }
      if (v.id.length == 24) { // it's only on the client so save to db as a new record
        var newEntityId = await lastValueFrom(this._catalogService.addItem(new FormationCementCatalog(v)));
        catalogRecord.get("id").patchValue(newEntityId, { emitEvent: false });
      } else {
        await lastValueFrom(this._catalogService.updateItem(new FormationCementCatalog(v)));
      }
    }
  }

  private newItem(): UntypedFormGroup {
    let specHeatCapacityDefault = this.catalogType == 'formation' ? this.specificHeatCapacityUnit == 'BTU/lbm-°F' ? 0.3 : 1256 : this.specificHeatCapacityUnit == 'BTU/lbm-°F' ? 0.2 : 837.9;
    let densityDefault = this.catalogType == 'formation' ? GetSolidsDensityFromLbmFt(140, this.solidsDensityUnit) : GetSolidsDensityFromLbmFt(160, this.solidsDensityUnit);
    let thermalConductivityDefault = this.catalogType == 'formation' ? this.thermalCondUnit == 'BTU/h·ft·°F' ? 0.92 : 1.592 : this.thermalCondUnit == 'BTU/h·ft·°F' ? 0.568 : 0.98;
    let thermalExpansionCoefficientDefault = this.catalogType == 'formation' ? this.thermalExpCoeffUnit == '1E-6/°F' ? 3 : 5.4 : this.thermalExpCoeffUnit == '1E-6/°F' ? 6 : 10.8;

    let itemFg = new UntypedFormGroup({
      // Setting a unique id here so that prime-ng p-table has a data key for the expand and collapse functionality.
      id: new UntypedFormControl(Utilities.generateFakeGuid()),
      name: new UntypedFormControl("", [Validators.required]),
      density: new UntypedFormControl(densityDefault, [Validators.required]),
      specificHeatCapacity: new UntypedFormControl(specHeatCapacityDefault, [Validators.required]),
      thermalConductivity: new UntypedFormControl(thermalConductivityDefault, [Validators.required]),
      youngsModulus: new UntypedFormControl(3000000, [Validators.required]),
      poissonsRatio: new UntypedFormControl(this.catalogType == 'formation' ? 0.18 : 0.3, [Validators.required]),
      thermalExpansionCoefficient: new UntypedFormControl(thermalExpansionCoefficientDefault, [Validators.required]),
      discriminator: new UntypedFormControl(this.catalogType),
    });

    let debouncedValueChanges = itemFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSaveCatalogRow(v, itemFg)));

    itemFg.markAllAsTouched();

    return itemFg;
  }

  private populateFormData() {
    this._items.forEach((data, idx) => {
      let catalogFg = this.newItem();
      this.itemsCatalog.push(catalogFg);
      let expandedData = { ...data };
      this.itemsCatalog.controls[idx].setValue(expandedData, { emitEvent: false });
      if (expandedData.name == 'Default') {
        this.itemsCatalog.controls[idx].disable();
      }
    });
  }

  public exportExcel() {
    let mappedData = this.itemsCatalog.controls.map(td => {
      let objWithHeaderKeys = {};
      this.columnDefs.forEach(cd => {
        let objHeaderKey = cd.header;
        objWithHeaderKeys[objHeaderKey.replace(/["']/g, '\"')] = td.value[cd.field];
      });
      return objWithHeaderKeys;
    });
    const worksheet = xlsx.utils.json_to_sheet(mappedData);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    let catalogType = this.catalogType == 'formation' ? 'Formations Catalog?' : 'Hard Cement Catalog?'
    this.saveAsExcelFile(excelBuffer, catalogType);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
}
