import { Injectable } from '@angular/core';
import { WellExplorerItem } from '../models/well-explorer.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WellType } from 'src/app/shared/models/wellType.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class WellExplorerService {

  constructor(private http: HttpClient) { }

  // -- Well Explorer API --
  public getWellExplorer(): Observable<WellExplorerItem> {
    return this.http.get<WellExplorerItem>(`${PeriforApiEndpoint}/wellexplorer`);
  }

  public addCompany(model: { parentId: string, level: string, name: string }): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/wellexplorer/create-company`, model);
  }

  public createTreeLevel(model: { parentId: string, level: string, name: string }): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/wellexplorer/create-item`, model);
  }

  public renameTreeLevel(model: { id: string, level: string, name: string }) {
    return this.http.put<string>(`${PeriforApiEndpoint}/wellexplorer/${model.id}/rename`, model)
  }

  public deleteTreeLevel(id: string) {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/wellexplorer/${id}/remove`)
  }

  public deleteCompanyLevel(id: string) {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/wellexplorer/${id}/remove-company`)
  }

  // -- End Well Explorer API --

  // -- Design Related 
  public addDesign(model: { parentId: string, level: string, name: string, wellType: WellType, dashboardDesignId: string }): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/wellexplorer/create-design`, model);
  }

  public cloneDesign(designId:string, wellboreId:string, designName:string) :Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/wellbores/${wellboreId}/designs/${designId}/clone`, { name: designName });
  }

  public importDesign(wellboreId: string, design: any) : Observable<string>{
    return this.http.post<string>(`${PeriforApiEndpoint}/wellbores/${wellboreId}/designs/import`, design);
  }

  public exportDesign(designId, wellboreId, designName): Observable<any> {
    return this.http.get(`${PeriforApiEndpoint}/wellbores/${wellboreId}/designs/${designId}/export?name=${designName}`);
  }

  public moveDesign(moveDesignDto: any) : Observable<boolean>{
    return this.http.post<boolean>(`${PeriforApiEndpoint}/wellbores/${moveDesignDto.fromWellboreId}/designs/${moveDesignDto.designId}/move`, moveDesignDto);
  }
  // -- End Design Related

}
