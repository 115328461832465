<form [formGroup]="form.formGroup">
  <div class="field">
    <label for="name">Name</label>
    <input
      id="name"
      type="text"
      pInputText
      [formControlName]="form.formControlNames.name"
    />
  </div>

  <div class="col-12">
    <label for="thermalOperation">Previous Operation</label>
    <div class="field">
      <p-dropdown
        [disabled]="isReadOnly"
        id="thermalOperation"
        [options]="previousOperations"
        [formControlName]="form.formControlNames.previousOperationId"
        appendTo="body"
        optionLabel="name"
        optionValue="id"
        (onChange)="onPreviousOperationChanged($event)"
      >
      </p-dropdown>
    </div>
  </div>

  <br />

  <div>
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          {{ insideTabHeader }}
          <i
            class="p-error pi pi-exclamation-circle"
            *ngIf="form.formGroupErrors?.inside"
            pTooltip="Operation not saved, some required inputs not specified."
          ></i>
        </ng-template>
        <app-single-operation
          [isReadOnly]="isReadOnly"
          [formControlName]="form.formControlNames.inside"
          [typeInput]="typeInput"
          [hideShutIn]="hideShutIn"
          (typeChanged)="onChildTypeChanged($event)"
          tabType="inside"
          (mudPitsUpdate)="handleMudPits($event)"
          (drillingDepthRangeUpdate)="drillingRangeChanged($event)"
          [user]="user"
          [units]="units"
        ></app-single-operation>
      </p-tabPanel>
      <p-tabPanel *ngIf="!circulationSelected">
        <ng-template pTemplate="header">
          Annulus
          <i
            class="p-error pi pi-exclamation-circle"
            *ngIf="form.formGroupErrors?.annulus"
            pTooltip="Operation not saved, some required inputs not specified."
          ></i>
        </ng-template>
        <app-single-operation
          [isReadOnly]="isReadOnly"
          [formControlName]="form.formControlNames.annulus"
          [typeInput]="typeInput"
          (typeChanged)="onChildTypeChanged($event)"
          tabType="annulus"
          [user]="user"
        ></app-single-operation>
      </p-tabPanel>
      <p-tabPanel *ngIf="mudPitsEnabled">
        <ng-template pTemplate="header">
          Mud Pits
          <i
            class="p-error pi pi-exclamation-circle"
            *ngIf="form.formGroupErrors?.mudPits"
            pTooltip="Mud Pits not saved, some required inputs not specified."
          ></i>
        </ng-template>
        <app-mud-pit-details
          [formControlName]="form.formControlNames.mudPits"
          (typeChanged)="onChildTypeChanged($event)"
          tabType="mudPits"
        ></app-mud-pit-details>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div *ngIf="hideTimeInput">
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header"> Duration </ng-template>
        <div class="grid grid-nogutter">
          <div *ngIf="showRop" class="col-2">
            <div class="field">
              <label for="time">ROP ({{ user.units.longLengths }}/hr)</label>
              <input
                [type]="'number'"s
                id="rop"
                type="text"
                pInputText
                value="{{ defaultRop }}"
                (change)="ropChange($event)"
              />
            </div>
          </div>

          <div class="timeCol">
            <div class="field">
              <label htmlFor="time">{{ timeInputLabel }}</label>
              <div class="p-inputgroup">
                <input
                  id="time"
                  type="text"
                  pInputText
                  [formControlName]="form.formControlNames.time"
                  (change)="timeChange()"
                />
                <p-dropdown
                  [disabled]="isReadOnly"
                  [options]="timeUnits"
                  [formControlName]="form.formControlNames.timeUnits"
                  optionLabel="name"
                  optionValue="value"
                  appendTo="body"
                  (onChange)="timeChange()"
                  class="w-full"
                ></p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</form>

<!-- <pre>{{ form.formGroupErrors | json }}</pre> -->
