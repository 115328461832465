<div class="grades-catalog">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <form [formGroup]="gradesForm" novalidate autocomplete="off">
      <ng-container formArrayName="grades">
        <p-table
          #catalogTable
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          styleClass="p-datatable-gridlines"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="gradesCatalog.controls"
          dataKey="value.id"
          stateStorage="local"
          [stateKey]="componentId"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn class="align-content-center">
                {{ col.header }}
              </th>
              <th class="align-content-center">
                <p-button
                  [disabled]="gradesCatalog.invalid"
                  label="Add"
                  class="p-button-success p-button-sm addButton"
                  (click)="onAddGrade()"
                  title="Add Grade"
                ></p-button>
                <p-button
                  [disabled]="gradesCatalog.invalid"
                  label="Export to Excel"
                  class="p-button-success p-button-sm addButton"
                  (click)="exportExcel()"
                  title="Export to Excel"
                ></p-button>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-gradeFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <div class="p-inputgroup">
                    <input
                      type="text"
                      pInputText
                      formControlName="name"
                      autocomplete="off"
                      title="Name"
                      class="w-full"
                    />
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'name')"
                      [errorMsg]="getControlErrors(gradeFg, 'name')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="minimumApiYieldStrength"
                      [minFractionDigits]="1"
                      title="API Minimum Yield ({{ pressureUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'minimumApiYieldStrength')"
                      [errorMsg]="getControlErrors(gradeFg, 'minimumApiYieldStrength')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                  <p-inputNumber
                    formControlName="youngsModulus"
                    title="Young's Modulus ({{ pressureUnit }})"
                  ></p-inputNumber>
                  <validation-error
                    [isValid]="isControlInvalid(gradeFg, 'youngsModulus')"
                    [errorMsg]="getControlErrors(gradeFg, 'youngsModulus')"
                  ></validation-error>
                </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="poissonsRatio"
                      [minFractionDigits]="1"
                      title="Poisson's Ratio"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'poissonsRatio')"
                      [errorMsg]="getControlErrors(gradeFg, 'poissonsRatio')"
                    ></validation-error>
                </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="thermalExpansionCoefficient"
                      [minFractionDigits]="2"
                      title="Thermal Expansion Coeff ({{ thermalExpCoeffUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'thermalExpansionCoefficient')"
                      [errorMsg]="getControlErrors(gradeFg, 'thermalExpansionCoefficient')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="ultimateTensileStrength"
                      [minFractionDigits]="0"
                      title="Ultimate Tensile Strength ({{ pressureUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'ultimateTensileStrength')"
                      [errorMsg]="getControlErrors(gradeFg, 'ultimateTensileStrength')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="density"
                      [minFractionDigits]="1"
                      title="Density ({{ solidsDensityUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'density')"
                      [errorMsg]="getControlErrors(gradeFg, 'density')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="specificHeatCapacity"
                      [minFractionDigits]="1"
                      title="Specific Heat Capacity ({{ specificHeatCapacityUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'specificHeatCapacity')"
                      [errorMsg]="getControlErrors(gradeFg, 'specificHeatCapacity')"
                    ></validation-error>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <p-inputNumber
                      formControlName="thermalConductivity"
                      [minFractionDigits]="2"
                      title="Thermal Conductivity ({{ thermalCondUnit }})"
                    ></p-inputNumber>
                    <validation-error
                      [isValid]="isControlInvalid(gradeFg, 'thermalConductivity')"
                      [errorMsg]="getControlErrors(gradeFg, 'thermalConductivity')"
                    ></validation-error>
                  </div>
                </td>
                <td class="dropdownClass">
                  <p-dropdown
                    [disabled]="userRoles.readOnly"
                    [title]="showJson(gradeFg.get('temperatureDeration').value)"
                    [placeholder]="getPlaceholderText(gradeFg.get('temperatureDeration').value)"
                    formControlName="temperatureDeration"
                    [options]="temperatureDerations"
                    appendTo="body"
                  >
                  </p-dropdown>
                  <validation-error
                    [isValid]="isControlInvalid(gradeFg, 'temperatureDeration')"
                    [errorMsg]="getControlErrors(gradeFg, 'temperatureDeration')"
                  ></validation-error>
                </td>
                <td class="dropdownClass">
                  <p-dropdown
                    [disabled]="userRoles.readOnly"
                    title="Strength Calculation Option"
                    formControlName="strengthCalculationOption"
                    [options]="strengthCalculationOptions"
                    appendTo="body"
                  >
                  </p-dropdown>
                  <validation-error
                    [isValid]="isControlInvalid(gradeFg, 'strengthCalculationOption')"
                    [errorMsg]="getControlErrors(gradeFg, 'strengthCalculationOption')"
                  ></validation-error>
                </td>
                <td style="width: 90px">
                  <button
                    pButton
                    class="p-button-text p-button-sm p-button-danger delete-btn"
                    title="Delete Grade"
                    icon="pi pi-trash"
                    (click)="onDeleteGrade(rIdx)"
                  ></button>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>
