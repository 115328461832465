import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { StressResultsService } from '../../services/stress-results.service';
import { Observable, Subscription, catchError, forkJoin, map } from 'rxjs';
import { PackerService } from 'src/app/wellbore-inputs/services/packers.service';
import { SelectItem } from 'primeng/api';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
  selector: 'app-packer-table-results',
  templateUrl: './packer-table-results.component.html',
  styles: [`
    p-dialog {
      user-select: none;
    }
  `]
})
export class PackerTableResultsComponent implements OnInit, OnDestroy {
  
  private _selectedPacker: string;
  private _packerCount: number;
  private _forceUnit: string;
  private _longLengthUnit: string;
  private _pressureUnit: string;
  
  public dispacementType: string = "absoluteDisplacements";
  public selectedResultView: any;
  public isDisplacementSelected: boolean = false;
  public resultTypes: any[] = [];
  public columnDefs :Array<{ header: string, field: string }>;
  public selectedResultDisplay: string;
  public resultsDisplay: SelectItem[];
  public displacementTypes: Array<{ label: string, value: string }>;
  public resultData: any;
  public selectedPacker: any;
  public results: any;
  public needToCalculate: boolean;
  public isLoading: boolean;
  public packersMissing: boolean;
  public packers: any[] = [];
  public cols: any[];
  
  @ViewChild('toolbarDiv', { static: false }) toolbarDiv: ElementRef;

  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName = 'packerResultsPlot';
  public downloadPlotName = 'packer_results_plot';
  private _subscriptions: Subscription;
  public tableHeight: string;
  private _tableHeightValue: number;
  public plot = {
    data: [],
    layout: { },
    config: {}
  };

  constructor(
    private _storeService : StoreService,
    private _signalRService: SignalRService,
    private _stressResultsService: StressResultsService,
    private _packerService: PackerService,
    private _messenger: MediatorService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Packer Results Table") {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this._tableHeightValue = e.itemHeight - divHeight;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this._forceUnit = Units.lib[uu.force].symbol;
    this._longLengthUnit = uu.longLengths;
    this._pressureUnit = uu.pressure;

    this.cols = [
      {
        field: 'loadCaseName',
        header: 'Load',
        valueFormatter: (params: any) => params
      },
      {
        field: 'differentialPressure',
        header: `Differential Pressure (${this._pressureUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'tubingToPackerForce',
        header: `Tubing to Packer Force (${this._forceUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'latchShearLoad',
        header: `Latch Shear Load (${this._forceUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'packerToCasingForce',
        header: `Packer to Casing Force (${this._forceUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'total',
        header: `Total Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'thermal',
        header: `Thermal Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'poisson',
        header: `Poisson Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'hooke',
        header: `Hooke Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'buckling',
        header: `Buckling Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      }
    ];

    this.displacementTypes = [
      { label: "Absolute Displacement", value: "absoluteDisplacements" },
      { label: "Relative Displacement", value: "relativeDisplacements" }
    ];

    this.resultsDisplay = [
      { label: 'Grid', value: 'grid' },
      { label: 'Plot', value: 'plot' }
    ];

    this.selectedResultDisplay = 'grid';
    
    this.selectedResultView = 'differentialPressure';

    this.refreshResults();
    
    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS || data.action == PeriforOnChangeMessages.REFRESH_PACKERS ||
      data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.selectedResultDisplay = 'grid';
      this.refreshResults();
    }
  }

  public refreshResults() {
    this.isLoading = true;
    this.results = [];
    this.plot.data = [];
    const sources = [
      this._stressResultsService.getPackerLoads() as Observable<any>,
      this._packerService.getPackersForTubular() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([results, packers]) => {
        this.resultData = results;
        this._packerCount = packers.packers.length;

        this.packers = this.resultData?.map(x => x.packerName ?? 'Packer') ?? [];
        this._selectedPacker = this.packers ? this.packers[0] : null;
  
        this.handleErrors();
  
        this.results = this.resultData[0].packerLoadResults;
        this.handleDisplacementResults();
        this.tableHeight = this._tableHeightValue - 1 + 'px';
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public toggleGridPlot(e: Event): void {
    this.showResults(e['value'] != 'plot');
  }

  public displacementTypeChange(event: any) {
    this.dispacementType = event.value;
    this.showResults(this.selectedResultDisplay === 'grid');
  }

  public selectPacker(packer: any) {
    this._selectedPacker = packer.originalEvent?.srcElement.innerText ?? packer;
    let index = this.packers?.findIndex(x => x == this._selectedPacker);
    if (index == -1) {
      index = 0;
    }

    this.handleErrors();

    this.results = this.resultData[index].packerLoadResults;

    this.showResults(this.selectedResultDisplay === 'grid');

    this.isLoading = false;
  }

  private showResults(isGrid: boolean): void {
    if(isGrid) {
      this.handleDisplacementResults();
    } else {
      this.handleMultipleLoadResults();
    }
  }

  private handleDisplacementResults(): void {
    this.results.forEach(element => {
      element['total'] = element[this.dispacementType].total;
      element['thermal'] = element[this.dispacementType].thermal;
      element['poisson'] = element[this.dispacementType].poisson;
      element['hooke'] = element[this.dispacementType].hooke;
      element['buckling'] = element[this.dispacementType].buckling;
    });
  }

  private handleErrors() {
    if (this._packerCount == 0) {
      this.results = [];
      this.packers = [];
      this.packersMissing = true;
      this.needToCalculate = false;
      this.isLoading = false;
      return;
    } else if ((this.resultData?.length ?? 0) < 1 && this._packerCount > 0) {
      this.results = [];
      this.packersMissing = false;
      this.needToCalculate = true;
      this.isLoading = false;
      return;
    } else {
      this.packersMissing = false;
      this.needToCalculate = false;
      this.isLoading = false;
    }
  }

  public toggleResultsView(e): void {
    this.selectedResultDisplay = 'plot';
    this.selectedResultView = e.value;
    this.isDisplacementSelected = this.resultTypes.find(x => x.value == this.selectedResultView)?.isDisplacement ?? false;
    this.handleMultipleLoadResults();
  }

  private handleMultipleLoadResults() {
    this.columnDefs = [];

    this.resultTypes = [
      { label: `Differential Pressure (${this._pressureUnit})`, value: "differentialPressure", isDisplacement: false },
      { label: `Tubing to Packer Force (${this._forceUnit})`, value: "tubingToPackerForce", isDisplacement: false },
      { label: `Latch Shear Load (${this._forceUnit})`, value: "latchShearLoad", isDisplacement: false },
      { label: `Packer to Casing Force (${this._forceUnit})`, value: "packerToCasingForce", isDisplacement: false },

      { label: `Total Displacement (${this._longLengthUnit})`, value: "total", isDisplacement: true },
      { label: `Thermal Displacement (${this._longLengthUnit})`, value: "thermal", isDisplacement: true },
      { label: `Poisson Displacement (${this._longLengthUnit})`, value: "poisson", isDisplacement: true },
      { label: `Hooke Displacement (${this._longLengthUnit})`, value: "hooke", isDisplacement: true },
      { label: `Buckling Displacement (${this._longLengthUnit})`, value: "buckling", isDisplacement: true }
    ];

    const traceArray = {
      x: [],
      y: [],
      type: "bar",
      width: 0.55,
    };

    this.results.forEach(load => {
      traceArray.y.push(this.isDisplacementSelected ? load[this.dispacementType][this.selectedResultView] : load[this.selectedResultView]);
      traceArray.x.push(load.loadCaseName);
    });

    this.yAxisTitle = this.resultTypes.find(x => x.value === this.selectedResultView).label;

    this.plot.data = [traceArray];
    this.isLoading = false;
  }

  formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }
}
