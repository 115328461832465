import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FlowType } from 'src/app/perical/models/flow.model';
import { Fluid } from '../../../../../models/fluid.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

export interface HydrocarbonFlowForm {
  type: FlowType.HYDROCARBON;
  oilFlowRate: number;
  gasFlowRate: number;
  waterFlowRate: number;
  multiphaseCorrelation: string;
  gasModel: string;
  pipeRoughness: number;
  co2EosOption: string;
}

@Component({
  selector: 'app-hydrocarbon-fluid',
  templateUrl: './hydrocarbon-fluid.component.html',
  styleUrls: ['./hydrocarbon-fluid.component.scss'],
  providers: subformComponentProviders(HydrocarbonFluidComponent)
})
export class HydrocarbonFluidComponent implements OnInit, AfterViewInit {

  constructor() {
    this.multiphaseCorrelations = [
      { name: 'Beggs & Brill', value: 'beggsBrill' },
      { name: 'Gray', value: 'gray' },
      { name: 'Hagedorn & Brown', value: 'hagedornBrown' },
      // { name: 'Zhang Mechanistic', value: 'zhangMechanistic' }
    ];

    this.gasModels = [
      { name: 'SRK', value: 'srk' }
    ];

    this.co2EosOptions = [
      { name: 'GERG-2008', value: 'GERG2008' },
      { name: 'PVT Import File', value: 'ImportFile' },
      // { name: 'Soave-BWR', value: 'SoaveBWR' },
      // { name: 'Peng-Robinson', value: 'PR' }
    ];

    this.gorOptions = [
      { label: 'Oil & Gas', value: 'oilGas' },
      { label: 'Oil & GOR', value: 'oilGor' },
      { label: 'Gas & GOR', value: 'gasGor' }
    ];
  }

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;

  public flowUnit: string;
  public gasFlowUnit: string;
  public gorUnit: string;
  public oilFlowRateValidation: { min: number, max: number };
  public waterFlowRateValidation: { min: number, max: number };
  public gasFlowRateValidation: { min: number, max: number };

  public oilGas: Array<boolean> = [false, false];
  @Input('selectedFluid') set _selectedFluid(selectedFluid: Fluid) {
    this.oilGas[0] = selectedFluid.state['oilApiGravity'] > 0 ? true : false;
    this.oilGas[1] = selectedFluid.state['gasComposition'] || selectedFluid.state.type === 'co2Fluid' ? true : false;
    this.vleSelected = selectedFluid.state.type === 'vle' ? true : false;
    this.gasModelHidden = selectedFluid.state.type === 'vle' ? true : false;
    this.co2FluidSelected = selectedFluid.state.type === 'co2Fluid' ? true : false;
    this._hasGas = selectedFluid.state['gasComposition'] ? true : false;

    this.form.formGroup.controls.gasFlowRate.reset();
    this.form.formGroup.controls.oilFlowRate.reset();
    this.form.formGroup.controls.waterFlowRate.reset();

    if (this.userUnits) {
      this.checkFluidTypeAndValidation();
    }

    this.calculateGor();
  }

  public pipeRoughnessValidation: { min: number, max: number };
  public multiphaseCorrelations: any[];
  public co2EosOptions: any[];
  public gasModels: any[];
  public gorOptions: any[];
  public emitInitialValueOnInit = false;
  // public emitNullOnDestroy = false;
  public gor: number;
  private selectedOption;
  public gorEnabled: boolean;
  public gasRateDisabled: boolean;
  public oilRateDisabled: boolean;
  public oilHidden: boolean;
  public gasHidden: boolean;
  public gasModelHidden: boolean = false;
  public vleSelected: boolean;
  public co2FluidSelected: boolean;

  private _hasGas: boolean;
  private _defaultPipeRoughness: number;

  public form = createForm<HydrocarbonFlowForm>(this, {
    formType: FormType.SUB,
    formControls:  {
      type: new UntypedFormControl(FlowType.HYDROCARBON),
      oilFlowRate: new UntypedFormControl(null, Validators.required),
      gasFlowRate: new UntypedFormControl(null, Validators.required),
      waterFlowRate: new UntypedFormControl(null, Validators.required),
      multiphaseCorrelation: new UntypedFormControl('beggsBrill', Validators.required),
      gasModel: new UntypedFormControl('srk', [Validators.required]),
      pipeRoughness: new UntypedFormControl(null, [Validators.required]),
      co2EosOption: new UntypedFormControl('GERG2008')
    }
  });

  ngOnInit(): void {
    this._defaultPipeRoughness = 0.001968;
    if (this.userUnits.shortLengths == 'in') {
      this.pipeRoughnessValidation = { min: 0, max: 0.25 };
    } else if (this.userUnits.shortLengths == 'cm') {
      this.pipeRoughnessValidation = { min: 0, max: 0.6 };
      this._defaultPipeRoughness = 0.00499;
    } else {
      this.pipeRoughnessValidation = { min: 0, max: 6.3 };
      this._defaultPipeRoughness = 0.0499;
    }
    this.form.formGroup.controls.pipeRoughness.setValidators([Validators.min(this.pipeRoughnessValidation.min), Validators.max(this.pipeRoughnessValidation.max), Validators.required]);

    this.flowUnit = Units.lib[this.userUnits.hydrocarbonFlow].symbol;

    this.gasFlowUnit = Units.lib[this.userUnits.gasInjectionRate].symbol;
  }

  ngAfterViewInit(): void {
    if (this.form.formGroup.controls.pipeRoughness.value == null) {
      this.form.formGroup.controls.pipeRoughness.patchValue(this._defaultPipeRoughness, { emitEvent: false });
    }

    let controls = this.form.formGroup.controls;
    if (!controls.oilFlowRate.value) { controls.oilFlowRate.setValue(this.co2FluidSelected || this.oilHidden ? 0 : controls.oilFlowRate.value, { emitEvent: false }); }
    if (!controls.gasFlowRate.value) { controls.gasFlowRate.setValue(this._hasGas ? controls.gasFlowRate.value : 0, { emitEvent: false }); }
    if (!controls.waterFlowRate.value) { controls.waterFlowRate.setValue(this.co2FluidSelected ? 0 : controls.waterFlowRate.value, { emitEvent: false }); }

    this.checkFluidTypeAndValidation();
    this.calculateGor();
  }

  private checkFluidTypeAndValidation(): void {
    if (this.userUnits.gasInjectionRate == 'scfh') {
      this.gorUnit = 'scf/bbl';
      this.gasFlowRateValidation = { min: 0.1, max: 1000 };
    } else {
      this.gorUnit = 'm³/m³';
      this.gasFlowRateValidation = { min: 0.0028, max: 28.3 };
    }

    if (this.co2FluidSelected) {
      this.form.formGroup.controls.waterFlowRate.setValidators(null);
      this.form.formGroup.controls.oilFlowRate.setValidators(null);
      this.form.formGroup.controls.waterFlowRate.setValue(0, { emitEvent: false });
      this.form.formGroup.controls.oilFlowRate.setValue(0, { emitEvent: false });
      this.gasHidden = false;
      this.oilHidden = true;
      this.gasModelHidden = true;
      this.form.formGroup.controls.gasFlowRate.setValidators([Validators.required, Validators.min(this.gasFlowRateValidation.min), Validators.max(this.gasFlowRateValidation.max)]);
    } else {
      if (this.userUnits.hydrocarbonFlow == 'bbl/d') {
        this.oilFlowRateValidation = { min: 0.1, max: 1440000 };
        this.waterFlowRateValidation = { min: 0, max: 40000 };
      } else {
        this.oilFlowRateValidation = { min: 0.01, max: 228941 };
        this.waterFlowRateValidation = { min: 0, max: 6359 };
      }

      this.form.formGroup.controls.waterFlowRate.setValidators([Validators.required, Validators.min(this.waterFlowRateValidation.min), Validators.max(this.waterFlowRateValidation.max)]);

      if (this.oilGas[0] && !this.oilGas[1] && !this.co2FluidSelected) {
        this.form.formGroup.controls.gasFlowRate.setValidators(null);
        this.form.formGroup.controls.gasFlowRate.setValue(0, { emitEvent: false });
        this.form.formGroup.controls.gasFlowRate.markAllAsTouched();
        this.gasHidden = true;
      } else {
        this.gasHidden = false;
        this.form.formGroup.controls.gasFlowRate.setValidators([Validators.required, Validators.min(this.gasFlowRateValidation.min), Validators.max(this.gasFlowRateValidation.max)]);
      }

      if (!this.oilGas[0] && this.oilGas[1]) {
        this.form.formGroup.controls.oilFlowRate.setValidators(null);
        this.form.formGroup.controls.oilFlowRate.setValue(0, { emitEvent: false });
        this.oilHidden = true;
        this.form.formGroup.controls.gasFlowRate.setValidators([Validators.required, Validators.min(this.gasFlowRateValidation.min), Validators.max(this.gasFlowRateValidation.max)]);
        this.form.formGroup.controls.gasFlowRate.updateValueAndValidity();
      } else {
        this.oilHidden = false;
        this.form.formGroup.controls.oilFlowRate.setValidators([Validators.required, Validators.min(this.oilFlowRateValidation.min), Validators.max(this.oilFlowRateValidation.max)]);
        this.form.formGroup.controls.oilFlowRate.updateValueAndValidity();
      }
    }
  }

  onGorTypeChange(e) {
    this.selectedOption = e.value;
    switch (e.value) {
      case "gasGor": {
        this.gasRateDisabled = null;
        this.oilRateDisabled = true;
        this.gorEnabled = false;
        break;
      }
      case "oilGor": {
        this.gasRateDisabled = true;
        this.oilRateDisabled = null;
        this.gorEnabled = false;
        break;
      }
      default: {
        this.gasRateDisabled = null;
        this.oilRateDisabled = null;
        this.gorEnabled = true;
        break;
      }
    }
  }

  updateGor(updatedValue) {
    const gorValue = parseFloat((<HTMLInputElement>document.getElementById("gorValue")).value) ?? 0;
    const gasRate = +(gorValue * (this.form.formGroup.value.oilFlowRate ?? 0) / 1000000).toFixed(2);
    const oilRate = +((this.form.formGroup.value.gasFlowRate ?? 0) / gorValue * 1000000).toFixed(2);
    let gor = +((this.form.formGroup.value?.gasFlowRate ?? 0) * 1000000 / (this.form.formGroup.value?.oilFlowRate ?? 0)).toFixed(2);

    if (Number.isNaN(gor)) {
      gor = 0;
    }

    switch (updatedValue) {
      case "gas": {
        if (this.gorEnabled) {
          this.gor = gor;
          break;
        }
        this.form.formGroup.controls.oilFlowRate.setValue(oilRate);
        break;
      }
      case "oil": {
        if (this.gorEnabled) {
          this.gor = gor;
          break;
        }
        this.form.formGroup.controls.gasFlowRate.setValue(gasRate);
        break;
      }
      default: {
        if (this.selectedOption === 'oilGor') {
          this.form.formGroup.controls.gasFlowRate.setValue(gasRate);
        } else {
          this.form.formGroup.controls.oilFlowRate.setValue(oilRate);
        }
        this.gorEnabled = false;
        break;
      }
    }
  }

  private calculateGor(): void {
    this.gorEnabled = true;
    this.gor = +((this.form.formGroup.value?.gasFlowRate ?? 0) * 1000000 / (this.form.formGroup.value?.oilFlowRate ?? 0)).toFixed(2);

    if (Number.isNaN(this.gor)) {
      this.gor = 0;
    }
  }
}
