import { CustomConnectionModel } from "src/app/shared/models/custom-connection.model";
import { GradeReference } from "./grade-reference";
import { PipeReference } from "./pipe-reference";
import { NonApiPipeModel } from "src/app/shared/models/non-api-pipe.model";

export class Tubular {
  constructor(init?: Partial<Tubular>) {
    this.id = init && init.id || "";
    this.sequenceNumber = init && init.sequenceNumber || 0;
    this.name = init && init.name || "";
    this.bottomMeasuredDepth = init && init.bottomMeasuredDepth || 0;
    this.grade = init && init.grade ? new GradeReference(init.grade) : new GradeReference();
    this.pipe = init && init.pipe ? new PipeReference(init.pipe) : new PipeReference();
    this.connection = init && init.connection ? new CustomConnectionModel(init.connection) : null;
    this.nonApiPipe = init && init.nonApiPipe ? new NonApiPipeModel(init.nonApiPipe) : null;
  }
  id: string;
  sequenceNumber: number;
  name: string;
  topMeasuredDepth: number; // Not set in form, used in validation code
  bottomMeasuredDepth: number;
  grade: GradeReference;
  pipe: PipeReference;
  connection: CustomConnectionModel;
  nonApiPipe: NonApiPipeModel;
}
