import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin, lastValueFrom, Observable, Subscription, timer } from 'rxjs';
import { catchError, debounce, map } from 'rxjs/operators';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { ConnectionCatalog } from '../models/connection-catalog.model';
import { GradeCatalog } from '../models/grade-catalog.model';
import { PipesCatalog } from '../models/pipes-catalog.model';
import { ConnectionsService } from '../shared/services/connections.service';
import { GradesService } from '../shared/services/grades-catalog.service';
import { PipesService } from '../shared/services/pipes-catalog.service';
import { connectionOdSmallerThanPipeOd } from '../validation/connectionOdSmallerThanPipeOd';
import { innerSmallerThanOuterDiameter } from '../validation/innerSmallerThanOuterDiameter';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { saveAs } from 'file-saver';
import * as xlsx from 'xlsx';
import { UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { Utilities } from 'src/app/core/services/utilities';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';

@Component({
  selector: 'app-connections-catalog',
  templateUrl: './connections-catalog.component.html',
  styleUrls: ['./connections-catalog.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class ConnectionsCatalogComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  private _connections: Array<ConnectionCatalog>
  private _pipes: Array<PipesCatalog>;

  public columnDefs: Array<any>;
  public grades: Array<{ label: string, value: GradeCatalog}> = [];
  public pipeOds: Array<{ label: string, value: number}> = [];
  public isLoading: boolean;
  public diameterUnit: string;
  public weightUnit: string;
  public pressureUnit: string;
  public forceUnit: string;
  public connectionsForm: UntypedFormGroup;
  public tableHeight: string;
  public userRoles : UserRoles;
  public filterPipeOds: Array<{ label: string, value: number}> = [];

  get connectionsCatalog(): UntypedFormArray {
    return this.connectionsForm.get("connections") as UntypedFormArray;
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  // State
  @Input()
  public componentId: string;
  
  @ViewChild("catalogTable") catalogTable;

  constructor(
    private _messenger: MediatorService,
    private _pipesCatalogService: PipesService,
    private _connectionsCatalogService: ConnectionsService,
    private _gradesCatalogService: GradesService,
    private _formBuilder: UntypedFormBuilder,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService,
    private _store : StoreService,
    private _toaster: AppNotificationService
  ) {
    this.isLoading = true;

    this._subscriptions = new Subscription();

    this.connectionsForm = this._formBuilder.group({
      connections: this._formBuilder.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.userRoles = await this._store.get<UserRoles>(StorageKeys.ROLES);
    let uu = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.diameterUnit = uu.shortLengths;
    this.weightUnit = Units.lib[uu.linearDensity].symbol;
    this.pressureUnit = uu.pressure;
    this.forceUnit = Units.lib[uu.force].symbol;

    this.columnDefs = [
      { field: 'name', header: 'Name' },
      { field: 'pipeOd', header: `Pipe OD (${uu.shortLengths})` },
      { field: 'tubularPipeId', header: `Pipe (${uu.shortLengths}, ${this.weightUnit})` },
      { field: 'outerDiameter', header: `OD (${uu.shortLengths})` },
      { field: 'innerDiameter', header: `ID (${uu.shortLengths})` },
      { field: 'gradeId', header: 'Grade' },
      { field: 'burstRating', header: `Burst Rating (${uu.pressure})` },
      { field: 'collapseRating', header: `Collapse Rating * (${uu.pressure})` },
      { field: 'tensionRating', header: `Tension Rating (${this.forceUnit})` },
      { field: 'compressionRating', header: `Compression Rating (${this.forceUnit})` }
    ];

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE,  (data) => this.signalRfunc(data));
 
    this.getConnectionsData(false);

    addEventListener("keydown", (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }

  private signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_GRADES_CATALOG ||
        data.action == PeriforOnChangeMessages.REFRESH_PIPES_CATALOG) {
      this._connections = [];
      this.getConnectionsData(true);
    }
  }

  private getConnectionsData(isCalledFromSignalR: boolean) {
    const sources: Observable<any>[] = [
      this._pipesCatalogService.getPipes(),
      this._pipesCatalogService.getPipeOds(),
      this._gradesCatalogService.getGrades(),
      this._connectionsCatalogService.getConnections()
    ];

    forkJoin(sources).pipe(
      map(([pipes, pipeOds, grades, connections]) => {
        this._connections = connections;
        this._pipes = pipes.filter(p => p.discriminator == 'tubularPipe');
        this.pipeOds = pipeOds.map(x => ({ label: x.outsideDiameter.toString(), value: x.outsideDiameter }));
        this.filterPipeOds = [...this.pipeOds].filter(x => this._connections.some(c => c.tubularPipe.outsideDiameter == x.value));
        this.filterPipeOds.unshift({ label: 'View All', value: 0 });
        this.grades = grades.map(g => ({label: g.name, value: g}));

        if (!isCalledFromSignalR) {
          this.populateFormData(connections);
        }

        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe(message => {
      if(message.name == "Connections Catalog"){
        this.tableHeight = (message.itemHeight - 140) + 'px';
      }
    }));
   }

  public onAddConnection(idx?: number): void {
    if (this.connectionsCatalog.invalid) {
      return;
    }
    this.connectionsCatalog.insert(idx || 0, this.newConnection());
    this.catalogTable?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public onDeleteConnection(idx: number): void {
    let connectionId = this.connectionsCatalog.controls[idx].get("id").value;
    let connectionName = this.connectionsCatalog.controls[idx].get("name").value;
    if (connectionId.length < 36) {
      this.connectionsCatalog.removeAt(idx);
    } else {
      this._confirmationService.confirm({
        message: `Are you sure that you want to delete the selected "${connectionName}" connection?`,
        accept: async () => {
          if (connectionId.length > 24) { // it's been saved to the db, so delete through API
            await lastValueFrom(this._connectionsCatalogService.deleteConnection(connectionId));
          }
          this.connectionsCatalog.removeAt(idx);
        }
      });
    }
  }

  public showJson(data: any) {
    return JSON.stringify(data, null, '\t');
  }

  public getPlaceholderText(data: any, field: string, isPipeOdFilter?: boolean): string {
    let text = data && data.id ? `${data[field]} (local only)` : "Select";
    
    if (field == 'name') {
      let gradeExists = this.grades.find(x => x.value?.name == data?.name &&
        x.value?.minimumApiYieldStrength == data?.minimumApiYieldStrength && x.value?.youngsModulus == data?.youngsModulus && x.value?.poissonsRatio == data?.poissonsRatio &&
        x.value?.thermalExpansionCoefficient == data?.thermalExpansionCoefficient && x.value?.ultimateTensileStrength == data?.ultimateTensileStrength &&
        x.value?.density == data?.density && x.value?.specificHeatCapacity == data?.specificHeatCapacity && x.value?.temperatureDeration?.name == data?.temperatureDeration?.name);
      if (gradeExists) {
        text = this.grades.find(x => x.value?.name == data?.name).label;
      } else {
        text = data && data.id ? `${data[field]} (local only)` : "Select";
      }
    }
    
    if (field == 'outsideDiameter' && !isPipeOdFilter) {
      text = (data && data.id.length > 0) ? data && data.id && this.pipeOds.find(x => x.value == data.outsideDiameter) ? `${data[field]}` : `${data[field]} (local only)` : 'Select';
    }

    if (field == 'outsideDiameter' && isPipeOdFilter) {
      text = (data && data.id.length > 0) ? data && data.id && this.pipeOds.find(x => x.value == data.outsideDiameter) ? `${data[field]}` : `${data[field]}` : 'View All';
    }

    if (text == 'Select') {
      this.connectionsCatalog.clear();
      this.populateFormData(this._connections);
    }

    return text;
  }

  private async handleSaveConnectionRow(v: any, connectionRecord: UntypedFormGroup): Promise<void> {
    if (connectionRecord.valid && !this.isLoading) {
      let connectionName = v.name;
      let existingConnection = this._connections.find(c => c.name == connectionName && c.id != v.id);
      if (existingConnection) {
        this._toaster.showError(`Connection with name ${connectionName} already exists.`);
        return;
      }
      if (v.id.length == 24) { // it's only on the client so save to db as a new record
        var newEntityId = await lastValueFrom(this._connectionsCatalogService.addConnection(new ConnectionCatalog(v)));
        connectionRecord.get("id").patchValue(newEntityId, { emitEvent: false });
      } else {
        await lastValueFrom(this._connectionsCatalogService.updateConnection(new ConnectionCatalog(v)));
      }
    }
  }

  private newConnection(): UntypedFormGroup {
    let connectionFg = new UntypedFormGroup({
      // Setting a unique id here so that prime-ng p-table has a data key for the expand and collapse functionality.
      id: new UntypedFormControl(Utilities.generateFakeGuid()),
      name: new UntypedFormControl("", [Validators.required]),
      pipeOd: new UntypedFormControl(""),
      tubularPipe: new UntypedFormControl("", [Validators.required]),
      grade: new UntypedFormControl(this.grades[0].value || "", [Validators.required]),
      outerDiameter: new UntypedFormControl("", [Validators.required, connectionOdSmallerThanPipeOd]),
      innerDiameter: new UntypedFormControl("", [Validators.required, innerSmallerThanOuterDiameter]),
      burstRating: new UntypedFormControl("", [Validators.required]),
      collapseRating: new UntypedFormControl("", [Validators.required]),
      tensionRating: new UntypedFormControl("", [Validators.required]),
      compressionRating: new UntypedFormControl("", [Validators.required]),
      tubularPipeLookup: new UntypedFormControl()
    });

    // Debouncing this by 1 sec. to cut db calls.
    let debouncedValueChanges = connectionFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSaveConnectionRow(v, connectionFg)));

    this._subscriptions.add(connectionFg.controls.pipeOd.valueChanges.subscribe((v) => {
      this.onPipeOdChange(v, connectionFg)
    }));

    let pipeOd = connectionFg.get("pipeOd");
    pipeOd.patchValue(this.pipeOds[0].value, {emitEvent: false});

    pipeOd.valueChanges.subscribe((e) => {
      let pipeLookup = this.getPipesFilteredByOd(e);
      connectionFg.get("tubularPipeLookup").patchValue(pipeLookup);
    });

    let pipeLookup = this.getPipesFilteredByOd(this.pipeOds[0].value);
    connectionFg.get("tubularPipe").patchValue(pipeLookup[0].value, {emitEvent: false});
    connectionFg.get("tubularPipeLookup").patchValue(pipeLookup, {emitEvent: false});

    connectionFg.markAllAsTouched();

    return connectionFg;
  }

  public async onPipeOdChange(pipeOd: number, tubularFg: UntypedFormGroup): Promise<void> {
    if (pipeOd) {
      let pipeCtrl = tubularFg.get('tubularPipe') as UntypedFormControl;
      let pipe = this._pipes.find(p => p.outsideDiameter == pipeOd);
      pipeCtrl.patchValue(pipe, { emitEvent: false });

      let odCtrl = tubularFg.get('outerDiameter') as UntypedFormControl;
      odCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  private getPipesFilteredByOd(od: number) {
    let filteredPipes = this._pipes.filter(p => p.outsideDiameter == od);
    let weightUnit = this.weightUnit == 'lb/ft' ? "#" : this.weightUnit;
    let diameterUnit = this.diameterUnit == "cm" ? "cm" : '"';
    let pipeLookup = filteredPipes.map(p => {
      return {
        label: `${p.outsideDiameter}${diameterUnit}, ${p.weightPerFoot}${weightUnit}`,
        value: p
      };
    });
    return pipeLookup;
  }

  public getSortedPipeOptions(rowData: any, parameter: any) {
    let data = rowData.get('tubularPipeLookup').value;
    return data?.sort((a, b) => (a.value[parameter] < b.value[parameter] ? -1 : 1));
  }

  private populateFormData(connections?: Array<ConnectionCatalog>) {
    connections.forEach((data, idx) => {
      let connectionFg = this.newConnection();
      this.connectionsCatalog.push(connectionFg);
      let pipeOd = this._pipes.find(p => p.id == data.tubularPipe?.id)?.outsideDiameter || "";

      let pipeLookup = this.getPipesFilteredByOd(pipeOd as any);

      let expandedData = {
        ...data,
        tubularPipeLookup : pipeLookup,
        pipeOd: pipeOd
      };
      this.connectionsCatalog.controls[idx].patchValue(expandedData, { emitEvent: false });
    });
  }

  public exportExcel() {
    let mappedData = this.connectionsCatalog.controls.map(td => {
        let objWithHeaderKeys = {};
        this.columnDefs.forEach(cd => {
            let objHeaderKey = cd.header;
            let name = '';
            if (cd.field == 'tubularPipeId') {
              name = `${td.value.tubularPipe.outsideDiameter}` + ', ' + `${td.value.tubularPipe.weightPerFoot}`;
            } else if (cd.field == 'gradeId') {
              name = td.value.grade.name;
            } else {
              name = td.value[cd.field];
            }
            objWithHeaderKeys[objHeaderKey.replace(/["']/g, '\"')] = name;
        });
        return objWithHeaderKeys;
    });
    const worksheet = xlsx.utils.json_to_sheet(mappedData);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "Connections Catalog");
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);
  }

  public filterByOd(e) {
    this.connectionsCatalog.clear();
    let connections = e.value == 0 ? this._connections : this._connections.filter(c => c.tubularPipe.outsideDiameter == e.value);
    this.populateFormData(connections);
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
