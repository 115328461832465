import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { User } from '@auth0/auth0-angular';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { lastValueFrom } from 'rxjs';
import { Units } from 'src/app/core/services/unit-library';
import { OperationType, ShutInOperation } from 'src/app/perical/models/thermal-operation.model';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';

export interface ShutInOperationForm {
  type: OperationType;
  wellheadPressure: number;
  annulusIndex: number;
  pressureLocation: string;
}

@Component({
  selector: 'app-shut-in-operation-details',
  templateUrl: './shut-in-operation-details.component.html',
  styleUrls: ['./shut-in-operation-details.component.scss'],
  providers: subformComponentProviders(ShutInOperationDetailsComponent)
})
export class ShutInOperationDetailsComponent implements OnInit {

  constructor(private _trajectoryService: TrajectoryService) { }

  public isLoading: boolean;
  public pressureUnit: string;
  public pressureValidation: { min: number, max: number };

  @Input()
  public isReadOnly: boolean;

  @Input()
  public currentString: any;

  @Input()
  public user: User;

  @Input()
  public isAnnulus: boolean = false;

  public pressureLocations = [
    { name: 'Wellhead', value: 'wellhead' },
    { name: 'Perforations', value: 'perforation' }
  ];

  public form = createForm<ShutInOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      wellheadPressure: new UntypedFormControl(null),
      pressureLocation: new UntypedFormControl('wellhead', [Validators.required]),
      annulusIndex: new UntypedFormControl(null)
    }, toFormGroup(obj: ShutInOperation | null): ShutInOperationForm {
      if (!obj) {
        return null;
      }

      const { type, pressureLocation, wellheadPressure, ...commonValues } = obj;

      return {
        type: obj.type,
        wellheadPressure: obj.wellheadPressure,
        pressureLocation: obj.pressureLocation,
        annulusIndex: obj.annulusIndex,
        ...commonValues
      };
    }, fromFormGroup(formValue: ShutInOperationForm): ShutInOperation {
      return {
        type: OperationType.SHUTIN,
        wellheadPressure: formValue.wellheadPressure,
        pressureLocation: formValue.pressureLocation,
        annulusIndex: formValue.annulusIndex
      };
    }
  });

  async ngOnInit(): Promise<void> {
    this.pressureUnit = this.user.units.pressure;

    switch (this.pressureUnit) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 100000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 6894 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 689475 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 6804 };
        break;
    }
    this.form.formGroup.controls.wellheadPressure.setValidators([Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required]);

    let density = this.currentString.annularFluid.state.nominalDensity ?? GetValueFromPpg(8.33, Units.lib[this.user.units.density].symbol);

    let tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds(this.currentString.hangerMd));
    let hydrostaticHangerPressure = GetMudHydrostaticPressure(tvds[0], density, this.user.units)
    if (!this.form.formGroup.controls.wellheadPressure.value?.toString()) {
      this.form.formGroup.controls.wellheadPressure.patchValue(hydrostaticHangerPressure);
    }
    
  }
}
