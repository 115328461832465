import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { forkJoin, lastValueFrom, Observable, Subscription, timer } from "rxjs";
import { catchError, debounce, map } from "rxjs/operators";
import { FluidType } from "src/app/perical/models/fluid.model";
import { CustomConnectionModel } from "src/app/shared/models/custom-connection.model";
import { ChangeSelectedTubular } from "src/app/shared/models/mediator-messages.model";
import { MediatorService } from "src/app/shared/services/mediator.service";
import { PeriforOnChangeMessages, SignalRService } from "src/app/shared/services/signal-r.service";
import { TrajectoryService } from "src/app/shared/services/trajectory.service";
import { getControlErrors, isControlInvalid } from "src/app/shared/services/validation-helpers";
import { WellConfigService } from "src/app/shared/services/well-config.service";
import { WellTypeService } from "../../services/well-type-datums.service";
import { GradeReference, PipeReference, Tubular, WellComponent } from "./models";
import { greaterThanZero } from "./validation/greaterThanZero";
import { hangerAbovePreviousShoe } from "./validation/hangerAbovePreviousShoe";
import { holeSizeSmallerThanPrevsId } from "./validation/holeSizeSmallerThanPrevsId";
import { measuredDepthMustBeGreaterThanPrevious } from "./validation/measureDepthMustBeGreaterThanPrevious";
import { revalidateTubulars } from "./validation/revalidateTubulars";
import { shoeValidator } from "./validation/shoeValidator";
import { topOfCementMdRequired } from "./validation/topOfCementRequired";
import { getMinimumDiameterFittingWellbore, getWellboreOutsideString, stringSectionsMustFitInWellbore } from "./validation/tubularFitsInWellConfiguration";
import { TotalDepthResult } from "src/app/shared/models/trajectory.model";
import { UserUnitsModel } from "src/app/core/components/user-units/user-units.model";
import { Units } from "src/app/core/services/unit-library";
import { UserRoles } from "src/app/core/components/user-admin-page/user-model";
import { Utilities } from "src/app/core/services/utilities";
import { StorageKeys, StoreService } from "src/app/core/services/store.service";
import { GetShortLengthValueFromInches, GetValueFromPsi } from "src/app/perivis/shared/helpers/units.helper";
import { CementingLandingService } from "src/app/shared/services/cementing-landing.service";
import { FluidsService } from "src/app/perical/services/fluids.service";

@Component({
  selector: "app-well-configuration",
  templateUrl: "./well-configuration.component.html",
  styleUrls: ['./well-configuration.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class WellConfigurationComponent implements OnInit, OnDestroy {

  private _wellComponentsData: Array<WellComponent>;
  private _subscriptions: Subscription;
  private _pipeCatalog: Array<PipeReference>;
  private tubularStrings;
  private selectedRowIdx: number;
  private wellCmpFg: any;
  private tubularsBackup: any;
  private _nonApiPipeExpandedDict: Array<{ tubularIndex: number, stringSections: Array<{ stringSectionIndex: number, isExpanded: boolean }> }> = [];
  private _forceUnit: string;
  private _densityUnit: string;
  private _shortLengthUnit: string;
  private _pressureUnit: string;

  public totalDepth: TotalDepthResult;
  public grades: Array<{ label: string, value: GradeReference }> = [];
  public connections: Array<{ label: string, value: CustomConnectionModel }> = [];
  public pipeOds: Array<{ label: string, value: number }> = [];
  public wellConfigForm: UntypedFormGroup;
  public isLoading: boolean;
  public wellheadDepth: number;
  public mudlineDepth: number;
  public wellType: string;
  public drillFloorElevation: number;
  public selectedStringId: number;
  public fluids: Array<{label:string, value:string}>;
  public contextMenuItems: Array<MenuItem>;
  public tubularCols: Array<{ field: string, header: string }>;
  public cols: Array<{ field: string, header: string }>;
  public nonApiPipeInputs: Array<{ field: string, header: string }>;
  public highCollapsePipeLabel: string;
  public userRoles : UserRoles;
  public longLengthUnit: string;

  get wellComponents(): UntypedFormArray {
    return this.wellConfigForm.get("wellComponents") as UntypedFormArray;
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  public stringTypes = [
    { label: "Casing", value: "Casing" },
    { label: "Tieback", value: "Tieback" },
    { label: "Liner", value: "Liner" },
    { label: "Tubing", value: "Tubing" }
  ];

  // Used for component state
  @Input()
  public componentId: string;

  constructor(
    private _trajectoryService: TrajectoryService,
    private _wellConfigService: WellConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _confirmationService: ConfirmationService,
    private _wellTypeService: WellTypeService,
    private _signalRService: SignalRService,
    private _store: StoreService,
    private _messenger: MediatorService,
    private _cementingLandingService: CementingLandingService,
    private _fluidsService: FluidsService
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.wellConfigForm = this._formBuilder.group({
      wellComponents: this._formBuilder.array([]),
    });

    const units: UserUnitsModel = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this._forceUnit = Units.lib[units.force].symbol;
    this._densityUnit = Units.lib[units.linearDensity].symbol;
    this._shortLengthUnit = units.shortLengths;
    this.longLengthUnit = units.longLengths;
    this._pressureUnit = units.pressure;
    this.userRoles = await this._store.get<UserRoles>(StorageKeys.ROLES);

    this.contextMenuItems = [
      { label: 'Add String', icon: 'pi pi-plus', command: (e) => { this.onAddWellComponent() } },
      { label: 'Insert string above', icon: 'pi pi-arrow-up', command: (e) => { this.onAddWellComponent(this.selectedRowIdx) } },
      { label: 'Delete String', icon: 'pi pi-trash', command: (e) => { this.onDeleteWellComponent(this.selectedRowIdx, this.wellCmpFg) } },
    ];

    this.tubularCols = [
      { field: 'bottomMeasuredDepth', header: `Bottom (${this.longLengthUnit}) MD` },
      { field: 'outsideDiameter', header: `OD (${this._shortLengthUnit})` },
      { field: 'weightPerFoot', header: `WPF (${this._densityUnit})` },
      { field: 'insideDiameter', header: `ID (${this._shortLengthUnit})` },
      { field: 'wallThickness', header: `Wall (${this._shortLengthUnit})` },
      { field: 'grade', header: 'Grade' },
      { field: 'wallPerformance', header: 'Wall (%) / Performance' },
      { field: 'connection', header: 'Connection' }
    ];

    this.cols = [
      { field: 'type', header: 'Type' },
      { field: 'hangerMd', header: `Hanger (${this.longLengthUnit}) MD` },
      { field: 'shoeMd', header: `Shoe (${this.longLengthUnit}) MD` },
      { field: 'holeSize', header: `Hole Size (${this._shortLengthUnit})` },
      { field: 'topOfCementMd', header: `Has Cement / TOC (${this.longLengthUnit}) MD` },
      { field: 'annularFluid', header: 'Fluid' }
    ];

    this.nonApiPipeInputs = [
      { field: 'tension', header: `Tension (${this._forceUnit})` },
      { field: 'compression', header: `Compression (${this._forceUnit})` },
      { field: 'burst', header: `Burst (${this._pressureUnit})` },
      { field: 'collapse', header: `Collapse (${this._pressureUnit})` },
      { field: 'wallToleranceHoop', header: 'Wall % (Triax-Hoop)' },
      { field: 'wallToleranceTriaxialAxial', header: 'Wall % (Triax-Axial)' },
      { field: 'wallToleranceAxial', header: 'Wall % (Axial)' },
      { field: 'wallToleranceBurst', header: 'Wall % (Burst)' },
      { field: 'wallToleranceCollapse', header: 'Wall % (Collapse)' }
    ];

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getData(false);

    this.onWellConfigFormChanges();

    addEventListener("keydown", (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }

  async signalRfunc(data: any) : Promise<any> {
    if (data.action == PeriforOnChangeMessages.REFRESH_WELL_CONFIG ||
      data.action == PeriforOnChangeMessages.REFRESH_FLUIDS ||
      data.action == PeriforOnChangeMessages.REFRESH_TRAJECTORY) {
      this.getData(true);
    }

    if (data.action == PeriforOnChangeMessages.REFRESH_PIPES_CATALOG ||
      data.action == PeriforOnChangeMessages.REFRESH_FLUIDS) {
      const pipes = await lastValueFrom(this._wellConfigService.getPipeCatalog());
      this.processPipeData(pipes);
      this.wellComponents.controls.forEach((wellCmpFg: UntypedFormGroup) => { // Update all the pipe lookups
        this.setPipeSelectOptions(wellCmpFg);
      });
    }

    if (data.action == PeriforOnChangeMessages.REFRESH_GRADES_CATALOG || data.action == PeriforOnChangeMessages.REFRESH_CONNECTIONS_CATALOG) {
      this.getData(false);
    }

    if (data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      const sources: Observable<any>[] = [
        this._wellConfigService.getTubulars() as Observable<any>,
        this._wellConfigService.getSelectedTubularId() as Observable<any>
      ];

      forkJoin(sources).pipe(
        map(([tubulars, currentStringId]) => {
          this.setSelectedTubularId(tubulars, currentStringId);
        }),
        catchError(err => {
          return err;
        })).subscribe();
    }
  }

  getRowIndex(row: any, wellCmpFg: any) {
    this.selectedRowIdx = row;
    this.wellCmpFg = wellCmpFg;
  }

  getData(calledFromSignalR: boolean) {
    this.isLoading = true;

    const sources: Observable<any>[] = [
      this._wellConfigService.getConnectionsList() as Observable<any>,
      this._wellConfigService.getTubulars() as Observable<any>,
      this._wellConfigService.getPipeCatalog() as Observable<any>,
      this._wellConfigService.getGradeCatalog() as Observable<any>,
      this._wellConfigService.getFluidsList() as Observable<any>,
      this._trajectoryService.getTotalDepth() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([connections, tubulars, pipes, grades, fluids, totalDepth, wellType]) => {
        this.tubularStrings = tubulars;
        this.wellheadDepth = wellType.wellheadDepthFromDrillFloor;
        this.drillFloorElevation = wellType.drillFloorElevation;
        this.mudlineDepth = +(wellType.drillFloorElevation + wellType.waterDepth).toFixed(2);
        this.wellType = wellType.type;
        this.totalDepth = totalDepth;
        this.fluids = fluids
          .filter(f=> f.state.type == FluidType.BRINE || f.state.type == FluidType.STANDARDMUD || f.state.type == FluidType.ADVANCEDMUD)
          .sort((a, b) => a.state.name.localeCompare(b.state.name))
          .map(f => ({ label: f.state.name, value: f.id }));

        this.processGradeData(grades);
        this.processPipeData(pipes);
        this.processConnectionData(connections);

        this.tubularsBackup = [...tubulars];

        if (this.wellComponents.value.length > 0) {
          let tubularIndex = 0;
          this.wellComponents.value.forEach(tubular => {
            let ssIndex = 0;
            if (tubular.stringSections.find(x => x.isNonApiPipeExpanded)) {
              tubular.stringSections.forEach(stringSection => {
                this._nonApiPipeExpandedDict.push({ tubularIndex: tubularIndex, stringSections: [{ stringSectionIndex: ssIndex, isExpanded: stringSection.isNonApiPipeExpanded }] });
                ssIndex++;
              });
            }
            tubularIndex++;
          });
        }

        // if (!calledFromSignalR) {
          this.wellComponents.clear();
          this.processTubularData(tubulars);

          this._nonApiPipeExpandedDict.forEach(x => {
            this.wellComponents.controls[x.tubularIndex]['controls'].stringSections.controls[x.stringSections[0].stringSectionIndex]['controls']['isNonApiPipeExpanded'].setValue(x.stringSections[0].isExpanded, { emitEvent: false });
          });
        // }

        if (tubulars.length > 0) {
          this._wellConfigService.getSelectedTubularId().subscribe(curStr => {
            this.setSelectedTubularId(tubulars, curStr);
          });
        } else {
          this.selectedStringId = null;
        }

        if (calledFromSignalR) {
          this.wellConfigForm.controls.wellComponents['controls'].forEach(element => {
            element.controls.shoeMd.setValidators(Validators.max(this.totalDepth.measuredDepth));
          });
        }
        this.isLoading = false;
      }),
      catchError(err => {
        return err;
      })).subscribe();
  }

  stringSectionsExpand(expanded: boolean, index: number) {
    if (!expanded) {
      this._messenger.publish(new ChangeSelectedTubular(this.tubularStrings[index]));

      this.wellCmpFg.controls.stringSections.controls.forEach(element => {
        element.get("isNonApiPipeExpanded").setValue(false, { emitEvent: false });
      });
    }
  }

  public onAddTubular(wellCmp: UntypedFormGroup, tIdx: number, isBelow?: boolean): void {
    const lowerIndex = isBelow ? 1 : 0;

    let newTubular = this.newTubular();

    //Set default values for pipe on the tubular added based on the current pipe.
    let stringSection = wellCmp.get('stringSections')?.value[tIdx] as Tubular;

    this.onPipeIdChange(stringSection.pipe.id, newTubular);
    newTubular.get("grade").patchValue(stringSection.grade);
    newTubular.get("connection").patchValue(stringSection.connection);

    const index = tIdx + lowerIndex;
    newTubular.patchValue({sequenceNumber: index});

    this.getWellComponentTubulars(wellCmp).insert(index || 0, newTubular);
    this.onTubularInteractions(wellCmp, true);
  }

  public onDeleteTubular(wellCmp: UntypedFormGroup, tubularIdx: number): void {
    this.getWellComponentTubulars(wellCmp).removeAt(tubularIdx);
  }

  public onAddWellComponent(idx?: number): void {
    if (this.wellComponents.invalid) {
      return;
    }
    this.wellComponents.markAllAsTouched();
    const index = idx >= 0 ? idx : this.wellComponents.length;
    let fg = this.newWellComponent([this.newTubular()]);
    fg.get("sequenceNumber")?.setValue(index, { emitEvent: false})
    this.wellComponents.insert(index, fg);
  }

  public onDeleteWellComponent(wellCmpIdx: number, wellCmpFg: UntypedFormGroup): void {
    let tubularId = wellCmpFg.get("id").value;
    if (tubularId.length < 36) {
      this.wellComponents.removeAt(wellCmpIdx);
    } else {
      this._confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected string?',
        accept: async () => {
          await lastValueFrom(this._wellConfigService.deleteTubular(tubularId));
          this.wellComponents.removeAt(wellCmpIdx);
          const outermostStringId = this.wellComponents.controls.length > 0 ? this.wellComponents.controls[0].get("id").value : null;
          this._messenger.publish(new ChangeSelectedTubular(new Tubular({id: outermostStringId})));
        }
      });
    }
  }

  public onHasCementChange(hasCement: boolean, wellCmpFg: UntypedFormGroup): void {
    let topCementCtrl = wellCmpFg.get("topOfCementMd");
    if (hasCement) {
      let toc = topCementCtrl?.value ?? -1 >= 0 ? topCementCtrl.value : this.wellheadDepth;
      topCementCtrl.patchValue(toc, { emitEvent: false }); //Handles reloading right value
      topCementCtrl.enable({ emitEvent: false });
    } else {
      topCementCtrl.disable({ emitEvent: false });
      topCementCtrl.patchValue(null, { emitEvent: false });
    }
  }

  public onPipeIdChange(pipeId: string, tubularFg: UntypedFormGroup): void {
    let pipeCtrl = tubularFg.get('pipe') as UntypedFormControl;
    pipeCtrl.get("id").patchValue(pipeId, { emitEvent: false });

    let pipe = this._pipeCatalog ? this._pipeCatalog.find(p => p.id == pipeId) : [];

    if (pipe) {
      pipeCtrl.patchValue(pipe, { emitEvent: false });
      pipeCtrl.updateValueAndValidity({ emitEvent: false });
    }
  }

  public async onPipeOdChange(pipeOd: number, tubularFg: UntypedFormGroup): Promise<void> {
    if (pipeOd) {
      let pipeCtrl = tubularFg.get('pipe') as UntypedFormControl;
      let pipe = this._pipeCatalog.find(p => p.outsideDiameter == pipeOd);
      pipeCtrl.patchValue(pipe, { emitEvent: false });
    }
  }

  public showJson(data: any) {
    return JSON.stringify(data, null, '\t');
  }

  public onTubularInteractions(wellCmpFg: UntypedFormGroup, isAdd: boolean): void {
    // Ensure bottom tubular btmDepthCtrl is disabled and set to sections Shoe Depth.
    let tubulars = this.getWellComponentTubulars(wellCmpFg);
    let lastTubular = tubulars.controls[tubulars.length - 1] as UntypedFormGroup;
    let depth = wellCmpFg.controls['shoeMd'].value;
    let btmDepthCtrl = lastTubular.get('bottomMeasuredDepth');
    btmDepthCtrl.setValue(depth, { emitEvent: false });
    let hangerMd = wellCmpFg.get("hangerMd").value;
    let topOfCementCtrl = wellCmpFg.controls["topOfCementMd"];

    if (isAdd) {
      let secondTubular = tubulars.controls[tubulars.length - 2] as UntypedFormGroup;
      let btmDepthCtrl1 = secondTubular.get('bottomMeasuredDepth');
      btmDepthCtrl1.setValue(depth - 10, { emitEvent: false });
      btmDepthCtrl1.enable({ emitEvent: false });
    } else {
      btmDepthCtrl.disable({ emitEvent: false })
    }

    if (hangerMd > topOfCementCtrl.value) {
      topOfCementCtrl.setValue(hangerMd, { emitEvent: false });
    }

    // This should not need to happen, but UI isn't updating validation without this
    topOfCementCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  public onWellConfigFormChanges(): void {
    this._subscriptions.add(this.wellComponents.valueChanges.subscribe(val => {
      this.wellConfigForm.markAllAsTouched();
      this.wellConfigForm.updateValueAndValidity({ emitEvent: false });
    }));
  }

  public onWellComponentTypeChange(wellCmpFg: UntypedFormGroup): void {
    // If Well Component is Casing, it should always have a disabled HangerMd with a starting value at the wellhead
    if (this.wellComponents && this.wellComponents.length > 0) {
      // Casing
      let isCasing = wellCmpFg.get('type').value == "Casing";
      let hangerCtrl = wellCmpFg.get("hangerMd") as UntypedFormGroup;
      isCasing ? hangerCtrl.disable({ emitEvent: false }) : hangerCtrl.enable({ emitEvent: false });
      // Tubing - handle current well tubular
      let isTubing = wellCmpFg.get("type").value == "Tubing";
      let isTieback = wellCmpFg.get("type").value == "Tieback";
      let holeSizeCtrl = wellCmpFg.get("holeSize") as UntypedFormGroup;
      let hasCementCtrl = wellCmpFg.get("hasCement") as UntypedFormGroup;

      if (isTubing || isTieback) {
        holeSizeCtrl.disable({ emitEvent: false });
        holeSizeCtrl.patchValue(null, { emitEvent: false });
      } else {
        holeSizeCtrl.enable({ emitEvent: false });
        if (!holeSizeCtrl.value) {
          const x: any = 0;
          holeSizeCtrl.patchValue(x, { emitEvent: false });
        }
      }

      if (isTieback) {
        let linerHangers = this.wellComponents.value.filter(wc => wc.type == "Liner").map(wc => wc.hangerMd);
        let shoeCtrl = wellCmpFg.get("shoeMd") as UntypedFormGroup;
        shoeCtrl.setValue(linerHangers[linerHangers.length - 1], { emitEvent: false });
      }

      isTubing || isCasing || isTieback ? hangerCtrl.disable({ emitEvent: false }) : hangerCtrl.enable({ emitEvent: false });
      hangerCtrl.setValue(isTubing || isCasing || isTieback ? this.wellheadDepth : hangerCtrl.value, { emitEvent: false });
      hasCementCtrl.setValue((isTubing || isTieback) && (!wellCmpFg.controls.hasCement.value || wellCmpFg.status === "INVALID") ? false : hasCementCtrl.value, { emitEvent: false });
      this.onHasCementChange(hasCementCtrl.value, wellCmpFg);
    }
  }

  public enableHighCollapsePipeRatings(rowData, type, grade, ratingType): void {
    if(rowData.value.nonApiPipe[type] == false || grade.value.strengthCalculationOption != "NonApi") {
      rowData.get('nonApiPipe').get(ratingType).disable({ emitEvent: false });
    } else {
      rowData.get('nonApiPipe').get(ratingType).enable({ emitEvent: false });
    }
  }

  public onWellComponentChange(wellCmpFg: UntypedFormGroup): void {
    wellCmpFg.markAllAsTouched();
    wellCmpFg.updateValueAndValidity({ emitEvent: false });

    this.onTubularInteractions(wellCmpFg, false);

    this.setPipeSelectOptions(wellCmpFg);

    if (wellCmpFg.valid && !this.isLoading) {
      this.saveUpdateWellComponent(wellCmpFg);
    }
  }

  public getWellComponentTubulars(wellCmp: UntypedFormGroup): UntypedFormArray {
    return wellCmp.get("stringSections") as UntypedFormArray;
  }

  public getNonApiPipeData(wellCmp: UntypedFormGroup, tIdx: number): UntypedFormArray {
    let stringSections = wellCmp.get("stringSections") as UntypedFormArray;
    return stringSections.controls[tIdx].get("nonApiPipe") as UntypedFormArray;
  }

  public expandNonApiPipeProperties(wellCmpFg: UntypedFormGroup, tIdx: number): void {
    let isNonApiPipeExpanded = ((this.wellComponents.controls[this.wellComponents.controls.indexOf(wellCmpFg)] as UntypedFormGroup).get("stringSections") as UntypedFormArray)
      .controls[tIdx].get("isNonApiPipeExpanded").value;

    ((this.wellComponents.controls[this.wellComponents.controls.indexOf(wellCmpFg)] as UntypedFormGroup).get("stringSections") as UntypedFormArray)
    .controls[tIdx].get("isNonApiPipeExpanded").setValue(!isNonApiPipeExpanded, { emitEvent: false });
  }

  public checkIfNonApiPipeDataExpanded(wellCmp: UntypedFormGroup, tIdx: number): boolean {
    let stringSections = wellCmp.get("stringSections") as UntypedFormArray;
    return stringSections.controls[tIdx].get("isNonApiPipeExpanded").value;
  }

  public saveUpdateWellComponent(wellCmpFg: UntypedFormGroup): void {
    let wellCmpId = wellCmpFg.value.id;
    let wellCmpToSave = new WellComponent(wellCmpFg.getRawValue());
    wellCmpToSave.stringSections.forEach((tubular: Tubular) => {
      if (tubular.grade.strengthCalculationOption == "Api") {
        tubular.nonApiPipe.tensionEnabled = false;
        tubular.nonApiPipe.compressionEnabled = false;
        tubular.nonApiPipe.burstEnabled = false;
        tubular.nonApiPipe.collapseEnabled = false;
      }
    });
    if (wellCmpId.length == 24) { // Item created on the client, API
      this._wellConfigService.createNewTubular(wellCmpToSave).subscribe(res => {
        wellCmpFg.controls["id"].patchValue(res, { emitEvent: false });
        this._messenger.publish(new ChangeSelectedTubular(new Tubular({id: res})));
        // console.log(`Well Config: item added ${res}`);
      });
    }
    if (wellCmpId.length > 24) { // Item exists in the DB, API Put
      this._wellConfigService.editTubular(wellCmpToSave).subscribe(() => {
        // console.log(`Well Config: item updated!`);
      });
    }
  }

  public getPlaceholderText(data: any, field: string, depth?: any, column?: string, connectionReset?: any) {
    const isFilter = ((data?.outerDiameter > 0) || false) || ((data?.outsideDiameter > 0) || false);

    let name = data && data.id ? isFilter ? `${data[field]}` : `${data[field]} (local only)` : "Select";

    if (column == 'pipe') {
      name = (data && data?.id?.length > 0) ? data && data?.id && this.pipeOds.find(x => x.value == data.outsideDiameter) ? `${data[field]}` : `${data[field]} (local only)` : 'Select';

      // Temp patch to fix pipe name issue when local only
      if (name.startsWith('null')) {
        this.wellComponents.clear();
        this.getData(false);
      }
    }

    if (column == 'grade') {
      let gradeExists = this.grades.find(x => x.value?.name == data?.name &&
        x.value?.minimumApiYieldStrength == data?.minimumApiYieldStrength && x.value?.youngsModulus == data?.youngsModulus && x.value?.poissonsRatio == data?.poissonsRatio &&
        x.value?.thermalExpansionCoefficient == data?.thermalExpansionCoefficient && x.value?.ultimateTensileStrength == data?.ultimateTensileStrength &&
        x.value?.density == data?.density && x.value?.specificHeatCapacity == data?.specificHeatCapacity && x.value?.temperatureDeration?.name == data?.temperatureDeration?.name);
      if (gradeExists) {
        name = this.grades.find(x => x.value?.name == data?.name).label;
      } else {
        name = this.getLocalOnlyName(depth, column);
      }
    }

    if (column == 'connection') {
      let connectionsExists = this.connections.find(x => x.value?.name === data?.name && x.value?.id === data?.id && x.value?.burstRating === data?.burstRating &&
        x.value?.collapseRating === data?.collapseRating && x.value?.tensionRating === data?.tensionRating && x.value?.compressionRating === data?.compressionRating &&
        x.value?.grade.name == data?.grade.name);

      if (data && data.label != 'No Connection' && connectionsExists) {
        name = this.connections.find(x => x.value?.name === data?.name).label;
      } else {
        name = this.getLocalOnlyName(depth, column);
      }
      // else if (connReset) {
      //   name = 'No Connection';
      // }
    }

    return name;
  }

  public resetConnection(depth: any) {
    let pipe = null;

    this.tubularsBackup.forEach(element => {
      element.stringSections.forEach(tubular => {
        if (tubular.bottomMeasuredDepth == depth?.value) {
          pipe = tubular;
          let wellCmpIndex = this.tubularsBackup.findIndex(x => x.id == element.id);
          let stringSectionIndex = this.tubularsBackup[wellCmpIndex].stringSections.findIndex(x => x.bottomMeasuredDepth == depth?.value);

          this.wellComponents.controls[wellCmpIndex]['controls'].stringSections.controls[stringSectionIndex]['controls']['connection'].setValue(null);
          this.wellComponents.controls[wellCmpIndex]['controls'].stringSections.controls[stringSectionIndex]['controls']['connection'].clearValidators();
          this.wellComponents.controls[wellCmpIndex]['controls'].stringSections.controls[stringSectionIndex]['controls']['connection'].updateValueAndValidity();
        }
      });
    });

    this.wellComponents.clear();
    this.getData(false);
  }

  private getLocalOnlyName(depth: any, column: string) {
    let pipe = null;

    this.tubularsBackup.forEach(element => {
      element.stringSections.forEach(tubular => {
        if (tubular.bottomMeasuredDepth == depth?.value) {
          pipe = tubular;
          let wellCmpIndex = this.tubularsBackup.findIndex(x => x.id == element.id);
          let stringSectionIndex = this.tubularsBackup[wellCmpIndex].stringSections.findIndex(x => x.bottomMeasuredDepth == depth?.value);

          this.wellComponents.controls[wellCmpIndex]['controls'].stringSections.controls[stringSectionIndex]['controls'][column].setValue(tubular[column], { emitEvent: false });
          this.wellComponents.controls[wellCmpIndex]['controls'].stringSections.controls[stringSectionIndex]['controls'][column].clearValidators();
          // this.wellComponents.controls[wellCmpIndex]['controls'].stringSections.controls[stringSectionIndex]['controls'][column].updateValueAndValidity();
        }
      });
    });

    let name = pipe && pipe[column]?.name ? `${pipe[column]?.name} (local only)` : "Select";
    if (column == 'connection' && name == 'Select') {
      name = 'No Connection';
    }
    return name;
  }

  public checkStrengthCalculation(data: any): boolean {
    let strengthCalculationOption = data?.value?.strengthCalculationOption == "Api" || !data?.value?.strengthCalculationOption ? true : null;
    this.highCollapsePipeLabel = strengthCalculationOption ? 'API' : 'Non-API';
    return !strengthCalculationOption;
  }

  public getStringSectionsTextHintHover(wellCmpFg: UntypedFormGroup): string {
    let stringSections = wellCmpFg.get("stringSections").value as Array<Tubular>;
    let unit = this._shortLengthUnit == 'in' ? '"' : this._shortLengthUnit;
    let textArray = stringSections.map(x => `${x.pipe.outsideDiameter}${unit}`)
    return textArray.join(" x ");
  }

  public getStringSectionsTextHint(wellCmpFg: UntypedFormGroup): string {
    let stringSections = wellCmpFg.get("stringSections").value as Array<Tubular>;
    let unit = this._shortLengthUnit == 'in' ? '"' : this._shortLengthUnit;
    return stringSections[0].pipe.outsideDiameter.toString() + `${unit}`;
  }

  // Private helper methods
  private getPipeCatalogForCtrl(tubularFg: UntypedFormGroup): Array<PipeReference> {
    let pipe = tubularFg.get("pipe").value as PipeReference;
    return this._pipeCatalog ? this._pipeCatalog.filter(p => p.outsideDiameter == pipe.outsideDiameter) : [];
  }

  public getSortedPipeOptions(rowData: any, parameter: any) {
    let data = rowData.get('pipeOptions').value;
    return data?.sort((a, b) => (a[parameter] > b[parameter] ? -1 : 1));
  }

  private getPipeOdsForCtrl(wellCmpFg: UntypedFormGroup, stringSectionFg: UntypedFormGroup): Array<{ label: string, value: number }> {
    let wellConfiguration = wellCmpFg?.parent?.getRawValue(); //Using RawValue so we can get the disabled inputs.
    let tubularString = wellCmpFg?.getRawValue();
    let stringSection = stringSectionFg?.getRawValue();
    if (wellConfiguration && tubularString && stringSection) {
      let wellbore = getWellboreOutsideString(tubularString, wellConfiguration);
      let minimumOd = getMinimumDiameterFittingWellbore(tubularString, stringSection, wellbore.wellboreSections);
      return this.pipeOds.filter(kv => kv.value <= minimumOd);
    }
    return this.pipeOds;
  }

  private getConnectionsForCtrl(stringSectionFg: UntypedFormGroup): Array<{ label: string, value: CustomConnectionModel }> {
    let stringSection = stringSectionFg?.getRawValue();
    if (stringSection && stringSection.pipe) {
      return this.connections.filter(kv => kv.value == null || kv.value.tubularPipe.id == stringSection.pipe.id);
    }
    return this.connections;
  }

  private setPipeSelectOptions(wellCmp: UntypedFormGroup) {
    (wellCmp.get('stringSections') as UntypedFormArray).controls.forEach((ss: UntypedFormGroup) => {
      ss.get('odOptions').patchValue(this.getPipeOdsForCtrl(wellCmp, ss), { emitEvent: false });
      ss.get('pipeOptions').patchValue(this.getPipeCatalogForCtrl(ss), { emitEvent: false });
      ss.get('connectionOptions').patchValue(this.getConnectionsForCtrl(ss), { emitEvent: false });
    });
  }

  private setSelectedTubularId(tubulars: Array<WellComponent>, curStr: any) {
    this.selectedStringId = tubulars.findIndex(x => x.id === curStr.id);
  }

  private processPipeData(pipeData: Array<PipeReference>) {
    this._pipeCatalog = pipeData.filter(x => x.discriminator == 'tubularPipe');
    let distinctOds = [...new Set(this._pipeCatalog?.map(p => p.outsideDiameter))];
    this.pipeOds = distinctOds?.map(od => ({ label: od.toString(), value: od }));
  }

  private processGradeData(grades: Array<GradeReference>) {
    this.grades = grades?.map(g => ({ label: g.name?.toString(), value: g }));
  }

  private processConnectionData(connections: Array<CustomConnectionModel>): void {
    this.connections = connections?.map(c => ({ label: c.name?.toString(), value: c }));
    // this.connections.unshift({ label: "No Connection", value: null });
  }

  private processTubularData(tubulars: Array<WellComponent>): void {
    this._wellComponentsData = tubulars;
    this.populateFormData();
  }

  private newWellComponent(tubularFgs: Array<UntypedFormGroup>): UntypedFormGroup {

    let defaultToc = this.wellComponents.length < 1 && this.mudlineDepth ? this.mudlineDepth : this.wellheadDepth;
    if (this.wellType === 'Land') {
      defaultToc = this.wellComponents.length < 1 ? this.drillFloorElevation : this.wellheadDepth;
    }

    const maxHoleSize = GetShortLengthValueFromInches(42, this._shortLengthUnit);
    let defaultFluid = this.fluids.find(f => f.label == "Freshwater")?.value ?? "";

    let wellComponent = new UntypedFormGroup({
      id: new UntypedFormControl(Utilities.generateFakeGuid()), // Setting a unique id here so that prime-ng p-table has a data key for the expand and collapse functionality.
      sequenceNumber: new UntypedFormControl(0),
      type: new UntypedFormControl("Casing"),
      hangerMd: new UntypedFormControl(this.wellheadDepth, [Validators.required, hangerAbovePreviousShoe]),
      shoeMd: new UntypedFormControl("", [Validators.required, shoeValidator, Validators.max(this.totalDepth.measuredDepth)]),
      holeSize: new UntypedFormControl("", [Validators.required, holeSizeSmallerThanPrevsId, Validators.max(maxHoleSize)]),
      hasCement: new UntypedFormControl(true),
      topOfCementMd: new UntypedFormControl(defaultToc, [Validators.required, Validators.min(defaultToc), topOfCementMdRequired]),
      annularFluidId: new UntypedFormControl(defaultFluid, [Validators.required]),
      stringSections: new UntypedFormArray(tubularFgs, { validators: [measuredDepthMustBeGreaterThanPrevious] })
    }, [revalidateTubulars, stringSectionsMustFitInWellbore]);

    // Events
    this._subscriptions.add(wellComponent.get('type').valueChanges.subscribe((v) => this.onWellComponentTypeChange(wellComponent)));
    this._subscriptions.add(wellComponent.get('hasCement').valueChanges.subscribe((v) => this.onHasCementChange(v, wellComponent)));
    wellComponent.get('hangerMd').disable({ emitEvent: false });

    // Debouncing this by 1 sec. to cut db calls.
    let debouncedWellComponetValueChanges = wellComponent.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedWellComponetValueChanges.subscribe((v) => this.onWellComponentChange(wellComponent)));

    return wellComponent;
  }

  private newTubular() {
    let tubular = new UntypedFormGroup({
      bottomMeasuredDepth: new UntypedFormControl("", [Validators.required, greaterThanZero]),
      pipe: new UntypedFormGroup({
        id: new UntypedFormControl(""),
        outsideDiameter: new UntypedFormControl("", [Validators.required]),
        insideDiameter: new UntypedFormControl("", [Validators.required]),
        weightPerFoot: new UntypedFormControl("", [Validators.required]),
        wallThickness: new UntypedFormControl("", [Validators.required]),
        driftDiameter: new UntypedFormControl(""),
        discriminator: new UntypedFormControl("")
      }),
      grade: new UntypedFormControl("", [Validators.required]),
      connection: new UntypedFormControl(),
      odOptions: new UntypedFormControl(),
      pipeOptions: new UntypedFormControl(),
      connectionOptions: new UntypedFormControl(),
      isNonApiPipeExpanded: new UntypedFormControl(false),
      nonApiPipe: new UntypedFormGroup({
        tension: new UntypedFormControl(0),
        compression: new UntypedFormControl(0),
        burst: new UntypedFormControl(GetValueFromPsi(12.5, this._pressureUnit)),
        collapse: new UntypedFormControl(0),
        wallToleranceHoop: new UntypedFormControl(0),
        wallToleranceTriaxialAxial: new UntypedFormControl(0),
        wallToleranceAxial: new UntypedFormControl(0),
        wallToleranceBurst: new UntypedFormControl(12.5),
        wallToleranceCollapse: new UntypedFormControl(0),
        tensionEnabled: new UntypedFormControl(false),
        compressionEnabled: new UntypedFormControl(false),
        burstEnabled: new UntypedFormControl(false),
        collapseEnabled: new UntypedFormControl(false),
      })
    });

    // Events
    // OD is a special case (this call cauese the wellComponent value change to fire before this change handler).
    // OnPipeOdChange fires next, and set the correct pipe size. Having wellComponent debounced by 1 sec. keeps two events from firing.
    this._subscriptions.add(tubular.get('pipe').get('outsideDiameter').valueChanges.subscribe((v) => {
      this.onPipeOdChange(v, tubular)
    }));

    //This is done to sync the pipe dropdown select controls: https://github.com/angular/angular/issues/10036
    this._subscriptions.add(tubular.get('pipe').get('id').valueChanges.subscribe((v) => this.onPipeIdChange(v, tubular)));

    tubular.markAllAsTouched();

    return tubular;
  }

  public onAnnularFluidChange(wellCmpFg: UntypedFormGroup): void {
    if (wellCmpFg.valid) {
      let updatedAnnularFluidDensity = wellCmpFg.get('annularFluidId').value;
      let tubularId = wellCmpFg.get('id').value;
      let previousFluidName = this.tubularStrings.find(x => x.id == tubularId).annularFluid.state.name;
      this._fluidsService.getFluidById(updatedAnnularFluidDensity).subscribe(fluid => {
        let fluidDensity = fluid.state['nominalDensity'];
        if (previousFluidName == 'Freshwater') {
          this.changeDisplacementFluidDensity(fluidDensity, tubularId);
        } else {
          this._confirmationService.confirm({
            message: 'Do you want to update Displacement Fluid density in Cementing & Landing?',
            accept: async () => {
              this.changeDisplacementFluidDensity(fluidDensity, tubularId);
            }
          });
        }
      });
    }
  }

  private changeDisplacementFluidDensity(newFluidDensity: number, tubularId: string): void {
    this._cementingLandingService.getCementingLanding(tubularId).subscribe(async cementingLanding => {
      cementingLanding.displacementFluidDensity = newFluidDensity;
      this._cementingLandingService.setCementingLanding(cementingLanding, tubularId).subscribe();
    });
  }

  private populateFormData() {
    this._wellComponentsData.forEach((data, idx) => {
      let tubulars = data.stringSections.map(() => this.newTubular());
      let wellCmp = this.newWellComponent(tubulars);
      this.wellComponents.push(wellCmp);
      this.wellComponents.controls[idx].patchValue(data, { emitEvent: false });
      this.setPipeSelectOptions(wellCmp);
      this.onWellComponentTypeChange(wellCmp);// This ensures all form controls are greyed out etc on load
      this.onTubularInteractions(wellCmp, false); //This ensures btm depth is greyed out
    });
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
