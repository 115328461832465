import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { TotalDepthResult } from 'src/app/shared/models/trajectory.model';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';

@Component({
  selector: 'app-interpolation-tool',
  template: `
    <div *ngIf="!isLoading">
      <span class="col-12" >
        <label>Total Depth: {{ totalDepthLabel }}</label>
      </span>
      <br>
      <br>
      <label>MD: &nbsp;</label>
      <p-inputNumber
        min="0"
        [max]="totalDepth?.measuredDepth"
        placeholder="0"
        [minFractionDigits]="0"
        [maxFractionDigits]="4"
        [(ngModel)]="interpDepth"
        (onBlur)="convertToTvd()"
        (keydown.enter)="$event.target.blur()"
        (onFocus)="$event.target.select()">
      </p-inputNumber>

      <label>&nbsp; => &nbsp;</label>
      <label>TVD: &nbsp;</label>
      <p-inputNumber
        placeholder="0"
        [minFractionDigits]="1"
        disabled="true"
        [(ngModel)]="depthTvd"
      ></p-inputNumber>

      <button icon="pi pi-copy" title="Copy TVD" pButton (click)="copyTvd()" value="click to copy" ></button>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-inputnumber-input {
    width: 75px;
    height: 25px;
    font-size: .85em;
    text-align: right;
    color: white;
  }

  label {
    font-size: smaller;
    height: 20px;
    margin-bottom: 0px;
  }

  button {
    background-color: transparent;
    border: transparent;
    height: 25px;
    margin-bottom: 0px;
    width: 20px !important;
    margin-right: 0px;
  }

  input {
    font-size: .85em;
    height: 25px;
    width: 65px;
  }
  `]
})
export class InterpolationToolComponent implements OnInit {
  public depthTvd: number;
  public totalDepth: TotalDepthResult;
  public interpDepth: number;
  public depthUnit: string;
  public isLoading: boolean = true;

  public get totalDepthLabel(): string {
    return `${(Math.trunc(this.totalDepth.measuredDepth * 100) / 100).toLocaleString('en-US')} ft MD`;
  }

  //Used for state management
  @Input()
  private componentId: string;

  constructor(
    private _trajectoryService: TrajectoryService,
    private _storeService: StoreService
  ) {
    this.interpDepth = 0;
    this.depthTvd = 0;
  }

  async ngOnInit(): Promise<void> {
    this.totalDepth = await lastValueFrom(this._trajectoryService.getTotalDepth());
    if (this.totalDepth) {
      this.isLoading = false;
    }
    this.depthUnit = (await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS)).longLengths;
  }

  public async convertToTvd(): Promise<void> {
    let tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([this.interpDepth], true));
    this.depthTvd = (Math.trunc(tvds[0] * 100) / 100);
  }

  public copyTvd(): void {
    navigator.clipboard.writeText(this.depthTvd.toFixed(3))
  }
  
}
