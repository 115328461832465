import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { validateCustomProfile } from 'src/app/perivis/shared/validators/custom-profile.validator';
import { CustomEPP, ExternalPressureProfileType, PressurePoint } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

interface CustomEppForm {
  type: ExternalPressureProfileType.CUSTOMEPP;
  pressurePoints: PressurePoint[];
}

@Component({
  selector: 'app-custom-epp',
  templateUrl: './custom-epp.component.html',
  styleUrls: ['./custom-epp.component.scss'],
  providers: subformComponentProviders(CustomEppComponent)
})
export class CustomEppComponent implements AfterViewInit, OnChanges {

  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;

  public lastPointAboveShoe: boolean;
  public customPressures: Array<PressurePoint>;

  public form = createForm<CustomEPP, CustomEppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.CUSTOMEPP),
      pressurePoints: new UntypedFormControl(null)
    }
  });

  public get columnDefinitions(): Array<{ field: string, header: string }> {
    return [
      { header: 'MD (' + this.userUnits.longLengths + ')', field: 'measuredDepth' },
      { header: 'Pressure (' + this.userUnits.pressure + ')', field: 'pressure' }
    ];
  }

  public inputFields = [{
    name: 'measuredDepth',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  },
  {
    name: 'pressure',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  }
  ];

  constructor() { }

  ngAfterViewInit(): void {
    this.form.formGroup.controls.pressurePoints.addValidators(validateCustomProfile(this.currentString, this.wellTd));

    if (this.form.formGroup.controls.pressurePoints?.value) {
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    } else {
      this.form.formGroup.controls.pressurePoints.setValue(this.createInitialCustomPressurePoints());
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    }
  }

  ngOnChanges(): void {
    this.form.formGroup.controls.pressurePoints.addValidators(validateCustomProfile(this.currentString, this.wellTd));

    if (this.form.formGroup.controls.pressurePoints?.value) {
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    } else {
      this.form.formGroup.controls.pressurePoints.setValue(this.createInitialCustomPressurePoints());
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    }
  }

  public newDataRow(): UntypedFormGroup {
    return new UntypedFormGroup({
      measuredDepth: new UntypedFormControl(0, [Validators.required]),
      pressure: new UntypedFormControl(null, [Validators.required])
    });
  }

  public onCustomPressuresChange(v: { dataRows: Array<any>, reload: boolean, triggeredBy: any }) {
    if (v.triggeredBy.type === 'reset') {
      this.customPressures = this.createInitialCustomPressurePoints();
      this.form.formGroup.controls.pressurePoints.setValue(this.customPressures);
    } else {
      this.form.formGroup.controls.pressurePoints.setValue(v.dataRows);
    }
  }

  private createInitialCustomPressurePoints() {
    return [{ measuredDepth: 0, pressure: 0 }];
  }

}
