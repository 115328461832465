<form [formGroup]="form.formGroup" bg-dark>
  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header"> Operation Details </ng-template>
      <div class="grid">
        <div class="col-6">
          <div class="field">
            <label for="fluids">Lead Slurry</label>
            <p-dropdown
                [disabled]="isReadOnly"
                [options]="slurries"
              [formControlName]="form.formControlNames.leadSlurry"
              dataKey="id"
              appendTo="body"
              tooltipPosition="top"
              showDelay="100"
              hideDelay="500"
            >
              <ng-template pTemplate="selectedItem">
                {{ form.formGroup.value.leadSlurry?.state.name }}
              </ng-template>
              <ng-template let-leadSlurry pTemplate="item">
                {{ leadSlurry?.state.name }}
              </ng-template>
            </p-dropdown>
          </div>

          <p-fieldset class="cementing">
            <ng-template pTemplate="header">
              <input
                style="height: 13px"
                type="checkbox"
                binary="true"
                (change)="onTailChange($event)"
                [checked]="form.formGroup.controls.length.value > 0"
              />
              <label class="tailLabel">Tail Slurry</label>
            </ng-template>
            <div class="field">
              <label for="fluids">Slurry</label>
              <p-dropdown
                  [disabled]="hasTailSlurry || isReadOnly"
                [options]="slurries"
                [formControlName]="form.formControlNames.slurry"
                dataKey="id"
                appendTo="body"
                tooltipPosition="top"
                showDelay="100"
                hideDelay="500"
              >
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.slurry?.state.name }}
                </ng-template>
                <ng-template let-slurry pTemplate="item">
                  {{ slurry?.state.name }}
                </ng-template>
              </p-dropdown>
            </div>

            <div class="field">
              <label for="length">Length</label>
              <div class="p-inputgroup">
                <input
                  pInputText
                  [formControlName]="form.formControlNames.length"
                />
                <span class="p-inputgroup-addon">{{
                  userUnits.longLengths
                }}</span>
              </div>
              <p *ngIf="form.formGroup.controls.length.invalid" class="error">
                Allowed range: 0 - {{ cementLength }}
                {{ userUnits.longLengths }}
              </p>
            </div>
          </p-fieldset>

          <div class="field">
            <label for="fluids">Drilling Fluid</label>
            <p-dropdown
                [disabled]="isReadOnly"
                [options]="fluids"
              [formControlName]="form.formControlNames.drillingFluid"
              dataKey="id"
              appendTo="body"
              tooltipPosition="top"
              showDelay="100"
              hideDelay="500"
            >
              <ng-template pTemplate="selectedItem">
                {{ form.formGroup.value.drillingFluid?.state.name }}
              </ng-template>
              <ng-template let-drillingFluid pTemplate="item">
                {{ drillingFluid?.state.name }}
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field">
            <label for="displacementRate">Displacement Rate</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="form.formControlNames.displacementRate"
              />
              <span class="p-inputgroup-addon">{{
                standardFluidFlowUnit
              }}</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.displacementRate.value &&
                form.formGroup.controls.displacementRate.invalid
              "
              class="error"
            >
              Allowed range: {{ displacementRateValidation.min }} -
              {{ displacementRateValidation.max }}
              {{ standardFluidFlowUnit }}
            </p>
          </div>

          <br />
        </div>

        <div class="col-6">
          <div class="field">
            <label for="slurryPumpRate">Slurry Pump Rate</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="form.formControlNames.slurryPumpRate"
              />
              <span class="p-inputgroup-addon">{{ slurryFlowUnit }}</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.slurryPumpRate.value &&
                form.formGroup.controls.slurryPumpRate.invalid
              "
              class="error"
            >
            Allowed range: {{ slurryFlowRateValidation.min }} -
            {{ slurryFlowRateValidation.max }}
            {{ slurryFlowUnit }}
            </p>
          </div>

          <div class="field textboxLevelClass">
            <label for="slurryInletTemperature">Slurry Inlet Temperature</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="form.formControlNames.slurryInletTemperature"
              />
              <span class="p-inputgroup-addon">{{
                userUnits.temperature
              }}</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.slurryInletTemperature.value &&
                form.formGroup.controls.slurryInletTemperature.invalid
              "
              class="error"
            >
              Allowed range: {{ this.temperatureValidation.min }} -
              {{ this.temperatureValidation.max }}
              {{ userUnits.temperature }}
            </p>
          </div>

          <label for="cementSetTime">Time to 50psi 'Right Angle' set:</label>

          <div class="field cementSetTimeClass">
            <label for="cementSetTime">Cement Set Time</label>
            <div class="p-inputgroup">
              <input
                pTooltip=""
                pInputText
                [formControlName]="form.formControlNames.cementSetTime"
              />
              <span class="p-inputgroup-addon">hr</span>
            </div>
            <p
              *ngIf="
                form.formGroup.controls.cementSetTime.value &&
                form.formGroup.controls.cementSetTime.invalid
              "
              class="error"
            >
              Allowed range: 0 - 1000 hr
            </p>
          </div>

          <!-- <br> -->

          <p-fieldset legend="Displacement Fluid" class="cementing">
            <div class="field">
              <label for="fluids">Fluid</label>
              <p-dropdown
                  [disabled]="isReadOnly"
                  [options]="fluids"
                [formControlName]="form.formControlNames.fluid"
                dataKey="id"
                appendTo="body"
                tooltipPosition="top"
                showDelay="100"
                hideDelay="500"
              >
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.fluid?.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  {{ fluid?.state.name }}
                </ng-template>
              </p-dropdown>
            </div>

            <div class="field">
              <label for="inletTemperature">Inlet Temperature</label>
              <div class="p-inputgroup">
                <input
                  pInputText
                  [formControlName]="form.formControlNames.inletTemperature"
                />
                <span class="p-inputgroup-addon">{{ userUnits.temperature }}</span>
              </div>
              <p *ngIf="form.formGroup.controls.inletTemperature.value && form.formGroup.controls.inletTemperature.invalid" class="error">
                Allowed range: {{ this.temperatureValidation.min }} -
                {{ this.temperatureValidation.max }}
                {{ userUnits.temperature }}
              </p>
            </div>

            <!-- <div class="field">
          <label for="leadSpacerVolume">Volume</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="formControlNames.leadSpacerVolume"
            />
            <span class="p-inputgroup-addon">bbl</span>
          </div>
        </div>

        <div class="field">
          <label for="leadSpacerPumpRate">Pump Rate</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="formControlNames.leadSpacerPumpRate"
            />
            <span class="p-inputgroup-addon">gpm</span>
          </div>
        </div> -->
          </p-fieldset>

          <!-- <div class="field">
          <label for="displacementInletTemperature">Drilling Mud Inlet Temperature</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="formControlNames.displacementInletTemperature"
            />
            <span class="p-inputgroup-addon">{{ userUnits.temperature }}</span>
          </div>
        </div> -->

          <!-- <p-fieldset legend="Displacement" class="cementing"> -->

          <!-- <div class="field">
            <label for="fluids">Fluid</label>
              <p-dropdown
                [disabled]="isReadOnly"
                [options]="fluids"
                [formControlName]="formControlNames.drillingFluid"
                dataKey="id"
                appendTo="body"
                tooltipPosition="top"
                showDelay="100"
                hideDelay="500"
              >
                <ng-template pTemplate="selectedItem">
                  {{ formGroupValues.drillingFluid.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                    {{ fluid.state.name }}
                </ng-template>
              </p-dropdown>
          </div> -->

          <!-- <div class="field">
            <label for="displacementInletTemperature">Inlet Temperature</label>
            <div class="p-inputgroup">
              <input
                pInputText
                [formControlName]="formControlNames.displacementInletTemperature"
              />
              <span class="p-inputgroup-addon">{{ userUnits.temperature }}</span>
            </div>
          </div> -->

          <!-- </p-fieldset> -->
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel *ngIf="showDrillPipeData">
      <ng-template pTemplate="header">
        Landing String
        <i
          class="p-error pi pi-exclamation-circle"
          *ngIf="drillpipeMissing"
          pTooltip="Landing string not specified."
        ></i>
      </ng-template>

      <label class="{{ drillpipeMissing ? 'error' : '' }}">{{
        selectedDrillPipe
      }}</label>
    </p-tabPanel>

    <p-tabPanel *ngIf="showRiser">
      <ng-template pTemplate="header">
        Riser
        <i
          class="p-error pi pi-exclamation-circle"
          *ngIf="
            form.formGroup.controls?.insideDiameter.invalid ||
            form.formGroup.controls?.outsideDiameter.invalid
          "
          pTooltip="Operation not saved, some required inputs not specified."
        ></i>
      </ng-template>

      <!-- <p-fieldset legend="Riser" class="cementing"> -->
      <div class="field">
        <label for="outsideDiameter">Outside Diameter</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.outsideDiameter"
          />
          <span class="p-inputgroup-addon">{{ userUnits.shortLengths }}</span>
        </div>
      </div>
      <div class="field">
        <label for="outsideDiameter">Inside Diameter</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.insideDiameter"
          />
          <span class="p-inputgroup-addon">{{ userUnits.shortLengths }}</span>
        </div>
      </div>
      <!-- </p-fieldset> -->
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header"> Other </ng-template>

      <div class="field">
        <label for="pipeRoughness">Pipe Roughness</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.pipeRoughness"
          />
          <label class="p-inputgroup-addon">{{ userUnits.shortLengths }}</label>
        </div>
        <p class="error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
          Allowed range: {{ pipeRoughnessValidation.min }} -
          {{ pipeRoughnessValidation.max }} {{ userUnits.shortLengths }}
        </p>
      </div>
      <br />
    </p-tabPanel>
  </p-tabView>
</form>
<!-- <pre>{{ form.formGroup.value | json }}</pre> -->
