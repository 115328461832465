import { AfterContentInit, Component, Input,  OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { InternalPressureProfileType, PressureTestIPP } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component'
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface PressureTestIppForm {
  type: InternalPressureProfileType.PRESSURETEST;
  surfacePressure: UomQuantityInput;
  fluidDensity: UomQuantityInput;
  hasPlug: boolean;
  plugMd: UomQuantityInput;
}

@Component({
  selector: 'app-pressure-test-ipp',
  templateUrl: './pressure-test-ipp.component.html',
  providers: subformComponentProviders(PressureTestIppComponent)
})
export class PressureTestIppComponent implements AfterContentInit, OnChanges {

  @Input() currentString: any;
  @Input() shoeDepth: number;
  @Input() userUnits: UserUnitsModel;

  public validators: any;
  public plugDepthDisabled: boolean;
  public densityLabel: string;
  public pressureLabel: string;
  public pressureValidation: { min: number, max: number };
  public densityValidation: { min: number, max: number };

  public form = createForm<PressureTestIPP, PressureTestIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.PRESSURETEST),
      surfacePressure: new UntypedFormControl(null),
      fluidDensity: new UntypedFormControl(null),
      hasPlug: new UntypedFormControl(false),
      plugMd: new UntypedFormControl(null)
    },
    toFormGroup: (obj: PressureTestIPP | null): PressureTestIppForm | null => {
      if (!obj) {
        return null;
      }

      obj.plugMd ? this.hasPlug() : this.doesntHavePlug();

      return {
        type: obj.type,
        surfacePressure:  { control: obj.surfacePressure },
        fluidDensity: { control: obj.fluidDensity },
        hasPlug: obj.plugMd > 0 ? true : false,
        plugMd: { control: obj.plugMd }
      };

    },
    fromFormGroup: (formValue: PressureTestIppForm): PressureTestIPP | null => {
      const { surfacePressure, fluidDensity, plugMd, hasPlug, ...commonValues } = formValue;
      return {
        surfacePressure: +surfacePressure?.control,
        fluidDensity: +fluidDensity?.control,
        plugMd: plugMd?.control ? +plugMd?.control : null,
        ...commonValues
      };
    }
  });

  constructor() { }

  ngAfterContentInit(): void {
    this.pressureTestPlugChange();
  }

  ngOnChanges(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
    this.pressureLabel = this.userUnits.pressure;

    switch (this.pressureLabel) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 30000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 2068 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 206800 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 2041 };
        break;
    }

    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 0.01, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
        this.densityValidation = { min: 0.00119, max: 3 };
        break;
    }

    this.validators = {
      surfacePressure: [Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required],
      fluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required],
      plugMd: [Validators.min(this.currentString.hangerMd  + 1), Validators.max(this.shoeDepth)]
    };
  }

  public pressureTestPlugChange() {
    if (this.form.formGroup.controls.hasPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }
  }

  private hasPlug() {
    this.form.formGroup.controls.hasPlug.setValue(true);
    if (!this.form.formGroup.controls.plugMd.value?.control) {
      this.form.formGroup.controls.plugMd.setValue({ control: this.shoeDepth});
    }
    this.plugDepthDisabled = false;
  }

  private doesntHavePlug() {
    this.form.formGroup.controls.plugMd.setValue({ control: null });
    this.form.formGroup.controls.hasPlug.setValue(false);
    this.plugDepthDisabled = true;
  }
}
