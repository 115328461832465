<div class="wellExplorerContainer">
  <p-card>

    <ng-template pTemplate="header">
      <well-explorer-header
        [isAdmin]="userRoles?.admin"
        (onCloseSidenav)="handleCloseSidenav()"
        (onCompanyAdded)="handleAddCompany($event)"
        (onDesignUpdated)="nodeSelect($event)"
        [design]="design"
        [allDesigns]="allDesigns"
      >
      </well-explorer-header>
    </ng-template>


    <p-treeTable
      #treeTable
      dataKey="id"
      [autoLayout]="false"
      [scrollable]="true"
      [scrollHeight]="scrollHeight"
      [columns]="cols"
      contextMenuSelectionMode="joint"
      indentation=".15"
      selectionMode="single"
      [(selection)]="selectedNode"
      [loading]="loading"
      (onNodeSelect)="nodeSelect($event)"
      [(contextMenuSelection)]="selectedNode"
      [contextMenu]="cm"
      [value]="treeItems"
    >
      <ng-template
        pTemplate="body"
        let-rowNode
        let-rowData="rowData"
        let-columns="columns"
      >
      <well-explorer-item
        [treeItems]="treeItems"
        [treeNode]="rowNode"
        [columns]="cols"
        [rowData]="rowData"
        [ttContextMenuRow]="rowNode"
        [selectedNode]="selectedNode"
        [isAdmin]="userRoles?.admin"
        (onAddChildNode)="handleAddChildNode($event)"
        (onCloneDesign)="handleCloneDesign($event)"
        (onDeleteNode)="handleDeleteNode($event)"
        (onRenameItem)="handleRenameItem($event)"
        (onExportDesign)="handleExportDesign($event)"
        (onMoveDesign)="handleShowMoveDesignDialog($event)"
        (onContextMenuUpdated)="handleContextMenuUpdated($event)"
        (onShowImportDesignDialog)="handleShowImportDesignDialog($event)"
      >
      </well-explorer-item>
    </ng-template>
  </p-treeTable>
</p-card>

<p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>

</div>
