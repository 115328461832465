import { Component, Input, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Flow } from 'src/app/perical/models/flow.model';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { OperationType, RunCasingAndCirculate } from 'src/app/perical/models/thermal-operation.model';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UndisturbedTemperature } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';
import { TubularString } from 'src/app/wellbore-inputs/models/well-schematic.model';
import { DrillstringService } from 'src/app/wellbore-inputs/services/drillstring.service';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';

export interface RunCasingAndCirculateForm {
  type: OperationType;
  fluid: Fluid;
  standardFluidFlow: Flow;
  tripIntoHole: number;
  circulationTime: number;
  outsideDiameter: number;
  insideDiameter: number;
  inletTemperature: number;
  staticPeriod: number;
  pipeRoughness: number;
  annulusIndex: number;
}

@Component({
  selector: 'app-run-casing-and-circulate-details',
  templateUrl: './run-casing-and-circulate-details.component.html',
  styleUrls: ['./run-casing-and-circulate-details.component.scss'],
  providers: subformComponentProviders(RunCasingAndCirculateDetailsComponent)
})
export class RunCasingAndCirculateDetailsComponent implements OnChanges, OnDestroy, AfterViewInit {

  constructor(
    private _wellTypeService: WellTypeService,
    private _drillstringService: DrillstringService,
    private _signalRService: SignalRService
  ) { }

  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[]) {
    this.fluids = fluids?.filter(f => f.state.type === FluidType.BRINE ||
      f.state.type === FluidType.STANDARDMUD ||
      f.state.type === FluidType.ADVANCEDMUD)
      .sort((a, b) => a.state.name.localeCompare(b.state.name));
  }

  public annularFluidId: string;
  @Input('annularFluidId') set _annularFluidId(annularFluidId: string) {
    this.annularFluidId = annularFluidId ?? null;
  }

  @Input()
  public isReadOnly: boolean;

  @Input()
  private udtProfile: UndisturbedTemperature;

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public currentString: TubularString;

  @Input()
  currentTubularIndex: number;

  public operationType: string = 'runCasingAndCirculate';
  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;
  public showLandingString: boolean;
  public landingStringMissing: boolean;
  public landingString: any;
  public selectedLandingString: string;
  public showRiser: boolean;
  public shortLengthUnit: string;
  public weigthUnit: string;
  public tempUnit: string;
  public pipeRoughnessUnit: string;
  public temperatureValidation: { min: number, max: number };
  public pipeRoughnessValidation: { min: number, max: number };
  
  private _defaultPipeRoughness: number;

  public form = createForm<RunCasingAndCirculate, RunCasingAndCirculateForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.RUNCASINGANDCIRCULATE),
      fluid: new UntypedFormControl(null, [Validators.required]),
      standardFluidFlow: new UntypedFormControl(null, [Validators.required]),
      tripIntoHole: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100000)]),
      outsideDiameter: new UntypedFormControl(21, [Validators.required, Validators.min(0)]),
      insideDiameter: new UntypedFormControl(19.75, [Validators.required, Validators.min(0)]),
      circulationTime: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100000)]),
      inletTemperature: new UntypedFormControl(null),
      pipeRoughness: new UntypedFormControl(null, [Validators.required]),
      staticPeriod: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(300)]),
      annulusIndex: new UntypedFormControl(null)
    },
    toFormGroup: (obj: RunCasingAndCirculate | null): RunCasingAndCirculateForm | null => {
      if (!obj) {
        return null;
      }

      const { fluid, tripIntoHole, riser, flow, circulationTime, pipeRoughness, inletTemperature, staticPeriod, ...commonValues } = obj;

      obj.fluid = this.fluids?.find(f => f.id === obj.fluid.id);

      return {
        type: obj.type,
        standardFluidFlow: obj.flow,
        tripIntoHole,
        circulationTime,
        fluid: obj.fluid,
        staticPeriod,
        pipeRoughness,
        outsideDiameter: riser.outsideDiameter,
        insideDiameter: riser.insideDiameter,
        inletTemperature,
        annulusIndex: obj.annulusIndex
      };
    },
    fromFormGroup: (formValue: RunCasingAndCirculateForm): RunCasingAndCirculate | null => {
      const { fluid, tripIntoHole, standardFluidFlow, circulationTime, outsideDiameter, insideDiameter,
        inletTemperature, pipeRoughness, staticPeriod, annulusIndex, ...commonValues } = formValue;

      return {
        type: OperationType.RUNCASINGANDCIRCULATE,
        flow: standardFluidFlow,
        tripIntoHole,
        riser: {
          outsideDiameter,
          insideDiameter
        },
        fluid,
        circulationTime,
        inletTemperature,
        pipeRoughness,
        staticPeriod,
        annulusIndex
      };
    }
  });

  async ngOnChanges(): Promise<void> {
    this.isLoading = true;

    this.tempUnit = this.userUnits.temperature;
    this.shortLengthUnit = this.userUnits.shortLengths == 'in' ? '"' : this.userUnits.shortLengths;
    this.weigthUnit = this.userUnits.linearDensity == 'lb/ft' ? '#' : this.userUnits.linearDensity;
    this.pipeRoughnessUnit = this.userUnits.shortLengths;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 500 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.77, max: 260 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255.37, max: 533.15 };
        break;
    }
    this.form.formGroup.controls.inletTemperature.setValidators([Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max), Validators.required]);

    this._defaultPipeRoughness = 0.001968;
    if (this.userUnits.shortLengths == 'in') {
      this.pipeRoughnessValidation = { min: 0, max: 0.25 };
    } else if (this.userUnits.shortLengths == 'cm') {
      this._defaultPipeRoughness = 0.00499;
      this.pipeRoughnessValidation = { min: 0, max: 0.6 };
    } else {
      this._defaultPipeRoughness = 0.0499;
      this.pipeRoughnessValidation = { min: 0, max: 6.3 };
    }
    this.form.formGroup.controls.pipeRoughness.setValidators([Validators.min(this.pipeRoughnessValidation.min), Validators.max(this.pipeRoughnessValidation.max), Validators.required]);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getRunCasingData();
  }

  signalRfunc(data: any) {
    if ((data.tubularStringId && data.tubularStringId !== this.currentString.id)) {
      return;
    }

    if (data.action === PeriforOnChangeMessages.REFRESH_DRILL_STRING || data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.getRunCasingData();
    }
  }

  ngAfterViewInit(): void {
    if (this.form.formGroup.controls.pipeRoughness.value == null) {
      this.form.formGroup.controls.pipeRoughness.patchValue(this._defaultPipeRoughness, { emitEvent: false });
    }
    let defaultFluid = this.fluids ? this.fluids.find(x => x.id === this.annularFluidId) : null;
    if (!this.form.formGroup.controls.fluid.value) {
      this.form.formGroup.controls.fluid.setValue(defaultFluid);
    }
  }

  private getRunCasingData() {
    const sources: Observable<any>[] = [
      this._wellTypeService.getWellType() as Observable<any>,
      this._drillstringService.getDrillstringForTubular()
    ];

    forkJoin(sources).pipe(
      map(([wellType, drillstring]) => {
        this.landingString = drillstring['drillPipe'];
        this.landingStringMissing = !this.landingString;

        this.selectedLandingString = this.landingString ?
          `Landing String: OD: ${this.landingString.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.landingString.weightPerFoot}${this.weigthUnit}, ID: ${this.landingString.insideDiameter}${this.shortLengthUnit}` :
          'Please Select a Landing String';

        this.showRiser = wellType.type == 'Subsea' && this.currentTubularIndex > 0 ? true : null;

        this.form.formGroup.controls.inletTemperature
          .setValue((this.form.formGroup.value.inletTemperature ?? this.udtProfile[0].temperature), { emitEvent: false });

        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  ngOnDestroy(): void {
      this.signalRfunc = null;
  }
}
