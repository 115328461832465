import { NgModule } from '@angular/core';

import { UserMenuComponent } from '../core/components/user-menu/user-menu.component';
import { HeaderComponent } from '../core/components/header/header.component';
import { ToolbarComponent } from '../core/components/toolbar/toolbar.component';
import { ProfileComponent } from '../core/components/user-profile/profile.component';
import { WellExplorerComponent } from '../core/components/well-explorer/well-explorer.component';
import { SidebarMenuComponent } from '../core/components/sidebar-menu/sidebar-menu.component';
import { LoginPageComponent } from '../core/components/login/login-page.component';
import { AuthCallbackPageComponent } from '../core/components/auth-callback/auth-callback-page.component';

import { CommonModule } from '@angular/common';

import { WellboreInputsModule } from './wellbore-inputs.module';
import { SharedModule } from './shared.module';
import { CreateTreeLevelDialogComponent } from '../core/components/well-explorer/create-tree-level-dialog/create-tree-level-dialog.component';
import { RenameTreeLevelDialogComponent } from '../core/components/well-explorer/rename-tree-level-dialog/rename-tree-level-dialog.component';
import { DeleteTreeLevelDialogComponent } from '../core/components/well-explorer/delete-tree-level-dialog/delete-tree-level-dialog.component';
import { NoCurrentDesignComponent } from '../core/components/no-current-design/no-current-design.component';
import { HelpMenuComponent } from '../core/components/help-menu/help-menu.component';
import { ChangelogComponent } from '../core/components/changelog/changelog.component';

import { MarkdownModule } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';
import { CreateDesignDialogComponent } from '../core/components/well-explorer/create-design-dialog/create-design-dialog.component';

import { DashboardContainerComponent } from '../core/components/dashboard/dashboard-container.component';
import { DashboardWorkspaceComponent } from '../core/components/dashboard/workspace/workspace-component';
import { DashboardWorkspaceTabsComponent } from '../core/components/dashboard/workspace-tabs/workspace-tabs.component';
import { SidebarMenuGroupComponent } from '../core/components/sidebar-menu/sidebar-menu-group/sidebar-menu-group.component';
import { WellDesignerPageComponent } from '../core/components/well-designer-page/well-designer-page.component';
import { WellExplorerItemComponent } from '../core/components/well-explorer/item/well-explorer-item.component';
import { WellExplorerHeaderComponent } from '../core/components/well-explorer/header/well-explorer-header.component';
import { AboutComponent } from '../core/components/about/about.component';
import { NgxResizerDirective } from '../core/components/well-designer-page/resizer-directive';
import { NotificationHubComponent } from '../core/components/notification-hub/notification-hub.component';
import { UserUnitsComponent } from '../core/components/user-units/user-units.component';
import { UserAdminComponent } from '../core/components/user-admin-page/user-admin.component';
import { AddUserFormComponent } from '../core/components/user-admin-page/add-user-form.component';
import { UserProfileFormComponent } from '../core/components/user-admin-page/user-profile-form.component';
import { UsersTableComponent } from '../core/components/user-admin-page/users-table.component';
import { EditUserFormComponent } from '../core/components/user-admin-page/edit-user-form.component';
import { EditPermissionsFormComponent } from '../core/components/user-admin-page/edit-permissions-form.component';
import { UserPermissionsFormComponent } from '../core/components/user-admin-page/user-permissions-form.component';
import { NgIdleModule } from '@ng-idle/core';
import { CloneWorkspacesDialogComponent } from '../core/components/dashboard/clone-workspaces-dialog/clone-workspaces-dialog.component';
import { MoveDesignDialogComponent } from '../core/components/well-explorer/move-design-dialog/move-design-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WellboreInputsModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgIdleModule.forRoot()
  ],
  declarations: [
    NgxResizerDirective,
    HeaderComponent,
    UserMenuComponent,
    ToolbarComponent,
    WellDesignerPageComponent,
    SidebarMenuGroupComponent,
    DashboardContainerComponent,
    DashboardWorkspaceComponent,
    DashboardWorkspaceTabsComponent,
    ProfileComponent,
    UserUnitsComponent,
    SidebarMenuComponent,
    LoginPageComponent,
    AuthCallbackPageComponent,
    WellExplorerComponent,
    WellExplorerItemComponent,
    WellExplorerHeaderComponent,
    CreateTreeLevelDialogComponent,
    MoveDesignDialogComponent,
    CreateDesignDialogComponent,
    RenameTreeLevelDialogComponent,
    DeleteTreeLevelDialogComponent,
    NoCurrentDesignComponent,
    HelpMenuComponent,
    ChangelogComponent,
    AboutComponent,
    NotificationHubComponent,
    UserAdminComponent,
    AddUserFormComponent,
    EditUserFormComponent,
    UserProfileFormComponent,
    UsersTableComponent,
    EditPermissionsFormComponent,
    UserPermissionsFormComponent,
    CloneWorkspacesDialogComponent
  ],
  providers: [],
  exports: [
    HeaderComponent,
    UserMenuComponent,
    ToolbarComponent,
    WellDesignerPageComponent,
    DashboardContainerComponent,
    DashboardWorkspaceComponent,
    DashboardWorkspaceTabsComponent,
    ProfileComponent,
    UserUnitsComponent,
    WellExplorerComponent,
    WellExplorerItemComponent,
    WellExplorerHeaderComponent,
    SidebarMenuComponent,
    LoginPageComponent,
    AuthCallbackPageComponent,
    CreateTreeLevelDialogComponent,
    RenameTreeLevelDialogComponent,
    DeleteTreeLevelDialogComponent,
    UserAdminComponent,
    AddUserFormComponent,
    EditUserFormComponent,
    UserProfileFormComponent,
    UsersTableComponent,
    EditPermissionsFormComponent,
    UserPermissionsFormComponent
  ]
})

export class CoreModule {}
