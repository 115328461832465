import { UntypedFormGroup } from "@angular/forms";
import { WellComponent } from "../models";

// Hole size must smaller than the smallest ID of the previous string. Except for the outermost one.
export function holeSizeSmallerThanPrevsId(control: UntypedFormGroup) {
    let wellConfiguration = control?.parent?.parent?.getRawValue() as Array<WellComponent>; //Using RawValue so we can get the disabled inputs.
    let holeSize :number = control?.value;
    if(!wellConfiguration || !holeSize || control.pristine){
        return null;
    }
    let tubularString = control?.parent.getRawValue() as WellComponent;
    let idx = wellConfiguration.findIndex(wc => wc.id == tubularString.id);
    if(idx == 0){
        return null;
    }
    let previousTubular = wellConfiguration[idx - 1];
    // let previousDiameters = previousTubular.stringSections.map(ss=> ss.pipe.insideDiameter);
    // let minDiameter = Math.min(...previousDiameters);
    let previousDiameter = previousTubular.stringSections[previousTubular.stringSections.length - 1].pipe.outsideDiameter - 0.01;
    if (holeSize > previousDiameter) {
       return { error: `Hole size must smaller than ${previousDiameter}" \n` };
    }
    if (holeSize < 0) {
        return { error: `Hole size must greater than 0`};
    }
    let nextTubular = wellConfiguration[idx + 1] ?? null;
    if (nextTubular) {
        let nextHoleSize = nextTubular.holeSize;
        if (holeSize < nextHoleSize) {
            return { error: `Hole size must greater than ${nextHoleSize}" \n` };
        }
    }
    return null;
 }