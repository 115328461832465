import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { LoadCaseService } from '../../services/load-case.service';
import { forkJoin, lastValueFrom, Observable, Subscription } from 'rxjs';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { UdtService } from '../../../shared/services/udt.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { LoadCaseDefinitionModel, LoadCaseSummaryResult } from '../../models/load-case.model';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PostInstallLoad, PostInstallLoadUi } from './post-install-load-details/models/post-install-load.model';
import { TubularString } from 'src/app/wellbore-inputs/models/well-schematic.model';
import { CementingLandingService } from 'src/app/shared/services/cementing-landing.service';
import { PoreAndFracService } from '../../services/pore-and-frac.service';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { PoreFrac } from '../../models/poreFrac.model';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';
import { WellType } from 'src/app/shared/models/wellType.model';
import { UndisturbedTemperature, UndisturbedTemperaturePlot } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';

@Component({
  selector: 'app-post-install-loads',
  templateUrl: './post-install-loads.component.html',
  styleUrls: ['./post-install-loads.component.scss'],
  providers: [ConfirmationService]
})
export class PostInstallLoadsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;

  public userUnits: UserUnitsModel;
  public plotData: any;
  public loadDetailsDialog: boolean;
  public loadCases: Array<LoadCaseSummaryResult>;
  public selectedLoad: LoadCaseSummaryResult;
  public selectedPlotLoad: any;
  public lastSelectedLoad: LoadCaseSummaryResult;
  public loadCase: PostInstallLoad;
  public wellType: WellType;
  public componentHeight: number;
  public tableHeight: string;
  public selectedLoadCaseIndexes: number[] = [];

  public pressTemp: SelectItem[] = [
    { label: 'Press', value: 'press' },
    { label: 'Temp', value: 'temp' }
  ];

  public tablePlot: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Table', value: 'table' }
  ];

  public configMissing: boolean;
  public currentString: TubularString;
  public shoeDepthTvd: number;
  public hangerTvd: number;
  public porePressures: PoreFrac[];
  public cement: CementingLanding;
  public thermalOperations: Partial<BaseOperation>[];
  public udtData: UndisturbedTemperature;
  public udtPlotData: UndisturbedTemperaturePlot;
  public isLoading: boolean;
  private deletedLoadIndex: number;
  private isSingleClick: boolean;
  public isDelete: boolean;
  public expandedRows: {} = {};
  public newLoadCaseDialogDisplay: boolean;
  public stringName: string;
  public wellTd: number;
  public userRoles : UserRoles;

  @ViewChild('loadsTable') loadsTable;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.postInstallLoadsStore = this._storeService.createStore(this.componentId, new PostInstallLoadUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public postInstallLoadsStore: Store;

  constructor(
    private _loadCaseService: LoadCaseService,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService,
    private _udtService: UdtService,
    private _wellConfigService: WellConfigService,
    private _trajectoryService: TrajectoryService,
    private _cementingLandingService: CementingLandingService,
    private _poreAndFracService: PoreAndFracService,
    private _wellTypeService: WellTypeService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Post-Install Load Cases") {
        this.componentHeight = e.itemHeight - 60;
        this.tableHeight = e.itemHeight - 180 + 'px';
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.getConfigAndUdt('stringChange');

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_LOAD_CASE_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST ||
      data.action === PeriforOnChangeMessages.REFRESH_PACKERS) {
      this.getConfigAndUdt('onInit');
    }
    if (data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.configMissing = false;
      this.getConfigAndUdt('stringChange');
      this.refreshLoadCases('stringChange');
      this.expandedRows = {};
    }
  }

  private getConfigAndUdt(action): void {
    this.isLoading = true;
    this._wellConfigService.getTubular().subscribe({
      next: (currentString) => {
      if (currentString !== null) {
        this.currentString = currentString;
        const sources: Observable<any>[] = [
          this._cementingLandingService.getCementingLanding(null) as Observable<CementingLanding>,
          this._poreAndFracService.getPorePressures() as Observable<PoreFrac[]>,
          this._loadCaseService.getThermalOperations() as Observable<Partial<BaseOperation>[]>,
          this._wellTypeService.getWellType() as Observable<WellType>,
          this._trajectoryService.getTotalDepth() as Observable<any>
        ];

        forkJoin(sources).pipe(
          map( async([cement, porePressures, thermalOperations, wellType, totalDepth]) => {
            this.porePressures = porePressures;
            this.cement = cement;
            this.thermalOperations = thermalOperations;
            this.wellType = wellType;
            this.stringName = currentString.type;
            this.wellTd = totalDepth.measuredDepth;

            const tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([currentString.hangerMd, currentString.shoeMd]));

            const hanger = tvds[0];
            const shoe = tvds[1];

            this.shoeDepthTvd = +shoe.toFixed(2);
            this.hangerTvd = +hanger.toFixed(2);

            this.configMissing = false;
            this.refreshLoadCases(action);
          }),
          catchError(err => {
            this.configMissing = true;
            this.isLoading = false;
            return err;
          })).subscribe();
      } else {
        this.configMissing = true;
        this.isLoading = false;
      }
    },
    error: () => {
      this.configMissing = true;
      this.isLoading = false;
    }});
  }

  private refreshLoadCases(action, loadCase?): void {
    this._loadCaseService.getLoadCasesSummary(true).subscribe({
      next: (loads) => {
      this.loadCases = [];
      if (this.currentString.type == 'Tubing') {
        loads.forEach(x => {
          if (x.eppDisplayName == 'Fluid to Shoe') {
            x.eppDisplayName = 'Annular Fluid';
          }
        });
      }
      this.loadCases = loads;

      if (action === 'clone') {
        this.selectedLoad = loads.find(x => x.name === loadCase.name);
        this.editLoadCase(this.selectedLoad);
      }

      if (!this.selectedLoad || action === 'stringChange') {
        this.selectedLoad = loads[loads.length - 1];
      }

      if (action === 'newLoad') {
        this.selectedLoad = loads[loads.length - 1];
        this.editLoadCase(this.selectedLoad);
      }

      if (action === 'refresh') {
        this.selectedLoad = loads.find(x => x.index === this.lastSelectedLoad.index);
      }

      if (action === 'edit') {
        this.selectedLoad = loads.find(x => x.index === loadCase.index);
      }

      if (action === 'delete') {
        if (this.selectedLoad.index === this.deletedLoadIndex) {
          this.selectedLoad = this.loadCases[this.loadCases.length - 1];
          if (this.selectedLoad.index === this.deletedLoadIndex) {
            this.selectedLoad = this.loadCases[this.loadCases.length - 2];
          }
        }
      }

      if (action === 'deleteMultiple') {
        this.selectedLoad = this.loadCases[this.loadCases.length - 1];
      }

      if (action === 'onInit' && !this.selectedLoad) {
        this.selectedLoad = loads[loads.length - 1];
      }

      // Refresh Plots
      if (!this.selectedLoad && this.loadCases.length > 0) {
        this.selectedLoad = loads[loads.length - 1];
      }

      if (!this.postInstallLoadsStore.state.drawerOpen) {
        this.isLoading = false;
        return;
      }

      if (loads.length < 1) {
        this.selectedLoad = null;
        this.plotData = null;
        this.isLoading = false;
        return;
      }

      this.pressTempToggle();
      this.isLoading = false;
    },
    error: () => {
      this.isLoading = false;
    }});
  }

  private getUdtPlotData() {
    let getUdtPlotDataDebounced = this._udtService.getUndisturbedTemperaturePlot().pipe(debounceTime(200), distinctUntilChanged());
    this._subscriptions.add(getUdtPlotDataDebounced.subscribe((res) => {
      this.udtPlotData = res;
    }));
  }


  // Plot Actions
  drawerToggle() {
    this.postInstallLoadsStore.update(state => ({...state, drawerOpen: !state.drawerOpen}));
    if (this.postInstallLoadsStore.state.drawerOpen) {
      this.isLoading = true;
      this.plotData = null;
      this.getUdtPlotData();
      this.isLoading = false;
    }
  }

  pressTempToggle(e?) {
    if (!this.selectedLoad || !this.postInstallLoadsStore.state.drawerOpen) {
      this.plotData = null;
      return;
    }
    e ? this.postInstallLoadsStore.update(state => ({...state, pressTempToggle: e.value})) : false;
    let getPressuresAndTemperatures = this._loadCaseService.getPressuresAndTemperatures(this.selectedLoad.index).pipe(debounceTime(500), distinctUntilChanged());
    this._subscriptions.add(getPressuresAndTemperatures.subscribe((data) => {
      if (this.postInstallLoadsStore.state.pressTempSelected === 'temp') { this.getUdtPlotData(); }
      this.selectedPlotLoad = data;
      this.plotData = data;
    }));
  }

  // Table Actions
  showNewLoadCaseDialog(): void {
    this.newLoadCaseDialogDisplay = true;
  }

  cancelNewLoadCaseDialog(): void {
    this.newLoadCaseDialogDisplay = false;
  }

  newLoadCase(name: string): void {
    this._loadCaseService.addLoadCaseDefinition({ name: name.trim() }).subscribe(() => {
      this.refreshLoadCases('newLoad');
      this.newLoadCaseDialogDisplay = false;
    });
    // this.newLoadRef = this._dialogService.open(NewPostInstallLoadDialogComponent, {
    //   header: 'New Load Case',
    //   // width: '575px',
    //   // height: '268px',
    //   // contentStyle: { overflow: 'auto' },
    //   // keepInViewport: true
    //   // baseZIndex: 10000
    // });

    // this._subscriptions.add(this.newLoadRef.onClose.subscribe((dataChanged: boolean) => {
    //   if (dataChanged) {
    //     this.refreshLoadCases('newLoad');
    //   }
    // }));
  }

  editLoadCase(load: LoadCaseSummaryResult): void {
    this.isSingleClick = false;
    this.isLoading = true;
    this.selectedLoad = load;
    this.expandedRows = {};
    this.expandedRows[this.selectedLoad.index] = true;
    this.lastSelectedLoad = load;
    this.loadCase = null;
    this._loadCaseService.getLoadCase(load.index).subscribe({
    next: (loadCase) => {
      this.loadCase = loadCase;
      this.isLoading = false;

      const index = this.loadCases.findIndex(x => x.index === load.index);
      let loadTop = this.loadsTable.containerViewChild.nativeElement.querySelectorAll('tr')[index].offsetTop;

      setTimeout(() => {
        this.loadsTable?.scrollTo({
            top: loadTop,
            behavior: 'smooth'
        });
      }, 100);
    },
    error: () => {
      this.isLoading = false;
    }});

    if (!this.postInstallLoadsStore.state.drawerOpen) {
      return;
    }
    this.pressTempToggle();
  }

  cancelEdit(e) {
    this.expandedRows = {};
  }

  saveLoadCase(loadCase: LoadCaseDefinitionModel): void {
    this._loadCaseService.updateLoadCase(loadCase).subscribe(res => {
      this.expandedRows = {};
      this.refreshLoadCases('edit', loadCase);
    });
  }

  selectLoad(load: LoadCaseSummaryResult): void {
    // Uses short timeout to prevent running pressTemp call twice on double-click.
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        if (!this.postInstallLoadsStore.state.drawerOpen) {
          return;
        }
        this.pressTempToggle();
      }
    }, 250);
  }

  async onRowReorder(e: any) {
    await lastValueFrom(this._loadCaseService.updateLoadCaseDefinitionOrder({ fromIndex: e.dragIndex, toIndex: e.dropIndex })).finally(async () => {
      // Always reload
      this.loadCases = await lastValueFrom(this._loadCaseService.getLoadCasesSummary(true));
      if (!this.currentString.hasCement) {
        this.loadCases.forEach(x => {
          if (x.eppDisplayName == 'Fluid to Shoe') {
            x.eppDisplayName = 'Annular Fluid';
          }
        });
      }
    });
  }

  cloneLoadCase(load: LoadCaseSummaryResult) {
    const loadCase = { name: load.name + ' 1', index: load.index }
    this._loadCaseService.cloneLoadCaseDefinition(loadCase).subscribe({
      next: () => {
      this.refreshLoadCases('clone', loadCase);
    },
    error: () => { }});
  }

  public selectLoadCases(e: any) {
    if (this.selectedLoadCaseIndexes.includes(e.index)) {
      const index = this.selectedLoadCaseIndexes.indexOf(e.index);
      this.selectedLoadCaseIndexes.splice(index, 1);
      return;
    } else {
      this.selectedLoadCaseIndexes.push(e.index);
    }
  }

  public selectAllLoadCaseIndexes(e: any) {
    this.selectedLoadCaseIndexes = [];
    if (e['srcElement']['checked']) {
      this.loadCases.forEach(x => {
        if (x.name != 'Initial Condition') {
          this.selectedLoadCaseIndexes.push(x.index);
        }
      });
    }
  }

  public deleteLoadCases() {
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete the selected load cases?',
      accept: () => {
        this.selectedLoadCaseIndexes = this.selectedLoadCaseIndexes.sort((a, b) => a - b);
        this._loadCaseService.deleteMultipleLoadCases(this.selectedLoadCaseIndexes).subscribe(() => {
          this.selectedLoadCaseIndexes = [];
          this.refreshLoadCases('deleteMultiple');
        });
      }
    });
  }

  deleteLoadCase(load: LoadCaseSummaryResult) {
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete load case ' + load.name.bold() + '?',
      accept: () => {
        this.isDelete = true;
        this.deletedLoadIndex = load.index;
        this._loadCaseService.deleteLoadCase(load.index).subscribe(x => {
          const index = this.loadCases.findIndex(x => x.index === load.index);
          this.loadCases.splice(index, 1);
          this.postInstallLoadsStore.update(state => ({...state, drawerOpen: false}));
          this.selectedLoad = this.loadCases[0];

          if (this.selectedLoad?.index === load.index) {
            if (!this.selectedLoad) {
              this.plotData = null;
              return;
            }
          }
          this.refreshLoadCases('delete', load);
          this.isDelete = false;
        });
      }
    });

  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }

}
