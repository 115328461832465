<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader class="udt" [isLoading]="isLoading" positionUnset="true">

    <label>Total Depth: {{ totalDepthLabel }}</label>
    <br><br>

    <div class="mr-2">
      <label style="font-size: 14px; padding-left: 5px;">Specify UDT as</label>
      <p-selectButton
        [options]="udtTypeOptions"
        [formControl]="udtForm.controls.isConstantGradient"
        (onChange)="changeUdtType()"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
    
    <form [formGroup]="udtForm">
      <div [style.display]="!isResetting && udtForm.controls.isConstantGradient.value ? 'block' : 'none'">

        <div formGroupName="constantGradient" bg-dark>
          <div class="grid grid-nogutter">
            <div class="col-12">
              <br>
              <div class="field">
                <label for="surfaceAmbientTemp">Surface Temperature</label>
                <div class="p-inputgroup">
                  <input
                    type="number"
                    pInputText
                    formControlName="surfaceAmbientTemperature"
                    id="surfaceAmbientTemp"
                    onfocus="this.select()"
                  />
                  <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                </div>
                <p
                  *ngIf="
                    constGrad.controls.surfaceAmbientTemperature.invalid && !isLoading
                  "
                  class="error"
                >
                  Allowed range: {{ surfaceAndMudlineValidation.min }} -
                  {{ surfaceAndMudlineValidation.max }} {{ tempUnit }}
                </p>
              </div>

              <p-fieldset *ngIf="isOffshore" legend="Offshore">
                <div class="field">
                  <label for="mudlineTemperature">Mudline Temperature</label>
                  <div class="p-inputgroup">
                    <input
                      type="number"
                      pInputText
                      formControlName="mudlineTemperature"
                      id="mudlineTemperature"
                      onfocus="this.select()"
                    />
                    <span class="p-inputgroup-addon">{{ tempUnit }}</span>
                  </div>
                  <p
                    *ngIf="
                      constGrad.controls.mudlineTemperature.invalid && !isLoading
                    "
                    class="error"
                  >
                    Allowed range: {{ surfaceAndMudlineValidation.min }} -
                    {{ surfaceAndMudlineValidation.max }} {{ tempUnit }}
                  </p>
                </div>
                <label>Mudline Depth: {{ mudlineDepth }} {{ depthUnit }}</label>
              </p-fieldset>

              <div class="field">
                <label for="temperatureGradient">Gradient</label>
                <div class="p-inputgroup">
                  <input
                    type="number"
                    pInputText
                    formControlName="temperatureGradient"
                    id="temperatureGradient"
                    onfocus="this.select()"
                  />
                  <span class="p-inputgroup-addon">{{ gradientUnit }}</span>
                </div>
                <p
                  *ngIf="constGrad.controls.temperatureGradient.invalid && !isLoading"
                  class="error"
                >
                  Allowed range: 0 - 6.33 {{ gradientUnit }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label for="temperatureGradient">Temperature at TD</label>
          <div class="p-inputgroup">
            <input
              type="number"
              pInputText
              formControlName="temperatureAtTd"
              id="temperatureAtTd"
              onfocus="this.select()"
            />
            <span class="p-inputgroup-addon">{{ tempUnit }}</span>
          </div>
          <p
            *ngIf="udtForm.controls.temperatureAtTd.invalid && !isLoading"
            class="error"
          >
            Allowed range: {{ tempAtTdValidaton.min }} -
            {{ tempAtTdValidaton.max }} {{ tempUnit }}
          </p>
        </div>

        <hr class="rounded" style="height: 2px" />

        <form [formGroup]="interpolatedTempForm" bg-dark>
          <div class="col-12">
            <label>Temperature Interpolation:</label>
            <div class="field">
              <label class="depth" for="interpolationDepth">Depth</label>
              <div class="p-inputgroup">
                <input
                  placeholder="{{ interpDepth.toFixed(2) ?? 0 }}"
                  type="number"
                  pInputText
                  id="interpolationDepth"
                  formControlName="interpolationDepth"
                  onfocus="this.select()"
                />
                <p-dropdown
                  id="depthTypeSelected"
                  [options]="depthTypes"
                  optionLabel="name"
                  optionValue="value"
                  (onChange)="updateInterpDepth($event)"
                  appendTo="body"
                  formControlName="interpolationDepthType"
                ></p-dropdown>
                <p-inputNumber
                  [ngModel]="interpolatedTemp"
                  [suffix]="tempUnit"
                  disabled="true"
                  [ngModelOptions]="{ standalone: true }"
                ></p-inputNumber>
                <button
                  icon="pi pi-copy"
                  title="Copy"
                  pButton
                  (click)="copyTemperature()"
                ></button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div [style.display]="isResetting || !udtForm.controls.isConstantGradient.value ? 'block' : 'none'">
        <br>
        <ng-table-grid-cmp
          tableName="UDT Profile"
          [tableHeight]="tableHeight"
          [tableData]="customPoints"
          [columnDefinitions]="columnDefinitions"
          [newRowFormGroup]="newDataRow"
          [inputFields]="inputFields"
          [isDynamicLoaded]="true"
          (dataChange)="customPointsChange($event)"
        ></ng-table-grid-cmp>
      </div>
    </form>
  </app-tool-loader>
</p-scrollPanel>
