import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CementingLandingService } from '../../../shared/services/cementing-landing.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, forkJoin, Subscription, lastValueFrom } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { CementingLanding } from '../../models/cementing-landing.model';
import { FormationsService } from 'src/app/catalogs/shared/services/formations-cement-catalog.service';
import { FormationCementCatalog } from 'src/app/catalogs/models/formations-cement-catalog.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-cementing-landing',
  templateUrl: './cementing-landing.component.html',
  styleUrls: ['./cementing-landing.component.scss']
})
export class CementingLandingComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  private _cementBackup: any;
  private _calledFromSignalR: boolean;

  public cementingLandingForm: UntypedFormGroup;
  public hardCementJson: string;
  public isLoading: boolean;
  public configMissing: boolean;
  public stringCemented: boolean;
  public maxTailSlurryLength: number;
  public _hardCement: Array<FormationCementCatalog>;
  public slurryLengthUnit: string;
  public forceUnits: string;
  public densityUnits: string;
  public slurryDensityValidation: { min: number, max: number };
  public displacementDensityValidation: { min: number, max: number };

  // State
  @Input()
  private componentId: string;

  constructor(
    private _cementingLandingService: CementingLandingService,
    private _wellConfigService: WellConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _signalRService: SignalRService,
    private _storeService: StoreService,
    private _cementCatalogService: FormationsService
  ) {
    this._subscriptions = new Subscription();
  }
  
  async ngOnInit(): Promise<void> {
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.slurryLengthUnit = uu.longLengths;
    this.forceUnits = Units.lib[uu.force].symbol;
    this.densityUnits = Units.lib[uu.density].symbol;
    
    this.createReactiveForm();

    let minSlurryDensity = GetValueFromPpg(8.33, this.densityUnits);
    let maxSlurryDensity = GetValueFromPpg(25, this.densityUnits);

    let minDisplacementDensity = GetValueFromPpg(0.01, this.densityUnits);
    let maxDisplacementDensity = GetValueFromPpg(25, this.densityUnits);

    this.slurryDensityValidation = { min: minSlurryDensity, max: maxSlurryDensity };
    this.displacementDensityValidation = { min: minDisplacementDensity, max: maxDisplacementDensity };
    this.cementingLandingForm.controls.leadSlurryDensity.setValidators([Validators.required, Validators.min(minSlurryDensity), Validators.max(maxSlurryDensity)]);
    this.cementingLandingForm.controls.tailSlurryDensity.setValidators([Validators.required, Validators.min(minSlurryDensity), Validators.max(maxSlurryDensity)]);
    this.cementingLandingForm.controls.displacementFluidDensity.setValidators([Validators.required, Validators.min(minDisplacementDensity), Validators.max(maxDisplacementDensity)]);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getCement();

    if (this.cementingLandingForm.controls.tailSlurryLength.value === 0) {
      this.onTailFalse();
    }
  }

  signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST ||
      data.action === PeriforOnChangeMessages.REFRESH_CEMENT_CATALOG) {
      this._calledFromSignalR = true;
      this.getCement();
    }
  }

  createReactiveForm(): void {
    this.cementingLandingForm = this._formBuilder.group({
      leadSlurryDensity: new UntypedFormControl(GetValueFromPpg(15.8, this.densityUnits)),
      tailSlurryDensity: new UntypedFormControl(GetValueFromPpg(16.4, this.densityUnits)),
      tailSlurryLength: new UntypedFormControl(0),
      displacementFluidDensity: new UntypedFormControl(null),
      hasTailCement: new UntypedFormControl({value: false }),
      cement: new UntypedFormControl(null),
      pickupAfterCementSet: new UntypedFormControl({value: ""}, Validators.required),
      slackoffForceOnBottom: new UntypedFormControl(null, Validators.required),
    });

    let debouncedOnChange = this.cementingLandingForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged());
    this._subscriptions.add(debouncedOnChange.subscribe(v => this.onCementLandingFormChange(v)));
  }

  private async onCementLandingFormChange(formData: CementingLanding) {
    if (this.cementingLandingForm.valid) {
        const controlsToCheck = [
            'leadSlurryDensity',
            'displacementFluidDensity',
            'slackoffForceOnBottom',
            'pickupAfterCementSet',
            'hasTailCement',
            'tailSlurryLength',
            'tailSlurryDensity'
        ];

        let inputChanged = controlsToCheck.some(controlName => !this.cementingLandingForm.controls[controlName].pristine);

        if (formData.cement == null) {
            formData.cement = this._cementBackup ?? 
                this._hardCement.find(x => x.name == 'Default') ?? 
                this._hardCement[0];
            
            this.cementingLandingForm.controls.cement.setValue(formData.cement, { emitEvent: false });

            if (inputChanged && !this._calledFromSignalR) {
                await lastValueFrom(this._cementingLandingService.setCementingLanding(formData, null));
            }

            this._calledFromSignalR = false;
            return;
        }

        if (!this._calledFromSignalR) {
          await lastValueFrom(this._cementingLandingService.setCementingLanding(formData, null));
        }
        this._calledFromSignalR = false;
    }
  }

  public onTailChange(type) {
    if (type.checked && this.stringCemented) {
      this.onTailTrue();
    } else {
      this.onTailFalse();
    }
  }

  private getCement() {
    this.isLoading = true;
    this._wellConfigService.getTubular().subscribe({
      next: (currentString) => {
      if (currentString?.id != null) {
        const sources: Observable<any>[] = [
          this._cementingLandingService.getCementingLanding(null) as Observable<any>,
          this._cementCatalogService.getFormationCement() as Observable<any>
        ];

        forkJoin(sources).pipe(
          map(([data, hardCement]) => {
            const cementingLanding = new CementingLanding(data);
            this.configMissing = false;
            this._hardCement = hardCement.filter(x => x.discriminator == 'cement').sort((a, b) => a.name.localeCompare(b.name));
            this._cementBackup = data.cement;

            this.maxTailSlurryLength = +(currentString.shoeMd - currentString.topOfCementMd).toFixed(1);
            this.cementingLandingForm.controls.tailSlurryLength.setValidators([Validators.min(0), Validators.max(this.maxTailSlurryLength)]);

            if (cementingLanding.displacementFluidDensity == 0) {
              cementingLanding.displacementFluidDensity = currentString.annularFluid.state['nominalDensity'];
            }

            if (!cementingLanding.pickupAfterCementSet) {
              cementingLanding.pickupAfterCementSet = 0;
            }

            if (!cementingLanding.slackoffForceOnBottom) {
              cementingLanding.slackoffForceOnBottom = 0;
            }

            // Sets all form values based on API response
            this.cementingLandingForm.patchValue(cementingLanding);

            this.stringCemented = currentString.hasCement;
            if (!currentString.hasCement) {
              this.cementingLandingForm.controls.cement.disable({ emitEvent: false });
              this.cementingLandingForm.controls.pickupAfterCementSet.disable({ emitEvent: false });
            } else {
              this.cementingLandingForm.controls.cement.enable({ emitEvent: false });
              this.cementingLandingForm.controls.pickupAfterCementSet.enable({ emitEvent: false });
            }
            if (currentString.hasCement && currentString.topOfCementMd < currentString.shoeMd) {
              this.cementingLandingForm.controls.leadSlurryDensity.enable({ emitEvent: false });
              this.cementingLandingForm.controls.tailSlurryDensity.enable({ emitEvent: false });
              this.cementingLandingForm.controls.tailSlurryLength.enable({ emitEvent: false });
            } else {
              this.cementingLandingForm.controls.leadSlurryDensity.disable({ emitEvent: false });
              this.cementingLandingForm.controls.tailSlurryDensity.disable({ emitEvent: false });
              this.cementingLandingForm.controls.tailSlurryLength.disable({ emitEvent: false });
            }

            if (this.cementingLandingForm.value.hasTailCement && currentString.hasCement) {
              this.onTailTrue();
            } else {
              this.onTailFalse();
            }

            this.hardCementJson = this.showJson(this.cementingLandingForm.get('cement').value);

            this.isLoading = false;
          }),
          catchError(err => {
            this.configMissing = true;
            this.isLoading = false;
            return err;
          })).subscribe();
      } else {
        this.configMissing = true;
        this.isLoading = false;
        return;
      }
    },
    error: () => {
      this.isLoading = false;
    }});
  }

  onTailTrue() {
    this.cementingLandingForm.controls.tailSlurryDensity.enable({ emitEvent: false });
    this.cementingLandingForm.controls.tailSlurryLength.enable({ emitEvent: false });
    if (!this.cementingLandingForm.controls.tailSlurryDensity.value) {
      this.cementingLandingForm.controls.tailSlurryDensity.setValue(GetValueFromPpg(16.4, this.densityUnits));
    }
    if (!this.cementingLandingForm.controls.tailSlurryLength.value) {
      this.cementingLandingForm.controls.tailSlurryLength.setValue(this.slurryLengthUnit == 'ft' ? 100 : 30);
    }
  }

  onTailFalse() {
    this.cementingLandingForm.controls.tailSlurryDensity.disable({ emitEvent: false });
    this.cementingLandingForm.controls.tailSlurryLength.disable({ emitEvent: false });
    this.cementingLandingForm.controls.tailSlurryDensity.setValue(GetValueFromPpg(16.4, this.densityUnits), { emitEvent: false });
    this.cementingLandingForm.controls.tailSlurryLength.setValue(0);
  }

  changeHasTailSlurry(e) {
    if (e.srcElement.valueAsNumber === 0) {
      this.cementingLandingForm.controls.hasTailCement.disable();
    } else {
      this.cementingLandingForm.controls.hasTailCement.enable();
    }
  }

  getPlaceholderText(data: any) {
    if (data == null) {
      return this._cementBackup?.name + ' (local only)';
    }
    let val = data?.value ? data.value : data;
    let name = !this.checkCementProperties(data) ?
       val?.name : val?.name + ' (local only)';
    return name;
  }

  private checkCementProperties(cement: FormationCementCatalog): boolean {
    return this._hardCement.find(x => x.name == cement.name && x.density == cement.density && x.specificHeatCapacity == cement.specificHeatCapacity &&
      x.thermalConductivity == cement.thermalConductivity && x.youngsModulus == cement.youngsModulus && x.poissonsRatio == cement.poissonsRatio &&
      x.thermalExpansionCoefficient == cement.thermalExpansionCoefficient) != null;
  }

  public showJson(data: any) {
    let val = data?.value ? data.value : data;
    return JSON.stringify(val, null, '\t');
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }

}
