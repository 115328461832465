import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header',
  template: `
  <p-toolbar>
    <!--Main Level Navigation-->
    <!--Navigation for the app modules-->
    <div class="p-toolbar-group-left">
      <p-tabMenu
        [model]="items"
        [activeItem]="items[0]"
        *ngIf="!document.location.href.includes('designs') && auth.isAuthenticated$ | async">
      </p-tabMenu>
    </div>

    <div class="p-toolbar-group-center header" *ngIf="auth.isAuthenticated$ | async">
      <h4 class="firstPart">PERI</h4><h4 class="secondPart">FOR</h4>
    </div>

    <div class="p-toolbar-group-right">
      <app-notification-hub *ngIf="auth.isAuthenticated$ | async"></app-notification-hub>
      <app-help-menu *ngIf="auth.isAuthenticated$ | async"></app-help-menu>
      <user-menu *ngIf="auth.isAuthenticated$ | async"></user-menu>
    </div>

  </p-toolbar>
`,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public items: Array<MenuItem> = [
    {
      label: 'Designs',
      routerLink: ['/designs']
    }
    // Add other module levels here, ie: Reports, Etc
  ];

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) public document
  ) { }
}
