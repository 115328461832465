<form [formGroup]="form.formGroup">
  <p-toolbar>
    <div class="mr-2">
      <p-inputSwitch
        (onChange)="typeChange($event)"
        [formControlName]="form.formControlNames.co2withImpurities"
        (onChange)="getPercentages()"
      ></p-inputSwitch>
      <label> C</label><label>O</label><label class="sub">2</label> <label> With Impurities</label>
    </div>
    <div class="mr-2">
      <label>Phase Envelope</label>
      <p-inputSwitch
        (onChange)="tablePlotToggle()"
      ></p-inputSwitch>
    </div>

    <div *ngIf="showPlot" class="mr-2">
      <label>Show Grid</label>
      <p-inputSwitch
        [disabled]="isReadOnly"
        (onChange)="plotTableResultChange()"
      ></p-inputSwitch>
    </div>

    <div class="p-toolbar-group-right mr-2 defaultButton">
      <img (click)="applyDefaults()" title="Apply Defaults" src="../../assets/icons/default-icon-white.png" />
    </div>

  </p-toolbar>
    <br *ngIf="!showPlot">
    <app-tool-loader class="toolLoader" [isLoading]="isLoading && showPlot" positionUnset="true">
      <div [style.display]="showGridResult ? 'block' : 'none'">
        <ng-table-results-cmp
        tableName="Phase Envelope"
        [tableData]="resultsGrid"
        [columnDefinitions]="cols"
        [tableHeight]="tableHeight"
      ></ng-table-results-cmp>
     </div>

    <div *ngIf="showPlot && !showGridResult">
      <app-xy-line-plot
        [plotData]="plot.data"
        [xAxisTitle]="xAxisTitle"
        [yAxisTitle]="yAxisTitle"
        [plotName]="plotName"
        [downloadPlotName]="downloadPlotName"
        [reverseAutoRange]="true"
        [plotTitle]="'Fluids'"
        [componentHeight]="fluidsComponentHeight"
      ></app-xy-line-plot>
    </div>
  </app-tool-loader>

    <div [style.display]="!showPlot ? 'block' : 'none'">
      <div *ngIf="!form.formGroup.controls.co2withImpurities.value">
        <label>Pure C</label><label>O</label><label class="sub">2</label> <label> will be modelled</label>
      </div>
      <div *ngIf="form.formGroup.controls.co2withImpurities.value">
        <label> C</label><label>O</label><label class="sub">2</label> <label> will be modelled with the following impurities:</label>
      </div>
      <br>

      <p-fieldset legend="Gas Composition %">
        <div class="{{ form.formGroup.controls.co2withImpurities.value ? '' : 'disableComposition' }}">

          <div class="grid">
            <div class="col">
              <div class="field">
                <label for="ar">A</label><label class="sub">r</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.ar"
                  id="ar"
                  required
                  (onInput)="validatePercentage('ar', $event)"
                  [placeholder]="form.formGroup.controls.co2withImpurities.value ? 0.002 : 0"
                  [maxFractionDigits]="6"
                  />
                <p *ngIf="form.formGroup.controls.ar.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
            <div class="col">
              <div class="field">
                <label for="h2S">H</label><label class="sub">2</label><label>S</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.h2s"
                  id="h2s"
                  required
                  (onInput)="validatePercentage('h2s', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.h2s.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
            <div class="col">
              <div class="field">
                <label for="n2">N</label><label class="sub">2</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.n2"
                  id="n2"
                  required
                  (onInput)="validatePercentage('n2', $event)"
                  [placeholder]="form.formGroup.controls.co2withImpurities.value ? 1.5 : 0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.n2.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
        </div>

        <div class="grid">
            <div class="col">
              <div class="field">
                <label>C1 =&nbsp;</label><label>CH</label><label class="sub">4</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.cH4"
                  id="cH4"
                  required
                  (onInput)="validatePercentage('cH4', $event)"
                  [placeholder]="this.form.formGroup.controls.co2withImpurities.value ? 0.49 : 0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.cH4.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="c2">C</label><label class="sub">2</label>
                <p-inputNumber
                  id="c2"
                  [formControlName]="form.formControlNames.c2"
                  required
                  (onInput)="validatePercentage('c2', $event)"
                  [maxFractionDigits]="6"
                  />
                  <p *ngIf="form.formGroup.controls.c2.invalid" class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="c3">C</label><label class="sub">3</label>
                <p-inputNumber
                  id="c3"
                  [formControlName]="form.formControlNames.c3"
                  required
                  (onInput)="validatePercentage('c3', $event)"
                  [maxFractionDigits]="6"
                  />
                  <p *ngIf="form.formGroup.controls.c3.invalid" class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col">
              <div class="field">
                <label for="cO">CO</label>
                <p-inputNumber
                  id="cO"
                  [formControlName]="form.formControlNames.cO"
                  required
                  (onInput)="validatePercentage('cO', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                  />
                  <p *ngIf="form.formGroup.controls.cO.invalid" class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
            <div class="col">
              <div class="field">
                <label for="nO2">N2O</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.nO2"
                  id="nO2"
                  required
                  (onInput)="validatePercentage('nO2', $event)"
                  [placeholder]=""
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.nO2.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
            <div class="col">
              <div class="field">
                <label>H</label><label class="sub">2</label><label>O</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.h2O"
                  id="h2O"
                  required
                  (onInput)="validatePercentage('h2O', $event)"
                  [placeholder]="form.formGroup.controls.co2withImpurities.value ? 0.005 : 0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.h2O.invalid" class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col">
              <div class="field">
                <label for="h2">H</label><label class="sub">2</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.h2"
                  id="h2"
                  required
                  (onInput)="validatePercentage('h2', $event)"
                  [placeholder]="form.formGroup.controls.co2withImpurities.value ? 2 : 0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.h2.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="o2">O</label><label class="sub">2</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.o2"
                  id="o2"
                  required
                  (onInput)="validatePercentage('o2', $event)"
                  [placeholder]="form.formGroup.controls.co2withImpurities.value ? 0.001 : 0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.o2.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label>S</label><label>O</label><label class="sub">2</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.sO2"
                  id="sO2"
                  required
                  (onInput)="validatePercentage('sO2', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.sO2.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col">
              <div class="field">
                <label for="nc4">NC</label><label class="sub">4</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.nC4"
                  id="nC4"
                  required
                  (onInput)="validatePercentage('nC4', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.nC4.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="iC4">i-C</label><label class="sub">4</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.iC4"
                  id="iC4"
                  required
                  (onInput)="validatePercentage('iC4', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.iC4.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="nC5">NC</label><label class="sub">5</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.nC5"
                  id="nC5"
                  required
                  (onInput)="validatePercentage('nC5', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.nC5.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col">
              <div class="field">
                <label for="iC5">IC</label><label class="sub">5</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.iC5"
                  id="iC5"
                  required
                  (onInput)="validatePercentage('iC5', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.iC5.invalid" class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="nC6">NC</label><label class="sub">6</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.nC6"
                  id="nC6"
                  required
                  (onInput)="validatePercentage('nC6', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.nC6.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <label for="c7">C</label><label class="sub">7</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.c7"
                  id="c7"
                  required
                  (onInput)="validatePercentage('c7', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.c7.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col">
              <div class="field">
                <label for="c8">C</label><label class="sub">8</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.c8"
                  id="c8"
                  required
                  (onInput)="validatePercentage('c8', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.c8.invalid"class="error">Allowed range: 0 - 100 %</p>
              </div>
            </div>

            <div class="col">
              <div class="field">
                <!-- <label for="h2SO4">H</label><label class="sub">2</label><label for="h2SO4">S</label><label for="h2SO4">O</label><label class="sub">4</label>
                <p-inputNumber
                  [formControlName]="form.formControlNames.h2SO4"
                  id="h2SO4"
                  required
                  (onInput)="validatePercentage('h2SO4', $event)"
                  [placeholder]="0"
                  [maxFractionDigits]="6"
                />
                <p *ngIf="form.formGroup.controls.h2SO4.invalid"class="error">Allowed range: 0 - 100 %</p> -->
              </div>
            </div>

            <div class="col">
              <div class="fluid">
                <div class="field totalLabel">
                  <label class="{{ totalError ? 'error' : '' }}">Total Impurities: {{ totalPercentage }} %</label>
                </div>
              </div>
            </div>

          </div>
      </div>
    </p-fieldset>

  <br>
  <div class="field">
    <label>Total CO</label><label class="sub">2</label><label> : {{ co2Percentage }} %</label>
  </div>
  <div class="field">
    <label class="gergWarning" *ngIf="gergLabel">{{ gergLabel }}</label>
  </div>
</div>
</form>
<!-- <pre>{{ form.formGroup.Values | json }}</pre> -->
