<form *ngIf="moveDesignForm" [formGroup]="moveDesignForm">

  <p-fieldset legend="Wellbores" class="col-12">

    <div class="col-12">
      <label for="designTemplate">Move the design to the following wellbore:</label>
      <br />
      <p-treeSelect
        [options]="treeItems"
        appendTo="body"
        id="wellbore"
        fromControlName="toWellboreId"
        placeholder="Select a wellbore"
        filter="true"
        filterBy="data.label"
        showClear="true"
        (onNodeSelect)="onWellboreSelect($event.node)"
        (onClear)="onWellboreClear()"
      >
        <ng-template pTemplate="default" let-node>
          <img
            *ngIf="node?.data.level != 'designs'"
            class="level-icon"
            [src]="node?.data.icon"
          />
          <span>{{ node?.data.label }}</span>
        </ng-template>
        <ng-template pTemplate="value" let-node>
          <div *ngIf="!node">Select a wellbore</div>
          {{ node?.data?.label }}
        </ng-template>
      </p-treeSelect>
    </div>
  </p-fieldset>

  <br>
  <div style="float: right">
    <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-danger" label="Cancel"
      (click)="onCancel()"></button>
    <button pButton type="button" icon="pi pi-check" class="p-button-text p-button-success"
      [disabled]="moveDesignForm.invalid" label="Save" (click)="onSave()"></button>
  </div>
</form>
