<div #toolbarDiv>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <div class="mr-2 typeDropdown">
        <p-dropdown
          [options]="singleMultiple"
          appendTo="body"
          (onChange)="modeToggle($event.value)"
          [(ngModel)]="pressureResultsStore.state.selectedSingleMultiple"
        ></p-dropdown>
      </div>
      <div class="mr-2">
        <p-selectButton
          [options]="resultsDisplay"
          [(ngModel)]="pressureResultsStore.state.selectedResultsDisplay"
          (onChange)="onSelectResultsDisplay($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
      <div class="flex grid-nogutter" *ngIf="pressureResultsStore.state?.selectedResultsDisplay === 'plot'">
        <div class="mr-2">
          <p-selectButton
            [options]="depthView"
            [(ngModel)]="pressureResultsStore.state.selectedDepthView"
            (onChange)="depthViewToggle($event)"
            [allowEmpty]="false"
          ></p-selectButton>
        </div>
      </div>
    </div>
    <div class="p-toolbar-group-center dropdownClassTemperatures">
      <p-checkbox
        *ngIf="
          pressureResultsStore.state?.selectedSingleMultiple ===
          'singleOperation'
        "
        (onChange)="syncPressAndTemp($event)"
        class="syncCheckbox"
        [(ngModel)]="pressureResultsStore.state.syncThermalResults"
        title="Sync P&T results"
        binary="true"
      ></p-checkbox>
      <p-dropdown
        [options]="operations"
        [(ngModel)]="pressureResultsStore.state.selectedOperation"
        (onChange)="onOperationSelected($event, true)"
        optionLabel="name"
        appendTo="body"
        *ngIf="
          pressureResultsStore.state?.selectedSingleMultiple ===
          'singleOperation'
        "
      >
      </p-dropdown>

      <p-dropdown
        [options]="annulusNames"
        (onChange)="annulusDropdownChange($event)"
        [(ngModel)]="pressureResultsStore.state.selectedAnnulus"
        appendTo="body"
        *ngIf="
          pressureResultsStore.state?.selectedSingleMultiple ===
          'multipleOperation'
        "
      ></p-dropdown>

      <p-button
        [disabled]="moveUpDisabled"
        (onClick)="onUpDownButtonClick('up', true)"
        icon="pi pi-arrow-up"
        class="upDownButtons"
      ></p-button>
      <p-button
        [disabled]="moveDownDisabled"
        (onClick)="onUpDownButtonClick('down', true)"
        icon="pi pi-arrow-down"
        class="upDownButtons"
      ></p-button>

      <p-dropdown
        [options]="phases"
        [ngModel]="pressureResultsStore.state.selectedPhase"
        (onChange)="onPhaseSelected($event, true)"
        optionLabel="phaseNumber"
        [hidden]="
          phases?.length <= 1 ||
          pressureResultsStore.state?.selectedSingleMultiple ===
            'multipleOperation' ||
          pressureResultsStore.state?.selectedSingleMultiple === 'operationType'
        "
        appendTo="body"
        class="phaseDropdown"
      >
        <ng-template pTemplate="selectedItem">
          Phase {{ pressureResultsStore.state?.selectedPhase?.phaseNumber }}
        </ng-template>
        <ng-template let-phase pTemplate="item">
          Phase {{ phase.phaseNumber }}
        </ng-template>
      </p-dropdown>
    </div>
    <div class="mr-2"></div>
  </p-toolbar>
</div>
<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <br *ngIf="pressureResultsStore.state?.selectedResultsDisplay == 'grid'" />
  <ng-table-results-cmp
    tableName="Thermal Results - Pressures"
    [tableData]="resultsGrid"
    [columnDefinitions]="
      pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'
        ? cols
        : operationNames
    "
    [tableHeight]="tableHeight"
    [selectedOperation]="pressureResultsStore.state.selectedOperation"
    *ngIf="pressureResultsStore.state?.selectedResultsDisplay == 'grid'"
    [virtualScroll]="true"
  ></ng-table-results-cmp>

  <app-thermal-results-plot
    *ngIf="pressureResultsStore.state?.selectedResultsDisplay === 'plot'"
    xAxisTitle="Pressure ({{ pressUnit }})"
    depthUnit="{{ depthUnit }}"
    plotTitle="Thermal Results - Pressures"
    [plotData]="
      pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'
        ? results
        : multipleResultsFull
    "
    [columnDefinitions]="
      pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'
        ? cols
        : operationNames
    "
    [selectedDepthDisplay]="pressureResultsStore.state.selectedDepthView"
    [plotName]="plotName"
    [componentHeight]="componentHeight"
    [downloadPlotName]="pressureResultsStore.state.selectedSingleMultiple === 'singleOperation' ? 'single_operation_plot' : 'multiple_operation_plot'"
  ></app-thermal-results-plot>

  <p-dialog
    [draggable]="false"
    [(visible)]="missingOperations"
    [closable]="false"
  >
    <i class="pi pi-exclamation-circle"></i> No Operations Exist: Please specify
    at least one operation to view results. <br />
    <br />
  </p-dialog>
  <p-dialog
    [draggable]="false"
    [visible]="isDialogVisible"
    [closable]="false"
  >
    <i class="pi pi-exclamation-circle"></i> {{ calculationError }} <br />
    <br />
  </p-dialog>
</app-tool-loader>
