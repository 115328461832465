// export class ExternalPressureProfile {

//     public type: string;
//     public hangerPressure: number;
//     public fluidDensity: number;
//     //optional (not used on all types)
//     public pressurePoints?: Array<any>;Venersborg, Washington 98604
//     public thermalOperationId?: string;

// }

export type ExternalPressureProfile = CustomEPP | PericalLinkEPP | FluidToShoeEPP | MudAndWetCementEPP |
  MudAndWetCementMixWaterEPP | InterpolationToPorePressureEPP | MudAndPorePressureEPP | PorePressureEPP;

export enum ExternalPressureProfileType {
  CUSTOMEPP = 'CustomExternalPressures',
  PERICALLINK = 'ThermalLink',
  FLUIDTOSHOE = 'MudToShoe',
  MUDANDWETCEMENT = 'MudAndWetCement',
  MUDANDCEMENTMIXWATER = 'MudAndCementMixWater',
  INTERPOLATIONTOPOREPRSSURE = 'InterpolationToPorePressure',
  MUDANDPOREPRESSURE = 'MudAndPorePressure',
  POREPRESSURE = "PorePressure"
}

export interface PressurePoint {
  measuredDepth: number;
  pressure: number;
}

export interface GeneralEPP {
  fluidDensity: number;
  hangerPressure: number;
}

export interface GasCap {
  hasGasCap: boolean;
  gasCapLength: number;
  gasGradient: number;
}

export interface CustomEPP {
  readonly type: ExternalPressureProfileType.CUSTOMEPP;
  pressurePoints: PressurePoint[];
}

export interface PericalLinkEPP {
  readonly type: ExternalPressureProfileType.PERICALLINK;
  thermalOperationId: string;
}

export interface FluidToShoeEPP {
  readonly type: ExternalPressureProfileType.FLUIDTOSHOE;
  fluidDensity: number;
  hangerPressure: number;
  hasGasCap: boolean;
  gasCapLength: number;
  gasGradient: number;
}

export interface MudAndWetCementEPP {
  readonly type: ExternalPressureProfileType.MUDANDWETCEMENT;
  fluidDensity: number;
  hangerPressure: number;
}

export interface MudAndWetCementMixWaterEPP {
  readonly type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER;
  fluidDensity: number;
  hangerPressure: number;
  cementMixwaterDensity: number;
}

export interface InterpolationToPorePressureEPP {
  readonly type: ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE;
  fluidDensity: number;
  hangerPressure: number;
}

export interface MudAndPorePressureEPP {
  readonly type: ExternalPressureProfileType.MUDANDPOREPRESSURE;
  fluidDensity: number;
  hangerPressure: number;
  cementMixwaterDensity: number;
  hasGasCap: boolean;
  gasCapLength: number;
  gasGradient: number;
}

export interface PorePressureEPP {
  readonly type: ExternalPressureProfileType.POREPRESSURE;
}
