import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { clipboardStringParser } from 'src/app/shared/services/data-helpers';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { saveAs } from 'file-saver';
import { Design } from '../../models/design.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'ng-table-grid-cmp',
  templateUrl: './ng-table-grid.component.html',
  styleUrls: ['./ng-table-grid.component.scss'],
  providers: [ConfirmationService]
})
export class NgTableGridComponent<T> implements OnInit, OnDestroy {

  private _tableData: Array<T>;
  private _initialized: boolean;
  private _subscriptions: Subscription;
  private _backupDataFormArray: any;

  public calculatedValuesDict: object;
  public dataRowsForm: FormGroup;
  public newDataRowForm: FormGroup;
  public calculating: boolean;
  public contextMenuItems: Array<MenuItem>;
  public selectedRowIdx: number;
  public currentDesign: Design;

  public get dataFormArray(): FormArray {
    return this.dataRowsForm.get("dataArray") as FormArray;
  }

  public pTableData : AbstractControl[];

  @Input()
  public componentId: string;

  @Input()
  set tableData(val: Array<T>) {
    if (val) {
      this._tableData = val;
      this.createCalculatedValuesLookup(val);
      this.populateFormData(val);
      this.calculating = false;
    }
  }

  @Input()
  public virtualScroll: string;

  @Input()
  public inputFields: Array<{ name: string, minFractions: number, maxFractions: number, formatDecimals: number }>;

  @Input()
  public calculatedFields: Array<{ name: string, formatDecimals: number }>;

  @Input()
  public tableName: string;

  @Input()
  public tableHeight: string;

  @Input()
  public isDynamicLoaded: boolean = false;

  @Input()
  public enableVirtualScroll: boolean = true;

  @Input()
  public columnDefinitions: Array<{ field: string, header: string }>;

  @Input()
  public newRowFormGroup: () => FormGroup;

  @Input()
  public tableHeader: string;

  @Output()
  public dataChange: EventEmitter<{ triggeredBy: any, dataRows: Array<T>, reload: boolean }> = new EventEmitter();

  @ViewChild('dataTable', { read: Table, static: false })
  public dataTable: Table;

  @ViewChild('inputFocusFieldRef', { read: ElementRef, static: false })
  public inputFocusFieldRef: ElementRef;

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  constructor(
    private _formBuilder: FormBuilder,
    private _storeService: StoreService,
    private _confirmationService: ConfirmationService
  ) {
    this._subscriptions = new Subscription();
    this.calculatedValuesDict = {};
    this.buildForm();
  }

  async ngOnInit(): Promise<void> {
    this.currentDesign = await this._storeService.get<Design>(StorageKeys.DESIGN);

    this.contextMenuItems = [
      { label: 'Insert', icon: 'pi pi-fw pi-angle-up', command: () => this.onInsert(this.selectedRowIdx) },
      { label: 'Delete', icon: 'pi pi-fw pi-times', command: () => this.onDelete(this.selectedRowIdx) }
    ];
    this.newDataRowForm = this.newRowFormGroup();
    this._initialized = true;
    this.populateFormData(this._tableData);
    this.createCalculatedValuesLookup(this._tableData);
  }

  public onDelete(idx: number): void {
    if (idx > 0) {
      this.dataFormArray.removeAt(idx);
      this.pTableData = [...this.dataFormArray.controls];
      this.outputEventData({type: 'rowDelete', data: idx});
    }
  }

  public addRow(): void {
    if (this.newDataRowForm.valid) {

      let newRow = this.newRowFormGroup();
      newRow.patchValue(this.newDataRowForm.value);
      newRow.markAllAsTouched(); // Trigger validation
      this._subscriptions.add(newRow.valueChanges.subscribe(v => this.onCellEdit(v, -1, '')));

      this.dataFormArray.push(newRow);

      this.outputEventData({type: 'rowAdd', data: newRow});
      this.newDataRowForm.reset();

      this.pTableData = [...this.dataFormArray.controls];

      setTimeout(() => {  //Ensure data is added in grid before scrolling
        this.dataTable.scrollTo({ top: 5000 });
        this.inputFocusFieldRef.nativeElement.getElementsByClassName("p-inputnumber-input")[0].focus();
      }, 500)
    }
  }

  public onInsert(idx: number): void {
    if (idx > 0) {
      let newRow = this.newRowFormGroup();
      newRow.patchValue(this.newDataRowForm.value);
      newRow.markAllAsTouched();
      this._subscriptions.add(newRow.valueChanges.subscribe(v => this.onCellEdit(v, -1, '')));

      this.dataFormArray.insert(idx, newRow);
      this.pTableData = [...this.dataFormArray.controls];
      this.newDataRowForm.reset(this.newDataRowForm.getRawValue());
    }
  }

  /*
    Builtin CSV export doesn't work because the table holds FormControls intead of basic data.
  */
  exportFunction = () => {
    let delimiter = ", ";
    let headers = this.columnDefinitions.map(x => `${x.header}`).join(delimiter);
    let outputData = this._tableData.map(x => Object.values(x));
    let csv = outputData.map(v => v.map(x => `${x}`).join(delimiter)).join('\n');;
    saveAs(new Blob([headers + '\n' + csv], { type: "application/csv;charset=utf-8" }), `${this.tableName}_Design_${this.currentDesign.name}.csv`);
  }

  public onCellEdit($event: any, rIdx: number, fieldName: string): void {
    // Validtion needs fired on all the controls, due to updating items possibly causing validation in adjacent rows
    this.dataFormArray.controls.forEach(x => x.updateValueAndValidity({ emitEvent: false }));

    const oldValue = this._backupDataFormArray[rIdx][fieldName];
    const newValue = $event.srcElement.ariaValueNow;
    if (oldValue != newValue && this.dataFormArray.valid) {
      this.outputEventData({type: 'cellEdit', data: $event});
    }
  }

  public clearTable(): void {
    this._confirmationService.confirm({
      message: this.tableName == 'UDT Profile' ? 'Reset grid to Gradient defaults?' : 'Are you sure that you want to clear this table?',
      accept: () => {
      this.dataFormArray.clear();
      this._tableData = [];

      setTimeout(()=>{
        this.populateFormData(this._tableData);
        this.pTableData = [...this.dataFormArray.controls];
      },500);

      this.outputEventData({type: 'reset'}, true);
    }
  });
  }

  public onPaste(event: ClipboardEvent, idx: number = null): void {
    if (this.tableName == 'Pore Pressure' || this.tableName == 'Fracture Gradient' || this.tableName == 'Trajectory') {
      this.dataFormArray.controls.splice(1, this.dataFormArray.controls.length - 1);
      this._tableData.splice(1, this._tableData.length - 1);
    } else {
      this.dataFormArray.clear();
      this._tableData = [];
    }

    let clipBoardData = clipboardStringParser(event);

    if ((this.tableName == 'Trajectory' || this.tableName == 'Pore Pressure' || this.tableName == 'Fracture Gradient') && clipBoardData[0][0] == 0 && idx == 1) {
      clipBoardData.shift();
    }

    if(clipBoardData.length == 1){ // Just paste single cell (control natively handles this)
      event.preventDefault();
      return;
    }

    idx = idx != null ? idx : this.dataFormArray.controls.length;

    if (clipBoardData != null) {

      let newDataArray = clipBoardData.map(r => {
        let obj = {};
        this.inputFields.forEach((k, i) => obj[k.name] = r[i] || "");
        return obj;
      });

      let updateData = [...this.dataFormArray.getRawValue()];
      updateData.splice(idx, 1, ...newDataArray);

      this.dataFormArray.clear();
      this.populateFormData(updateData);

      this.outputEventData({type: 'clipboard', data: event}, true, true);
    }
  }

  public getDictValue(row: FormGroup): number {
    let key = this.inputFields.map(inFs => {
      return parseFloat(row.value[inFs.name]); // Trims trailing 0
    }).join(":");
    return this.calculatedValuesDict[key] || {};
  }

  private createCalculatedValuesLookup(valuesArray: Array<any>): void {
    if (!valuesArray || !this._initialized || !this.calculatedFields) {
      return;
    }
    for (let v of valuesArray) {
      let key = this.inputFields.map(inFs => {
        return parseFloat(v[inFs.name]); // Trims trailing 0
      }).join(":");
      let valObj = {};
      this.calculatedFields.forEach(cf => {
        valObj[cf.name] = v[cf.name]
      });
      this.calculatedValuesDict[key] = valObj;
    }
  }

  private buildForm(): void {
    this.dataRowsForm = this._formBuilder.group({
      dataArray: this._formBuilder.array([]),
    });
  }

  private populateFormData(formData: Array<T>) {
    if (!formData || !this._initialized || this.dataFormArray.controls.length > 0 && !this.isDynamicLoaded) {
      return; // Only want to populate the form the first time, then update just values dictionary on data changes.
    }
    if (this.isDynamicLoaded) {
      this.dataFormArray.clear();
    }
    formData.forEach((data, idx) => {
      let fg = this.newRowFormGroup();
      this.dataFormArray.push(fg);
      this.dataFormArray.controls[idx].patchValue(data, { emitEvent: false });
      this.dataFormArray.controls[idx].markAllAsTouched(); // Runs validators
    });
    if(formData.length == 0){
      this.dataFormArray.push(this.newRowFormGroup());
    }
    // Setting a property here for virtual scroll to work correctly.
    this.pTableData = [...this.dataFormArray.controls];
    this._backupDataFormArray = [...this.dataFormArray.getRawValue()];
  }

  protected outputEventData(triggeredBy: any, reload: boolean = true, isDelete: boolean = false): void {
    if (isDelete || this.dataRowsForm.valid) {  // Form is typically not valid when all the data is pulled...
      let data = this.dataFormArray.getRawValue();
      // Find exact change value
      this.dataChange.next({ triggeredBy, dataRows: data, reload });
      this.calculating = (reload && this.calculatedFields !== undefined);
    }
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
   }
}
