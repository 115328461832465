<tr
  [title]="getWellExplorerLevelName(treeNode?.node.data.level)"
  class="{{ treeNode?.node.data.level != designLevel ? '' : 'nameClass' }}"
  [ttRow]="treeNode"
  [ttSelectableRowDblClick]="treeNode"
  (contextmenu)="filterContextMenu(treeNode.node.data.level)"
  (mouseover)="onMouseOver(treeNode)"
  (mouseleave)="hover = false; hoverNode = null"
  [style]="{ 'user-select': 'none' }"
>
  <td
    *ngFor="let col of columns; let i = index"
    class="{{ treeNode.node.data.level != designLevel ? '' : 'nameClass' }}"
  >
    <div class="flex align-items-center">
      <p-treeTableToggler
        *ngIf="i == 0"
        class="{{ treeNode.node.data.level != designLevel ? '' : 'nameClass' }}"
        [rowNode]="treeNode"
      ></p-treeTableToggler>
      <img *ngIf="treeNode.node.data.level != designLevel" class="level-icon" [src]="treeNode.node.data.icon" />
      <span class="{{ treeNode.node.data.level == designLevel ? 'might-overflow-design' : 'might-overflow' }}">{{ rowData[col.field] }}</span>
    </div>
  </td>

  <td class="treeFunctionIconsColumn">
    <div *ngIf="hover === true && hoverNode === treeNode">
      <span class="buttonsCol">
        <i
          *ngIf="treeNode.node.data.level !== designLevel"
          class="pi pi-plus p-button p-button-text p-button-sm p-button-success addBtn"
          pTooltip="{{ 'Create New ' + getChildLevel(treeNode.node.data.level)?.slice(0, - 1) | titlecase }}"
          (click)="addChildNode(treeNode.node)"
        ></i>
        <i
          *ngIf="treeNode.node.data.level === designLevel"
          class="pi pi-copy p-button p-button-text p-button-sm p-button-info cloneBtn"
          pTooltip="Clone"
          (click)="cloneDesign(treeNode.node)"
        ></i>

        <i
          *ngIf="isAdmin"
          class="pi pi-trash p-button p-button-text p-button-sm p-button-danger deleteBtn"
          pTooltip="Delete"
          (click)="deleteItem(treeNode.node)"
        ></i>
        <i
          *ngIf="!isAdmin"
          class="pi pi-lock p-button p-button-text p-button-sm p-button-danger deleteBtn"
          pTooltip="Only Admin level users have permissions to delete Well Explorer items"
        ></i>
      </span>
    </div>
  </td>
</tr>
