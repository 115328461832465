<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="flex">
    <div class="mr-2">
      <p-selectButton
        [options]="resultsDisplay"
        [(ngModel)]="liftoffResultsStore.state.selectedResultDisplay"
        (onChange)="onSelectedResultDisplayUpdated($event)"
        [allowEmpty]="false"
      ></p-selectButton>
    </div>
  </div>

  <app-xy-line-plot
    *ngIf="liftoffResultsStore.state?.selectedResultDisplay === 'plot'"
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [downloadPlotName]="downloadPlotName"
    [legendBelowPlot]="true"
    [annotations]="annotations"
    [shapes]="shapes"
    [plotTitle]="'Lift-Off Results'"
  ></app-xy-line-plot>

  <div class="lockdownRating flex justify-content-end" *ngIf="!wellheadDisabled && liftoffResultsStore.state?.selectedResultDisplay == 'grid'">Wellhead Hanger Lockdown Rating: {{ lockdownRating }} {{ forceUnit }}</div>

  <app-single-load-grid
    *ngIf="liftoffResultsStore.state?.selectedResultDisplay === 'grid'"
    [rowData]="results"
    [columnDefinitions]="columnDefinitions"
    tableName="Lift-Off Results"
    [hangerLiftoffForce]="lockdownRating"
    gridName="Lift-Off Results"
    [tableHeight]="tableHeight"
  ></app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>
</app-tool-loader>
