import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-thermal-results-plot',
  template: `
    <app-xy-line-plot
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="plotTitle"
      [legendGroupClick]="'toggle'"
      [componentHeight]="componentHeight"
    ></app-xy-line-plot>
  `
})
export class ThermalResultsPlotComponent implements OnChanges, AfterViewInit {

  public depthDisplay: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public missingResults: boolean = false;
  public yAxisTitle: string = '';
  @Input() public xAxisTitle: string = '';
  @Input() public depthUnit: string = '';
  @Input() public temperatureUnit: string = '';
  @Input() private diameterUnit: string = '';
  @Input() private plotData: any[];
  @Input() private columnDefinitions: any[];
  @Input() public plotTitle: string = '';
  @Input() selectedDepthDisplay = 'md';
  @Input() isAnnularResults = false;
  @Input() xAxisTickFormatNumber = '';
  @Input() plotName: string = '';
  @Input() tubulars: any[] = [];
  @Input() selectedAnnulus: string = '';
  @Input() componentHeight: number;
  @Input() downloadPlotName: string = '';
  
  public plot = {
    data: [],
    layout: {
      xaxis: {
        autorange: true
      },
      yaxis: {
        title: {
          text: ''
        },
        autorange: 'reversed'
      }
    },
    config: {
      scrollZoom: false
    }
  };

  constructor( ) { }

  ngOnChanges(): void {
    this.parseResultsForPlot();
    this.resetAxes();
  }

  private resetAxes(): void {
    this.plot.layout.xaxis.autorange = true;
    this.plot.layout.yaxis.autorange = 'reversed';
  }

  ngAfterViewInit() {
    this.plot.data.length === 0 ? this.missingResults = true : this.missingResults = false;
  }

  parseResultsForPlot() {
    if (this.plotData === undefined) { return; }
    this.plot.config.scrollZoom = this.plotData ? true : false;
    const traceArray = [];

    this.columnDefinitions?.forEach((element => {
      if (element.field === 'md' || element.field === 'tvd') {
        return;
      } else {
        const isUdt = element.header === `UDT (${this.temperatureUnit})`;
        if (element.header != `MD (${this.depthUnit})` && element.header != `TVD (${this.depthUnit})`) {
          let stringIndex = this.tubulars.findIndex(x => this.selectedAnnulus?.startsWith(x.name.substring(0, x.name.indexOf(this.diameterUnit))) || element.header.startsWith(x.name.substring(0, x.name.indexOf(this.diameterUnit))));
          let shoe = 0;
          if (stringIndex >= 0) {
            shoe = this.tubulars[stringIndex].shoeMd;
          }

          let shoeIndex = this.plotData.findIndex(x => x[0] >= shoe);
          if (shoeIndex <= 0 || element.header.includes('Drill')) {
            shoeIndex = this.plotData.length - 1;
          }
          if (element.header?.endsWith(` ${this.depthUnit} (${this.temperatureUnit})`) || this.selectedAnnulus?.endsWith(` ${this.depthUnit} (${this.temperatureUnit})`)) {
            shoeIndex = 0;
          }
          if (!this.selectedAnnulus?.endsWith(` ${this.depthUnit} (${this.temperatureUnit})`)) {
            traceArray.push({
              name: element.header,
              x: this.plotData.map(r => r[this.columnDefinitions.indexOf(element)]).slice(0, shoeIndex + 1),
              y: this.selectedDepthDisplay === 'md' ? this.plotData.map(r => r[0]) : this.plotData.map(r => r[1]).slice(0, shoeIndex + 1),
              mode: 'lines',
              line: {
                dash: isUdt ? 'dot' : 'solid',
                width: 1.25
              },
              legendgroup: element.header
            });

            if (this.plotName == 'thermalTemperatureResultsPlot') {
              traceArray.push({
                name: element.header.endsWith(` ${this.depthUnit} (${this.temperatureUnit})`) ? element.header : 'Formation below the ' + element.header + ' Shoe',
                x: this.plotData.map(r => r[this.columnDefinitions.indexOf(element)]).slice(shoeIndex),
                y: this.selectedDepthDisplay === 'md' ? this.plotData.map(r => r[0]).slice(shoeIndex) : this.plotData.map(r => r[1]).slice(shoeIndex),
                mode: 'lines',
                line: {
                  dash: 'dot',
                  width: 1.25,
                  color: '#9F570D'
                },
                legendgroup: element.header,
                showlegend: false,
              });
            }
          } else {
            traceArray.push({
              name: element.header,
              x: this.plotData.map(r => r[this.columnDefinitions.indexOf(element)]),
              y: this.selectedDepthDisplay === 'md' ? this.plotData.map(r => r[0]) : this.plotData.map(r => r[1]),
              mode: 'lines',
              line: {
                dash: 'dot',
                width: 1.25
              },
              legendgroup: element.header
            });
          }
        }
      }
    }));

    traceArray.forEach((trace, index) => {
      if (trace.name.includes(`UDT (${this.temperatureUnit})`)) {
        traceArray[index].line.color = 'red';
        traceArray[index].line.dash = 'dot';
      }
    });

    this.plot.layout.xaxis['hoverformat'] = this.xAxisTickFormatNumber === '' ? '.2f' : '.' + this.xAxisTickFormatNumber + 'f';
    this.plot.layout.xaxis['tickformat'] = this.xAxisTickFormatNumber === '' ? '.2f' : '.' + this.xAxisTickFormatNumber + 'f';
    this.plot.data = traceArray;
    this.yAxisTitle = this.selectedDepthDisplay === 'md' ? `MD (${this.depthUnit})` : `TVD (${this.depthUnit})`;
    setTimeout(() => {
      this.plot.data.length === 0 ? this.missingResults = true : this.missingResults = false;
    });
  }
}
