import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from '../shared/components/loader/loader.component';
import { ToolLoaderComponent } from '../shared/components/tool-loader/tool-loader.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GridsterModule } from 'angular-gridster2';
import { DynamicModule } from 'ng-dynamic-component';

import { PlotlyViaWindowModule } from 'angular-plotly.js';

import { PrimeNGModule } from '../modules/primeng.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationErrorComponent } from '../wellbore-inputs/components/well-configuration/components/validation-error.component';
import { NgTableGridComponent } from '../shared/components/ng-table-grid/ng-table-grid.component';
import { NgTableResultsComponent } from '../shared/components/ng-table-results/ng-table-results.component';
import { RangeSelectionDirective } from '../shared/helpers/range-select.directive';
import { BlockableDivComponent } from '../shared/components/blockable-div/blockable-div.component';
import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { AllowedRangeComponent } from '../shared/components/allowed-range/allowed-range.component';
import { XyLinePlotComponent } from '../core/components/xy-line-plot/xy-line-plot.component';
import { devTools } from '@ngneat/elf-devtools';

devTools();

@NgModule({
  declarations: [
    NgTableGridComponent,
    NgTableResultsComponent,
    LoaderComponent,
    ToolLoaderComponent,
    ValidationErrorComponent,
    RangeSelectionDirective,
    BlockableDivComponent,
    DateAgoPipe,
    AllowedRangeComponent,
    XyLinePlotComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GridsterModule,
    PlotlyViaWindowModule,
    PrimeNGModule,
    DynamicModule,
    FlexLayoutModule
  ],
  providers: [
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GridsterModule,
    PlotlyViaWindowModule,
    PrimeNGModule,
    DynamicModule,
    FlexLayoutModule,
    LoaderComponent,
    ToolLoaderComponent,
    ValidationErrorComponent,
    NgTableGridComponent,
    NgTableResultsComponent,
    BlockableDivComponent,
    DateAgoPipe,
    AllowedRangeComponent,
    XyLinePlotComponent
  ]
})
export class SharedModule { }
