<div bg-dark>
  <form [formGroup]="form.formGroup" bg-dark>
    <div class="grid">
      <div class="field col-12">
        <input
          style="height: 13px; width: 20px;"
          type="checkbox"
          binary="true"
          [formControlName]="form.formControlNames.hasGasCap"
          (change)="hasGasCapChange()"
        >
        <label style="font-size: 14px; padding-left: 5px;">Has Gas Cap</label>
      </div>

      <div class="field col-12">
        <label>Gas Cap Length</label>
        <validation-error
          [isValid]="isControlInvalid(form.formGroup, 'gasCapLength')"
          [errorMsg]="getControlErrors(form.formGroup, 'gasCapLength')"
        ></validation-error>
        <div class="p-inputgroup">
          <p-inputNumber
            [formControlName]="form.formControlNames.gasCapLength"
            [maxFractionDigits]="2"
            (onFocus)="onInputFocus($event)"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ lengthUnit }}</span>
        </div>
      </div>

      <div class="field col-12">
        <label>Gas Gradient</label>
        <validation-error
          [isValid]="isControlInvalid(form.formGroup, 'gasGradient')"
          [errorMsg]="getControlErrors(form.formGroup, 'gasGradient')"
        ></validation-error>
        <div class="p-inputgroup">
          <p-inputNumber
            [formControlName]="form.formControlNames.gasGradient"
            [maxFractionDigits]="4"
            (onFocus)="onInputFocus($event)"
          ></p-inputNumber>
          <span class="p-inputgroup-addon">{{ gasGradientLabel }}</span>
        </div>
      </div>
    </div>
  </form>
</div>