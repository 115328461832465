import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, catchError, forkJoin, lastValueFrom, map } from 'rxjs';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { ThermalOperationsService } from 'src/app/perical/services/thermal-operations.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { Apb, ApbAnnulus, ApbInputUi, FinalOperation } from '../../models/apb.model';
import { ApbService } from '../../services/apb.service';
import { StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';

@Component({
  selector: 'app-apb-inputs',
  templateUrl: './apb-inputs.component.html',
  styleUrls: ['./apb-inputs.component.scss']
})
export class ApbInputsComponent implements OnInit, OnDestroy {
  public apbData: Apb;
  public thermalOperations: Array<Partial<BaseOperation>>;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.apbInputsStore = this._storeService.createStore(this.componentId, new ApbInputUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public apbInputsStore: Store;

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _apbService: ApbService,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) { }

  async ngOnInit(): Promise<void> {
    const sources = [
      this._thermalOperationsService.getApbFinalConditionOperations() as Observable<Array<Partial<BaseOperation>>>,
      this._apbService.getApb() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([thermalOperations, apb]) => {
        this.apbData = apb;
        if (!this.apbInputsStore.state.selectedAnnulus?.tubularStringId) {
          this.apbInputsStore.update(state => ({ ...state, selectedAnnulus: this.apbData.apbAnnuli[0] }));
        }
        this.thermalOperations = thermalOperations;
      }),
      catchError(err => {
        return err;
      })).subscribe();

      const hub = this._signalRService.getConnectionToNotificationHub();
      this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  private async signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_APB ||
      data.action == PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
      this.apbData = await lastValueFrom(this._apbService.getApb());
      this.thermalOperations = await lastValueFrom(this._thermalOperationsService.getApbFinalConditionOperations());
    }
  }

  public onAnnulusSelected(e): void {
    this.apbInputsStore.update(state => ({ ...state, selectedAnnulus: e.value }));
  }

  async updateAnnulus(annulus: ApbAnnulus): Promise<void> {
    await lastValueFrom(this._apbService.editAnnulus(annulus));
  }

  async updateFinalConditions(finalOperation: FinalOperation): Promise<void> {
    await lastValueFrom(this._apbService.changeFinalOperation(finalOperation.id));
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }
}
