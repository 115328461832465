<p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
  <div *ngIf="wellheadDisabled"><i class="pi pi-exclamation-circle"></i> Not applicable for the outermost string or liners</div>
  <div *ngIf="configMissing"><i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well Configuration Missing: Please specify at least one string</div>
  <br> <br>
</p-dialog>

<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="header align-items-center justify-content-center">
    <label class="stringLabel">{{ currentStringName }}</label>
  </div>

  <hr class="separator">

  <form [formGroup]="wellheadForm" bg-dark>
    <div class="field">
      <label>Wellhead Inner Diameter</label>
      <div class="p-inputgroup">
        <input
          formControlName="wellheadInnerDiameter"
          pInputText
          required
        />
        <span class="p-inputgroup-addon">{{ innerDiameterUnit }}</span>
      </div>
    </div>


    <div class="field">
      <label>Hanger Lockdown Rating</label>
      <div class="p-inputgroup">
        <input
          formControlName="hangerLockdownRating"
          pInputText
          required
        />
        <span class="p-inputgroup-addon">{{ forceUnit }}</span>
      </div>
    </div>
  </form>
</app-tool-loader>
