<div #div class="workspaceWrapper">

  <gridster [options]="gridOptions" style="width: 100%">
    <gridster-item (mouseleave)="clearActiveComponent()" (mouseover)="getActiveComponent(item?.id)" [item]="item" *ngFor="let item of workspace?.uiComponents">
      <p-panel>
        <p-header [style]="{ width: '100%' }">
          <div class="grid justify-content-between grid-nogutter">
            <div class="drag-handler col nameClass">
              <div>
                {{ item.name }}
              </div>
            </div>
            <div class="col-fixed">
              <p-button
                icon="pi pi-times"
                styleClass="p-button-danger"
                class="p-button-sm dashboardButtons"
                (click)="removeUiComponent(item)"
              ></p-button>
            </div>
          </div>
        </p-header>
        <!--Wrap the tool components to handle read-only mode.-->
        <fieldset class="read-only-fieldset" [disabled]="userRoles?.readOnly">
          <ndc-dynamic class="no-drag" [ndcDynamicComponent]="item.componentInstance" [ndcDynamicInputs]="{componentId: item.id}"></ndc-dynamic>
        </fieldset>
      </p-panel>
    </gridster-item>
  </gridster>

  <p-contextMenu [target]="div" [model]="contextMenuItems" appendTo="body"></p-contextMenu>
</div>
