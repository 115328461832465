import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { Choke, ChokePerformanceResult } from 'src/app/perical/models/chokes.model';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { ChokesService } from 'src/app/perical/services/chokes.service';
import { FluidsService } from 'src/app/perical/services/fluids.service';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { AppNotificationService as AppMessageNoticeService } from 'src/app/shared/services/app-notification.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-choke-performance-utility',
  templateUrl: './choke-performance-utility.component.html',
  styleUrls: ['./choke-performance-utility.component.scss']
})
export class ChokePerformanceUtilityComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public fluids: Fluid[];
  public chokes: Choke[];
  public chokePerformanceForm: UntypedFormGroup;
  public pressureUnit: string;
  public gasFlowRateUnit: string;
  public liquidFlowRateUnit: string;
  public tempUnit: string;
  public flowRateUnit: string;
  public massFlowUnit: string;
  public chokePerformanceResult: ChokePerformanceResult;
  public chokePerformanceCalculating: boolean;
  public temperatureValidation: { min: number, max: number }
  public pressureValidation: { min: number, max: number }
  public gasFlowRateValidation: { min: number, max: number }

  // State
  @Input()
  public componentId: string;

  constructor(
    private _signalRService: SignalRService,
    private _fluidsService: FluidsService,
    private _formBuilder: UntypedFormBuilder,
    private _chokeService: ChokesService,
    private _storeService: StoreService,
    private _toaster: AppNotificationService,
    private _appMessageNoticeService: AppMessageNoticeService) {
    this._appMessageNoticeService.getMessages().subscribe(res => {
      res.forEach(r => {
        if (r.summary === 'Chokes') {
          this.chokePerformanceCalculating = false;
        }
      });
    });
  }

  async ngOnInit(): Promise<void> {
    let userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.pressureUnit = userUnits.pressure;
    this.gasFlowRateUnit = Units.lib[userUnits.gasInjectionRate].symbol;
    this.liquidFlowRateUnit = Units.lib[userUnits.standardFluidFlow].symbol;
    this.tempUnit = userUnits.temperature;
    this.massFlowUnit = Units.lib[userUnits.massFlow].symbol;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    let defaultPressure: number;
    switch (this.pressureUnit) {
      case 'psi':
        this.pressureValidation = { min: 100, max: 100000 };
        defaultPressure = 1000;
        break;
      case 'bar':
        this.pressureValidation = { min: 6.8, max: 6894 };
        defaultPressure = 70;
        break;
      case 'KPa':
        this.pressureValidation = { min: 689, max: 689475 };
        defaultPressure = 6894;
        break;
      case 'atm':
        this.pressureValidation = { min: 6.8, max: 6804 };
        defaultPressure = 68;
        break;
    }

    let defaultTemp: number;
    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 1000 };
        defaultTemp = 60;
        break;
      case '°C':
        this.temperatureValidation = { min: -20, max: 540 };
        defaultTemp = 15.5;
        break;
      case 'K':
        this.temperatureValidation = { min: 255, max: 810 };
        defaultTemp = 288.7;
        break;
    }

    this.createReactiveForm(defaultTemp, defaultPressure);

    this.chokePerformanceForm.controls.upstreamPressure.setValidators([Validators.required, Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max)]);
    this.chokePerformanceForm.controls.upstreamTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);

    if (this.gasFlowRateUnit == 'MMscf/day') {
      this.gasFlowRateValidation = { min: 0.1, max: 1000 };
    } else {
      this.gasFlowRateValidation = { min: 0.0028, max: 28.3 };
    }
    this.chokePerformanceForm.controls.gasFlowRate.setValidators([Validators.required, Validators.min(this.gasFlowRateValidation.min), Validators.max(this.gasFlowRateValidation.max)]);

    await this.getData();
  }

  private async signalRfunc(data: any): Promise<void> {
    if (data.action === PeriforOnChangeMessages.REFRESH_FLUIDS || data.action === PeriforOnChangeMessages.REFRESH_CHOKES) {
      this.chokes = [];
      this.fluids = [];
      await this.getData();
    }
  }

  public selectedFluidChange(event: any): void {
    this.chokePerformanceForm.controls.fluidId.setValue(event.value.id);
    this.chokePerformanceForm.controls.isCo2.setValue(event.value.state.type === FluidType.CO2FLUID);
    this.flowRateUnit = event.value?.state.type == 'brine' ? this.liquidFlowRateUnit : this.gasFlowRateUnit;
  }

  public async getData(): Promise<void> {
    this.isLoading = true; // Set isLoading to true at the beginning
    const sources = [
      this._fluidsService.getFluids() as Observable<any>,
      this._chokeService.getChokes()
    ];

    try {
      const [fluids, chokeData] = await lastValueFrom(forkJoin(sources));

      this.fluids = fluids.filter(f => f.state.type === FluidType.CO2FLUID && f.state['co2withImpurities']);
      this.chokes = chokeData?.chokes;
      if (this.fluids.length > 0) {
        this.selectedFluidChange({ value: this.fluids[0] });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      this.isLoading = false; // Ensure isLoading is set to false after completion
    }
  }

  public async calculateChokePerformance() {
    const flowCoefficientSchedule = this.chokePerformanceForm.value.choke.flowCoefficientSchedule;
    if (flowCoefficientSchedule.length <= 1 || flowCoefficientSchedule[1].percentOpen == 0) {
      this._toaster.showError("Please populate the Cv Schedule.");
      return;
    }

    let phaseEnvelope = await lastValueFrom(this._fluidsService.getPhaseEnvelope(this.chokePerformanceForm.value.fluidId));
    if (phaseEnvelope['x'].length <= 0) {
      this.chokePerformanceCalculating = false;
      return;
    } else {
      this.chokePerformanceCalculating = true;
      this.chokePerformanceResult = await lastValueFrom(this._chokeService.calculateChokePerformance(this.chokePerformanceForm.value));
      if (this.chokePerformanceResult) {
        this.chokePerformanceCalculating = false;
      }
    }
  }

  private createReactiveForm(defaultTemp: number, defaultPressure: number): void {
    this.chokePerformanceForm = this._formBuilder.group({
      choke: new UntypedFormControl(null, [Validators.required]),
      openingTravel: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      fluidId: new UntypedFormControl(null),
      gasFlowRate: new UntypedFormControl(null, [Validators.required]),
      upstreamPressure: new UntypedFormControl(defaultPressure, [Validators.required]),
      upstreamTemperature: new UntypedFormControl(defaultTemp, [Validators.required]),
      isCo2: new UntypedFormControl(false)
    });
  }

  ngOnDestroy(): void {
    this.signalRfunc = null;
  }
}
