import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Fluid } from '../models/fluid.model';
import { ThermalOpOrderModel } from '../models/op-order.model';
import { Perforation } from '../models/perforation.model';
import { BaseOperation } from '../models/thermal-operation.model';
import { PressureResults, TemperatureResults } from '../models/thermal-results.model';
import { PericalOptions } from '../models/pericalOptions';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class ThermalOperationsService {
  
  constructor(private http: HttpClient) {
  }

  getThermalOperations(): Observable<Partial<BaseOperation>[]> {
    return this.http.get<BaseOperation[]>(`${PeriforApiEndpoint}/thermalOperations/`).pipe(catchError(e => of(e)));
  }

  getPreviousThermalOperations(currentOperationId: string): Observable<Partial<BaseOperation>[]> {
    return this.http.get<BaseOperation[]>(`${PeriforApiEndpoint}/thermalOperations/previous-ops?currentOperationId=${currentOperationId}`);
  }

  getApbFinalConditionOperations(): Observable<Partial<BaseOperation>[]> {
    return this.http.get<BaseOperation[]>(`${PeriforApiEndpoint}/apb/thermalOperations`);
  }

  getThermalOperationById(operationId: string): Observable<BaseOperation> {
    return this.http.get<BaseOperation>(`${PeriforApiEndpoint}/thermalOperations/${operationId}`);
  }

  updateThermalOperation(operation: BaseOperation): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/${operation.id}`, operation);
  }

  createThermalOperation(operation: BaseOperation): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/thermalOperations/`, operation);
  }

  deleteThermalOperation(operationId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/thermalOperations/${operationId}`);
  }

  cloneThermalOperation(operationId: string, operationName: string): Observable<Partial<BaseOperation>> {
    return this.http.post<Partial<BaseOperation>>(`${PeriforApiEndpoint}/thermalOperations/${operationId}/clone`, {name: operationName});
  }

  updateThermalOperationOrder(order: ThermalOpOrderModel) {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/order`, order);
  }

  getThermalTemperatureResults(takeFirstThreeCols: boolean = false): Observable<TemperatureResults[]> {
    return this.http.get<TemperatureResults[]>(`${PeriforApiEndpoint}/thermalResults/temperatures?takeFirstThreeCols=${takeFirstThreeCols}`);
  }  

  getDetailedAnnularResults(): Observable<any[]> {
    return this.http.get<TemperatureResults[]>(`${PeriforApiEndpoint}/thermalResults/annuli`);
  }

  getThermalPressureResults(takeFirstThreeCols: boolean = false): Observable<PressureResults[]> {
    return this.http.get<PressureResults[]>(`${PeriforApiEndpoint}/thermalResults/pressures?takeFirstThreeCols=${takeFirstThreeCols}`);
  }

  getFluids(): Observable<Fluid[]> {
    return this.http.get<Fluid[]>(`${PeriforApiEndpoint}/thermalOperations/fluids`);
  }

  getPerforations(skipUnits: boolean = false): Observable<Perforation[]> {
    return this.http.get<Perforation[]>(`${PeriforApiEndpoint}/thermalOperations/perforations?skipUnits=${skipUnits}`);
  }

  setPerforations(perforation: Perforation): Observable<Perforation> {
    return this.http.post<Perforation>(`${PeriforApiEndpoint}/thermalOperations/perforations`, perforation);
  }

  updatePerforation(perforation: Perforation): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/perforations/`, perforation);
  }

  deletePerforation(perforationId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/thermalOperations/perforations/${perforationId}`);
  }

  getPericalOptions(): Observable<PericalOptions> {
    return this.http.get<PericalOptions>(`${PeriforApiEndpoint}/thermalOperations/pericalOptions`)
      .pipe(map(r=>  new PericalOptions({...r})));
  }

  setPericalOptions(pericalOptions: PericalOptions) {
    return this.http.put(`${PeriforApiEndpoint}/thermalOperations/pericalOptions`, pericalOptions);
  }
}
