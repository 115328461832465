import { UntypedFormControl, Validators } from '@angular/forms';
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { SelectItem } from 'primeng/api';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetValueFromFahrenheit, GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface CementSlurriesForm {
  name: string;
  type: string;
  density: number;
  mixWaterDensity: number;
  densityTemperature: number;
  fann600: number;
  fann300: number;
  fann200: number;
  fann100: number;
  fann6: number;
  fann3: number;
  rheologyTemperature: number;
}

@Component({
  selector: 'app-cement-slurries',
  templateUrl: './cement-slurries.component.html',
  styleUrls: ['./cement-slurries.component.scss'],
  providers: subformComponentProviders(CementSlurriesComponent)
})
export class CementSlurriesComponent implements OnChanges, AfterViewInit {

  constructor() {}


  @Input()
  public userUnits: UserUnitsModel;

  public tablePlot: SelectItem[];
  public showPlot: boolean;
  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  public yAxisTitle = 'Shear Stress, τ (lbf / ft²)';
  public xAxisTitle = 'Shear Rate, γ (1/s)';
  public plotName = 'cementSlurriesPlot';
  public downloadPlotName = 'slurry_rheology_plot';

  private e = 20;
  private tauBinghamArray: number[];
  private gammaPlotArray: number[] = [0, 5, 10, 15, 20, 40, 60, 80, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200];
  private tauRegressionArray: number[];
  private dataArray: any[];

  public plasticViscosity: number;
  public yieldPoint: number;
  public kPrime: number;
  public nPrime: number;

  public densityUnits: string;
  public tempUnit: string;
  public mixWaterDensityValidation: { min: number, max: number };
  public densityValidation: { min: number, max: number };
  public temperatureValidation: { min: number, max: number };

  public form = createForm<CementSlurriesForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      density: new UntypedFormControl(null),
      mixWaterDensity: new UntypedFormControl(null),
      densityTemperature: new UntypedFormControl(null),
      fann600: new UntypedFormControl(null, [Validators.min(0.1), Validators.max(500)]),
      fann300: new UntypedFormControl(null, [Validators.min(0.1), Validators.max(500)]),
      fann200: new UntypedFormControl(null, [Validators.min(0.1), Validators.max(500)]),
      fann100: new UntypedFormControl(null, [Validators.min(0.1), Validators.max(500)]),
      fann6: new UntypedFormControl(null, [Validators.min(0.1), Validators.max(500)]),
      fann3: new UntypedFormControl(null, [Validators.min(0.1), Validators.max(500)]),
      rheologyTemperature: new UntypedFormControl(null)
    }
  });

  ngOnChanges(): void {
    if (!this.userUnits) {
      return;
    }
    this.tempUnit = this.userUnits.temperature;
    this.densityUnits = Units.lib[this.userUnits.density].symbol;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 32, max: 500 };
        break;
      case '°C':
        this.temperatureValidation = { min: 0, max: 260 };
        break;
      case 'K':
        this.temperatureValidation = { min: 273.15, max: 533.15 };
        break;
    }

    this.form.formGroup.controls.densityTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    this.form.formGroup.controls.rheologyTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    
    switch (this.densityUnits) {
      case 'ppg':
        this.densityValidation = { min: 0.1, max: 25 };
        this.mixWaterDensityValidation = { min: 8.33, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 12, max: 2995 };
        this.mixWaterDensityValidation = { min: 998.1, max: 2995.6 };
        break;
      case 'g/cm³':
      case 'kg/l':
        this.densityValidation = { min: 0.01, max: 3 };
        this.mixWaterDensityValidation = { min: 0.9981, max: 2.9956 };
        break;
    }

    this.form.formGroup.controls.density.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
    this.form.formGroup.controls.mixWaterDensity.setValidators([Validators.required, Validators.min(this.mixWaterDensityValidation.min), Validators.max(this.mixWaterDensityValidation.max)]);
  }

  private calculateNpKp() {
    let Sx = 0, Sy = 0, Sxx = 0, Sxy = 0, n = 0;
    this.dataArray = [];
    Object.entries(this.form.formGroup.controls).forEach(control => {
      if ((control[0] === 'fann600' || control[0] === 'fann300' || control[0] === 'fann200'
        || control[0] === 'fann100' || control[0] === 'fann6' || control[0] === 'fann3') && +control[1].value > 0.00001) {
        let shearStress = +control[1].value;
        let shearRate = +control[0].substring(4);
        let gamma = this.convertRpmTo1PerSecond(shearRate);
        let tau = this.convertFannReadingToFieldUnits(shearStress);

        this.dataArray.push(
          {
            gamma: gamma,
            tau: tau
          }
        )

        if (tau != 0) {
          shearRate = Math.log(gamma);
          shearStress = Math.log(tau);
        }

        // Sumation terms
        Sx += shearRate;
        Sy += shearStress;
        Sxx += Math.pow(shearRate, 2);
        Sxy += shearRate * shearStress;
        n++;
      }
    });

    let b = (Sxy - Sx * Sy / n) / (Sxx - Sx * Sx / n);
    let a = (Sy - b * Sx) / n;

    //Since data has been transformed to logs, then a must be transformed back
    this.kPrime = Math.exp(a);

    this.nPrime = b;
  }

  private GetTauBinghamPlotArray() {
    this.tauBinghamArray = [];
    this.tauBinghamArray.push(this.yieldPoint / 100);

    for (let i = 1; i < this.e; i++) {
      this.tauBinghamArray.push(this.tauBinghamArray[i - 1] + this.plasticViscosity * (this.gammaPlotArray[i] - this.gammaPlotArray[i - 1]) / 47880.259);
    }
  }

  private calculatePvYp() {
    //Approximate Bingham Plastic parameters PV and YP with linear approximate based on simulated R300 and R600 Fann readings.
    var gam300 = this.convertRpmTo1PerSecond(300);
    var gam600 = this.convertRpmTo1PerSecond(600);
    var tau300 = this.kPrime * Math.pow(gam300, this.nPrime);
    var tau600 = this.kPrime * Math.pow(gam600, this.nPrime);

    //Calculate PV in lbf-s/ft2 as slope of Bingham-Plastic line
    var pv = (tau600 - tau300) / (gam600 - gam300);

    //Calculate YP as slope-intercept of Bingham-Plastic line
    var yp = tau300 - pv * gam300;

    //Convert PV to centiPoise, cP
    this.plasticViscosity = +(pv * 47880.259).toFixed(3);

    //Convert YP to lbf/100ft2
    this.yieldPoint = +(yp * 100).toFixed(3);
  }

  private convertRpmTo1PerSecond(shearRateInRpm) {
    return shearRateInRpm * 1.703;
  }

  private convertFannReadingToFieldUnits(shearStress) {
    return shearStress * 1.067 / 100.0;
  }

  public calculateRheologyProperties(): void {
    this.calculateNpKp();
    this.calculatePvYp();
    this.GetTauRegressionFitArray();
    this.GetTauBinghamPlotArray();
    this.plotData();
  }

  tablePlotToggle() {
    this.showPlot = !(this.showPlot ?? false);
  }

  ngAfterViewInit() {
    this.calculateRheologyProperties();
  }

  public applyDefaults(): void {
    let densityDefault = GetValueFromPpg(15.8, this.densityUnits);
    let mixWaterDefault = GetValueFromPpg(8.33, this.densityUnits);
    let densityTempDefault = GetValueFromFahrenheit(120, this.tempUnit);
    let Fann300_default = 85;
    let Fann200_default = 74;
    let rheologyTempDefault = GetValueFromFahrenheit(120, this.tempUnit);

    this.form.formGroup.controls.density.setValue(densityDefault);
    this.form.formGroup.controls.mixWaterDensity.setValue(mixWaterDefault);
    this.form.formGroup.controls.densityTemperature.setValue(densityTempDefault);
    this.form.formGroup.controls.fann300.setValue(Fann300_default);
    this.form.formGroup.controls.fann200.setValue(Fann200_default);
    this.form.formGroup.controls.rheologyTemperature.setValue(rheologyTempDefault);

    this.calculateRheologyProperties();
  }

  private GetTauRegressionFitArray(): Array<number> {
    this.tauRegressionArray = [];

    for (let i = 0; i < this.e; i++) {
      this.tauRegressionArray.push(this.kPrime * Math.pow(this.gammaPlotArray[i], this.nPrime));
    }

    return this.tauRegressionArray;
  }

  private plotData() {
    const plot = [];

    // Power-Law
    const powerLawArray = (
      {
        name: 'Power-Law',
        x: this.gammaPlotArray,
        y: this.tauRegressionArray,
        line: {
          dash: 'solid',
          // color: '#E69138'
        }
      }
    )
    plot.push(powerLawArray);

    // Bingham-Plastic
    const binghamPlasticArray = (
      {
        name: 'Bingham-Plastic',
        x: this.gammaPlotArray,
        y: this.tauBinghamArray,
        line: {
          dash: 'dash',
          // color: '#0000FF'
        }
      }
    )
    plot.push(binghamPlasticArray);

    // Data
    const gammaValues = this.dataArray.map(x => x.gamma);
    const tauValues = this.dataArray.map(x => x.tau);
    const dataArray = (
      {
        name: 'Data',
        x: gammaValues,
        y: tauValues,
        mode: 'markers'
      }
    )
    plot.push(dataArray);

    this.plot.data = plot;
  }
}
