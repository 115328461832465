import { Component, Input, OnChanges } from '@angular/core';
import { InternalPressureProfileType } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { FormType, createForm, subformComponentProviders } from 'ngx-sub-form';
import { SssvIPP } from '../../models/internal-pressure-profile.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';

interface SssvIppForm {
  type: InternalPressureProfileType.SSSV;
  upperStageSurfacePressure: UomQuantityInput;
  upperStageFluidDensity: UomQuantityInput;
  upperStagePlugMd: UomQuantityInput;
  upperStageEvacAbovePlug: boolean;
  lowerStageSurfacePressure: UomQuantityInput;
  lowerStageFluidDensity: UomQuantityInput;
  lowerStageHasPlug: boolean;
  lowerStagePlugMd: UomQuantityInput;
}

@Component({
  selector: 'app-sssv-ipp',
  templateUrl: './sssv-ipp.component.html',
  styles: [`
    .leftFieldset {
      width: 50%;
    }
  `],
  providers: subformComponentProviders(SssvIppComponent)
})
export class SssvIppComponent implements OnChanges {

  @Input() currentString: any;
  @Input() shoeDepth: number;
  @Input() userUnits: UserUnitsModel;

  public validators: any;
  public lowerStagePlugDepthDisabled: boolean;
  public upperStageEvacAbovePlug: boolean;
  public densityLabel: string;
  public pressureLabel: string;
  public pressureValidation: { min: number, max: number };
  public densityValidation: { min: number, max: number };

  public form = createForm<SssvIPP, SssvIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl("SubSurfaceSafetyValveTest"),
      upperStageSurfacePressure: new UntypedFormControl(null),
      upperStageFluidDensity: new UntypedFormControl(null),
      upperStagePlugMd: new UntypedFormControl(null),
      upperStageEvacAbovePlug: new UntypedFormControl(false),
      lowerStageSurfacePressure: new UntypedFormControl(null),
      lowerStageFluidDensity: new UntypedFormControl(null),
      lowerStageHasPlug: new UntypedFormControl(false),
      lowerStagePlugMd: new UntypedFormControl(null)
    },
    toFormGroup: (obj: SssvIPP | null): SssvIppForm | null => {
      if (!obj) {
        return null
      }

      obj.lowerStagePlugMd ? this.lowerStageHasPlug() : this.lowerStageDoesntHavePlug();
      (obj.upperStageFluidDensity || obj.upperStageFluidDensity) ? this.upperStageNoEvacAbovePlug() : this.upperStageHasEvacAbovePlug();

      return {
        type: obj.type,
        upperStageSurfacePressure: { control: obj.upperStageSurfacePressure },
        upperStageFluidDensity: { control: obj.upperStageFluidDensity },
        upperStagePlugMd: { control: obj.upperStagePlugMd },
        upperStageEvacAbovePlug: obj.upperStageFluidDensity > 0 ? false : true,
        lowerStageSurfacePressure: { control: obj.lowerStageSurfacePressure },
        lowerStageFluidDensity: { control: obj.lowerStageFluidDensity },
        lowerStagePlugMd: { control: obj.lowerStagePlugMd },
        lowerStageHasPlug: obj.lowerStagePlugMd > 0 ? true : false,
      }
    },
    fromFormGroup: (formValue: SssvIppForm): SssvIPP | null => {
      const { upperStageSurfacePressure, upperStageFluidDensity, upperStagePlugMd, upperStageEvacAbovePlug,
              lowerStageSurfacePressure, lowerStageFluidDensity, lowerStagePlugMd, lowerStageHasPlug, ...commonValues } = formValue;
      return {
        upperStageSurfacePressure: +upperStageSurfacePressure?.control,
        upperStageFluidDensity: +upperStageFluidDensity?.control,
        upperStagePlugMd: +upperStagePlugMd?.control,
        lowerStageSurfacePressure: +lowerStageSurfacePressure?.control,
        lowerStageFluidDensity: +lowerStageFluidDensity?.control,
        lowerStagePlugMd: lowerStagePlugMd?.control ? +lowerStagePlugMd?.control : null,
        ...commonValues
      }
    }
  })

  constructor() { }

  ngOnInit(): void {
    this.lowerStagePlugChange();
    this.upperStagePlugChange();
  }

  ngOnChanges(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
    this.pressureLabel = this.userUnits.pressure;
    
    switch (this.pressureLabel) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 30000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 2068 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 206800 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 2041 };
        break;
    }

    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 0.01, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
        this.densityValidation = { min: 0.00119, max: 3 };
        break;
    }

    this.validators = {
      upperStageFluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)],
      upperStagePlugMd: [Validators.required, Validators.min(this.currentString.hangerMd + 1), Validators.max(this.shoeDepth)],
      lowerStageSurfacePressure: [Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max)],
      lowerStageFluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)],
      lowerStagePlugMd: [Validators.min(this.currentString.hangerMd + 1), Validators.max(this.shoeDepth)]
    }
  }

  lowerStagePlugChange(): void {
    if (this.form.formGroup.controls.lowerStageHasPlug.value) {
      this.lowerStageHasPlug();
    } else {
      this.lowerStageDoesntHavePlug();
    }
  }

  private lowerStageHasPlug() {
    this.form.formGroup.controls.lowerStageHasPlug.setValue(true);
    if (!this.form.formGroup.controls.lowerStagePlugMd.value?.control) {
      this.form.formGroup.controls.lowerStagePlugMd.setValue({ control: this.shoeDepth });
    }
    this.lowerStagePlugDepthDisabled = false;
  }

  private lowerStageDoesntHavePlug() {
    this.form.formGroup.controls.lowerStagePlugMd.reset();
    this.form.formGroup.controls.lowerStageHasPlug.setValue(false);
    this.lowerStagePlugDepthDisabled = true;
  }

  upperStagePlugChange(): void {
    if (this.form.formGroup.controls.upperStageEvacAbovePlug.value) {
      this.upperStageHasEvacAbovePlug();
    } else {
      this.upperStageNoEvacAbovePlug();
    }
  }

  private upperStageNoEvacAbovePlug() {
    this.form.formGroup.controls.upperStageEvacAbovePlug.setValue(false);
    if (this.form.formGroup.controls.upperStageFluidDensity.value?.control === 0) {
      this.form.formGroup.controls.upperStageFluidDensity.setValue({ control: this.currentString.annularFluid?.state.nominalDensity });
    }
    if (this.form.formGroup.controls.upperStageSurfacePressure.value?.control === 0) {
      this.form.formGroup.controls.upperStageSurfacePressure.setValue({ control: 0 });
    }
    this.form.formGroup.controls.upperStageFluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)],);
    this.form.formGroup.controls.upperStageSurfacePressure.setValidators([Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max)]);
    this.upperStageEvacAbovePlug = false;
  }

  private upperStageHasEvacAbovePlug() {
    this.validators.upperStageFluidDensity = null;
    this.validators.upperStageSurfacePressure = null;
    this.form.formGroup.controls.upperStageFluidDensity.setValue( { control: 0 });
    this.form.formGroup.controls.upperStageSurfacePressure.setValue( { control: 0 });
    this.form.formGroup.controls.upperStageEvacAbovePlug.setValue(true);
    this.upperStageEvacAbovePlug = true;
  }
}
