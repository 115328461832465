<form [formGroup]="form.formGroup">
  <div class="generalContainer">
    <app-general-epp
      [formControlName]="form.formControlNames.generalEpp"
      [currentString]="currentString"
      [userUnits]="userUnits"
    ></app-general-epp>

    <div class="field col-12">
      <label class="densityLabel">Cement Mix-Water Density</label>
      <validation-error
        [isValid]="isControlInvalid(form.formGroup, 'cementMixwaterDensity')"
        [errorMsg]="getControlErrors(form.formGroup, 'cementMixwaterDensity')"
      ></validation-error>
      <div class="p-inputgroup">
        <p-inputNumber
          [formControlName]="form.formControlNames.cementMixwaterDensity"
        [maxFractionDigits]="2"
          title="Allowed range: {{ densityValidation.min }} - {{
            densityValidation.max
          }} {{ densityLabel }}"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <span class="p-inputgroup-addon">{{ densityLabel }}</span>
      </div>
    </div>

    <label class="field col-12">
      Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }}
      {{ densityLabel }}
    </label>
    <br />
    <label class="field col-12">
      Top Of Cement: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.topOfCementMd.toFixed(2) }} {{ userUnits.longLengths }}
    </label>
    <br />
    <label class="field col-12">
      Lead Slurry Density: &nbsp;&nbsp;
      {{ cement?.leadSlurryDensity }} {{ densityLabel }}</label>
    <br *ngIf="cement?.tailSlurryLength > 0" />
    <label *ngIf="cement?.tailSlurryLength > 0" class="p-field p-col-12">
      Tail Slurry Density: &nbsp; &nbsp; &nbsp;{{ cement?.tailSlurryDensity.toFixed(2) }}
      {{ densityLabel }}
    </label>
    <br *ngIf="cement?.tailSlurryLength > 0" />
    <label *ngIf="cement?.tailSlurryLength > 0" class="p-field p-col-12">
      Tail Slurry Length: &nbsp; &nbsp; &nbsp;{{ cement?.tailSlurryLength.toFixed(2) }}
      {{ userUnits.longLengths }}
    </label>
  </div>
</form>
