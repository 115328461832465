import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateTreeLevelDialogComponent } from '../create-tree-level-dialog/create-tree-level-dialog.component';
import { Design } from 'src/app/shared/models/design.model';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'well-explorer-header',
  templateUrl: './well-explorer-header.component.html',
  styles: [`
    :host ::ng-deep .p-dropdown {
      height: 25px;
    }

    :host ::ng-deep .p-dropdown .p-dropdown-label {
      padding: 4px;
    }

    :host ::ng-deep .p-dropdown-trigger {
      width: 20px;
    }

    ::ng-deep .collapseButton button {
      width: 25px !important;
    }

    ::ng-deep .scrollButton button {
      width: 15px !important;
      height: 12px !important;
      background-color:transparent;
    }

    h4 {
      font-size: small;
    }
  `],
  providers: [DialogService]
})
export class WellExplorerHeaderComponent implements OnChanges {

  @Input()
  public isAdmin : boolean;

  @Output()
  public onCloseSidenav: EventEmitter<string>;

  @Output()
  public onDesignUpdated: EventEmitter<TreeNode>;

  @Output()
  public onCompanyAdded: EventEmitter<{levelData: {id:string, name: string}, level:string}>;



  @Input()
  public design: Design;

  public designs: Design[] = [];

  @Input()
  public allDesigns: Design[] = [];

  public selectedDesign: any;

  constructor(
    public _dialogService: DialogService,) {
    this.onCompanyAdded = new EventEmitter();
    this.onCloseSidenav = new EventEmitter();
    this.onDesignUpdated = new EventEmitter();
  }

  ngOnChanges() {
    if (this.design && !this.designs.find(x => x.id == this.design.id)) {
      this.designs.push(this.design);
    }
  }

  public updateDesign(design: Design) {
    let selectedDesign: TreeNode = {
      data: {
        id: design['value'].id,
        name: design['value'].name,
        level: 'designs'
      }
    }
    this.onDesignUpdated.emit(selectedDesign);
    this.selectedDesign = [];
  }

  public collapseSidenav() {
    this.onCloseSidenav.emit("close");
  }

  public async addCompany() {
    const dialogReference = this._dialogService.open(CreateTreeLevelDialogComponent, {
      data: {
        level: 'companies'
      },
      header: 'Create a New Company',
      width: '400px'
    });

    dialogReference.onClose.subscribe((levelData : {name: string}) => {
      if (levelData) {
        this.onCompanyAdded.emit({levelData: {id: null, name: levelData.name}, level: 'companies'});
      }
    });
  }
}
