<form [formGroup]="form.formGroup">
  <div class="generalContainer">
    <i
      class="pi pi-info-circle pickupSlackoffClass"
      title="This EPP applies the mud/fluid hydrostatic to TOC of the current casing and applies Pore Pressure for cement sections in the open hole.&#13;In the case of cement interval inside the shoe of the previous casing, the cement mix-water gradient is applied from TOC down to the previous shoe depth."
    ></i>
    <app-general-epp
      [formControlName]="form.formControlNames.generalEpp"
      [currentString]="currentString"
      [userUnits]="userUnits"
    ></app-general-epp>

    <div class="field col-12">
      <label class="densityLabel">Cement Mix-Water Density</label>
      <validation-error
        [isValid]="isControlInvalid(form.formGroup, 'cementMixwaterDensity')"
        [errorMsg]="getControlErrors(form.formGroup, 'cementMixwaterDensity')"
      ></validation-error>
      <div class="p-inputgroup">
        <p-inputNumber
          [formControlName]="form.formControlNames.cementMixwaterDensity"
          [maxFractionDigits]="2"
          title="Allowed range: {{ densityValidation.min }} - {{
            densityValidation.max
          }} {{ densityLabel }}"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <span class="p-inputgroup-addon">{{ densityLabel }}</span>
      </div>
    </div>
    
    <br>
    <app-gas-cap
      [formControlName]="form.formControlNames.gasCap"
      [userUnits]="userUnits"
      [currentString]="currentString"
    ></app-gas-cap>
    
    <label
      class="error field col-12"
      *ngIf="form.formGroup.hasError('porePressureNotSpecified')"
      >Pore Pressure profile not specified.</label
    >
    <label
      class="error field col-12"
      *ngIf="
        form.formGroup.hasError('porePressureMustBeToShoe') &&
        !form.formGroup.hasError('porePressureNotSpecified')
      "
      >Pore Pressure profile must be extended to the shoe of the string.</label
    >
    <br
      *ngIf="
        form.formGroup.hasError('porePressureMustBeToShoe') ||
        form.formGroup.hasError('porePressureNotSpecified')
      "
    />
    <label class="field col-12"
      >Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}</label
    >
  </div>
</form>
