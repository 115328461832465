<app-tool-loader [isLoading]="isLoading || isDelete" positionUnset="true">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>
  <div class="grid">
    <div class="flex">
      <div class="content">
        <div class="col-3">
          <p-toolbar styleClass="mb-4">
            <ng-template pTemplate="left">
              <p-button
                label="Add Load Case"
                icon="pi pi-plus"
                class="p-button-success p-button-sm addLoadButton"
                (click)="!configMissing && showNewLoadCaseDialog()"
                [disabled]="configMissing"
              ></p-button>
            </ng-template>
            <ng-template pTemplate="right">
              <p-button
                label="Plot"
                icon="p-button-info p-button-sm pi pi-fw pi-chart-line"
                class="plotButton"
                (click)="drawerToggle()"
                (click)="pressTempToggle()"
                [disabled]="configMissing"
              ></p-button>
              <p-selectButton
                *ngIf="postInstallLoadsStore.state?.drawerOpen"
                [options]="pressTemp"
                visible="true"
                class="pressTempButton"
                [(ngModel)]="postInstallLoadsStore.state.pressTempSelected"
                (onChange)="pressTempToggle($event)"
                [disabled]="configMissing"
                [allowEmpty]="false"
              ></p-selectButton>
            </ng-template>
          </p-toolbar>
          <p-card>
            <ng-template pTemplate="header">
              <tr class="grid">
                <div class="justify-content-end" style="display: inline-flex;">
                  <div style="display: inline-flex;" class="justify-content-start">
                  <td style="width: 8%;"></td>
                  <td class="col" style="width: 22%;"><b>Name</b></td>
                  <td class="col" style="width: 23%;"><b>Internal Pressure</b></td>
                  <td class="col" style="width: 27%;"><b>External Pressure</b></td>
                  <td class="col" style="width: 10%;"><b>Temperature</b></td>
                </div>
                  <td *ngIf="loadCases" class="justify-content-end">
                    <div class="p-toolbar-group-center">
                      <button
                      pRipple
                      pButton
                      label="Delete"
                      (click)="deleteLoadCases()"
                      class="p-button-warning p-button-outlined"
                      [disabled]="selectedLoadCaseIndexes.length < 1"
                      title="Delete selected load cases"
                    ></button>

                    <input
                      type="checkbox"
                      (click)="selectAllLoadCaseIndexes($event)"
                      title="Select All"
                      [disabled]="loadCases?.length < 2"
                      [checked]="selectedLoadCaseIndexes.length === loadCases?.length - 1"
                    >
                    <td class="col"></td>
                    <td class="col"></td>
                    </div>
                  </td>
                </div>
              </tr>
            </ng-template>
            <p-table
              #loadsTable
              scrollable
              [value]="loadCases"
              selectionMode="single"
              [(selection)]="selectedLoad"
              metaKeySelection="true"
              (onRowReorder)="onRowReorder($event)"
              dataKey="index"
              [expandedRowKeys]="expandedRows"
              rowExpandMode="single"
              [scrollHeight]="tableHeight"
            >

              <ng-template
                pTemplate="body"
                let-load
                let-index="rowIndex"
                let-expanded="expanded"
              >
                <tr
                  (dblclick)="editLoadCase(load)"
                  (click)="selectLoad(load)"
                  [pSelectableRow]="load"
                  [pReorderableRow]="index"
                >
                  <td style="width: 7%;">
                    <span
                      *ngIf="load.name != 'Initial Condition'"
                      class="pi pi-sort"
                      pReorderableRowHandle
                    ></span>
                  </td>
                  <td style="width: 25%;">
                    {{ load.name }}
                  </td>
                  <td style="width: 25%;">
                    {{ load.ippDisplayName }}
                  </td>
                  <td style="width: 25%;">
                    {{ load.eppDisplayName }}
                  </td>
                  <td class="loadTypeTd" style="width: 25%;">
                    {{ load.tpDisplayName }}
                  </td>
                  <td class="buttons flex justify-content-end">
                    <i
                      class="pi pi-pencil p-button p-button-text p-button-sm edit"
                      title="Edit &quot;{{ load.name }}&quot;"
                      [pRowToggler]="load"
                      (click)="editLoadCase(load)"
                      *ngIf="!expandedRows[load.index] === true"
                    ></i>
                    <i
                      class="pi pi-copy p-button p-button-text p-button-sm p-button-info clone"
                      title="Clone &quot;{{ load.name }}&quot;"
                      (click)="cloneLoadCase(load)"
                    ></i>
                    <i
                      *ngIf="load.name != 'Initial Condition'"
                      class="pi pi-trash p-button p-button-text p-button-sm p-button-danger delete"
                      title="Delete &quot;{{ load.name }}&quot;"
                      (click)="deleteLoadCase(load)"
                    ></i>
                    <i
                      *ngIf="load.name == 'Initial Condition'"
                      class="pi pi-lock p-button p-button-text p-button-sm p-button-danger delete"
                    >
                    </i>
                    <div class="p-toolbar-group-center checkbox">
                      <input
                      *ngIf="load.name != 'Initial Condition'"
                      style="height: 13px; width: 20px; padding-right: 5px;"
                      type="checkbox"
                      binary="true"
                      [checked]="selectedLoadCaseIndexes.includes(load.index)"
                      (click)="selectLoadCases(load)"
                      title="Select &quot;{{ load.name }}&quot; to delete multiple load cases"
                    >
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-load>
                <tr>
                  <td colspan="9">
                    <app-post-install-load-details
                      [isReadOnly]="userRoles.readOnly"
                      [load]="loadCase"
                      (loadUpdate)="saveLoadCase($event)"
                      (cancelEdit$)="cancelEdit($event)"
                      [shoeDepth]="currentString.shoeMd"
                      [shoeDepthTvd]="shoeDepthTvd"
                      [currentString]="currentString"
                      [cement]="cement"
                      [porePressures]="porePressures"
                      [thermalOperations]="thermalOperations"
                      [wellType]="wellType"
                      [userUnits]="userUnits"
                      [wellTd]="wellTd"
                    ></app-post-install-load-details>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-card>
        </div>
      </div>

    <div class="drawer" *ngIf="postInstallLoadsStore.state?.drawerOpen">
          <app-post-install-loads-plot
            *ngIf="postInstallLoadsStore.state?.tablePlotSelected === 'plot'"
            [plotData]="plotData"
            [udt]="udtPlotData"
            [pressTempSelected]="postInstallLoadsStore.state?.pressTempSelected"
            [hangerTvd]="hangerTvd"
            [shoeDepthTvd]="shoeDepthTvd"
            [userUnits]="userUnits"
            [stringName]="stringName"
            [componentHeight]="componentHeight"
          ></app-post-install-loads-plot>
    </div>
  </div>
</div>

  <p-dialog [(visible)]="configMissing" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> Well Configuration Missing: Please
    specify at least one string <br />
    <br />
  </p-dialog>

  <p-dialog
    [(visible)]="newLoadCaseDialogDisplay"
    [closable]="true"
    [draggable]="false"
    modal="true"
  >
    <ng-template pTemplate="header">New Load Case</ng-template>
    <ng-template pTemplate="body">
      <app-new-post-install-load-dialog
        (saveLoad$)="newLoadCase($event)"
        (cancelNewLoad$)="cancelNewLoadCaseDialog()"
        appendTo="body"
      ></app-new-post-install-load-dialog>
    </ng-template>
  </p-dialog>
  <br>
</app-tool-loader>
