import { AfterContentInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { DisplacementFluidIPP, InternalPressureProfileType } from '../../models/internal-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-displacement-fluid-ipp',
  template: `
    <label class="field col-12">Displacement Fluid: &nbsp; &nbsp; &nbsp; {{ cement.displacementFluidDensity }} {{ densityLabel }}</label>
    <br>
    <label class="field col-12">Hanger Pressure:  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ hydrostaticHangerPressure }} {{ userUnits.pressure }}</label>
  `,
  providers: subformComponentProviders(DisplacementFluidIppComponent)
})
export class DisplacementFluidIppComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() cement: CementingLanding;
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;

  public form = createForm<DisplacementFluidIPP>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.DISPLACEMENTFLUID)
    }
  });

  public hydrostaticHangerPressure: number;

  constructor(private _trajectoryService: TrajectoryService) { }

  ngOnInit(): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
  }

  async ngOnChanges(): Promise<void> {
    let tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([this.currentString.hangerMd], true));
    this.hydrostaticHangerPressure = GetMudHydrostaticPressure(tvds[0], this.cement.displacementFluidDensity, this.userUnits)
  }

  ngAfterContentInit(): void {
    this.form.formGroup.controls.type.setValue(InternalPressureProfileType.DISPLACEMENTFLUID);
  }
}
