import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { catchError, forkJoin, lastValueFrom, map, Observable, Subscription } from 'rxjs';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { PoreAndFracService } from '../../services/pore-and-frac.service';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';
import { PoreFrac, PoreFracUi } from '../../models/poreFrac.model';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgTableGridComponent } from 'src/app/shared/components/ng-table-grid/ng-table-grid.component';
import { valuesIncreaseValidator } from 'src/app/shared/components/ng-table-grid/shared-grid.validators';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { greaterThanZero } from 'src/app/wellbore-inputs/components/well-configuration/validation/greaterThanZero';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { GetFeetFromValue, GetPpgFromValue, GetPsiFromValue, GetValueFromPpg, GetValueFromPsi } from '../../shared/helpers/units.helper';

function calculatePressure(tvd: number, emw: number, uu: UserUnitsModel): Number {
  if (!tvd || !emw) {
    return 0;
  }
  let depth = GetFeetFromValue(tvd, uu.longLengths);
  let mudWeight = GetPpgFromValue(emw, Units.lib[uu.density].symbol);
  return GetValueFromPsi((depth * mudWeight * 0.052), uu.pressure);
}

function calculteEmw(tvd: number, psi: number, uu: UserUnitsModel): Number {
  if (!tvd || !psi) {
    return 0;
  }
  let depth = GetFeetFromValue(tvd, uu.longLengths);
  let pressure = GetPsiFromValue(psi, uu.pressure);
  return GetValueFromPpg((pressure / 0.052 / depth), Units.lib[uu.density].symbol);
}

@Component({
  selector: 'app-pore-and-frac',
  templateUrl: './pore-and-frac.component.html',
  styles: [`
    .pore-frac-cmp {
      height: calc(100% - 40px);
      position: relative;
      user-select: none;

      .toggle-btns {
        display: inline-flex;
        padding-bottom: 1em;
      }
    }
  `]
})
export class PoreAndFracComponent implements OnInit, AfterViewInit, OnDestroy {
  private _subscriptions: Subscription;
  private _densityUnit: string;

  @Input() pressureType: string = '';

  public casingArray: Array<{ hanger: number, shoe: number, name: string, seqNumber: number }> = Array();
  public shoeDepth: number;
  public hangerMd: number;
  public annularFluidDensity: number;
  public pressures: Array<PoreFrac>;
  public columnDefsPlot: Array<any>;
  public mudlineDepth: number;
  public tablePlot: SelectItem[];
  public pressureEmw: SelectItem[];
  public isLoading: boolean;
  public tableHeightPore: string;
  public tableHeightFrac: string;
  private currentString;
  private currentStringHangerTvd: number;
  private currentStringShoeTvd: number;
  private _userUnits: UserUnitsModel;
  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName = 'poreFracPressurePlot';
  public downloadPlotName = 'pore_pressure_plot';
  public plotTitle: string;
  public componentHeight: number;

  @ViewChild("poreFracPressureTable")
  public poreFracPressureTable: NgTableGridComponent<PoreFrac>;

  public columnDefinitions: Array<{ field: string, header: string }>;
  public inputFields: Array<{ name: string, minFractions: number, maxFractions: number, formatDecimals: number }>;
  public calculatedFields: Array<{ name: string, formatDecimals: number }>;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.poreFracStore = this._storeService.createStore(this.componentId, new PoreFracUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public poreFracStore: Store;

  constructor(
    private _poreAndFracService: PoreAndFracService,
    private _messenger: MediatorService,
    private _wellTypeService: WellTypeService,
    private _wellConfigService: WellConfigService,
    private _signalRService: SignalRService,
    private _trajectoryService: TrajectoryService,
    private _storeService: StoreService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.newDataRow = this.newDataRow.bind(this);

    this.inputFields = [
      { name: 'trueVerticalDepth', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
      { name: 'equivalentMudWeight', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
      { name: 'pressure', minFractions: 2, maxFractions: 6, formatDecimals: 1 }
    ];

    this.columnDefsPlot = [
      { header: 'EMW', field: 'equivalentMudWeight' }
    ];
  }

  async ngOnInit(): Promise<void> {
    this._userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this._densityUnit = Units.lib[this._userUnits.density].symbol;

    this.plotTitle = this.pressureType === 'porePressure' ? 'Pore Pressure' : 'Fracture Gradient';

    this.columnDefinitions = [
      { field: 'trueVerticalDepth', header: `TVD (${this._userUnits.longLengths})` },
      { field: 'equivalentMudWeight', header: `EMW (${this._densityUnit})` },
      { field: 'pressure', header: `Pressure (${this._userUnits.pressure})` }
    ];

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getTubularString();

    this.tablePlot = [
      { label: 'Table', value: 'table' },
      { label: 'Plot', value: 'plot' }
    ];

    this.pressureEmw = [
      { label: 'EMW', value: 'emw' },
      { label: 'Pressure', value: 'pressure' }
    ];
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_PORE_PRESSURE_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_WELL_CONFIG) {
      this.getTubularString();
    }
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Pore Pressure") {
        this.tableHeightPore = (e.itemHeight - 95) + 'px';
        this.plotTitle = 'Pore Pressure';
      }
      if (e.name == "Fracture Gradient") {
        this.tableHeightFrac = (e.itemHeight - 95) + 'px';
        this.plotTitle = 'Fracture Gradient';
      }
    }));
    
    //Handle disabeling of the first row. Still allow edit of first row for pasting.
    this._subscriptions.add(this.poreFracPressureTable.dataFormArray.valueChanges.subscribe(() => {
      this.poreFracPressureTable.dataFormArray?.controls[0]?.disable({ emitEvent: false });
    }));
  }

  public newDataRow(): UntypedFormGroup {
    var fg = new UntypedFormGroup({
      trueVerticalDepth: new UntypedFormControl(0, [Validators.required, valuesIncreaseValidator("trueVerticalDepth")]),
      equivalentMudWeight: new UntypedFormControl(null, [greaterThanZero]),
      pressure: new UntypedFormControl(null, [greaterThanZero])
    });
    // Handles Calc Client side for EMW / Pressures...
    fg?.get('equivalentMudWeight')?.valueChanges.subscribe(emw => {
      let pf = fg.value as PoreFrac;
      let val = calculatePressure(pf.trueVerticalDepth, emw, this._userUnits);
      fg?.get('pressure')?.setValue(val, { emitEvent: false });
    });

    fg?.get('pressure')?.valueChanges.subscribe(psi => {
      let pf = fg.value as PoreFrac;
      let val = calculteEmw(pf.trueVerticalDepth, psi, this._userUnits);
      fg?.get('equivalentMudWeight')?.setValue(val, { emitEvent: false });
    });
    return fg;
  }

  public onPorePressuresChanged(v: { triggeredBy: any, dataRows: Array<PoreFrac>, reload: boolean }) {
    if (v.triggeredBy.type === "clipboard") {
      this.poreFracPressureTable.dataFormArray.controls.forEach((fg: any) => {
        let pf = fg.value as PoreFrac;
        if (pf.equivalentMudWeight) {
          fg.controls.pressure.setValue(calculatePressure(pf.trueVerticalDepth, pf.equivalentMudWeight, this._userUnits), { emitEvent: false });
        }
        if (pf.pressure) {
          fg.controls.equivalentMudWeight.setValue(calculteEmw(pf.trueVerticalDepth, pf.pressure, this._userUnits), { emitEvent: false });
        }
      });
      this.poreFracPressureTable.pTableData = [...this.poreFracPressureTable.dataFormArray.controls];
    }

    let defaultDensity = 8.6;
    switch (this._userUnits.density) {
      case 'g/L':
      case 'kg/m³': {
        defaultDensity = 1030.51;
        break;
      }
      case 'kg/l':
      case 'g/cm³': {
        defaultDensity = 1.03;
        break;
      }
      default: {
        break;
      }
    }

    let valueToSave: Array<PoreFrac> = v.dataRows.length == 0 ? [{ trueVerticalDepth: this.mudlineDepth, equivalentMudWeight: defaultDensity, pressure: 0 }] : v.dataRows;

    if (this.pressureType === 'porePressure') {
      this._poreAndFracService.setPorePressures(valueToSave).subscribe(async () => {
        // Values calculated on client, no need to pull data from server.
      });
    } else {
      this._poreAndFracService.setFractureGradient(valueToSave).subscribe(async () => {
        // Values calculated on client, no need to pull data from server.
      });
    }
    this.pressures = valueToSave;
    this.plotData();
  }

  public getTubularString() {
    this.isLoading = true;
    const sources: Observable<any>[] = [
      this.pressureType === 'porePressure' ? this._poreAndFracService.getPorePressures() : this._poreAndFracService.getFractureGradient(),
      this._wellConfigService.getTubulars(),
      this._wellConfigService.getSelectedTubularId() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([pressures, tubulars, currentString, wellType]) => {
        this.mudlineDepth = +(wellType.drillFloorElevation + wellType.waterDepth).toFixed(2);
        this.currentString = tubulars.find(x => x.id === currentString.id);

        if (tubulars.length < 1) {
          this.pressures = pressures;
        } else {
          this.casingArray = [];
          tubulars.forEach(async tubular => {
            if (tubular.type === 'Tubing') {
              return;
            }
            await this.getTvdData(tubular);
          });

          this.annularFluidDensity = this.currentString.annularFluid.state.nominalDensity > 0 ? this.currentString.annularFluid.state.nominalDensity : undefined;
          this.pressures = pressures;
        }
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public async getTvdData(tubular: any) {
    const stringSections = tubular.stringSections;
    const bottomCasingDepth = stringSections[stringSections.length - 1].bottomMeasuredDepth;
    const topCasingDepth = tubular.hangerMd;

    const mds = [bottomCasingDepth, topCasingDepth, this.currentString?.hangerMd, this.currentString?.shoeMd];

    try {
      const tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds(mds, true));

      this.shoeDepth = tvds[0];              // Bottom casing depth Tvd
      this.hangerMd = tvds[1];               // Top casing depth Tvd
      this.currentStringHangerTvd = tvds[2]; // Hanger Md Tvd
      this.currentStringShoeTvd = tvds[3];   // Shoe Md Tvd

      this.casingArray.push({ hanger: this.hangerMd, shoe: this.shoeDepth, name: tubular.name, seqNumber: tubular.sequenceNumber });
      this.casingArray.sort((a, b) => (a.seqNumber > b.seqNumber ? 1 : -1));

      this.plotData();
    } catch (error) {
      console.error('Error fetching TVD values:', error);
    } finally {
      this.isLoading = false;
    }
  }

  plotData() {
    const data = this.pressures;
    if (!data) return;

    const trueVerticalDepths = data.map(r => r.trueVerticalDepth);
    const pressures = data.map(r => r.pressure);

    // Find the index where the true vertical depth is greater than or equal to the mudline depth
    const mudlineIndex = trueVerticalDepths.findIndex(depth => depth >= this.mudlineDepth);

    // Create new arrays that exclude depths and pressures below the mudline
    const filteredDepths = trueVerticalDepths.slice(mudlineIndex);
    const filteredPressures = pressures.slice(mudlineIndex);

    // Add the mudline depth if necessary
    if (filteredDepths[0] > this.mudlineDepth) {
      filteredDepths.unshift(this.mudlineDepth);
      filteredPressures.unshift(0);
    }

    const traceArray = this.columnDefsPlot.map(element => ({
      name: element.header,
      x: this.poreFracStore.state.pressureEmwSelected === 'emw'
        ? data.map(r => r.equivalentMudWeight)
        : filteredPressures,
      y: filteredDepths
    }));

    this.plot.data = traceArray;
    this.plotPressures(this.plot.data);
  }


  tablePlotToggle(e): void {
    this.poreFracStore.update(state => ({ ...state, tablePlotSelected: e.value }));
    this.plotData();
  }

  pressureEmwToggle(e): void {
    this.poreFracStore.update(state => ({ ...state, pressureEmwSelected: e.value }));
    this.plotData();
  }

  plotPressures(plotData) {
    const plot = [];
    const data = plotData[0];

    const pressureData = {
      name: this.poreFracStore.state.pressureEmwSelected === 'emw' ? 'EMW ' : 'Pressure',
      x: data.x,
      y: data.y
    }

    plot.push(pressureData);
    let casingX;

    let pressureFactorMin;
    let pressureFactorMax;
    let xAxisCasingIncrement;
    switch (this._userUnits.pressure) {
      case 'psi':
        pressureFactorMin = 1000;
        pressureFactorMax = 2500;
        xAxisCasingIncrement = 250;
        break;
      case 'KPa':
        pressureFactorMin = 6000;
        pressureFactorMax = 15000;
        xAxisCasingIncrement = 4000;
        break;
      case 'bar':
        pressureFactorMin = 170;
        pressureFactorMax = -500;
        xAxisCasingIncrement = 30;
        break;
      case 'atm':
        pressureFactorMin = 150;
        pressureFactorMax = 250;
        xAxisCasingIncrement = 30;
        break;
    }

    let densityFactorMin;
    let densityFactorMax;
    switch (Units.lib[this._userUnits.density].symbol) {
      case 'ppg':
        densityFactorMin = 0.5;
        densityFactorMax = 1.5;
        break;
      case 'kg/m³':
      case 'g/L':
        densityFactorMin = 100;
        densityFactorMax = 500;
        break;
      case 'g/cm³':
      case 'kg/l':
        densityFactorMin = 0.5;
        densityFactorMax = 0.5;
        break;
    }

    const isPressure = this.poreFracStore.state.pressureEmwSelected === 'pressure';
    const xAxisFactorMudlineMin = isPressure ? pressureFactorMin : densityFactorMin;
    const xAxisFactorMudlineMax = isPressure ? pressureFactorMax : densityFactorMax;

    if (this.poreFracStore.state.pressureEmwSelected === 'emw') {
      if (this.annularFluidDensity) {
        const annDensity = {
          name: "Annular Fluid Density",
          x: [this.annularFluidDensity, this.annularFluidDensity],
          y: [this.currentStringHangerTvd, this.currentStringShoeTvd]
        }

        plot.push(annDensity);
      }
    } else {
      const lastElement = plotData[0].x.length - 1;
      if (!plotData[0].x[lastElement]) {
        plotData[0].x.pop();
        plotData[0].y.pop();
      }
      const largestPointExt = Math.max(...plotData[0].x);
      const pressureIncrement = GetValueFromPsi(450, this._userUnits.pressure);
      casingX = largestPointExt + pressureIncrement;

      const csgArrayLength = this.casingArray.length;
      let distance = 25;
      for (let i = 0; i < csgArrayLength; i++) {
        const hanger = this.casingArray[i].hanger;
        const shoe = this.casingArray[i].shoe;
        const name = this.casingArray[i].name;

        const casing = {
          name: name,
          x: [casingX + distance, casingX + distance],
          y: [hanger, shoe],
          marker: {
            size: 10,
            symbol: ["line-ew", "triangle-up-dot"]
          },
          line: {
            color: '#999999'
          }
        }
        const increment = xAxisCasingIncrement;
        distance += increment;

        plot.push(casing);
      }
    }

    const minX = this.poreFracStore.state.pressureEmwSelected === 'emw' ? Math.min(...plotData[0].x) : Math.min(...plotData[0].x) - 500
    let maxX = this.poreFracStore.state.pressureEmwSelected === 'emw' ? Math.max(...plotData[0].x) : casingX + 700;
    maxX = Math.max(maxX, this.annularFluidDensity);

    const mudline = {
      name: "Mudline",
      y: [this.mudlineDepth, this.mudlineDepth],
      x: [minX - xAxisFactorMudlineMin, maxX + xAxisFactorMudlineMax],
      line: {
        color: '#783F04'
      },
      mode: 'lines',
      hoverinfo: 'none'
    }
    if (mudline.y[0] != null) {
      plot.push(mudline);
    }

    this.plot.data = plot;
    this.xAxisTitle = this.poreFracStore.state.pressureEmwSelected === 'emw' ? `EMW (${this._densityUnit})` : `Pressure (${this._userUnits.pressure})`
    this.yAxisTitle = `TVD (${this._userUnits.longLengths})`;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }

}
