import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserProfile } from 'src/app/core/components/user-admin-page/user-model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Flow } from 'src/app/perical/models/flow.model';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { Perforation } from 'src/app/perical/models/perforation.model';
import { OperationType, ProductionOperation } from 'src/app/perical/models/thermal-operation.model';

export interface ProductionOperationForm {
  fluid: Fluid;
  perforation: Perforation;
  perforationPressure: number;
  tempAtPerfs: number;
  standardFluidFlow: Flow;
  hydrocarbonFlow: Flow;
  type: OperationType;
  pressureLocation: string;
  time: number;
  pressure: number;
  location: string;
  annulusIndex: number;
}

@Component({
  selector: 'app-production-operation-details',
  templateUrl: './production-operation-details.component.html',
  styleUrls: ['./production-operation-details.component.scss'],
  providers: subformComponentProviders(ProductionOperationDetailsComponent)
})
export class ProductionOperationDetailsComponent implements OnChanges, AfterViewInit {

  constructor(
  ) {
    this.timeUnits = [
      { name: 'Minutes', value: 'DurationUnit.Minute' },
      { name: 'Hours', value: 'DurationUnit.Hour' },
      { name: 'Days', value: 'DurationUnit.Day' },
      { name: 'Months', value: 'DurationUnit.Month30' },
      { name: 'Years', value: 'DurationUnit.Year365' }
    ];
  }

  @Input()
  public isReadOnly: boolean;

  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[]) {
    this.fluids = fluids?.filter(f => f.state.type !== FluidType.CEMENTSLURRY).sort((a, b) => a.state.name.localeCompare(b.state.name));
  }

  public perforations: Perforation[];
  @Input('perforation') set _perforations(perforations: Perforation[]) {
    this.perforations = perforations;
  }

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public userProfile: UserProfile;

  public isShutInCheck: boolean;
  public shutinTimeDisabled: boolean;
  public timeUnits: any[];
  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;
  public tempUnit: string;
  public pressureUnit: string;
  public temperatureValidation: { min: number, max: number };
  public pressureValidation: { min: number, max: number };

  public pressureLocations = [
    { name: 'Wellhead', value: 'wellhead' },
    { name: 'Perforations', value: 'perforation' }
  ];

  public form = createForm<ProductionOperation, ProductionOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.PRODUCTION),
      fluid: new UntypedFormControl(null, [Validators.required]),
      perforationPressure: new UntypedFormControl(null),
      tempAtPerfs: new UntypedFormControl(null),
      perforation: new UntypedFormControl(null, [Validators.required]),
      standardFluidFlow: new UntypedFormControl(null),
      hydrocarbonFlow: new UntypedFormControl(null),
      pressureLocation: new UntypedFormControl('perforation', [Validators.required]),
      time: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100000)]),
      pressure: new UntypedFormControl(0),
      location: new UntypedFormControl('wellhead'),
      annulusIndex: new UntypedFormControl(null),
    },
    toFormGroup: (obj: ProductionOperation | null): ProductionOperationForm | null => {
      if (!obj) {
        return null;
      }

      const { fluid, perforation, flow, shutIn, ...commonValues } = obj;

      obj.fluid = this.fluids?.find(f => f.id === obj.fluid.id);

      // this.checkIsShutin(shutIn.time);

      return {
        standardFluidFlow: obj.fluid.state?.type === FluidType.BRINE || obj.fluid.state?.type === FluidType.STANDARDMUD || obj.fluid.state?.type === FluidType.ADVANCEDMUD ? obj.flow : null,
        hydrocarbonFlow: obj.fluid.state?.type === FluidType.BLACKOIL || obj.fluid.state?.type === FluidType.HYDROCARBON || obj.fluid.state?.type === FluidType.VLE || obj.fluid.state?.type === FluidType.CO2FLUID ? obj.flow : null,
        perforation,
        fluid: obj.fluid,
        tempAtPerfs: obj.perforation.temperature ?? this.perforations[0].temperature,
        pressureLocation: obj.pressureLocation,
        time: shutIn.time,
        pressure: shutIn.pressure?.pressure ?? 0,
        location: shutIn.pressure?.location ?? 'wellhead',
        type: obj.type,
        ...commonValues,
      };
    },
    fromFormGroup: (formValue: ProductionOperationForm): ProductionOperation | null => {
      if (!formValue.time) {
        formValue['time'] = 0;
      }
      if (!formValue.time) {
        formValue['pressure'] = 0;
      }
      const { fluid, perforation, tempAtPerfs, standardFluidFlow, hydrocarbonFlow, type, pressureLocation, time, pressure, location, ...commonValues } = formValue;

      this.updateTempAtPerfs();

      switch (true) {
        case fluid?.state.type === FluidType.BRINE || fluid?.state.type === FluidType.STANDARDMUD || fluid?.state.type === FluidType.ADVANCEDMUD:
          return standardFluidFlow ? {
            fluid,
            perforation: {
              id: perforation.id,
              designId: perforation.designId,
              name: perforation.name,
              measuredDepth: perforation.measuredDepth,
              temperature: tempAtPerfs ?? this.perforations[0].temperature
            },
            flow: standardFluidFlow,
            type: OperationType.PRODUCTION,
            pressureLocation: pressureLocation,
            shutIn: {
              time: +time,
              pressure: {
                pressure: +pressure,
                location,
              },
            },
            ...commonValues
          } : null;
        case fluid?.state.type === FluidType.BLACKOIL || fluid?.state.type === FluidType.HYDROCARBON || fluid?.state.type === FluidType.VLE || fluid?.state.type === FluidType.CO2FLUID:
          return hydrocarbonFlow ? {
            fluid,
            perforation: {
              id: perforation.id,
              designId: perforation.designId,
              name: perforation.name,
              measuredDepth: perforation.measuredDepth,
              temperature: tempAtPerfs ?? this.perforations[0].temperature
            },
            flow: hydrocarbonFlow,
            type: OperationType.PRODUCTION,
            pressureLocation: pressureLocation,
            shutIn: {
              time: +time,
              pressure: {
                pressure: +pressure,
                location,
              },
            },
            ...commonValues
          } : null;
        default:
          return null;
      }
    },
  });

  async ngOnChanges(): Promise<void> {
    this.isLoading = true;
    this.tempUnit = this.userUnits.temperature;
    this.pressureUnit = this.userUnits.pressure;

    if (this.userProfile) {
      let company = this.userProfile?.organization?.toLowerCase();
      if (company != "altus" && company != "harbour" && company != "baker" && company != "bp" && company != "fervo" && company != "neptune" && company != "shell" && company != "oxy" && company != "cop") {
        this.fluids = this.fluids.filter(f => f.state.type != 'co2Fluid');
      }
    }

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 1000 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.78, max: 537.77 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255.37, max: 810.92 };
        break;
    }
    this.form.formGroup.controls.tempAtPerfs.setValidators([Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max), Validators.required]);

    switch (this.pressureUnit) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 100000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 6894 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 689475 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 6804 };
        break;
    }
    this.form.formGroup.controls.perforationPressure.setValidators([Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required]);

    this.updateTempAtPerfs();
  }

  ngAfterViewInit(): void {
    // this.isNotShutin();
    let controls = this.form.formGroup.controls;
    let defaultFluid = this.fluids ? this.fluids[0] : {
      id: "0000-0000-0000-0000",
      isInUse: false,
      state: {
        brineType: "seawater",
        nominalDensity: 10,
        plasticViscosity: 1,
        viscosityTemperature: 120,
        name: "10ppg SW",
        type: FluidType.BRINE
      }
    };

    if (!controls.fluid.value) { controls.fluid.setValue(defaultFluid); }
    if (!controls.perforation.value) {
      this.perforations ? controls.perforation.setValue(this.perforations[0]) : controls.perforation.setValue({
        id: "0000-0000-0000-0000",
        designId: '0000-0000-0000-0000',
        name: "Deepest Shoe",
        measuredDepth: 0.0,
        temperature: 250.0
      });
    }
  }

  private updateTempAtPerfs() {
    if (!this.form.formGroup.controls.tempAtPerfs.value) {
      this.form.formGroup.controls.tempAtPerfs.setValue(this.perforations[0].temperature, { emitEvent: false });
    }
  }

  public isShutinChange(e) {
    if (e['srcElement']['checked']) {
      this.isShutin();
    } else {
      this.isNotShutin();
    }
  }

  public perfChange(e) {
    this.form.formGroup.controls.tempAtPerfs.setValue(e.value.temperature);
  }

  private isShutin() {
    this.form.formGroup.controls.time.setValue(10, { emitEvent: false });
    this.shutinTimeDisabled = false;
    this.form.formGroup.controls.time.enable({ emitEvent: false });
    this.form.formGroup.controls.pressure.enable({ emitEvent: false });
  }

  private isNotShutin() {
    this.form.formGroup.controls.time.patchValue(0, { emitEvent: false });
    this.shutinTimeDisabled = true;
    this.form.formGroup.controls.time.disable({ emitEvent: false });
    this.form.formGroup.controls.pressure.disable({ emitEvent: false });
  }

  checkIsShutin(e) {
    if (e === 0) {
      this.isShutInCheck = false;
      this.isNotShutin();
    } else {
      this.isShutInCheck = true;
      this.isShutin();
    }
  }
}
