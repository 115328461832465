<app-tool-loader [isLoading]="isLoading">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <span class="designTxt"
        ><b *ngIf="!smallerScreen">Design: </b><b>{{ currentDesign && currentDesign.name }}</b> <b *ngIf="user?.roles?.readOnly" style="user-select: none;">&nbsp; [read-only]</b> &nbsp;</span>

      <p-dropdown
        placeholder="&#60;Well Configuration: Empty>"
        [options]="tubulars"
        [(ngModel)]="selectedTubular"
        dataKey="id"
        optionLabel="name"
        (ngModelChange)="tubularSelectionChanged()"
        appendTo="body"
      >
        <ng-template pTemplate="selectedItem">
          <div title="Current String" *ngIf="selectedTubular">
            {{ selectedTubular.name }}
          </div>
        </ng-template>
        <ng-template let-tubular pTemplate="item">
          <div *ngIf="tubular">{{ tubular.name }}</div>
        </ng-template>
      </p-dropdown>

      <div class="dropdownCalculate" *ngIf="smallerScreen">
        <p-dropdown appendTo="body" resetFilterOnHide=true [(ngModel)]="calcItem" defaultLabel="Calculate"  placeholder="Calculate" [options]="calcButtons" (onChange)="onCalculate($event)"></p-dropdown>
      </div>

      <div *ngIf="!smallerScreen">
        <p-button
          class="thermalButton"
          styleClass="p-button-sm p-button-warning"
          label="Perical"
          (onClick)="onCalculateThermal()"
          [disabled]="isPericalDisabled"
          icon="{{ calculatingPerical ? 'pi pi-spin pi-spinner' : '' }}"
          >
          <img *ngIf="!calculatingPerical" class="img pericalIcon" src="../../assets/icons/icons8-fire-90.png" />
        </p-button>

        <p-button
          class="apbButton"
          styleClass="p-button-sm p-button-success"
          label="Perinix"
          (onClick)="onCalculateApb()"
          [disabled]="isPerinixDisabled"
          icon="{{ calculatingPerinix ? 'pi pi-spin pi-spinner' : '' }}"
        >
        <img *ngIf="!calculatingPerinix" class="img perinixIcon" src="../../assets/icons/icons8-pressure-100.png" />
        </p-button>

        <p-button
          class="stressButton"
          styleClass="p-button-sm p-button-success"
          label="Perivis"
          (onClick)="onCalculateStress()"
          [disabled]="isPerivisDisabled"
          icon="{{ calculatingPerivis ? 'pi pi-spin pi-spinner' : '' }}"
          >
          <img *ngIf="!calculatingPerivis" class="img perivisIcon" src="../../assets/icons/icons8-sigma-96.png" />
        </p-button>
      </div>
    </div>

    <div class="p-toolbar-group-right">
      <div class="searchClassToolbar">
        <p-multiSelect [filter]="true" class="multiSelectClass" #search resetFilterOnHide=true [(ngModel)]="selectedItems" defaultLabel="Search" [showToggleAll]="false" (onChange)="addUiComponent($event)" optionLabel="name" [options]="components"></p-multiSelect>
      </div>
      <p-button
        styleClass="p-button-sm p-button-secondary"
        (click)="toggleSchematic()"
      >
        <img class="img" src="../../assets/icons/Schematic.png" />
        <span *ngIf="!smallerScreen">Well Schematic</span>
      </p-button>
    </div>
  </p-toolbar>
</app-tool-loader>
