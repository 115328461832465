import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { Subscription } from 'rxjs';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { ChangeSelectedTubular, GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { StableZoom } from './services/paperJs-pan-zoom';
import { Tubular, WellComponent } from '../well-configuration/models';
import { SchematicDrawingEngine } from './services/schematic-draw-engine';
import { saveAs } from 'file-saver';
import { Design } from 'src/app/shared/models/design.model';
import { WellSchematicService } from '../../services/well-schematic.service';
import { WellSchematicConsolidated } from '../../models/well-schematic.model';
import { WellSchematicUi } from './models/well-schematic-models';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-well-schematic2',
  templateUrl: './well-schematic.component.html',
  styleUrls: ['./well-schematic.component.scss'],
  providers: [SchematicDrawingEngine]
})
export class WellSchematicComponent implements OnInit, OnDestroy {

  private _designStore: Store<any, any>;
  private _design: Design;
  private _subscriptions: Subscription;
  private _destroy$ = new Subject<void>();

  public isPanning: boolean;
  public selectedTubularString: WellComponent;
  public data: WellSchematicConsolidated;

  @Input()
  public isSidebarComp?: boolean = false;

  @ViewChild('wellSchematic', { static: true })
  public wellSchematic: ElementRef;

  // State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.wellSchematicStore = this._storeService.createStore(this.componentId, new WellSchematicUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public wellSchematicStore: Store;

  constructor(
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _drawingService: SchematicDrawingEngine,
    private _wellSchematicService: WellSchematicService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    if (!this.isSidebarComp) {
      this._subscriptions.add(this._messenger.of(GridItemResizedMessage).pipe(debounceTime(200), takeUntil(this._destroy$)).subscribe((e) => {
        this.wellSchematic.nativeElement.width = e.itemWidth;
        this.wellSchematic.nativeElement.height = e.itemHeight - 32;
        window.devicePixelRatio = 2;
        this.drawSchematic();
      }));
    }
  }

  async ngOnInit(): Promise<void> {
    this._designStore = this._storeService.getStore(StorageKeys.DESIGN);
    this._subscriptions.add(this._designStore.subscribe(design => {
      this._design = design;
      this.getDataAndDrawSchematic();
    }).add(() => {
      // Ensures the subscription is handled properly
      if (this._destroy$) this._destroy$.next();
    }));

    this._signalRService.getConnectionToNotificationHub().on(SignalRService.ON_PFB_CHANGE, (d) => this.signalRfunc(d));

    if (this.isSidebarComp) {
      this.wellSchematicStore = this._storeService.createStore('well-schematic-sidebar@' + this._design.id, new WellSchematicUi);
    }
  }

  signalRfunc(data: any) {
    if (data.designId != this._design.id) {
      return;
    }
    if (data.action == PeriforOnChangeMessages.REFRESH_SCHEMATIC || data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.getDataAndDrawSchematic();
    }
  }

  getDataAndDrawSchematic() {
    this._wellSchematicService.getWellSchematic(this._design.id).pipe(takeUntil(this._destroy$)).subscribe({
      next: (res) => {
        this.data = res;
        this.wellSchematicStore.update((state) => ({
          ...state,
          drawWater: state.drawWater ? state.drawWater : (this.data.wellType.type == 'Platform' ? true : false)
        }));
        this.drawSchematic();
      },
      error: () => {
        console.log("Well configuration fetch failed.");
      }
    });
  }

  drawSchematic() {

    if (this.selectedTubularString) {
      this._messenger.publish(new ChangeSelectedTubular(new Tubular({ ...this.selectedTubularString } as any)));
    }

    let height = this.wellSchematic.nativeElement.offsetHeight - 32; // 32 pixels for component toolbar
    let verticalFactor = parseFloat((1 / (Math.max(...this?.data?.tubularStrings?.map(x => x.shoeMd)) / height)).toFixed(8));

    this.selectedTubularString = null;

    this._drawingService.onPaperClick = ((e: any) => {
      this.selectedTubularString = null;
    })

    this._drawingService.heightScaleFactor = verticalFactor;
    this._drawingService.drawLabels = !this.wellSchematicStore.state.drawNoLabels;
    this._drawingService.drawWater = this.wellSchematicStore.state.drawWater;

    this._drawingService.onPathSelect = (e, tsId, odPath, idPath) => this.schematicItemOnClickHandler(e, tsId, odPath, idPath);

    let wellConfigToDraw = this.wellSchematicStore.state.currentStageOnly
      ? this.data.tubularStrings.slice(0, this.data.tubularStrings.findIndex(x => x.id == this.data.currentTubular) + 1)
      : this.data.tubularStrings;

    let drawingObjects = this._drawingService.convertWellConfigurationToSchematicObjs(this.data, wellConfigToDraw);
    this._drawingService.drawSchematic(this.wellSchematic, drawingObjects);
  }

  onExportPng() {
    this._drawingService.project.view.element.toBlob((blob) => { saveAs(blob, `${this?._design?.name}_schematic.png`); });
  }

  onLabelToggle(e) {
    this.wellSchematicStore.update((state) => ({
      ...state,
      drawNoLabels: e.checked
    }));
    this.drawSchematic();
  }

  onWaterToggle(e) {
    this.wellSchematicStore.update((state) => ({
      ...state,
      drawWater: e.checked
    }))
    this.drawSchematic();
  }

  onCurrentStageOnly(e) {
    this.wellSchematicStore.update((state) => ({
      ...state,
      currentStageOnly: e.checked
    }));
    this.drawSchematic();
  }

  onMouseDown = (e: MouseEvent) => { this.isPanning = true; }

  onMouseUp = (e: MouseEvent) => { this.isPanning = false; }

  onMouseOver(event: MouseEvent) {
    (event.currentTarget as any).classList.add("mouseDown");
    this.isPanning = false;
  }

  onMouseOut(event: MouseEvent) {
    (event.currentTarget as any).classList.remove("mouseDown");
    this.isPanning = false;
  }

  onMouseMove(event: MouseEvent) {
    if (this.isPanning) {
      let project = this._drawingService.project;
      let stablePanAndZoom = new StableZoom();
      var newCenter = stablePanAndZoom.changeCenter(project.view.center, -event.movementX, event.movementY, .3);
      project.view.center = newCenter;
      event.preventDefault();
    }
  }

  onMouseWheel(event: WheelEvent) {
    let project = this._drawingService.project;
    let stablePanAndZoom = new StableZoom();
    if (event.shiftKey) {
      var newCenter = stablePanAndZoom.changeCenter(project.view.center, event.deltaX, event.deltaY, 2.25);
      project.view.center = newCenter;
      event.preventDefault();
    } else {
      let viewPosition = project.view.viewToProject(new paper.Point(event.offsetX, event.offsetY))
      let [newZoom, offset] = stablePanAndZoom.changeZoomStable(project.view.zoom, event.deltaY, project.view.center, viewPosition);
      project.view.zoom = newZoom
      project.view.center = project.view.center.add(offset);
      event.preventDefault()
    }
  }

  private schematicItemOnClickHandler(e: Event, tsId: string, odPath: paper.Path, idPath: paper.Path): void {
    this._drawingService.project.deselectAll();
    this.selectedTubularString = this.data.tubularStrings.find(t => t.id == tsId);
    idPath.selected = true;
    odPath.selected = true;
    e.stopPropagation();
  };

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this._destroy$.next();
    this._destroy$.complete();
  }

}
