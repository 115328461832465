import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { CreateTreeLevelDialogComponent } from '../create-tree-level-dialog/create-tree-level-dialog.component';
import { RenameTreeLevelDialogComponent } from '../rename-tree-level-dialog/rename-tree-level-dialog.component';
import { DeleteTreeLevelDialogComponent } from '../delete-tree-level-dialog/delete-tree-level-dialog.component';
import { CreateDesignDialogComponent } from '../create-design-dialog/create-design-dialog.component';
import { getChildLevel } from '../well-explorer-helpers';
import { MoveDesignDialogComponent } from '../move-design-dialog/move-design-dialog.component';

@Component({
  selector: 'well-explorer-item',
  templateUrl: './well-explorer-item.component.html',
  styleUrls: ['./well-explorer-item.component.scss'],
  providers: [DialogService]
})
export class WellExplorerItemComponent implements OnDestroy {

  readonly companyLevel = 'companies';
  readonly designLevel = 'designs';

  private _subscriptions = new Subscription();
  private titleCasePipe = new TitleCasePipe();

  public hover: boolean;
  public hoverNode: TreeNode;
  public getChildLevel = getChildLevel;

  @Input() public isAdmin: boolean;
  @Input() public treeNode: any;
  @Input() public selectedNode: TreeNode;
  @Input() public columns: Array<any>;
  @Input() public rowData: Array<any>;
  @Input() public treeItems: TreeNode[];

  @Output() public onContextMenuUpdated = new EventEmitter<Array<MenuItem>>();
  @Output() public onDeleteNode = new EventEmitter<TreeNode>();
  @Output() public onRenameItem = new EventEmitter<{ name: string, treeNode: TreeNode }>();
  @Output() public onCloneDesign = new EventEmitter<TreeNode>();
  @Output() public onExportDesign = new EventEmitter<TreeNode>();
  @Output() public onMoveDesign = new EventEmitter<TreeNode>();
  @Output() public onShowImportDesignDialog = new EventEmitter<TreeNode>();
  @Output() public onAddChildNode = new EventEmitter<{ levelData: { id: string, name: string }, level: string, treeNode: TreeNode }>();

  constructor(private _dialogService: DialogService) {}

  public addChildNode(treeNode: TreeNode): void {
    const level = getChildLevel(treeNode.data.level);
    const dialogReference = this._dialogService.open(level === this.designLevel ? CreateDesignDialogComponent : CreateTreeLevelDialogComponent, {
      data: { level, treeItems: this.treeItems },
      header: 'Create a New ' + this.titleCasePipe.transform(level.slice(0, -1)),
      width: '500px'
    });

    this._subscriptions.add(
      dialogReference.onClose.subscribe((levelData: { id: string, name: string }) => {
        if (levelData) {
          this.onAddChildNode.emit({ levelData, treeNode, level });
        }
      })
    );
  }

  public deleteItem(treeNode: TreeNode): void {
    const { level, label: levelName } = treeNode.data;

    const deleteDialogRef = this._dialogService.open(DeleteTreeLevelDialogComponent, {
      data: { levelName, parentName: treeNode?.parent?.data?.label ?? '', level },
      header: `Delete ${level === this.companyLevel ? 'Company' : this.titleCasePipe.transform(level.slice(0, -1))}`,
      width: '60%'
    });

    this._subscriptions.add(
      deleteDialogRef.onClose.subscribe(res => {
        if (res === 'confirmed') {
          this.onDeleteNode.emit(treeNode);
        }
      })
    );
  }

  public cloneDesign(treeNode: TreeNode): void {
    this.onCloneDesign.emit(treeNode);
  }

  public exportDesign(treeNode: TreeNode): void {
    this.onExportDesign.emit(treeNode);
  }

  public moveDesign(treeNode: TreeNode): void {
    const { level, label: levelName, id } = treeNode.data;
    let fromWellboreId = treeNode.parent.data.id;
    const moveDesignRef = this._dialogService.open(MoveDesignDialogComponent, {
      data: { id, fromWellboreId, level, currentLevelName: levelName, treeItems: this.treeItems },
      header: `Move - ${levelName}`,
      width: '30%'
    });

    this._subscriptions.add(
      moveDesignRef.onClose.subscribe(res => {
          this.onMoveDesign.emit(res);
      })
    );
  }

  public renameItem(treeNode: TreeNode): void {
    const { level, label: currentLevelName } = treeNode.data;
    const singularLevel = level === this.companyLevel ? 'Company' : this.titleCasePipe.transform(level.slice(0, -1));

    const ref = this._dialogService.open(RenameTreeLevelDialogComponent, {
      data: { level, currentLevelName, singularLevel },
      header: `Rename ${singularLevel}`,
      width: '400px'
    });

    this._subscriptions.add(
      ref.onClose.subscribe((levelName: string) => {
        if (levelName) {
          this.onRenameItem.emit({ name: levelName, treeNode });
        }
      })
    );
  }

  public onMouseOver(treeNode: TreeNode): void {
    this.hover = true;
    this.hoverNode = treeNode;
  }

  public filterContextMenu(level: string): void {
    let actions: MenuItem[] = [
      { label: 'Delete', icon: 'pi pi-trash', command: () => this.deleteItem(this.selectedNode) },
      { label: 'Rename', icon: 'pi pi-pencil', command: () => this.renameItem(this.selectedNode) }
    ];

    if ((level === 'companies' || level === 'projects' || level === 'sites') && !this.isAdmin) {
      actions = [];
    }

    if (level === 'designs') {
      actions.push(
        { label: 'Clone', icon: 'pi pi-copy', command: () => this.cloneDesign(this.selectedNode) },
        { label: 'Export', icon: 'pi pi-download', command: () => this.exportDesign(this.selectedNode) },
        { label: 'Move', icon: 'pi pi-upload', command: () => this.moveDesign(this.selectedNode) }
      );
    }

    if (level === 'wellbores') {
      actions.push({ label: 'Import', icon: 'pi pi-upload', command: () => this.onShowImportDesignDialog.emit(this.selectedNode) });
    }

    this.onContextMenuUpdated.emit(actions);
  }

  public getWellExplorerLevelName(level: string): string {
    return level == 'companies' ? 'Company' : this.titleCasePipe.transform(level.slice(0, -1));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
