import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { PackerService } from 'src/app/wellbore-inputs/services/packers.service';
import { StressResultsService } from '../../services/stress-results.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { PackerEnvelopeUi } from 'src/app/wellbore-inputs/models/packers.model';

@Component({
  selector: 'app-packer-envelope-results',
  template: `
    <app-tool-loader [isLoading]="isLoading" positionUnset="true">
      <p-dropdown
          id="thermalOperation"
          [options]="packers"
          [(ngModel)]="packerEnvelopeStore.state.selectedPacker"
          appendTo="body"
          (onChange)="selectPacker($event.value)"
      ></p-dropdown>

      <app-xy-line-plot
          [plotData]="plot.data"
          [xAxisTitle]="xAxisTitle"
          [yAxisTitle]="yAxisTitle"
          [plotName]="plotName"
          [downloadPlotName]="downloadPlotName"
          [plotTitle]="'Packer Envelope'"
          [reverseAutoRange]="true"
      ></app-xy-line-plot>

      <p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false" *ngIf="!isLoading">
          <div *ngIf="needToCalculate"><i class="pi pi-exclamation-circle"></i> Please calculate Perivis to see results.</div>
          <div *ngIf="packersMissing"><i class="pi pi-exclamation-circle"></i> No Packers set on selected string.</div>
          <div *ngIf="envelopeMissing"><i class="pi pi-exclamation-circle"></i> Packer Operating Envelope not specified for the selected packer.</div>
          <br>
      </p-dialog>
  </app-tool-loader>
  `,
  styles: [`
    p-dialog {
      user-select: none;
    }

    :host ::ng-deep .p-dropdown {
      height: 28px;

      .p-dropdown-label {
        padding-top: 4px;
        font-size: 12px;
      }
    }
  `]
})
export class PackerEnvelopeResultsComponent implements OnInit, OnDestroy {
  private _packerResults: any;
  private _packerLoadResults: any;

  public packers: any[] = [];
  public tableName: string;
  public columnDefs :Array<{ header: string, field: string }>;
  public results: any;
  public needToCalculate: boolean;
  public isLoading: boolean;
  public packersMissing: boolean;
  public envelopeMissing: boolean;
  public userUnits: UserUnitsModel;

  public plot = {
    data: [],
    layout: {
      xaxis: {},
      yaxis: {}
    },
    config: {
      scrollZoom: false,
    }
  };

  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName = 'packerOperatingEnvelope';
  public downloadPlotName = 'packer_operating_envelope';

    //State Management
    private _componentId: string;
    @Input() set componentId(value: string) {
      this._componentId = value;
      this.packerEnvelopeStore = this._storeService.createStore(this.componentId, new PackerEnvelopeUi);
    }
    get componentId(): string {
      return this._componentId;
    }
    public packerEnvelopeStore: Store;

  constructor(
    private _packerService: PackerService,
    private _signalRService: SignalRService,
    private _stressResultsService: StressResultsService,
    private _storeService: StoreService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.yAxisTitle = `Axial Force +Up [Tension] / -Down [Compression] (${Units.lib[this.userUnits.force].symbol})`;
    this.xAxisTitle = `Differential Pressure +Up/-Down (${this.userUnits.pressure})`;;

    this.refreshResults();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS || data.action == PeriforOnChangeMessages.REFRESH_PACKERS ||
      data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.refreshResults();
    }
  }

  private async refreshResults() {
    this.isLoading = true;
    this._packerResults = await lastValueFrom(this._packerService.getPackersForTubular());
    this._packerLoadResults = await lastValueFrom(this._stressResultsService.getPackerLoads());

    if (this._packerResults.packers.length == 0) {
      this.packersMissing = true;
      this.envelopeMissing = false;
      this.needToCalculate = false;
      this.packers = [];
      this.plot.data = [];
      this.isLoading = false;
      return;
    } else if ((this._packerLoadResults?.length ?? 0) == 0) {
      this.plot.data = [];
      this.packersMissing = false;
      this.needToCalculate = true;
      this.envelopeMissing = false;
      this.isLoading = false;
      return;
    } else {
      this.packersMissing = false;
      this.needToCalculate = false;
    }
    this.plot.config.scrollZoom = this.plot ? true : false;
    this.packers = this._packerLoadResults.map(x => x.packerName);
    if (!this.packerEnvelopeStore.state.selectedPacker) {
      this.packerEnvelopeStore.update(state => ({...state, selectedPacker: this._packerLoadResults[0].packerName}));
    }
    this.selectPacker();
  }

  public selectPacker(packer?: string) {
    if (packer) { this.packerEnvelopeStore.update(state => ({...state, selectedPacker: packer})) };

    const selectedPackerEnvelope = this._packerResults.packers.find(x => x.name === this.packerEnvelopeStore.state.selectedPacker)?.packerEnvelope;

    const index = this._packerLoadResults.findIndex(x => x.packerName === this.packerEnvelopeStore.state.selectedPacker);

    const packerLoads = this._packerLoadResults ? this._packerLoadResults[index]?.packerLoadResults : null;

    if (!selectedPackerEnvelope || selectedPackerEnvelope.length == 1) {
      this.envelopeMissing = true;
      this.plot.data = [];
      this.isLoading = false;
      this.needToCalculate = false;
      return;
    } else if (!packerLoads) {
      this.plot.data = [];
      this.needToCalculate = true;
      this.envelopeMissing = false;
      this.isLoading = false;
      return;
    } else {
      this.envelopeMissing = false;
      this.needToCalculate = false;
    }

    let packerLoadData = packerLoads.filter(x => !isNaN(x.differentialPressure) && !isNaN(x.tubingToPackerForce)).map(x => {
      return {
        x: [-x.differentialPressure.toFixed(2)],
        y: [-x.tubingToPackerForce.toFixed(2)],
        mode: 'markers',
        name: x.loadCaseName,
        marker: {
          size: 10,
          line: {
            width: 2
          }
        }
      }
    });

    this.plot.data = [{
      x: selectedPackerEnvelope.map(x => x.differentialPressure),
      y: selectedPackerEnvelope.map(x => x.force),
      mode: 'markers+lines',
      showlegend: false,
      name: 'POE',
      line: {
        color: 'red',
      },
      marker: {
        size: 2
      }
    }];

    this.plot.data.push(...packerLoadData);

    this.isLoading = false;
  }

  get isDialogVisible(): boolean {
    return this.needToCalculate || this.packersMissing || this.envelopeMissing;
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }
}
