import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { User } from 'src/app/core/components/user-admin-page/user-model';
import { debounceTime, lastValueFrom, Subscription } from 'rxjs';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-perical-options',
  templateUrl: './perical-options.component.html',
  styleUrls: ['./perical-options.component.scss']
})
export class PericalOptionsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;
  public verticalGridUnit: string;
  public gridSpacingValidation: { min: number, max: number };
  public pericalOptionsForm: UntypedFormGroup;
  public isLoading: boolean;
  public hideGridRefinement: boolean;

  // State
  @Input()
  private componentId: string;

  constructor(
    private _storeService: StoreService,
    private _formBuilder: UntypedFormBuilder,
    private _thermalOperationsService: ThermalOperationsService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();
  }
  
  async ngOnInit(): Promise<void> {
    this.verticalGridUnit = (await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS)).longLengths;
    let user = await this._storeService.get<User>(StorageKeys.USER);
    
    this.gridSpacingValidation = this.verticalGridUnit == 'ft' ? { min: 5, max: 1000 } : { min: 1.5, max: 300 };
    
    var pericalOptions = await lastValueFrom(this._thermalOperationsService.getPericalOptions());
    
    let company = user.profile?.organization?.toLowerCase();
    this.hideGridRefinement = company != 'altus';
    
    this.createReactiveForm();
    this.pericalOptionsForm.patchValue(pericalOptions, { emitEvent: false });

    this.isLoading = false;
  }

  private createReactiveForm(): void {
    this.pericalOptionsForm = this._formBuilder.group({
      id: new UntypedFormControl(""),
      maximumGridSpacing: new UntypedFormControl(null, [Validators.required, Validators.min(this.gridSpacingValidation.min), Validators.max(this.gridSpacingValidation.max)]),
      disableInterfaceRefinement: new UntypedFormControl(false),
      successiveRelationFactor: new UntypedFormControl(0.3, [Validators.required, Validators.min(0.01), Validators.max(2)]),
      latentHeatDampingFactor: new UntypedFormControl(100, [Validators.required, Validators.min(0.01), Validators.max(1000)]),
      includeLatentHeatPhaseChange: new UntypedFormControl(false),
      useAdvancedNaturalConvectionCorrelation: new UntypedFormControl(true),
      applyPseudoPhaseEnvelope: new UntypedFormControl(true),
    });

    this._subscriptions.add(this.pericalOptionsForm.valueChanges.pipe(debounceTime(500)).subscribe(async (f) => {
      if (!this.isLoading && this.pericalOptionsForm.valid) {
        await lastValueFrom(this._thermalOperationsService.setPericalOptions(this.pericalOptionsForm.getRawValue())); //getRawValue() to get disabled fields
      }
    }));
  }

  ngOnDestroy() {
    this?._subscriptions?.unsubscribe();
  }
}
