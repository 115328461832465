import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { catchError, debounceTime, forkJoin, lastValueFrom, map, Observable, Subscription } from 'rxjs';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { OpOrderModel, ThermalOpOrderModel } from '../../models/op-order.model';
import { BaseOperation, EMPTY_GUID, OperationType } from '../../models/thermal-operation.model';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { User, UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { OrderList } from 'primeng/orderlist';

@Component({
  selector: 'app-thermal-operations',
  templateUrl: './thermal-operations.component.html',
  styleUrls: ['./thermal-operations.component.scss'],
  providers: [ConfirmationService]
})
export class ThermalOperationsComponent implements OnInit, OnDestroy {

  @ViewChild("orderlist") 
  public orderlist : OrderList;

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService,
    private _store: StoreService,
    private _messenger: MediatorService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).pipe(debounceTime(200)).subscribe((e) => {
      if (e.name == "Thermal Operations" && this.orderlist) {
        this.orderlist.listStyle = { 'max-height': `${(e.itemHeight - 140) + 'px'}` };
        if (this.selectedOperation && this.operations.length > 5) {
          this.onOperationSelect({ value: this.selectedOperation }, true);
        }
      }
    }));
  }

  public operations: Partial<BaseOperation>[];
  public previousOperations: Partial<BaseOperation>[];
  public selectedOperation: Array<Partial<BaseOperation>>;
  public selectedOperationDetail$: Observable<BaseOperation>;
  public isLoading: boolean;
  public drawerOpen: boolean;
  public userUnits: UserUnitsModel;
  public userRoles : UserRoles;
  public user : User;

  private _previousSelectedOperationId: string = EMPTY_GUID;
  private _operationSelectActive: boolean;
  private _selectedFluidId: string;
  private _subscriptions: Subscription;

  // State
  @Input()
  private componentId: string;

  async ngOnInit(): Promise<void> {

    this.isLoading = true;
    this.drawerOpen = true;

    this.user = await this._store.get<User>(StorageKeys.USER);
    this.userUnits = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.userRoles = this.user.roles;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this._operationSelectActive = true;
    this.getThermalOperations();
  }

  private signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.isLoading = true;
      this._operationSelectActive = true;
      this.getThermalOperations();
    }
  }

  async getThermalOperations(): Promise<void> {
    try {
      const operations = await lastValueFrom(this._thermalOperationsService.getThermalOperations());
      this.operations = operations?.length > 0 ? operations : [];
      this.selectedOperation = this.operations?.length > 0 ? [this.operations[0]] : [];
      this._previousSelectedOperationId = this.selectedOperation[0]?.id;
  
      if (this.selectedOperation.length > 0) {
        const operation = await lastValueFrom(this._thermalOperationsService.getThermalOperationById(this.selectedOperation[0]?.id));
        this._selectedFluidId = operation.inside['fluid']?.id ?? null;
        this.previousOperations = await lastValueFrom(this._thermalOperationsService.getPreviousThermalOperations(this.selectedOperation[0]?.id));
  
        this.selectedOperationDetail$ = new Observable(observer => observer.next(operation));
        this._operationSelectActive = false;
      } else {
        this.previousOperations = await lastValueFrom(this._thermalOperationsService.getPreviousThermalOperations('00000000-0000-0000-0000-000000000000'));
        this.clearOperationDetails();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async onOperationSelect(e, calledFromCode: boolean = false, isDropdown: boolean = false): Promise<void> {
    let allOperationsSaved = this.operations.find(o => o.id === EMPTY_GUID) === undefined;
    if (!allOperationsSaved) {
      return;
    }

    const value = isDropdown ? e.value : e.value[0];

    if (this._previousSelectedOperationId === value.id && !calledFromCode) {
      return;
    }
  
    this._operationSelectActive = false;
    this.clearOperationDetails();
    this.previousOperations = [];
    const selectedOpId = value.id;
  
    try {
      if (selectedOpId !== EMPTY_GUID) {
        const [operation, previousOperations] = await lastValueFrom(forkJoin(this.currentAndPreviousOperationsSources(selectedOpId)));
  
        this._selectedFluidId = operation.inside['fluid']?.id ?? null;
        this.selectedOperation = [this.operations.find(x => x.id === selectedOpId)];
        this.selectedOperationDetail$ = new Observable(observer => observer.next(operation));
        this.previousOperations = previousOperations;
        this._previousSelectedOperationId = this.selectedOperation[0]?.id;
      } else {
        this.selectedOperationDetail$ = new Observable(observer => observer.next(e.value));
      }
    } catch (err) {
      console.log(err);
    } finally{
      this.isLoading = false;
    }
  }

  drawerToggle() {
    this.drawerOpen = !this.drawerOpen;
  }

  updateOrCreateOperation(operation: BaseOperation): void {
    if(this._operationSelectActive){
      this._operationSelectActive = false;
      return;
    }
    if (operation.id === EMPTY_GUID) {
      this._thermalOperationsService.createThermalOperation(operation).subscribe(res => {
        let newOperation: Partial<BaseOperation> = this.operations.find(o => o.id === operation.id);
        if (newOperation) {
          newOperation.name = operation.name;
          newOperation.id = res;
          operation.id = res;
        }
        operation.id = res;
        this.selectedOperation[0].id = res;
        this.selectedOperationDetail$ = new Observable(observer => observer.next(operation));
        this._previousSelectedOperationId = res;
      })
    } else {
      if (operation.inside['flow'] && !operation.inside['flow']['type'] && (operation.inside.type === OperationType.DRILLING
        || operation.inside.type === OperationType.CEMENTING
        || operation.inside.type === OperationType.CIRCULATION
        || operation.inside.type === OperationType.RUNCASINGANDCIRCULATE
        || operation.inside.type === OperationType.TRIPPIPEANDCIRCULATE)) {
          operation.inside['flow'].type = 'standardFluidFlow';
        }
        if (operation.inside['fluid'] && this._selectedFluidId != null && this._selectedFluidId !== operation.inside['fluid'].id) {
          this.selectedOperationDetail$ = new Observable();
        }

      this._thermalOperationsService.updateThermalOperation(operation).subscribe(res => {
        let updatedOperation: Partial<BaseOperation> = this.operations.find(o => o.id === operation.id);
        if (updatedOperation) {
          updatedOperation.name = operation.name;
          if (this._selectedFluidId != null && this._selectedFluidId !== operation.inside['fluid'].id) {
            this._selectedFluidId = operation.inside['fluid']?.id ?? null;
            this.selectedOperationDetail$ = new Observable(observer => observer.next(operation));
          }
        }
      });
    }
  }

  deleteOperation(operation: BaseOperation): void {
    if (operation.id === EMPTY_GUID) {
      this.selectOperationAfterDelete(operation.id);
      return;
    }
    this._confirmationService.confirm({
          message: 'Are you sure that you want to delete operation ' + operation.name.bold() + '?',
          accept: () => {
        this._thermalOperationsService.deleteThermalOperation(operation.id).subscribe(res => {
          this._operationSelectActive = true;
          this.selectOperationAfterDelete(operation.id);
        });
      }
    });
  }

  private selectOperationAfterDelete(operationId) {
    const index = this.operations.indexOf(this.operations.find(o => o.id === operationId));
    this.operations.splice(index, 1);
    this.operations = [...this.operations];
    this.selectedOperation = this.operations.length !== 0 ? [this.operations[0]] : null;
    if (this.selectedOperation !== null) {
      this._thermalOperationsService.getThermalOperationById(this.selectedOperation[0].id).subscribe(operation => {
        this.onOperationSelect({value: [operation]}, true);
      });
    } else {
      this.clearOperationDetails();
    }
  }

  cloneOperation(operation: BaseOperation): void {
    let operationName = operation.name + ' - Copy';
    this._thermalOperationsService.cloneThermalOperation(operation.id, operationName).subscribe(res => {
      forkJoin(this.currentAndPreviousOperationsSources(res.id)).pipe(
        map(([clonedOperation, previousOperations]) => {
          this.operations = [...this.operations, clonedOperation];
          this.selectedOperation = [this.operations.find(o => o.id === clonedOperation.id)];
          this.selectedOperationDetail$ = new Observable(observer => observer.next(clonedOperation));
          this.previousOperations = previousOperations;
          this._previousSelectedOperationId = clonedOperation.id;
          this.onOperationSelect({value: [clonedOperation]}, true);
          this.onReorder();
        }),
        catchError(err => {
          this.isLoading = false;
          return err;
        })).subscribe();
    });
  }

  private currentAndPreviousOperationsSources(operationId) {
    return [
      this._thermalOperationsService.getThermalOperationById(operationId) as Observable<any>,
      this._thermalOperationsService.getPreviousThermalOperations(operationId) as Observable<any>
    ];
  }

  addOperation(): void {
    if (this.operations.length > 0 && this.operations[this.operations.length - 1].id === EMPTY_GUID) {
      return;
    }
    this._thermalOperationsService.getPreviousThermalOperations('00000000-0000-0000-0000-000000000000').subscribe(previousOps => {
      this.previousOperations = previousOps;
      this._operationSelectActive = false;
      const newOperation: BaseOperation = {
        id: EMPTY_GUID,
        name: 'New Operation',
        time: { value: 24, unit: 'DurationUnit.Hour'},
        inside: null,
        annulus: null,
        mudPits: null,
        previousOperationId: null
      };
      this.operations = [...this.operations, newOperation];
      this.selectedOperation = [this.operations.find(o => o.id === newOperation.id)];
      this.selectedOperationDetail$ = new Observable(observer => observer.next(newOperation));
    });
  }

  clearOperationDetails(): void {
    const clearedOperation: BaseOperation = {
      id: null,
      name: null,
      time: { value: null, unit: 'DurationUnit.Hour'},
      inside: null,
      annulus: null,
      mudPits: null,
      previousOperationId: null
    };
    this.selectedOperation = [this.operations.find(o => o.id === clearedOperation.id)];
    this.selectedOperationDetail$ = new Observable(observer => observer.next(clearedOperation));
  }

  async onReorder() {
    let order = this.operations.map((o,i)=> new OpOrderModel({opId: o.id,  sort: i }))
    let sortModel = new ThermalOpOrderModel({sortOrder :order});
    await lastValueFrom(this._thermalOperationsService.updateThermalOperationOrder(sortModel));
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
