import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { WellExplorerService } from '../../services/well-explorer.service';
import { WellExplorerItem } from '../../models/well-explorer.model';
import { TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute } from '@angular/router';
import { StoreService, StorageKeys } from '../../services/store.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { Design } from 'src/app/shared/models/design.model';
import { getChildLevel } from './well-explorer-helpers';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { lastValueFrom } from 'rxjs';
import { UserRoles } from '../user-admin-page/user-model';
import { UsersService } from '../../services/users-service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-well-explorer',
  templateUrl: './well-explorer.component.html',
  styleUrls: ['./well-explorer.component.scss'],
  providers: [DialogService]
})
export class WellExplorerComponent implements OnInit, OnChanges {
  private _wellExplorer: WellExplorerItem;

  readonly companyLevel: string = "companies";
  readonly designLevel: string = "designs";

  public treeLevelId : string;
  public treeItems: TreeNode[];
  public selectedNode: TreeNode;
  public loading: boolean;
  public contextMenuItems: Array<MenuItem>;
  public cols: any = [{ field: 'label', header: 'Label' }];
  public design: Design;
  public allDesigns: Design[] = [];
  public userRoles: UserRoles;
  public scrollHeight: string;

  @Output()
  public onClose: EventEmitter<string>;

  @Input() public onScrollToNode: boolean;

  @ViewChild('treeTable') treeTable;

  constructor(
    private _usersService: UsersService,
    private _wellExplorerService: WellExplorerService,
    private _location: Location,
    private _route: ActivatedRoute,
    private _store: StoreService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService
  ) {
    this.onClose = new EventEmitter();
    _messenger.of(GridItemResizedMessage).subscribe((e) => {
      this.scrollHeight = (window.innerHeight - 80) + "px";
    });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    let routeDesignId = this._route.snapshot.paramMap.get('designId');
    this.design = await this._store.get<Design>(StorageKeys.DESIGN);
    this.userRoles = await this._store.get<UserRoles>(StorageKeys.ROLES);

    this._wellExplorer = await lastValueFrom(this._wellExplorerService.getWellExplorer());

    this.treeItems = this.buildTree(this._wellExplorer);

    this.allDesigns = this.findDesigns(this.treeItems);

    let designToLoad = routeDesignId || this.design?.id;

    if (!routeDesignId) {
      this._location.replaceState('/designs/' + this.design?.id);
    }

    this.selectedNode = this.searchTree(null, this.treeItems, designToLoad);
    this.expandParents(this.selectedNode);
    
    if (this.design?.id != routeDesignId) {
      this.nodeSelect({ node: this.selectedNode });
    }

    let signalRHub = this._signalRService.getConnectionToNotificationHub();
    signalRHub.on(SignalRService.ON_PFB_CHANGE, (data: any) => {
      if (data.action === PeriforOnChangeMessages.REFRESH_WELL_EXPLORER) {
        this.loading = true;
        let treeLevelId = this.treeLevelId ? this.treeLevelId : (data.designId ?? this.design.id);
        this.refreshWellExplorer(treeLevelId);
      }
    });

    setTimeout(() => {
      this.scrollToNode();
    }, 100);

    this.loading = false;
  }

  ngOnChanges(): void {
    this.scrollToNode();
  }

  public refreshWellExplorer(treeNodeId: string): void {
    this._wellExplorerService.getWellExplorer().subscribe((wellExplorer: WellExplorerItem) => {

      this.treeItems = this.buildTree(wellExplorer);
      if (treeNodeId) {
        this.selectedNode = this.searchTree(null, this.treeItems, treeNodeId);
        this.expandParents(this.selectedNode);
      }
      this.loading = false;
    });
  }

  public handleCloseSidenav(): void {
    this.onClose.emit("close");
  }

  public async handleAddCompany(e: { levelData: { name: string, wellType?: any }, level: string, treeNode: TreeNode }): Promise<void> {
    await lastValueFrom(this._wellExplorerService.addCompany({
      parentId: e.treeNode?.data?.id,
      name: e.levelData.name,
      level: e.level
    }));
  }

  public async handleAddChildNode(e: { levelData: { name: string, wellType?: any, designTemplate?: string }, level: string, treeNode: TreeNode }): Promise<void> {
    if (e.level == this.designLevel) {
      let createdDesignId = await lastValueFrom(this._wellExplorerService.addDesign({
        parentId: e.treeNode?.data?.id,
        name: e.levelData.name,
        level: e.level,
        wellType: e.levelData.wellType,
        dashboardDesignId: e.levelData.designTemplate ? e.levelData.designTemplate : null
      }));
      this.treeLevelId = createdDesignId.toString();
      this.allDesigns.unshift(new Design({ name: e.levelData.name, id: createdDesignId.toString() }));
      await this.updateCurrentDesign(createdDesignId.toString(), e.levelData.name);
    } else {
      this.treeLevelId = await lastValueFrom(this._wellExplorerService.createTreeLevel({
        parentId: e.treeNode?.data?.id,
        name: e.levelData.name,
        level: e.level
      }));
    }
  }

  public async handleDeleteNode(treeNode: TreeNode): Promise<void> {
    this.loading = true;
    let level = treeNode.data.level;
    let levelId = treeNode?.data.id;

    let deleted = false;
    if (level == this.companyLevel) {
      deleted = await lastValueFrom(this._wellExplorerService.deleteCompanyLevel(levelId));
    } else {
      deleted = await lastValueFrom(this._wellExplorerService.deleteTreeLevel(levelId));
    }

    if (deleted) {
      if (level !== this.companyLevel) {
        treeNode.parent.children = treeNode?.parent?.children?.filter(tnc => tnc.data.id != levelId);
        this.treeItems = [...this.treeItems];
      } else {
        let treeItems = this.treeItems.filter(tn => tn.data.id != levelId);
        this.treeItems = [...treeItems];
      }

      if (level === this.designLevel && this._route.snapshot.params['designId'] === levelId) {
        this._store.remove(StorageKeys.DESIGN);
        this._location.replaceState('/designs');
      }
      this.allDesigns = this.findDesigns(this.treeItems);
      this.loading = false;
    }
  }

  public async handleRenameItem(e: { name: string, treeNode: TreeNode }): Promise<void> {
    if (e.name) {
      let renamed = await lastValueFrom(this._wellExplorerService
        .renameTreeLevel({
          id: e.treeNode.data.id,
          level: e.treeNode?.data?.level,
          name: e.name
        }));
      if (renamed) {
        e.treeNode.data.label = e.name;
        e.treeNode.parent.children.sort(this.alphaNumericSort);
        this.treeItems = [...this.treeItems];
      }
      if (e.treeNode.data.level == this.designLevel) {
        this._store.updateStore(StorageKeys.DESIGN, {id:e.treeNode.data.id, name: e.name});
      }
      this.selectedNode = this.searchTree(null, this.treeItems, e.treeNode.data.id);
    }
  }

  public async handleCloneDesign(treeNode: TreeNode): Promise<void> {
    this.loading = true;
    let level = treeNode.data.level;
    if (level !== this.designLevel) {
      return;
    }

    const designId = treeNode.data.id;
    const wellboreId = treeNode.parent.data.id;
    const designName = treeNode.data.label + ' - Copy';

    let createdLevelId = await lastValueFrom(this._wellExplorerService.cloneDesign(designId, wellboreId, designName));

    await this.updateTreeWithNewDesign(designName, level, createdLevelId, treeNode.parent);
    this.allDesigns.unshift(new Design({ name: designName, id: createdLevelId }));
    this.loading = false;
  }

  public async nodeSelect(event: any): Promise<void> {
    if (event?.node?.data?.level === this.designLevel) {
      await this.updateCurrentDesign(event.node?.data.id, event.node?.data.label);
    } else if (event?.data?.level === this.designLevel) {
      await this.updateCurrentDesign(event.data.id, event.data.name);

      this.treeItems = this.buildTree(this._wellExplorer);
      this.selectedNode = this.searchTree(null, this.treeItems, event.data.id);
      this.expandParents(this.selectedNode);
    }
  }

  public handleContextMenuUpdated(contextMenuItems: Array<MenuItem>) {
    this.contextMenuItems = contextMenuItems;
  }

  public async handleExportDesign(treeNode: TreeNode) {
    if (treeNode.data.level !== this.designLevel) {
      return;
    }
    const designId = treeNode.data.id;
    const wellboreId = treeNode.parent.data.id;
    const designName = treeNode.data.label + ' - Export';

    let exportedDesign = await lastValueFrom(this._wellExplorerService.exportDesign(designId, wellboreId, designName));

    const bytes = new TextEncoder().encode(JSON.stringify(exportedDesign));
    let blob = new Blob([bytes], { type: "application/json;charset=utf-8" });
    saveAs(blob, `${exportedDesign.design.name} - Export.json`);
  }

  public async handleShowMoveDesignDialog(moveDesignForm: any): Promise<void> {
    this.loading = true;
  
    if(moveDesignForm != undefined){
      let moved = await lastValueFrom(this._wellExplorerService.moveDesign(moveDesignForm));
      if(moved){
        this.refreshWellExplorer(moveDesignForm.designId);
      }
    }

    this.loading = false;
  }

  public handleShowImportDesignDialog(treeNode: TreeNode): void {
    this.loading = true;
    let wellboreId: string = treeNode.data.id;

    let level = treeNode.data.level;
    if (level !== "wellbores") {
      return;
    }

    let input = document.createElement("input");
    input.setAttribute("accept", ".json");
    input.setAttribute("type", "file");
    input.onchange = (e => this.onFileSelected(e, wellboreId, treeNode));
    input.click();
    this.loading = false;
  }

  private async updateTreeWithNewDesign(designName: string, level: any, createdLevelId: string, treeNode: TreeNode<any>) {
    let newTreeNode = {
      data: {
        label: designName,
        icon: this.setIcon(getChildLevel(level)),
        id: createdLevelId,
        level: this.designLevel
      }
    };

    let treeChildren: Array<TreeNode> = [...treeNode.children, newTreeNode];
    treeNode.children = treeChildren.sort(this.alphaNumericSort);
    this.treeItems = [...this.treeItems];

    await this.updateCurrentDesign(createdLevelId, designName);

    this.selectedNode = newTreeNode;
  }

  private buildTree(wellExplorerItem: WellExplorerItem): Array<TreeNode> {
    return wellExplorerItem?.children?.map((a: WellExplorerItem) => {
      return {
        data: {
          label: a.name,
          icon: this.setIcon(wellExplorerItem?.childLevel?.toLowerCase()),
          id: a.id,
          level: a.level,
          childLevel: a.childLevel
        },
        children: this.buildTree(a),
        expanded: false
      };
    });
  }

  public scrollToNode(): void {
    if (!this.treeTable) {
      return;
    }
    const rows = this.treeTable.containerViewChild?.nativeElement?.querySelectorAll('tr');
    const selectedNodeName = this.selectedNode.data.label;
    let targetRow = null;
    rows.forEach((row: any, i: number) => {
        if (row.innerText.includes(selectedNodeName)) {
            targetRow = row;
        }
    });

    setTimeout(() => {
        this.treeTable.scrollTo({
            top: targetRow.offsetTop - 200,
            behavior: 'smooth'
        });
    }, 100);
  }

  private expandParents(node: TreeNode): void {
    if (node && node.parent) {
      node.parent.expanded = true;
      this.expandParents(node.parent);
    }
    this.treeItems = [...this.treeItems];
  }

  private findDesigns(tree: Array<TreeNode>) {
    let designs: Array<TreeNode> = [];
    tree.forEach((company: TreeNode) => {
      company.children.forEach(project => {
        project.children.forEach((site: TreeNode) => {
          site.children.forEach((well: TreeNode) => {
            well.children.forEach((wellbore: TreeNode) => {
              wellbore.children.forEach((design: TreeNode) => {
                designs.push(design);
              });
            });
          });
        });
      });
    });
    return designs.sort(this.alphaNumericSort).map((design: TreeNode) => {
      return new Design({ name: design.data.label, id: design.data.id });
    });
  }

  private searchTree(parent: TreeNode, tree: Array<TreeNode>, id: string): TreeNode {
    let foundNode: TreeNode<any>;
    for (const node of tree) {
      node.parent = parent;
      if (node.data.id === id) {
        foundNode = node;
      } else {
        if (node.children) {
          foundNode = this.searchTree(node, node.children, id);
        }
      }
      if (foundNode) {
        return foundNode;
      }
    }
  }

  private setIcon(menuLevel: string): string {
    switch (menuLevel) {
      case this.companyLevel:
        return '../../../../assets/icons/domain-24px.svg';
      case 'projects':
        return '../../../../assets/icons/assignment-24px.svg';
      case 'sites':
        return '../../../../assets/icons/place-24px.svg';
      case 'wells':
        return '../../../../assets/icons/oil-platform.svg';
      case 'wellbores':
        return '../../../../assets/icons/noun_drill bit_331639.svg';
      default:
        return '';
    }
  }

  private onFileSelected(event: any, wellboreId: string, treeNode: TreeNode) {
    let files = event.target.files;
    let jsonFile = files.item(0);

    var reader = new FileReader();
    reader.onload = async (e) => await this.onReaderLoad(e, wellboreId, treeNode);
    reader.readAsText(jsonFile);
  }

  private async onReaderLoad(event: any, wellboreId: string, treeNode: TreeNode): Promise<void> {
    let designJsonObj = JSON.parse(event.target.result);
    let importedDesignId = await lastValueFrom(this._wellExplorerService.importDesign(wellboreId, designJsonObj));
    if (importedDesignId) {
      const designName = designJsonObj.design.name + ' - Import';
      this.updateTreeWithNewDesign(designName, "design", importedDesignId, treeNode);
    }
  }

  private alphaNumericSort(a: TreeNode, b: TreeNode): number {
    return a.data.label.replace(" ", "").localeCompare(b.data.label.replace(" ", ""), undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  }

  private async updateCurrentDesign(designId: string, name: string): Promise<boolean> {
    let saved = await lastValueFrom(this._usersService.updateCurrentAppIds({ designId: designId }));
    if (saved) {
      this.design = new Design({ name: name, id: designId });

      this._store.updateStore(StorageKeys.DESIGN, this.design);

      this._location.replaceState('/designs/' + this.design.id);
    }
    return saved;
  }

}
