import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GradeReference, PipeReference, WellComponent } from 'src/app/wellbore-inputs/components/well-configuration/models';
import { CustomConnectionModel } from '../models/custom-connection.model';
import { TubularString } from 'src/app/wellbore-inputs/models/well-schematic.model';
import { Fluid } from 'src/app/perical/models/fluid.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class WellConfigService {

  constructor(private http: HttpClient) { }

  public getTubulars(): Observable<Array<WellComponent>> {
    return this.http.get<Array<WellComponent>>(`${PeriforApiEndpoint}/WellConfiguration/tubulars`).pipe(catchError(e => of(e)));
  }

  public getPipeCatalog() : Observable<Array<PipeReference>>  {
    return this.http.get<Array<PipeReference>>(`${PeriforApiEndpoint}/pipesCatalog`);
  }

  public getPipeCatalogByOd(selectedOd: number): Observable<Array<PipeReference>> {
    return this.http.get<Array<PipeReference>>(`${PeriforApiEndpoint}/pipesCatalog?od=${selectedOd}`).pipe(catchError(e => of(e)));
  }

  public getPipeOds(): Observable<Array<number>> {
    return this.http.get<Array<number>>(`${PeriforApiEndpoint}/pipesCatalog/ods`).pipe(catchError(e => of(e)));
  }

  public getGradeCatalog(): Observable<Array<GradeReference>> {
    return this.http.get<Array<GradeReference>>(`${PeriforApiEndpoint}/gradesCatalog`).pipe(catchError(e => of(e)));;
  }

  public createNewTubular(tubular: WellComponent): Observable<string> { //Returns guid
    return this.http.post<string>(`${PeriforApiEndpoint}/WellConfiguration/tubulars`, tubular);
  }

  public editTubular(tubular: WellComponent): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/WellConfiguration/tubulars/${tubular.id}`, tubular)
  }

  public deleteTubular(tubularId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/WellConfiguration/tubulars/${tubularId}`)
  }

  public getStringsList() {
    return this.http.get(`${PeriforApiEndpoint}/WellConfiguration/tubulars/lookup`).pipe(catchError(e => of(e)));
  }

  public getConnectionsList() : Observable<Array<CustomConnectionModel>> {
    return this.http.get<Array<CustomConnectionModel>>(`${PeriforApiEndpoint}/customConnections`).pipe(catchError(e => of(e)));
  }

  public getTubular(): Observable<TubularString> {
    return this.http.get<TubularString>(`${PeriforApiEndpoint}/WellConfiguration/tubular`).pipe(catchError(e => of(e)));
  }

  public getSelectedTubularId(): Observable<any> {
    return this.http.get<any>(`${PeriforApiEndpoint}/WellConfiguration/tubular/id`).pipe(catchError(e => of(e)));
  }

  public getFluidsList() : Observable<Array<Fluid>> {
    return this.http.get<Array<Fluid>>(`${PeriforApiEndpoint}/fluids`).pipe(catchError(e => of(e)));
  }
}
