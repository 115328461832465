import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { StressResultsService } from '../../services/stress-results.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { DesignFactorsService } from '../../services/design-factors.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { DesignLimitsPlotUi } from '../../models/perivis-results-state.model';
import { Store } from '@ngneat/elf';

@Component({
  selector: 'app-design-limits-plot',
  templateUrl: './design-limits-plot.component.html',
  styles: [`
  p-dialog {
    user-select: none;
  }
 `]
})
export class DesignLimitsPlotComponent implements OnInit, OnDestroy {
  private _plotData: any;
  private connectionBurstLimit: number[] = [];
  private connectionTensionLimit: number[] = [];
  private connectionCompressionLimit: number[] = [];
  private connectionCollapseLimit: number[] = [];
  private _userUnits: UserUnitsModel;

  public isLoading: boolean;
  public configMissing: boolean;
  public stringSections: any[] = [];
  public needToCalculate: boolean;

  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName = 'dlpPlot';
  public downloadPlotName = 'design_limits_plot';
  public plot = {
    data: [],
    layout: {},
    config: {}
  };

    //State Management
    private _componentId: string;
    @Input() set componentId(value: string) {
      this._componentId = value;
      this.designLimitsPlotStore = this._storeService.createStore(this.componentId, new DesignLimitsPlotUi);
    }
    get componentId(): string {
      return this._componentId;
    }
    public designLimitsPlotStore: Store;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService,
    private _designFactorsService: DesignFactorsService,
    private _storeService: StoreService
  ) {}

 async ngOnInit() : Promise<void> {
    this._userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.getDesignLimits();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS || data.action == PeriforOnChangeMessages.REFRESH_DESIGN_LIMITS_PLOT) {
      this.getDesignLimits();
    }
  }

  getDesignLimits() {
    this.isLoading = true;
    this._wellConfigService.getTubular().subscribe({
      next: (currentString) => {
        if (currentString != null) {
          const sources: Observable<any>[] = [
            this._stressResultsService.getDesignLimitsPlots(),
            this._designFactorsService.getDesignFactors() as Observable<any>
          ];

          forkJoin(sources).pipe(
            map(([dlp, designFactors]) => {
              this.connectionTensionLimit = [];
              this.connectionCompressionLimit = [];
              this.connectionBurstLimit = [];
              this.connectionCollapseLimit = [];

              this.stringSections = dlp?.map(ss => {
                const index = Number(ss.section.substr(ss.section.length - 2)) - 1;
                const sectionTop = currentString.stringSections[index - 1]?.bottomMeasuredDepth ?? currentString.hangerMd.toLocaleString('en-US');
                const stringSection: any = currentString.stringSections[index];
                const sectionBottom = stringSection.bottomMeasuredDepth.toLocaleString('en-US');
                let diameterLabel = this._userUnits.shortLengths == 'in' ? '\"' : this._userUnits.shortLengths;
                let weigthUnit = this._userUnits.linearDensity == 'lb/ft' ? '#' : this._userUnits.linearDensity;
                let sectionLabel = 'Section ' + (index + 1) + ': ' + stringSection.pipe.outsideDiameter + `${diameterLabel}, ` + stringSection.pipe.weightPerFoot + `${weigthUnit}, ` + stringSection.grade.name + '  ' + '[' + sectionTop + ' - ' + sectionBottom + ` ${this._userUnits.longLengths}MD]`;

                if (stringSection?.connection) {
                  let tensionRating = stringSection?.connection.tensionRating;
                  let compressionRating = stringSection?.connection.compressionRating;
                  let burstRating = stringSection?.connection.burstRating;
                  let collapseRating = stringSection?.connection.collapseRating;

                  this.connectionTensionLimit.push(+(tensionRating / (designFactors.connection?.tension ?? 1.3)).toFixed(2));
                  this.connectionCompressionLimit.push(+(-compressionRating / (designFactors.connection?.compression ?? 1.15)).toFixed(2));
                  this.connectionBurstLimit.push(+(burstRating / (designFactors.connection?.burstLeak ?? 1.1)).toFixed(2));
                  this.connectionCollapseLimit.push(+(-collapseRating / (designFactors.connection?.collapse ?? 1)).toFixed(2));
                } else {
                  this.connectionTensionLimit.push(null);
                  this.connectionCompressionLimit.push(null);
                  this.connectionBurstLimit.push(null);
                  this.connectionCollapseLimit.push(null);
                }

                return { label: sectionLabel, value: ss.designLimitsPlot }
              }) ?? [];

              this.xAxisTitle = 'Axial Force (' + this._userUnits.force + ')';
              this.yAxisTitle = 'Effective Pressure (' + this._userUnits.stressPressure + ')';

              if (this.stringSections.length === 0) {
                this.needToCalculate = true;
                this.isLoading = false;
                this.plotDesignLimits([]);
              } else {
                this.needToCalculate = false;
                let stateStringSectionExists = this.stringSections.find(stringSection => stringSection.label == this.designLimitsPlotStore.state.selectedStringSection);
                stateStringSectionExists ? this._plotData = stateStringSectionExists : this._plotData = this.stringSections[0];
                // this._plotData = this.stringSections[0];
                this.plotDesignLimits(this._plotData.value);
              }
            }),
            catchError(err => {
              this.isLoading = false;
              this.configMissing = true;
              return err;
            })).subscribe();
        } else {
          this.configMissing = true;
          this.isLoading = false;
        }
      }
    });
  }

  selectedStirngSectionUpdated(e, originalEvent) {
    this.designLimitsPlotStore.update(state => ({...state, selectedStringSection: e.value}));
    this.plotDesignLimits(this.stringSections.find(stringSection => stringSection.label == this.designLimitsPlotStore.state.selectedStringSection).value, originalEvent);
  }

  plotDesignLimits(plotData, originalEvent?) {
    this._plotData = [];
    this.plot.data = [];
    if (plotData.length === 0) {
      return;
    }

    plotData = plotData.filter((thing, index, self) =>
      index != self.findIndex((t) => (
        t.label != thing.label
      ))
    );

    plotData.forEach(element => {
      element.x.forEach((x, i) => {
        element.x[i] = (Math.trunc(x * 100) / 100);
      });

      element.y.forEach((y, i) => {
        element.y[i] = (Math.trunc(y * 100) / 100);
      });
    });

    if (this.connectionBurstLimit.length > 0) {
      let index = originalEvent ? this.stringSections.findIndex(x => x.label == originalEvent?.currentTarget.ariaLabel) : 0;

      const burstLine = {
        name: "Connection Burst",
        x: [this.connectionCompressionLimit[index], this.connectionTensionLimit[index]],
        y: [this.connectionBurstLimit[index], this.connectionBurstLimit[index]],
        line: {
          color: '#3366CC',
          dash: 'dot'
        },
        width: 11
      }

      const tensionLine = {
        name: "Connection Tension",
        x: [this.connectionTensionLimit[index], this.connectionTensionLimit[index]],
        y: [this.connectionBurstLimit[index], this.connectionCollapseLimit[index]],
        line: {
          color: '#FF9900',
          dash: 'dot'
        },
        width: 11
      }

      const collapseLine = {
        name: "Connection Collapse",
        x: [this.connectionCompressionLimit[index], this.connectionTensionLimit[index]],
        y: [this.connectionCollapseLimit[index], this.connectionCollapseLimit[index]],
        line: {
          color: '#AB63FA',
          dash: 'dot'
        },
        width: 11
      }

      const compressionLine = {
        name: "Connection Compression",
        x: [this.connectionCompressionLimit[index], this.connectionCompressionLimit[index]],
        y: [this.connectionBurstLimit[index], this.connectionCollapseLimit[index]],
        line: {
          color: 'red',
          dash: 'dot'
        },
        width: 11
      }

      if (burstLine.x[0] != null) {
        plotData.splice(6, 0, burstLine);
        plotData.splice(7, 0, tensionLine);
        plotData.splice(8, 0, collapseLine);
        plotData.splice(9, 0, compressionLine);
      }
    }

    plotData = plotData.map(x => {
      return { ...x, ...{mode: 'lines', hoverlabel: { namelength: -1 }} };
    });

    let initialConditionIndex = plotData.findIndex(x => x.name == 'Initial Condition');

    for (let i = 0; i < plotData.length; i++) {
      if (i < initialConditionIndex) {
        plotData[i].legendgroup = "ellipse";
      } else {
        plotData[i].legendgroup = "loads";
      }
    }

    this.plot.data = plotData;
    // setTimeout(() => { this.updateImageButtonOptions(); });
    this.isLoading = false;
  }

  get isDialogVisible(): boolean {
    return this.needToCalculate || this.configMissing;
  }

  ngOnDestroy() {
    this.signalRfunc = null
  }
}
