import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Fluid, FluidType } from '../../models/fluid.model';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { FluidsService } from '../../services/fluids.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { ReservoirPerformanceResult } from '../../models/reservoirPerformance';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { ReservoirService } from '../../services/reservoir.service';
import { AppNotificationService as AppMessageNoticeService } from 'src/app/shared/services/app-notification.service';
import { UdtService } from 'src/app/shared/services/udt.service';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { PoreAndFracService } from 'src/app/perivis/services/pore-and-frac.service';
import { LinearInterpSingle, LinearInterpSingleVal } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { GetShortLengthValueFromInches, GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';

@Component({
  selector: 'app-reservoir-performance-utility',
  templateUrl: './reservoir-performance-utility.component.html',
  styleUrls: ['./reservoir-performance-utility.component.scss']
})
export class ReservoirPerformanceUtilityComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public calculating: boolean;
  public operationTypes: Array<{ name: string, value: string }>;
  public gasFlowRateValidation: { min: number, max: number };
  public fluids: Fluid[];
  public reservoirPerformanceForm: UntypedFormGroup;
  public flowRateUnit: string;
  public liquidFlowRateUnit: string;
  public gasFlowRateUnit: string;
  public tempUnit: string;
  public massFlowUnit: string;
  public temperatureValidation: { min: number, max: number };
  public pressureValidation: { min: number, max: number };
  public reservoirPerformanceResult: ReservoirPerformanceResult;
  public reservoirTemperatureLabel: string;
  public tempAtTd: number;
  public surfaceTemperature: number;
  public selectedOperationType: string;
  public userUnits: UserUnitsModel;

  @Input()
  private componentId: string;

  constructor(
    private _fluidsService: FluidsService,
    private _formBuilder: UntypedFormBuilder,
    private _storeService: StoreService,
    private _signalRService: SignalRService,
    private _reservoirService: ReservoirService,
    private _appMessageNoticeService: AppMessageNoticeService,
    private _udtService: UdtService,
    private _trajectoryService: TrajectoryService,
    private _wellConfigService: WellConfigService,
    private _poreAndFracService: PoreAndFracService
  ) {
    this.operationTypes = [
      { name: 'Production', value: 'production' },
      { name: 'Injection', value: 'injection' }
    ];

    this._appMessageNoticeService.getMessages().subscribe(res => {
      res.forEach(r => {
        if (r.summary === 'ReservoirPerformance') {
          this.calculating = false;
        }
      });
    });
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.gasFlowRateUnit = Units.lib[this.userUnits.gasInjectionRate].symbol;
    this.liquidFlowRateUnit = Units.lib[this.userUnits.standardFluidFlow].symbol;
    this.tempUnit = this.userUnits.temperature;
    this.massFlowUnit = Units.lib[this.userUnits.massFlow].symbol;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    switch (this.userUnits.pressure) {
      case 'psi':
        this.pressureValidation = { min: 100, max: 50000 };
        break;
      case 'bar':
      case 'atm':
        this.pressureValidation = { min: 6.8, max: 3447 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 689, max: 344737 };
        break;
    }

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 1000 };
        break;
      case '°C':
        this.temperatureValidation = { min: -20, max: 540 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255, max: 810 };
        break;
    }

    this.selectedOperationType = 'production';

    this.createReactiveForm();

    await this.getData();

    this.reservoirPerformanceForm.controls.reservoirPressure.setValidators([Validators.required, Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max)]);
    this.reservoirPerformanceForm.controls.bottomholeTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    this.reservoirPerformanceForm.controls.reservoirTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);

    if (this.gasFlowRateUnit == 'MMscf/day') {
      this.gasFlowRateValidation = { min: 0.1, max: 1000 };
    } else {
      this.gasFlowRateValidation = { min: 0.0028, max: 28.3 };
    }
    this.reservoirPerformanceForm.controls.gasFlowRate.setValidators([Validators.required, Validators.min(this.gasFlowRateValidation.min), Validators.max(this.gasFlowRateValidation.max)]);

  }

  private async signalRfunc(data: any): Promise<void> {
    if (data.action === PeriforOnChangeMessages.REFRESH_FLUIDS) {
      this.fluids = [];
      await this.getData();
    }
  }

  private createReactiveForm(): void {
    this.reservoirPerformanceForm = this._formBuilder.group({
      operationType: new UntypedFormControl(null, [Validators.required]),
      fluidId: new UntypedFormControl(null),
      gasFlowRate: new UntypedFormControl(null, [Validators.required]),
      bottomholeTemperature: new UntypedFormControl(null, [Validators.required]),
      reservoirTemperature: new UntypedFormControl(null, [Validators.required]),
      bottomholeDiameter: new UntypedFormControl(null, [Validators.required]),
      reservoirPressure: new UntypedFormControl(null, [Validators.required]),
      permeability: new UntypedFormControl(100, [Validators.required]),
      height: new UntypedFormControl(null, [Validators.required]),
      radialExtent: new UntypedFormControl(null, [Validators.required]),
      skinFactor: new UntypedFormControl(0, [Validators.required]),
      isCo2: new UntypedFormControl(false)
    });
  }

  private async getData(): Promise<void> {
    const sources = [
      this._fluidsService.getFluids() as Observable<any>,
      this._udtService.getUndisturbedTemperaturePoints() as Observable<any>,
      this._wellConfigService.getTubulars() as Observable<any>,
      this._poreAndFracService.getPorePressures()
    ];

    const [fluids, udt, wellConfig, porePressure] = await lastValueFrom(forkJoin(sources));

    this.fluids = fluids.filter(f => f.state.type === FluidType.CO2FLUID && f.state['co2withImpurities']);
    if (this.fluids.length > 0) {
      this.selectedFluidChange({ value: this.fluids[0] });
    }

    let bottomHoleDiameterDefault = wellConfig.reduce((acc, curr) => {
      if (curr.holeSize < acc && curr.holeSize > 0) {
        return curr.holeSize;
      }
      return acc;
    }, GetShortLengthValueFromInches(12.25, this.userUnits.shortLengths));

    let depthDefault = wellConfig.find(x => x.holeSize === bottomHoleDiameterDefault)?.shoeMd ?? wellConfig[wellConfig.length - 1].shoeMd;

    this.surfaceTemperature = udt[0].temperature;
    let tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([depthDefault], true));
    this.tempAtTd = +LinearInterpSingleVal(tvds[0], udt.map(x => x.trueVerticalDepth), udt.map(x => x.temperature)).toFixed(2);
    this.reservoirPerformanceForm.controls.bottomholeDiameter.setValue(bottomHoleDiameterDefault);

    let defaultPorePressure = porePressure.length > 1 ? +LinearInterpSingle(tvds[0], porePressure, this.userUnits, true).toFixed(2) : +GetMudHydrostaticPressure(tvds[0], GetValueFromPpg(8.6, Units.lib[this.userUnits.density].symbol), this.userUnits).toFixed(2);
    this.reservoirPerformanceForm.controls.reservoirPressure.setValue(defaultPorePressure);

    let heightDefault = this.userUnits.longLengths == 'ft' ? 500 : 152.4;
    let radialExtentDefault = this.userUnits.longLengths == 'ft' ? 5000 : 1524;
    this.reservoirPerformanceForm.controls.height.setValue(heightDefault);
    this.reservoirPerformanceForm.controls.radialExtent.setValue(radialExtentDefault);

    this.isLoading = false;

  }

  public selectedFluidChange(event: any): void {
    this.reservoirPerformanceForm.controls.fluidId.setValue(event.value.id);
    this.reservoirPerformanceForm.controls.isCo2.setValue(event.value.state.type === FluidType.CO2FLUID);
    this.flowRateUnit = this.gasFlowRateUnit;
  }

  public selectedOperationChange(event: any): void {
    this.reservoirTemperatureLabel = event.value === 'production' ? 'Reservoir Temperature' : 'Injection Temperature';
    this.selectedOperationType = event.value;
    this.reservoirPerformanceForm.controls.operationType.setValue(event.value);

    if (event.value === 'production') {
      this.reservoirPerformanceForm.controls.reservoirTemperature.setValue(this.tempAtTd);
      this.reservoirPerformanceForm.controls.bottomholeTemperature.setValue(0);
    } else {
      this.reservoirPerformanceForm.controls.bottomholeTemperature.setValue(this.surfaceTemperature);
      this.reservoirPerformanceForm.controls.reservoirTemperature.setValue(0);
    }
  }

  public async calculateReservoirPerformance() {
    let phaseEnvelope = await lastValueFrom(this._fluidsService.getPhaseEnvelope(this.reservoirPerformanceForm.value.fluidId));
    if (phaseEnvelope['x'].length <= 0) {
      this.calculating = false;
      return;
    } else {
      this.calculating = true;
      let reservoirPerformanceFormCalc = this.reservoirPerformanceForm.value;
      if (this.reservoirPerformanceForm.value.operationType === 'injection' && reservoirPerformanceFormCalc.gasFlowRate > 0) {
        reservoirPerformanceFormCalc.gasFlowRate = reservoirPerformanceFormCalc.gasFlowRate * -1;
      } else if (this.reservoirPerformanceForm.value.operationType === 'production' && reservoirPerformanceFormCalc.gasFlowRate < 0) {
        reservoirPerformanceFormCalc.gasFlowRate = reservoirPerformanceFormCalc.gasFlowRate * -1;
      }
      this.reservoirPerformanceResult = await lastValueFrom(this._reservoirService.calculateReservoirPerformance(reservoirPerformanceFormCalc));
      if (this.reservoirPerformanceResult) {
        this.calculating = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.signalRfunc = null;
  }
}
