import { Component, EventEmitter, Input,  Output } from '@angular/core';
import { BaseOperation, OperationForm } from 'src/app/perical/models/thermal-operation.model';
import { createForm, FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES } from 'ngx-sub-form';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ThermalOperationsService } from 'src/app/perical/services/thermal-operations.service';
import { User } from 'src/app/core/components/user-admin-page/user-model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

@Component({
  selector: 'app-operation-data',
  templateUrl: './operation-data.component.html',
  styleUrls: ['./operation-data.component.scss']
})
export class OperationDataComponent {

  constructor(private _thermalOperationsService: ThermalOperationsService) {
    this.timeUnits = [
      { name: 'Minutes', value: 'DurationUnit.Minute' },
      { name: 'Hours', value: 'DurationUnit.Hour' },
      { name: 'Days', value: 'DurationUnit.Day' },
      { name: 'Months', value: 'DurationUnit.Month30' },
      { name: 'Years', value: 'DurationUnit.Year365' }
    ];
  }

  private input$: Subject<BaseOperation | null | undefined> = new Subject();
  @Input() set operation(operation: BaseOperation | undefined) {
    this.input$.next(operation);
  }

  private disabled$: Subject<boolean> = new Subject();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Input()
  public user: User;

  @Input()
  public units: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;

  @Output() operationUpdate: EventEmitter<BaseOperation> = new EventEmitter();

  // tslint:disable-next-line: no-input-rename
  @Input('previousOperations')
  previousOperations: BaseOperation[] | undefined;

  public timeUnits: any[];
  public circulationSelected: boolean = false;
  public hideTimeInput: boolean = true;
  public timeInputLabel: string;
  public insideTabHeader: string = 'Inside';
  public typeInput: any = {};
  public mudPitsEnabled: boolean;
  private drillingDepthRange: any;
  public defaultRop: number;
  public showRop: boolean = false;
  public hideShutIn: boolean = false;
  public isValid: boolean;
  public errorMsg: string = "";

  public form = createForm<BaseOperation, OperationForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.operationUpdate,
    formControls: {
      id: new UntypedFormControl(null),
      name: new UntypedFormControl('New Operation', [Validators.required]),
      time: new UntypedFormControl(null, [Validators.required]),
      timeUnits: new UntypedFormControl(null),
      inside: new UntypedFormControl(null, [Validators.required]),
      annulus: new UntypedFormControl(null, [Validators.required]),
      mudPits: new UntypedFormControl(null, [Validators.required]),
      previousOperationId: new UntypedFormControl(null)
    },
    toFormGroup: (obj: BaseOperation | null): OperationForm | null => {
      const { time, inside, annulus, mudPits, previousOperationId, ...commonValues } = obj;

      if (!obj) {
        return null;
      }

      this.drillingDepthRange = undefined;

      if (!obj.id || obj?.id.length < 24) {
        this.circulationSelected = false;
        this.insideTabHeader = 'Inside';
        this.form.formGroup.controls.annulus.setValidators(Validators.required);
        this.form.formGroup.controls.annulus.updateValueAndValidity();
      }

      if (obj.inside?.type === 'cementing' || obj.inside?.type === 'runCasingAndCirculate' || obj.inside?.type === 'tripPipeAndCirculate') {
        this.hideTimeInput = false;
      } else {
        this.hideTimeInput = true;
      }

      if (obj.inside?.type === 'drilling') {
        this.timeInputLabel = 'Drilling Time';
        this.showRop = true;
      } else {
        this.timeInputLabel = 'Time';
        this.showRop = false;
      }

      if (obj.inside?.type === 'circulation' || obj.inside?.type === 'drilling' || obj.inside?.type === 'cementing' ||
       obj.inside?.type === 'runCasingAndCirculate' || obj.inside?.type === 'tripPipeAndCirculate') {
        this.circulationSelected = true;
        this.insideTabHeader = 'Inside + Annulus';
        this.form.formGroup.controls.annulus.setValidators(null);
      } else if (obj.inside?.type === 'production' || obj.inside?.type === 'injection' || obj.inside?.type === 'shutIn' || obj.inside?.type === 'gasLift') {
        this.circulationSelected = false;
        this.insideTabHeader = 'Inside';
        this.form.formGroup.controls.annulus.setValidators(Validators.required);
      }

      this.checkMudPitValidity();

      this.form.formGroup.controls.inside.updateValueAndValidity();
      this.form.formGroup.controls.annulus.updateValueAndValidity();

      return {
        time: time.value,
        timeUnits: time.unit,
        inside,
        annulus: annulus ? annulus : null,
        mudPits: mudPits ? mudPits : null,
        previousOperationId: previousOperationId ? previousOperationId : null,
        ...commonValues,
      };
    },
    fromFormGroup: (formValue: OperationForm): BaseOperation | null => {
      // if (!formValue.inside) {
      //   this.mudPitsEnabled = false;
      // }
      const { timeUnits, time, annulus, mudPits, previousOperationId, ...commonValues } = formValue;
      return {
        time: { value: +time, unit: timeUnits },
        annulus: annulus ? annulus : null,
        mudPits: mudPits ? mudPits : null,
        previousOperationId,
        ...commonValues
      };
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(500)
  });

  public handleMudPits(e) {
    this.mudPitsEnabled = e;
    this.checkMudPitValidity();
  }

  private checkMudPitValidity() {
    if (!this.mudPitsEnabled) {
      this.form.formGroup.controls.mudPits.setValidators(null);
    } else {
      this.form.formGroup.controls.mudPits.setValidators(Validators.required);
    }

    this.form.formGroup.controls.mudPits.updateValueAndValidity();
  }

  timeChange() {
    let timeInHours = this.getTimeInHours();

    this.defaultRop = +((this.drillingDepthRange.endDepth - this.drillingDepthRange.startDepth) / timeInHours).toFixed(2);
  }

  onPreviousOperationChanged(e) {
    if (e.value == "00000000-0000-0000-0000-000000000000") {
      this.hideShutIn = false;
      return;
    }
    this._thermalOperationsService.getThermalOperationById(e.value).subscribe((operation) => {
      if (operation.inside.type == 'drilling' || operation.inside.type == 'tripPipeAndCirculate' ||
       operation.inside.type == 'cementing' || operation.inside.type == 'runCasingAndCirculate') {
        this.hideShutIn = true;
      } else {
        this.hideShutIn = false;
      }
    });
  }

  drillingRangeChanged(e) {
    let timeChanged = e?.startDepth !== this.drillingDepthRange?.startDepth || e?.endDepth !== this.drillingDepthRange?.endDepth;
    if (!this.drillingDepthRange) {
      timeChanged = false;
    }

    this.drillingDepthRange = e;

    let timeInHours = this.getTimeInHours();

    this.defaultRop = +((e.endDepth - e.startDepth) / timeInHours).toFixed(2);

    let time = +((this.drillingDepthRange.endDepth - this.drillingDepthRange.startDepth) / this.defaultRop).toFixed(2);

    if (timeChanged) {
      this.form.formGroup.controls.timeUnits.setValue('DurationUnit.Hour', { emitEvent: false });
      this.form.formGroup.controls.time.setValue(time, { emitEvent: false });
      this.form.formGroup.controls.time.updateValueAndValidity({ emitEvent: false });
    }
  }

  private getTimeInHours(): number {
    let timeInHours: number;

    switch (this.form.formGroup.controls.timeUnits.value) {
      case 'DurationUnit.Minute':
        timeInHours = this.form.formGroup.controls.time.value / 60;
        break;
      case 'DurationUnit.Hour':
        timeInHours = this.form.formGroup.controls.time.value;
        break;
      case 'DurationUnit.Day':
        timeInHours = this.form.formGroup.controls.time.value * 24;
        break;
      case 'DurationUnit.Month30':
        timeInHours = this.form.formGroup.controls.time.value * 720;
        break;
      case 'DurationUnit.Year365':
        timeInHours = this.form.formGroup.controls.time.value * 12 * 720;
        break;
      default:
        timeInHours = 0;
        break;
    }

    return timeInHours;
  }

  ropChange(e) {
    const time = +((this.drillingDepthRange.endDepth - this.drillingDepthRange.startDepth) / e['srcElement']['valueAsNumber']).toFixed(2);
    this.form.formGroup.controls.timeUnits.setValue('DurationUnit.Hour', { emitEvent: false });
    this.form.formGroup.controls.time.setValue(time);
  }

  onChildTypeChanged(e) {
    if (e.type.value === 'cementing' || e.type.value === 'runCasingAndCirculate' || e.type.value === 'tripPipeAndCirculate') {
      this.hideTimeInput = false;
    } else {
      this.hideTimeInput = true;
    }

    if (e.type.value === 'drilling') {
      this.timeInputLabel = 'Drilling Time';
      this.showRop = true;
    } else {
      this.timeInputLabel = 'Time';
      this.showRop = false;
    }

    if (e.type.value === 'circulation' || e.type.value === 'drilling' || e.type.value === 'cementing'
     || e.type.value === 'runCasingAndCirculate'|| e.type.value === 'tripPipeAndCirculate') {
      this.circulationSelected = true;
      this.insideTabHeader = 'Inside + Annulus';
      this.form.formGroup.controls.annulus.clearValidators();
      this.form.formGroup.controls.annulus.updateValueAndValidity();
      // console.log(this.formGroupErrors);
    } else {
      this.mudPitsEnabled = false;
      this.circulationSelected = false;
      this.insideTabHeader = 'Inside';
      this.form.formGroup.controls.annulus.setValidators(Validators.required);
      // this.formGroupControls.annulus.controls.type.setValue(OperationType.SHUTIN);
      this.form.formGroup.controls.annulus.updateValueAndValidity();
    }

    if (e.type === 'production' || e.type === 'injection' || e.type === 'shutIn') {
      this.form.formGroup.controls.annulus.clearValidators();
      this.form.formGroup.controls.annulus.updateValueAndValidity();
    }

    if (e.source === 'inside' && e.type === 'shutIn') {
      this.form.formGroup.controls.inside.clearValidators();
      this.form.formGroup.controls.inside.updateValueAndValidity();
    }

    this.typeInput = e;
  }
}
