import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrajectoryService } from '../../../shared/services/trajectory.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { SelectItem } from 'primeng/api';
import { TrajectoryXyzModel } from 'src/app/shared/models/trajectory-xyz.model';
import { TrajectoryModel, TrajectoryPlotUi } from 'src/app/shared/models/trajectory.model';
import { Subscription, lastValueFrom } from 'rxjs';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-trajectory-plot',
  templateUrl: './trajectory-plot-container.component.html',
  styles: [`
    .plotContainer {
        height: 100%;
        user-select: none;
    }

    :host ::ng-deep p-selectButton div {
      font-size: smaller;
      padding-left: 8px;
      padding-right: 8px;
    }
  `]
})
export class TrajectoryPlotContainerComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;

  public plotly: any;

  public uu: UserUnitsModel;
  public isLoading: boolean;
  public plotView: SelectItem[] = [
    { label: '3D', value: '3d' },
    { label: 'Northing/TVD', value: 'northingTvd' },
    { label: 'Easting/TVD', value: 'eastingTvd' },
    { label: 'Dogleg/MD', value: 'doglegMd' },
    { label: 'Plan View', value: 'planView' }
  ];

  public trajectoryData: Array<TrajectoryModel>;
  public trajectoryXyzData: TrajectoryXyzModel;
  public longLengthUnits: string;
  public componentHeight: number;

  @ViewChild('toolbarDiv', { static: false }) toolbarDiv: ElementRef;

  @ViewChild('plotElement', { read: ElementRef, static: false })
  public plotElement: ElementRef;

  //Used for component state
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.trajectoryPlotStore = this._storeService.createStore(this.componentId, new TrajectoryPlotUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public trajectoryPlotStore: Store;

  constructor(
    private _trajectoryService: TrajectoryService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Trajectory Plot")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 75;
        this.componentHeight = e.itemHeight - divHeight;
      }
      this.getPlotData(false);
    }));
  }

  async ngOnInit(): Promise<void> {
    this.uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnits = this.uu?.longLengths;

    this.getPlotData(true);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_TRAJECTORY_PLOT) {
      this.getPlotData(true);
    }
  }

  public async getPlotData(load: boolean) {
    this.isLoading = load;

    if (this.trajectoryPlotStore.state.selectedPlotView === '3d') {
      this.trajectoryXyzData = await lastValueFrom(this._trajectoryService.getTrajectoryXyz());
    } else {
      this.trajectoryData = await lastValueFrom(this._trajectoryService.getTrajectoryPoints());
    }

    this.isLoading = false;
  }

  public selectPlotView(e): void {
    this.trajectoryPlotStore.update((state) => ({
      ...state,
      selectedPlotView: e.value
    }));
    this.getPlotData(true);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
