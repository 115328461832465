import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StressResultsService } from '../../services/stress-results.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { SelectItem } from 'primeng/api';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { DisplacementResultsUi } from '../../models/perivis-results-state.model';
import { Store } from '@ngneat/elf';
import { isEqual } from "lodash";

@Component({
  selector: 'app-displacement-results',
  templateUrl: './displacement-results.component.html',
  styles: [`
    p-dialog {
      user-select: none;
    }
    label {
      padding-left: 5px;
    }
  `]
})
export class DisplacementResultsComponent implements OnInit, OnDestroy {
  private _displacementResults: any[];
  private _subscriptions: Subscription;
  private _forceUnit: string;
  private _doglegUnit: string;
  private _pressureUnit: string;
  private _temperatureUnit: string;
  private _torqueUnit: string;
  
  public loadCases: SelectItem[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public modes: SelectItem[] = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' }
  ];
  public errorMsg: string;
  public results: any[];
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public isLoading: boolean;
  public yAxisTitle: string = '';
  public xAxisTitle: string = '';
  public unit: string = '(psi)';
  public tableName: string;
  public userUnits: UserUnitsModel;
  public plotName: string = 'displacementPlot';
  public signalRChange: boolean;
  public tableHeight: string;
  public componentHeight: number;
  public columnDefs: Array<{ header: string, field: string }>;
  public columnDefinitions: Array<any>;
  public longLengthUnit: string;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.displacementResultsStore = this._storeService.createStore(this.componentId, new DisplacementResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public displacementResultsStore: Store;

  @ViewChild('toolbarDiv', {static: false}) toolbarDiv: ElementRef;
  
  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Forces/Displacements")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this._forceUnit = Units.lib[this.userUnits.force].symbol;
    this._doglegUnit = Units.lib[this.userUnits.doglegSeverity].symbol;
    this.longLengthUnit = this.userUnits.longLengths;
    this._pressureUnit = this.userUnits.pressure;
    this._temperatureUnit = this.userUnits.temperature;
    this._torqueUnit = Units.lib[this.userUnits.torque].symbol;

    this.setColumnDefinitions();
    this.refreshResults(true, false);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.refreshResults(true, true);
    }
  }

  public async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode?: boolean): Promise<void> {
    this.isLoading = isLoading;
    this.results = [];

    if ((!this._displacementResults || calledFromSignalR) && !calledFromMode) {
      try {
        this._displacementResults = await lastValueFrom(this._stressResultsService.getDisplacementResults());
        this.signalRChange = true;
      } catch(ex) {
        this.errorMsg = "Well Configuration Missing: Please specify at least one string";
        this.isLoading = false;
        return;
      }
    }

    if (this._displacementResults && (this._displacementResults.length === 0 || this._displacementResults[0].loadCaseName === '')) {
      this.errorMsg = "Please calculate Perivis to see results";
      this.columnDefs = [];
      this.results = [];
      this.isLoading = false;
      return;
    } else {
      this.errorMsg = null;
    }

    if (this.displacementResultsStore.state.selectedMode == "single" && this._displacementResults) {
      this.handleSingleDisplacementLoad(this._displacementResults);
    } else {
      this.handleMultiDisplacementLoad(this._displacementResults)
    }

    this.isLoading = false;
  }

  private formatDecimal(value: any, divisor: number): string {
    return !isNaN(value) ? value : value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private formatDecimalNew(value: any, numDecimalPoints: number): string {
    return !isNaN(value) ? value : value || value > 0 ? (value.toFixed(numDecimalPoints)).toLocaleString('en-US') : value;
  }

  private setColumnDefinitions(): void {
    this.columnDefs = this.getResultTypes('header', 'field');
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Internal Pressure (${this._pressureUnit})`, field: 'internalPressure',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `External Pressure (${this._pressureUnit})`, field: 'externalPressure',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Temperature (${this._temperatureUnit})`, field: 'temperature',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Effective Force (${this._forceUnit})`, field: 'effectiveForce',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `True Axial Force (${this._forceUnit})`, field: 'trueAxialForce',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Total Displacement (${this.longLengthUnit})`, field: 'totalDisplacement',
        valueFormatter: (params: any) => this.formatDecimalNew(params, this.longLengthUnit == 'm' ? 2 : 9)
      },
      {
        header: `Hooke Displacement (${this.longLengthUnit})`, field: 'hookeDisplacement',
        valueFormatter: (params: any) => this.formatDecimalNew(params, this.longLengthUnit == 'm' ? 2 : 9)
      },
      {
        header: `Poisson Displacement (${this.longLengthUnit})`, field: 'poissonDisplacement',
        valueFormatter: (params: any) => this.formatDecimalNew(params, this.longLengthUnit == 'm' ? 2 : 9)
      },
      {
        header: `Thermal Displacement (${this.longLengthUnit})`, field: 'thermalDisplacement',
        valueFormatter: (params: any) => this.formatDecimalNew(params, this.longLengthUnit == 'm' ? 2 : 9)
      },
      {
        header: `Dogleg (${this._doglegUnit})`, field: 'doglegSeverity',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Dogleg Due To Buckling (${this._doglegUnit})`, field: 'doglegDueToBuckling',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Torque Due To Buckling (${this._torqueUnit})`, field: 'torqueDueToBuckling',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Buckling Displacement (${this.longLengthUnit})`, field: 'bucklingDisplacement',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Critical Sinusoidal Buckling Force (${this._forceUnit})`, field: 'criticalSinusoidallBucklingForce',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Axial Stress (${this._pressureUnit})`, field: 'axialStress',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: 'Strain', field: 'strain',
        valueFormatter: (params: any) => this.formatDecimalNew(params, 9)
      }
    ];
  }
  
  onModeChange(e): void {
    this.displacementResultsStore.update(state => ({ ...state, selectedMode: e.value }));
    if (this.displacementResultsStore.state.selectedMode == "multiple") {
      this.displacementResultsStore.update(state => ({ ...state, selectedResultDisplay: 'plot', selectedLoadCase: { label: 'Internal Pressure', value: 'internalPressure' } }));
    } else {
      this.displacementResultsStore.update(state => ({ ...state, selectedResultDisplay: 'grid' }));
      this.xAxisTitle = '';
    }
    this.refreshResults(true, false, true);
  }

  onSelectedResultDisplayUpdated(e): void {
    this.displacementResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  setLoadCase(e): void {
    if (this.displacementResultsStore.state.selectedMode === "single") {
      this.displacementResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.results = e.value.value.displacementResults;
    } else {
      this.displacementResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.refreshResults(true, false, false);
    }
  }

  public depthViewToggle(e) {
    this.displacementResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  private handleSingleDisplacementLoad(displacementResults: Array<any>) {
    this.setColumnDefinitions();
    this.results = [];

    this.loadCases = displacementResults?.map(lc => {
      return { label: lc.loadCaseName, value: lc };
    }) ?? [];
    if (!this.displacementResultsStore.state.selectedLoadCase || !this.loadCases.find(l => isEqual(l, this.displacementResultsStore.state.selectedLoadCase))) {
      this.displacementResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.results = this.displacementResultsStore.state.selectedLoadCase?.value.displacementResults || [];
  }

  private handleMultiDisplacementLoad(displacementResults: Array<any>) {
    this.columnDefinitions.splice(2, this.columnDefinitions.length);
    this.columnDefs = [];
    this.results = [];

    this.yAxisTitle = `MD (${this.longLengthUnit})`;

    this.loadCases = this.getResultTypes('label', 'value');

    switch (this.displacementResultsStore.state.selectedLoadCase.value) {
      case 'internalPressure': {
        this.xAxisTitle = `Internal Pressure (${this._pressureUnit})`;
        this.unit = `(${this._pressureUnit})`;
        break;
      }
      case 'externalPressure': {
        this.xAxisTitle = `External Pressure (${this._pressureUnit})`;
        this.unit = `(${this._pressureUnit})`;
        break;
      }
      case 'temperature': {
        this.xAxisTitle = `Temperature (${this._temperatureUnit})`;
        this.unit = `(${this._temperatureUnit})`;
        break;
      }
      case 'trueAxialForce': {
        this.xAxisTitle = `True Axial Force (${this._forceUnit})`;
        this.unit = `(${this._forceUnit})`;
        break;
      }
      case 'axialStress': {
        this.xAxisTitle = `Axial Stress (${this._pressureUnit})`;
        this.unit = `(${this._pressureUnit})`;
        break;
      }
      case 'totalDisplacement': {
        this.xAxisTitle = `Total Displacement (${this.longLengthUnit})`;
        this.unit = `(${this.longLengthUnit})`;
        break;
      }
      case 'thermalDisplacement': {
        this.xAxisTitle = `Thermal Displacement (${this.longLengthUnit})`;
        this.unit = `(${this.longLengthUnit})`;
        break;
      }
      case 'poissonDisplacement': {
        this.xAxisTitle = `Poisson Displacement (${this.longLengthUnit})`;
        this.unit = `(${this.longLengthUnit})`;
        break;
      }
      case 'bucklingDisplacement': {
        this.xAxisTitle = `Buckling Displacement (${this.longLengthUnit})`;
        this.unit = `(${this.longLengthUnit})`;
        break;
      }
      case 'hookeDisplacement': {
        this.xAxisTitle = `Hooke Displacement (${this.longLengthUnit})`;
        this.unit = `(${this.longLengthUnit})`;
        break;
      }
      case 'strain': {
        this.xAxisTitle = 'Strain';
        this.unit = '';
        break;
      }
      case 'effectiveForce': {
        this.xAxisTitle = `Effective Force (${this._forceUnit})`;
        this.unit = `(${this._forceUnit})`;
        break;
      }
      case 'criticalSinusoidallBucklingForce': {
        this.xAxisTitle = `Critical Sinusoidal Buckling Force (${this._forceUnit})`;
        this.unit = `(${this._forceUnit})`;
        break;
      }
      case 'doglegSeverity': {
        this.xAxisTitle = `Dogleg Severity (${this._doglegUnit})`;
        this.unit = `(${this._doglegUnit})`;
        break;
      }
      case 'doglegDueToBuckling': {
        this.xAxisTitle = `Dogleg Due To Buckling (${this._doglegUnit})`;
        this.unit = `(${this._doglegUnit})`;
        break;
      }
      case 'torqueDueToBuckling': {
        this.xAxisTitle = `Torque Due To Buckling (${this._torqueUnit})`;
        this.unit = `(${this._torqueUnit})`;
        break;
      }
      default: {
        break;
      }
    }

    displacementResults.forEach(load => {
      const loadName: string = load.loadCaseName + ' ' + this.unit;
      const loadNameCamel = toCamelCaseString(load.loadCaseName);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      let numDecimals = 2;
      switch (this.displacementResultsStore.state.selectedLoadCase.value) {
        case 'hookeDisplacement': {
          numDecimals = this.longLengthUnit == 'm' ? 2 : 9;
          break;
        }
        case 'poissonDisplacement': {
          numDecimals = this.longLengthUnit == 'm' ? 2 : 9;
          break;
        }
        case 'totalDisplacement': {
          numDecimals = this.longLengthUnit == 'm' ? 2 : 9;
          break;
        }
        case 'thermalDisplacement': {
          numDecimals = this.longLengthUnit == 'm' ? 2 : 9;
          break;
        }
        case 'strain': {
          numDecimals = this.longLengthUnit == 'm' ? 2 : 9;
          break;
        }
        default: {
          numDecimals = 2;
          break;
        }
      }

      this.columnDefinitions.push({
        header: loadName,
        field: loadNameCamel,
        valueFormatter: (params: any) => this.formatDecimalNew(params, numDecimals)
      });

      let backupArray = [];

      load.displacementResults.forEach(loadResult => {
        let result = {
          [loadNameCamel]: loadResult[this.displacementResultsStore.state.selectedLoadCase.value],
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (displacementResults[0].loadCaseName === load.loadCaseName) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        let otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });
    });
  }

  private getResultTypes(labelName, valueName): Array<any> {
    return [
      { [labelName]: 'Internal Pressure', [valueName]: 'internalPressure' },
      { [labelName]: 'External Pressure', [valueName]: 'externalPressure' },
      { [labelName]: 'Temperature', [valueName]: 'temperature' },
      { [labelName]: 'True Axial Force', [valueName]: 'trueAxialForce' },
      { [labelName]: 'Axial Stress', [valueName]: 'axialStress' },
      { [labelName]: 'Total Displacement', [valueName]: 'totalDisplacement' },
      { [labelName]: 'Thermal Displacement', [valueName]: 'thermalDisplacement' },
      { [labelName]: 'Poisson Displacement', [valueName]: 'poissonDisplacement' },
      { [labelName]: 'Buckling Displacement', [valueName]: 'bucklingDisplacement' },
      { [labelName]: 'Hooke Displacement', [valueName]: 'hookeDisplacement' },
      { [labelName]: 'Strain', [valueName]: 'strain' },
      { [labelName]: 'Effective Force', [valueName]: 'effectiveForce' },
      { [labelName]: 'Critical Sinusoidal Buckling Force', [valueName]: 'criticalSinusoidallBucklingForce' },
      { [labelName]: 'Dogleg Severity', [valueName]: 'doglegSeverity' },
      { [labelName]: 'Dogleg Due To Buckling', [valueName]: 'doglegDueToBuckling' },
      { [labelName]: 'Torque Due To Buckling', [valueName]: 'torqueDueToBuckling' }
    ];
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
