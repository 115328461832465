import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription, timer, lastValueFrom } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { greaterThanZero } from '../../../wellbore-inputs/components/well-configuration/validation/greaterThanZero';
import { PipesCatalog, PipesCatalogUi } from '../../models/pipes-catalog.model';
import { PipesService } from '../../shared/services/pipes-catalog.service';
import { innerSmallerThanOuterDiameter } from '../../validation/innerSmallerThanOuterDiameter';
import { outerGreaterThanInnerDiameter } from '../../validation/outerGreaterThanInnerDiameter';
import { driftLargerThanId } from '../../validation/driftLargerThanId';
import { checkForDuplicates } from '../../validation/checkForDuplicates';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { Utilities } from 'src/app/core/services/utilities';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import isEqual from 'lodash/isEqual';

@Component({
  selector: 'app-pipes-catalog',
  templateUrl: './pipes-catalog.component.html',
  styleUrls: ['./pipes-catalog.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class PipesCatalogComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  private _pipes: Array<PipesCatalog>;
  public pipeOds: SelectItem[] = [];
  public pipeTypes: SelectItem[] = [
    { label: 'Tubular Pipes', value: 'tubularPipe' },
    { label: 'Drill Pipes', value: 'drillPipe' },
    { label: 'HWDPs', value: 'hwdp' },
    { label: 'Collars', value: 'collars' }
  ];
  private discriminator: string;
  public diameterUnit: string;

  public columnDefs: Array<any>;
  public isLoading: boolean;
  public pipesForm: UntypedFormGroup;
  public tableHeight: string;

  get pipesCatalog(): UntypedFormArray {
    return this.pipesForm.get("pipes") as UntypedFormArray;
  }

  public pTableData: AbstractControl[];

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.pipesCatalogStore = this._storeService.createStore(this.componentId, new PipesCatalogUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public pipesCatalogStore: Store;

  constructor(
    private _messenger: MediatorService,
    private _pipesCatalogService: PipesService,
    private _formBuilder: UntypedFormBuilder,
    private _confirmationService: ConfirmationService,
    private _storeService: StoreService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.pipesForm = this._formBuilder.group({
      pipes: this._formBuilder.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.diameterUnit = uu.shortLengths;

    this.columnDefs = [
      { field: 'outsideDiameter', header: `OD (${uu.shortLengths})` },
      { field: 'insideDiameter', header: `ID (${uu.shortLengths})` },
      { field: 'weightPerFoot', header: `Weight (${Units.lib[uu.linearDensity].symbol})` },
      { field: 'driftDiameter', header: `Drift (${uu.shortLengths})` },
      { field: 'wallThickness', header: `Wall (${uu.shortLengths})` },
    ];

    this.getPipes(this.pipesCatalogStore.state?.selectedType || 'tubularPipe');

    addEventListener("keydown", (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Pipes Catalog") {
        this.tableHeight = (e.itemHeight - 115) + 'px';
      }
    }));
  }

  public getPipes(discriminator: string) {
    this.pipesCatalogStore.update(state => ({ ...state, selectedType: discriminator }));
    this._pipesCatalogService.getPipes().subscribe(pipes => {
      this.discriminator = discriminator;
      this._pipes = pipes;

      this.setPipeOds();
      // this.populateFormData(this._pipes.filter(p => p.discriminator == discriminator));
      this.isLoading = false;
    });
  }

  public onAddPipe(idx?: number): void {
    if (this.pipesCatalog.invalid) {
      return;
    }
    this.pipesCatalog.insert(idx || 0, this.newPipe());
    this.pTableData = [...this.pipesCatalog.controls];
  }

  public onDeletePipe(idx: number): void {
    let pipeId = this.pipesCatalog.controls[idx].get("id").value;
    let pipeOuterDiameter = this.pipesCatalog.controls[idx].get("outsideDiameter").value;
    let diameterUnitShort = this.diameterUnit == 'in' ? '"' : this.diameterUnit;
    if (pipeId.length < 36) {
      this.pipesCatalog.removeAt(idx);
    } else {
      this._confirmationService.confirm({
        message: `Are you sure that you want to delete the selected ${pipeOuterDiameter + diameterUnitShort} pipe?`,
        accept: async () => {
          if (pipeId.length > 24) { // it's been saved to the db, so delete through API
            await lastValueFrom(this._pipesCatalogService.deletePipe(pipeId));
          }
          this.pipesCatalog.removeAt(idx);
          this.pTableData = [...this.pipesCatalog.controls];
          this._pipes.splice(0, 1);
          this.setPipeOds();
          this.filterByOd({ value: this.pipesCatalogStore.state.selectedOd });
        }
      });
    }
  }

  private async handleSavePipeRow(v: any, pipeRecord: UntypedFormGroup): Promise<void> {
    if (pipeRecord.valid && !this.isLoading) {
      if (v.id.length == 24) { // it's only on the client so save to db as a new record
        var newEntityId = await lastValueFrom(this._pipesCatalogService.addPipe(new PipesCatalog(v)));
        pipeRecord.get("id").patchValue(newEntityId, { emitEvent: false });
      } else {
        await lastValueFrom(this._pipesCatalogService.updatePipe(new PipesCatalog(v)));
      }
      v["wallThickness"] = (v.outsideDiameter - v.insideDiameter) / 2;
      this._pipes.unshift(new PipesCatalog(v));
      this.setPipeOds();
    }
  }

  private newPipe(): UntypedFormGroup {
    let pipeFg = new UntypedFormGroup({
      id: new UntypedFormControl(Utilities.generateFakeGuid()),
      outsideDiameter: new UntypedFormControl(null, [Validators.required, outerGreaterThanInnerDiameter]),
      insideDiameter: new UntypedFormControl(null, [Validators.required, innerSmallerThanOuterDiameter]),
      weightPerFoot: new UntypedFormControl(null, [Validators.required, greaterThanZero]),
      driftDiameter: new UntypedFormControl(null, [Validators.required, driftLargerThanId]),
      wallThickness: new UntypedFormControl({ value: '', disabled: true }),
      discriminator: new UntypedFormControl(this.discriminator),
    }, checkForDuplicates);

    let debouncedValueChanges = pipeFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSavePipeRow(v, pipeFg)));
    this._subscriptions.add(pipeFg.valueChanges.subscribe(() => this.updateWallThickness(pipeFg)));

    pipeFg.markAllAsTouched();

    return pipeFg;
  }

  private updateWallThickness(pipeRecord: UntypedFormGroup) {
    let outsideDiameter = pipeRecord.get("outsideDiameter") as UntypedFormGroup;
    let insideDiameter = pipeRecord.get("insideDiameter") as UntypedFormGroup;
    const wallThickness = ((outsideDiameter.value - insideDiameter.value) / 2).toFixed(3);
    pipeRecord.controls.wallThickness.patchValue(wallThickness, { emitEvent: false });
  }

  private populateFormData(pipeData: Array<PipesCatalog>) {
    this.pipesCatalog.clear();
    this.pipesCatalog.controls = pipeData.map((data) => {
      let pipeFg = this.newPipe();
      pipeFg.setValue({ ...data }, { emitEvent: false });
      return pipeFg;
    });
    this.pTableData = [...this.pipesCatalog.controls];
  }

  public filterByOd(e) {
    this.pipesCatalogStore.update(state => ({ ...state, selectedOd: e.value }));
    let pipeData = this._pipes.filter(p =>
      e.value == 0
        ? p.discriminator == this.discriminator
        : p.outsideDiameter == e.value && p.discriminator == this.discriminator
    );

    this.populateFormData(pipeData);
  }

  private setPipeOds(): void {
    let pipeOds = this._pipes.filter(p => p.discriminator == this.discriminator)
      .map(x => ({ label: x.outsideDiameter.toString(), value: x.outsideDiameter }));
    this.pipeOds = [...new Set(pipeOds.map(item => item.value))].map(x => ({ label: x.toString(), value: x }));
    this.pipeOds.unshift({ label: 'View All', value: 0 });
    if (!this.pipesCatalogStore.state.selectedOd || !this.pipeOds.find(p => isEqual(p.value, this.pipesCatalogStore.state.selectedOd))) {
      this.pipesCatalogStore.update(state => ({ ...state, selectedOd: this.pipeOds[0].value }));
    }
    if ((this.pipesCatalogStore.state.selectedOd || this.pipesCatalogStore.state.selectedOd === 0) && this.pipeOds.find(p => isEqual(p.value, this.pipesCatalogStore.state.selectedOd))) {
      this.filterByOd({ value: this.pipesCatalogStore.state.selectedOd });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }
}
