import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseOperation } from '../../models/thermal-operation.model';
import { TemperatureResultPhase, TemperatureResults, TemperatureResultsUi } from '../../models/thermal-results.model';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { ChangeSelectedThermalOperation, GridItemResizedMessage, SyncPressAndTempResults } from 'src/app/shared/models/mediator-messages.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { cloneDeep, zipObject } from 'lodash';

@Component({
  selector: 'app-thermal-temperature-results',
  templateUrl: './thermal-temperature-results.component.html',
  styleUrls: ['./thermal-temperature-results.component.scss']
})
export class ThermalTemperatureResultsComponent implements OnInit, OnDestroy {

  public isLoading: boolean = true;
  public missingOperations: boolean = false;
  public needToCalculate: boolean;
  public tableHeight: string;
  public plotName: string = 'thermalTemperatureResultsPlot';

  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' },
    { label: 'Heatmap', value: 'heatmap' }
  ];

  public singleMultiple: SelectItem[] = [
    { label: 'Single', value: 'singleOperation' },
    { label: 'Multiple', value: 'multipleOperation' }
  ];

  public operationTypes: SelectItem[] = [
    { label: 'Drilling', value: 'drilling' },
    { label: 'Cementing', value: 'cementing' }
  ];

  public selectedOperationType: string = 'drilling';

  public depthView = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];

  public operations: Partial<BaseOperation>[];
  public allOperationsResults: TemperatureResults[];
  public results: any[];
  public resultsBackup: any[];
  public resultsGrid: any[];
  public cols: any[];
  public phases: TemperatureResultPhase[];
  public calculationError: string = "Please calculate Perical to see results.";
  public multipleResultsFull: any[] = [];
  public annulusNames: Array<{ label: string, value: string }> = [];
  public operationNames: any[] = [];
  public operationNamesPlot: Array<{ field: string, header: string }> = [];
  public selectedOperationIndex: number = 0;
  public moveUpDisabled: boolean = true;
  public moveDownDisabled: boolean = false;
  public temperatureUnit: string;
  public depthUnit: string;
  public diameterUnit: string;
  public minTemp: number;
  public maxTemp: number;
  public tubulars: any[];
  public annulusName: string;
  public componentHeight: number;

  private _subscriptions: Subscription;

  // State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.temperatureResultsStore = this._storeService.createStore(this.componentId, new TemperatureResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public temperatureResultsStore: Store;

  @ViewChild('toolbarDiv', { static: false }) toolbarDiv: ElementRef;

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _messenger: MediatorService,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Thermal Results - Temperatures")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));

    this._subscriptions.add(this._messenger.of(ChangeSelectedThermalOperation).subscribe((e) => {
      let selectedOperation;
      if (!e.selectedThermalOperation.value?.['phaseNumber']) {
        selectedOperation = e.selectedThermalOperation;

        this.temperatureResultsStore.update((state) => ({
          ...state,
          selectedSingleMultiple: 'singleOperation',
          selectedOperation: selectedOperation.value
        }));
        this.onOperationSelected(selectedOperation, false);
      } else {
        selectedOperation = this.temperatureResultsStore.state.selectedOperation;
        this.temperatureResultsStore.update((state) => ({
          ...state,
          selectedSingleMultiple: 'singleOperation',
          selectedPhase: this.allOperationsResults.find(x => x.operationId === this.temperatureResultsStore.state.selectedOperation.id).phases.find(x => x.phaseNumber === e.selectedThermalOperation.value['phaseNumber'])
        }));
        this.onOperationSelected(selectedOperation, false, this.temperatureResultsStore.state.selectedPhase);
      }
    }));

    this._subscriptions.add(this._messenger.of(SyncPressAndTempResults).subscribe((e) => {
      this.temperatureResultsStore.update(state => ({
        ...state,
        syncThermalResults: e.syncPressAndTemp
      }));
    }));
  }

  async ngOnInit(): Promise<void> {
    let uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.temperatureUnit = uu.temperature;
    this.depthUnit = uu.longLengths;
    this.diameterUnit = uu.shortLengths == 'in' ? '"' : uu.shortLengths;

    this.getThermalTemperatureResults();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  private signalRfunc(data: any): void {
    if (data.action == PeriforOnChangeMessages.REFRESH_THERMAL_RESULTS || data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.isLoading = true;
      this.getThermalTemperatureResults(true);
    }
  }

  get isDialogVisible(): boolean {
    return !this.missingOperations && this.needToCalculate;
  }

  private getThermalTemperatureResults(calledFromSignalR?: boolean): void {
    const sources = [
      this._wellConfigService.getTubulars(),
      this._thermalOperationsService.getThermalTemperatureResults() as Observable<any>,
      this._thermalOperationsService.getThermalOperations()
    ];

    forkJoin(sources).subscribe({
      next: ([tubulars, allResults, operations]) => {
        this.operations = operations;
        if (!this.temperatureResultsStore.state.selectedOperation || calledFromSignalR) {
          this.temperatureResultsStore.update(state => ({
            ...state,
            selectedOperation: this.operations[0]
          }));
        }

        this.operations.length === 0 ? this.missingOperations = true : this.missingOperations = false;
        this.missingOperations && (this.results = []);
        if (this.operations.length == 0) {
          this.isLoading = false;
          // this.operations = null;
          this.missingOperations = true;
          return;
        }

        this.tubulars = tubulars;

        this.allOperationsResults = allResults;
        this.resultsBackup = allResults;

        if (this.allOperationsResults.length > 0) {

          if (this.allOperationsResults.length < 2) {
            this.moveUpDisabled = true;
            this.moveDownDisabled = true;
          }

          if (this.temperatureResultsStore.state.selectedSingleMultiple === 'singleOperation') {
            let selectedOperation = this.allOperationsResults.find(x => x.operationId === this.temperatureResultsStore.state.selectedOperation.id);
            this.temperatureResultsStore.update(state => ({...state, selectedPhase: selectedOperation?.phases[selectedOperation.phases.length - 1]}));
            this.remapResults();
          } else {
            this.remapMultipleResults(0);
          }
        } else {
          this.clearResults();
          this.calculationError = "Please calculate Perical to see results.";
        }
        this.isLoading = false;
      },
      error: () => {
        this.missingOperations = true;
        this.isLoading = false;
      }
    });
  }

  public depthViewToggle(e) {
    this.temperatureResultsStore.update(state => ({
      ...state,
      selectedDepthView: e.value
    }));
  }

  public onSelectedResultsDisplayUpdated(e) {
    this.temperatureResultsStore.update(state => ({
      ...state,
      selectedResultsDisplay: e.value
    }));
  }

  public syncPressAndTemp(e) {
    this.temperatureResultsStore.update(state => ({
      ...state,
      syncThermalResults: e.checked
    }));
    this._messenger.publish(new SyncPressAndTempResults(this.temperatureResultsStore.state.syncThermalResults));
  }

  public onOperationSelected(e, isCalledFromHere, selectedPhase?: TemperatureResultPhase): void {
    if (isCalledFromHere && this.temperatureResultsStore.state.syncThermalResults) {
      this._messenger.publish(new ChangeSelectedThermalOperation(e));
    }
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
    if (this.allOperationsResults[0].operationId === (e.value?.id ?? e.id)) {
      this.moveUpDisabled = true;
    }
    if (this.allOperationsResults[this.allOperationsResults.length - 1].operationId === (e.value?.id ?? e.id)) {
      this.moveDownDisabled = true;
    }
    if (this.allOperationsResults.length > 0) {
      let firstOperation = this.allOperationsResults.find(x => x.operationId === (e.value?.id ?? e.id));
      this.temperatureResultsStore.update(state => ({
        ...state,
        selectedOperation: e.value ?? e,
        selectedPhase: !selectedPhase ? firstOperation?.phases[firstOperation.phases.length - 1] : selectedPhase
      }));
      this.remapResults(selectedPhase);
    } else {
      this.needToCalculate = true;
    }
  }

  public onUpDownButtonClick(e, isCalledFromHere): void {
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
    if (this.temperatureResultsStore.state.selectedSingleMultiple === 'singleOperation') {
      this.moveSingleOperations(e, isCalledFromHere);
    } else {
      this.moveMultipleOperations(e);
    }
  }

  private moveMultipleOperations(e) {
    this.annulusNames = this.temperatureResultsStore.state.selectedPhase.temperatureResultsTable.headers.slice(2).map(h => {
      return {
        value: toCamelCaseString(h),
        label: h
      };
    });

    this.annulusName = this.annulusNames.find(x => x.value == this.temperatureResultsStore.state.selectedAnnulus).label;

    if (e === 'up') {
      let selectedAnnulusIndex = this.annulusNames.findIndex(x => x.value === this.temperatureResultsStore.state.selectedAnnulus);
      if (selectedAnnulusIndex === 1) {
        this.moveUpDisabled = true;
        this.temperatureResultsStore.update(state => ({
          ...state,
          selectedAnnulus: this.annulusNames[selectedAnnulusIndex - 1].value
        }));
        this.remapMultipleResults(0);
        return;
      }
      this.temperatureResultsStore.update(state => ({
        ...state,
        selectedAnnulus: this.annulusNames[selectedAnnulusIndex - 1].value
      }));
    } else {
      let selectedAnnulusIndex = this.annulusNames.findIndex(x => x.value === this.temperatureResultsStore.state.selectedAnnulus);
      if (selectedAnnulusIndex === this.annulusNames.length - 2) {
        this.moveDownDisabled = true;
        this.temperatureResultsStore.update(state => ({
          ...state,
          selectedAnnulus: this.annulusNames[selectedAnnulusIndex + 1].value
        }));
        this.remapMultipleResults(0);
        return;
      }
      this.temperatureResultsStore.update(state => ({...state, selectedAnnulus: this.annulusNames[selectedAnnulusIndex + 1].value}));
    }

    this.remapMultipleResults(0);
  }

  private moveSingleOperations(e, isCalledFromHere) {
    this.selectedOperationIndex = this.operations.findIndex(x => x.id === this.temperatureResultsStore.state.selectedOperation.id);
    if (e === 'up') {
      let selectedOperation = this.operations[this.selectedOperationIndex - 1];
      if (!selectedOperation) {
        this.moveUpDisabled = true;
        return;
      }
      if (isCalledFromHere && this.temperatureResultsStore.state.syncThermalResults) {
        this._messenger.publish(new ChangeSelectedThermalOperation(selectedOperation));
      }
      let firstOperation = this.allOperationsResults[this.selectedOperationIndex];
      if (!firstOperation) {
        this.clearResults();
      }
      this.temperatureResultsStore.update(state => ({
        ...state,
        selectedOperation: selectedOperation,
        selectedPhase: firstOperation?.phases[firstOperation.phases.length - 1]
      }));
      this.selectedOperationIndex = this.operations.findIndex(x => x.id === this.temperatureResultsStore.state.selectedOperation.id);
      this.moveUpDisabled = this.selectedOperationIndex === 0 ? true : false;
      this.moveDownDisabled = this.selectedOperationIndex === this.operations.length - 1 ? true : false;
      this.remapResults();
    } else {
      let selectedOperation = this.operations[this.selectedOperationIndex + 1];
      if (!selectedOperation) {
        this.moveDownDisabled = true;
        return;
      }
      if (isCalledFromHere && this.temperatureResultsStore.state.syncThermalResults) {
        this._messenger.publish(new ChangeSelectedThermalOperation(selectedOperation));
      }
      let firstOperation = this.allOperationsResults[this.selectedOperationIndex];
      if (!firstOperation) {
        this.clearResults();
      }
      this.temperatureResultsStore.update(state => ({
        ...state,
        selectedOperation: selectedOperation,
        selectedPhase: firstOperation?.phases[firstOperation.phases.length - 1]
      }));
      this.selectedOperationIndex = this.operations.findIndex(x => x.id === this.temperatureResultsStore.state.selectedOperation.id);
      this.moveUpDisabled = this.selectedOperationIndex === 0 ? true : false;
      this.moveDownDisabled = this.selectedOperationIndex === this.operations.length - 1 ? true : false;
      this.remapResults();
    }
  }

  private clearResults() {
    this.moveDownDisabled = true;
    this.moveUpDisabled = true;
    this.results = [];
    this.cols = [];
    this.resultsGrid = [];
    this.multipleResultsFull = [];
    this.needToCalculate = true;
    return
  }

  public onPhaseSelected(results: any[], isCalledFromHere: boolean): void {
    if (isCalledFromHere && this.temperatureResultsStore.state.syncThermalResults) {
      this._messenger.publish(new ChangeSelectedThermalOperation(results));
    }
    if (this.allOperationsResults.length > 0) {
      this.temperatureResultsStore.update(state => ({...state, selectedPhase: results['value']}));
      this.remapResults(results['value']);
    } else {
      this.needToCalculate = true;
    }
  }

  public toggleFormationPoints(e): void {
    this.temperatureResultsStore.update(state => ({...state, showFormationPoints: e.checked}));
    if (!this.temperatureResultsStore.state.showWellborePoints) {
      this.temperatureResultsStore.update(state => ({...state, showWellborePoints: true}));
    }
    this.allOperationsResults = cloneDeep(this.resultsBackup);
    this.remapResults();
  }

  public toggleWellborePoints(e): void {
    this.temperatureResultsStore.update(state => ({...state, showWellborePoints: e.checked}));
    if (!this.temperatureResultsStore.state.showFormationPoints) {
      this.temperatureResultsStore.update(state => ({...state, showFormationPoints: true}));
    }
    this.allOperationsResults = cloneDeep(this.resultsBackup);
    this.remapResults();
  }

  public modeToggle(e) {
    this.moveUpDisabled = true;
    this.moveDownDisabled = false;
    this.temperatureResultsStore.update(state => ({
      ...state,
      selectedSingleMultiple: e
    }));
    if (e === 'singleOperation') {
      this.annulusName = '';
      this.temperatureResultsStore.state.syncThermalResults = true;
      this._messenger.publish(new SyncPressAndTempResults(this.temperatureResultsStore.state.syncThermalResults));
      this.temperatureResultsStore.update(state => ({
        ...state,
        selectedOperation: this.operations[0]
      }));
      this.remapResults(null);
    } else {
      this.temperatureResultsStore.update(state => ({...state, syncThermalResults: false}));
      this.remapMultipleResults(0);
    }
  }

  public annulusDropdownChange(e) {
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
    this.temperatureResultsStore.update(state => ({
      ...state,
      selectedAnnulus: e.value
    }));
    this.remapMultipleResults(0);
  }

  private remapMultipleResults(result: any, selectedPhase?: TemperatureResultPhase): void {
    let allSelectedTypeOperations: any[] = [];

    if (this.temperatureResultsStore.state.selectedSingleMultiple === 'operationType') {

      this.allOperationsResults.forEach(x => {
        if (x.operationType == this.selectedOperationType) {
          allSelectedTypeOperations.push(x);
        }
      });
    }

    let allOperationsResults = this.temperatureResultsStore.state.selectedSingleMultiple === 'operationType' ?
      allSelectedTypeOperations.filter(ar => ar.phases[0]) : this.allOperationsResults.filter(ar => this.operations.find(rm => (rm.name === ar.name)));

    this.temperatureResultsStore.update(state => ({
      ...state,
      selectedOperation: allOperationsResults[0]
    }));

    const selectedOperationResults = allOperationsResults[0];
    this.needToCalculate = !selectedOperationResults;
    this.phases = selectedOperationResults ? selectedOperationResults.phases : null;
    if (!selectedPhase) {
      selectedPhase = this.phases ? this.phases[this.phases.length - 1] : null;
    }

    if (this.temperatureResultsStore.state.selectedSingleMultiple === 'operationType' && !selectedPhase) {
      this.results = [];
      this.cols = [];
      this.resultsGrid = [];
      this.multipleResultsFull = [];
      this.calculationError = "Please make sure that " + this.selectedOperationType + " operations are calculated.";
      this.missingOperations = null;
      return;
    } else if (this.operations.length === 0) {
      this.missingOperations = true;
    }

    this.annulusNames = selectedPhase.temperatureResultsTable.headers.slice(2).map(h => {
      return {
        value: toCamelCaseString(h),
        label: h
      };
    });

    this.annulusName = this.annulusNames.find(x => x.value == this.temperatureResultsStore.state.selectedAnnulus)?.label;

    if (this.temperatureResultsStore.state.selectedSingleMultiple === 'operationType') {
      this.temperatureResultsStore.update(state => ({...state, selectedAnnulus: this.annulusNames[0].label}))

      this.operationNames = allSelectedTypeOperations.map(x => {
        return {
          field: toCamelCaseString(x.name),
          header: x.name,
          valueFormatter: (params: any) => this.formatDecimal(params)
        };
      });
    } else {
      allOperationsResults = allOperationsResults.sort((a, b) => {
        return this.operations.findIndex(x => a.name == x.name) - this.operations.findIndex(x => b.name == x.name);
      });

      this.operationNames = allOperationsResults.map(x => {
        return {
          field: toCamelCaseString(x.name),
          header: x.name.startsWith('MD') || x.name.startsWith('TVD') ? x.name : x.name + ` (${this.temperatureUnit})`,
          valueFormatter: (params: any) => this.formatDecimal(params)
        };
      });
    }

    this.multipleResultsFull = [];
    const allOpResultsLength = allOperationsResults.length;

    if (allOpResultsLength > 0 && this.temperatureResultsStore.state.selectedSingleMultiple === 'operationType') {
      this.missingOperations = null;
    } else if (this.operations.length === 0) {
      this.missingOperations = true;
    }

    let longestOperationDataIndex = 0;
    let dataLength = 0;
    for (let l = 0; l < allOpResultsLength; l++) {
      const operation = allOperationsResults[l];

      if (operation.phases.length === 0) {
        continue;
      }

      const { length: phaseLength } = operation.phases[0].temperatureResultsTable.rows;

      if (dataLength < phaseLength) {
        longestOperationDataIndex = l;
        dataLength = phaseLength;
      }
    }

    const opPhaseIndex = allOperationsResults[longestOperationDataIndex].phases;
    const depths = opPhaseIndex[0].temperatureResultsTable.rows.map(x => x[0]);

    let operationCount = 0;
    const rowLength = opPhaseIndex[0].temperatureResultsTable.rows.length;
    for (let l = 0; l < rowLength; l++) {
      let multipleResults = [];
      // let lastPhase;
      for (let i = 0; i < allOpResultsLength; i++) {
        const lastPhaseNew = allOperationsResults[i].phases.length - 1;
        if (allOperationsResults[i].phases.length == 0) {
          continue;
        }
        // lastPhase = allOperationsResults[operationCount].phases.length - 1;
        if (i === 0) {
          const row = opPhaseIndex[opPhaseIndex.length - 1].temperatureResultsTable.rows[l];
          multipleResults.push(row[0]);
          multipleResults.push(row[1]);
        }
        const lastPhaseTempResultsAtIndex = allOperationsResults[i].phases[lastPhaseNew].temperatureResultsTable;
        let annulusColumnIndex = lastPhaseTempResultsAtIndex.headers.findIndex(x => toCamelCaseString(x) == this.temperatureResultsStore.state.selectedAnnulus);
        if (annulusColumnIndex < 0) {
          annulusColumnIndex = 2;
        }

        const longestOpPhaseIndex = opPhaseIndex.length - 1;
        const longestOpPhaseIndexRows = opPhaseIndex[longestOpPhaseIndex].temperatureResultsTable.rows;
        if (l < longestOpPhaseIndexRows.length) {
          const currentDepth = longestOpPhaseIndexRows[l][0];
          const depthIndex = depths.findIndex(x => x == currentDepth);

          if (depths[depthIndex] == currentDepth) {
            const rowIndex = allOperationsResults[i].phases[0].temperatureResultsTable.rows.findIndex(x => x[0] == currentDepth);
            // lastPhase = allOperationsResults[i].phases.length - 1;
            const lastPhaseAtIndexRowLength = lastPhaseTempResultsAtIndex.rows.length;
            const currentDepthCheck = lastPhaseTempResultsAtIndex.rows[lastPhaseAtIndexRowLength - 1][0];
            if (l < lastPhaseAtIndexRowLength && rowIndex >= 0 && currentDepth <= currentDepthCheck) {
              multipleResults.push(lastPhaseTempResultsAtIndex.rows[rowIndex][annulusColumnIndex]);
            } else if (currentDepth <= currentDepthCheck) {
              const currentOperationDepths = lastPhaseTempResultsAtIndex.rows.map(x => x[0]);
              const previousDepthIndex = currentOperationDepths.findIndex(x => x >= currentDepth) - 1;

              if (previousDepthIndex < 0) {
                multipleResults.push(null);
                continue;
              }
              const nextDepthIndex = currentOperationDepths.findIndex(x => x >= currentDepth);
              const previousDepth = currentOperationDepths[previousDepthIndex];
              const nextDepth = currentOperationDepths[nextDepthIndex];
              
              const [previousTemp, nextTemp] = [
                  lastPhaseTempResultsAtIndex.rows[previousDepthIndex][annulusColumnIndex],
                  lastPhaseTempResultsAtIndex.rows[nextDepthIndex][annulusColumnIndex]
              ];
              
              const interpolatedTemperature = previousTemp + 
                  ((nextTemp - previousTemp) / (nextDepth - previousDepth)) * (currentDepth - previousDepth);

              multipleResults.push(interpolatedTemperature);
            } else {
              multipleResults.push(null);
            }
          }
        } else {
          multipleResults.push(null);
        }
      }

      if (l === allOperationsResults[operationCount].phases[allOperationsResults[operationCount].phases.length - 1].temperatureResultsTable.rows.length - 1) {
        operationCount++;
      }

      this.multipleResultsFull.push(multipleResults);
    }

    if (this.temperatureResultsStore.state.selectedSingleMultiple === 'multipleOperation') {
      this.operationNames = this.operationNames.filter(ar => allOperationsResults.find(rm => (ar.header.startsWith(rm.name))));
    }

    this.operationNames.unshift({ field: 'tvdFt', header: `TVD (${this.depthUnit})`, valueFormatter: (params: any) => this.formatDecimal(params) });
    this.operationNames.unshift({ field: 'mdFt', header: `MD (${this.depthUnit})`, valueFormatter: (params: any) => this.formatDecimal(params) });

    this.operationNames.push({
      field: 'udt',
      header: 'UDT' + ' (' + this.temperatureUnit + ')',
      valueFormatter: (params: any) => this.formatDecimal(params)
    });

    this.resultsGrid = this.multipleResultsFull.map(r => zipObject(this.operationNames.map(c => c.field), r));

    const longestOpIndexPhases = allOperationsResults[longestOperationDataIndex].phases;
    const longestOpPhaseIndex = longestOpIndexPhases[longestOpIndexPhases.length - 1];
    const resGridLength = this.resultsGrid.length - 1;
    const resultsCount = longestOpPhaseIndex.temperatureResultsTable.rows[0].length - 1;
    for (let i = 0; i <= resGridLength; i++) {
      if (this.resultsGrid[i]['mdFt'] == longestOpPhaseIndex.temperatureResultsTable.rows[i][0]) {
        const currentUdt = longestOpPhaseIndex.temperatureResultsTable.rows[i][resultsCount];
        this.resultsGrid[i]['udt'] = this.multipleResultsFull[i][this.operationNames.length - 1] = currentUdt;
      } else {
        const prev = this.resultsGrid[i - 2];
        const next = this.resultsGrid[i - 1];
        const currentDepth = this.resultsGrid[i]['mdFt'];
        
        const interpolatedTemperature = prev['udt'] + (currentDepth - prev['mdFt']) * (next['udt'] - prev['udt']) / (next['mdFt'] - prev['mdFt']);

        let depthIndex = depths.findIndex(x => x >= currentDepth);
        this.resultsGrid[depthIndex]['udt'] = interpolatedTemperature;
        this.multipleResultsFull[depthIndex][this.operationNames.length - 1] = interpolatedTemperature;
      }
    }
    this.getColor(this.multipleResultsFull);
  }

  private remapResults(selectedPhase?: TemperatureResultPhase): void {
    if (!this.temperatureResultsStore.state.selectedOperation) {
      this.results = [];
      this.cols = [];
      this.resultsGrid = [];
      this.phases = null;
      this.missingOperations = true;
    }
    let selectedOperationResults = this.allOperationsResults.find(o => o.operationId === this.temperatureResultsStore.state.selectedOperation.id ?? (this.temperatureResultsStore.state.selectedOperation['operationId'] ?? this.temperatureResultsStore.state.selectedOperation['value']?.id ?? this.temperatureResultsStore.state.selectedOperation.id));
    this.needToCalculate = !selectedOperationResults;
    if (this.needToCalculate && this.temperatureResultsStore.state.selectedSingleMultiple != 'singleOperation') {
      this.remapMultipleResults(0);
      return;
    }
    this.phases = selectedOperationResults ? selectedOperationResults.phases : null;
    if (!selectedPhase) {
      selectedPhase = this.phases ? this.phases[this.phases.length - 1] : null;
    }

    if (selectedPhase) {
      this.results = cloneDeep(selectedPhase.temperatureResultsTable.rows);
      if (isNaN(this.results[0][3])) {
        this.results = [];
        this.cols = [];
        this.resultsGrid = [];
        this.needToCalculate = true;
        this.calculationError = !this.needToCalculate ? "Perical engine error while calculating operation." : "Please calculate Perical to see results.";
        return;
      }
      let headers = this.temperatureResultsStore.state.showFormationPoints ?
        selectedPhase.temperatureResultsTable.headers :
        selectedPhase.temperatureResultsTable.headers.filter(x => (!x.includes(`(${this.depthUnit})`) || x.includes('MD') || x.includes('TVD')));

      const headerCountOriginal = headers.length;
      const originalResults = cloneDeep(selectedPhase.temperatureResultsTable.rows);
      if (!this.temperatureResultsStore.state.showWellborePoints) {
        headers = headers.filter(x => (x.includes(`(${this.depthUnit})`) || !x.includes(`${this.diameterUnit}`)) && !x.includes('Inner Fluid'));
        const removeWellboreColumns = headerCountOriginal - headers.length;
        this.results.forEach(x => x.splice(2, removeWellboreColumns));
      }

      if (!this.temperatureResultsStore.state.showFormationPoints) {
        headers = headers.filter(x => !!x.includes(`(${this.depthUnit})`) || x.includes(`${this.diameterUnit}`) || x.includes('Inner Fluid'));
        let resLength = this.results[0].length;
        this.results.forEach(x => x.splice(headers.length, resLength));
      }

      this.cols = headers.map(h => {
        return {
          field: toCamelCaseString((h === `MD (${this.depthUnit})` || h === `TVD (${this.depthUnit})`) ? h.split(' ').shift() : h),
          header: h.startsWith('MD') || h.startsWith('TVD') ? h : h + ` (${this.temperatureUnit})`,
          valueFormatter: (params: any) => this.formatDecimal(params)
        };
      });

      this.cols.push({
        field: 'udt',
        header: `UDT (${this.temperatureUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      })

      this.getColor(this.results);

      this.resultsGrid = this.results.map(r => zipObject(this.cols.map(c => c.field), r));

      const resGridLength = this.resultsGrid.length - 1;
      const resultsCount = this.results[0].length - 1;
      const originalResultsCount = originalResults[0].length - 1;
      for (let i = 0; i <= resGridLength; i++) {
        this.resultsGrid[i]['udt']  = originalResults[i][originalResultsCount];
        this.results[i][resultsCount + 1] = originalResults[i][originalResultsCount];
      }
    } else {
      this.needToCalculate = true;
      this.results = [];
      this.cols = [];
      this.resultsGrid = [];
      this.calculationError = !this.needToCalculate ? "Perical engine error while calculating operation." : "Please calculate Perical to see results.";
    }
  }

  private getColor(res): void {
    let results = [...res];
    results.forEach((array, index) => {
      results[index] = array.slice(2);
    });

    let allResults = [].concat.apply([], results).map(x => Math.round(x * 10) / 10);

    if (allResults.length > 0) {
      allResults = [...new Set(allResults.sort((a, b) => (a < b ? -1 : 1)))];
    }

    this.minTemp = allResults[0];
    this.maxTemp = allResults[allResults.length - 1];
  }

  public toggleHeatmap(event) {
    this.temperatureResultsStore.update(state => ({
      ...state,
      showHeatmap: event['srcElement']['checked']
    }));
  }

  private formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
