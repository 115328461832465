import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { CementingOperation, OperationType } from 'src/app/perical/models/thermal-operation.model';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UndisturbedTemperature } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';
import { TubularString } from 'src/app/wellbore-inputs/models/well-schematic.model';
import { DrillstringService } from 'src/app/wellbore-inputs/services/drillstring.service';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';

export interface CementingOperationForm {
  type: OperationType;
  drillingFluid: Fluid;
  slurryPumpRate: number;
  slurryInletTemperature: number;
  leadSlurry: Fluid;
  slurry: Fluid;
  length: number;
  fluid: Fluid;
  inletTemperature: number;
  displacementRate: number;
  cementSetTime: number;
  pipeRoughness: number;
  outsideDiameter: number;
  insideDiameter: number;
  annulusIndex: number;
}

@Component({
  selector: 'app-cementing-operation-details',
  templateUrl: './cementing-operation-details.component.html',
  styleUrls: ['./cementing-operation-details.component.scss'],
  providers: subformComponentProviders(CementingOperationDetailsComponent)
})
export class CementingOperationDetailsComponent implements OnChanges, OnDestroy, AfterViewInit {

  constructor(
    private _wellTypeService: WellTypeService,
    private _drillstringService: DrillstringService,
    private _signalRService: SignalRService
  ) { }

  public slurries: Fluid[];
  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[] | null | undefined) {
    this.fluids = fluids?.filter(f => f.state.type === FluidType.BRINE ||
      f.state.type === FluidType.STANDARDMUD ||
      f.state.type === FluidType.ADVANCEDMUD)
      .sort((a, b) => a.state.name.localeCompare(b.state.name));
    this.slurries = fluids?.filter(f => f.state.type === FluidType.CEMENTSLURRY).sort((a, b) => a.state.name.localeCompare(b.state.name));
  }

  public annularFluidId: string;
  @Input('annularFluidId') set _annularFluidId(annularFluidId: string) {
    this.annularFluidId = annularFluidId ?? null;
  }

  public surfaceTemperature: number;
  @Input('surfaceTemperature') set _surfaceTemperature(surfaceTemperature: number) {
    this.surfaceTemperature = surfaceTemperature ?? null;
  }

  @Input()
  public isReadOnly: boolean;

  @Input()
  private udtProfile: UndisturbedTemperature;

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public currentString: TubularString;

  @Input()
  public currentTubularIndex: number;

  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;
  public selectedDrillPipe: string;
  public drillPipe: any;
  public drillpipeMissing: boolean;
  public showDrillPipeData: boolean;
  public showRiser: boolean;
  public hasTailSlurry: boolean;
  public cementLength: number;
  public tempUnit: string;
  public standardFluidFlowUnit: string;
  public slurryFlowUnit: string;
  public pipeRoughnessValidation: { min: number, max: number };
  public temperatureValidation: { min: number, max: number };
  public displacementRateValidation: { min: number, max: number };
  public slurryFlowRateValidation: { min: number, max: number };
  
  private _defaultPipeRoughness: number;

  public form = createForm<CementingOperation, CementingOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.CEMENTING),
      drillingFluid: new UntypedFormControl(null, [Validators.required]),
      slurryPumpRate: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(1000)]),
      slurryInletTemperature: new UntypedFormControl(null),
      leadSlurry: new UntypedFormControl(null, [Validators.required]),
      slurry: new UntypedFormControl(null, [Validators.required]),
      length: new UntypedFormControl(null, [Validators.required]),
      fluid: new UntypedFormControl(null, [Validators.required]),
      inletTemperature: new UntypedFormControl(),
      displacementRate: new UntypedFormControl(null),
      cementSetTime: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(1000)]),
      pipeRoughness: new UntypedFormControl(null, [Validators.required]),
      outsideDiameter: new UntypedFormControl(21, [Validators.required, Validators.min(0)]),
      insideDiameter: new UntypedFormControl(19.75, [Validators.required, Validators.min(0)]),
      annulusIndex: new UntypedFormControl(null)
    },
    toFormGroup: (obj: CementingOperation | null): CementingOperationForm | null => {
      if (!obj) {
        return null;
      }

      this.getData(false);

      const { drillingFluid, slurryPumpRate, slurryInletTemperature, leadSlurry, tailCement, cementDisplacement,
        displacementRate, cementSetTime, pipeRoughness, riser, ...commonValues } = obj;

      obj.drillingFluid = this.fluids?.find(f => f.id === obj.drillingFluid.id);
      obj.leadSlurry = this.slurries?.find(f => f.id === obj.leadSlurry.id);
      obj.tailCement.slurry = this.slurries?.find(f => f.id === obj.tailCement.slurry.id);
      obj.cementDisplacement.fluid = this.fluids?.find(f => f.id === obj.cementDisplacement.fluid.id);

      return {
        type: obj.type,
        drillingFluid: obj.drillingFluid,
        slurryPumpRate,
        slurryInletTemperature,
        leadSlurry: obj.leadSlurry,
        slurry: obj.tailCement.slurry ?? null,
        length: tailCement.length,
        fluid: obj.cementDisplacement.fluid ?? null,
        inletTemperature: cementDisplacement.inletTemperature,
        displacementRate,
        cementSetTime,
        pipeRoughness,
        outsideDiameter: riser.outsideDiameter,
        insideDiameter: riser.insideDiameter,
        annulusIndex: obj.annulusIndex
      };
    },
    fromFormGroup: (formValue: CementingOperationForm): CementingOperation | null => {
      const { drillingFluid, slurryPumpRate, slurryInletTemperature, leadSlurry, slurry, length, fluid, inletTemperature,
        displacementRate, cementSetTime, pipeRoughness, outsideDiameter, insideDiameter, type, annulusIndex, ...commonValues } = formValue;

        this.form.formGroup.controls.inletTemperature.setValue((this.form.formGroup.value.inletTemperature ?? this.surfaceTemperature), { emitEvent: false });

      return {
        type: OperationType.CEMENTING,
        drillingFluid,
        slurryPumpRate,
        slurryInletTemperature,
        leadSlurry,
        tailCement: {
          slurry,
          length
        },
        cementDisplacement: {
          fluid,
          inletTemperature
        },
        displacementRate,
        cementSetTime,
        pipeRoughness,
        riser: {
          outsideDiameter,
          insideDiameter
        },
        annulusIndex
      };
    },

  });

  ngOnChanges(): void {
    this.isLoading = true;
    this.tempUnit = this.userUnits.temperature;
    this.slurryFlowUnit =this.userUnits.slurryFlow;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this._defaultPipeRoughness = 0.001968;
    if (this.userUnits.shortLengths == 'in') {
      this.pipeRoughnessValidation = { min: 0, max: 0.25 };
    } else if (this.userUnits.shortLengths == 'cm') {
      this.pipeRoughnessValidation = { min: 0, max: 0.6 };
      this._defaultPipeRoughness = 0.00499;
    } else {
      this.pipeRoughnessValidation = { min: 0, max: 6.3 };
      this._defaultPipeRoughness = 0.0499;
    }

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 500 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.77, max: 260 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255.37, max: 533.15 };
        break;
    }

    switch (this.slurryFlowUnit) {
      case 'bbl/min':
        this.slurryFlowRateValidation = { min: 0, max: 1000 };
        break;
      case 'm³/d':
        this.slurryFlowRateValidation = { min: 0, max: 228942 };
        break;
    }

    this.standardFluidFlowUnit = Units.lib[this.userUnits.standardFluidFlow].symbol;
    if (this.userUnits.standardFluidFlow == 'GPM') {
      this.displacementRateValidation = { min: 0.1, max: 1500 };
    } else {
      this.displacementRateValidation = { min: 0.0003, max: 5.6 };
    }

    this.form.formGroup.controls.displacementRate.setValidators([Validators.required, Validators.min(this.displacementRateValidation.min), Validators.max(this.displacementRateValidation.max)]);
    this.form.formGroup.controls.inletTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    this.form.formGroup.controls.slurryInletTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    this.form.formGroup.controls.slurryPumpRate.setValidators([Validators.required, Validators.min(this.slurryFlowRateValidation.min), Validators.max(this.slurryFlowRateValidation.max)]);

    this.getData(false);
  }

  signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_DRILL_STRING || data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.getData(true);
    }
  }

  ngAfterViewInit() {
    if (this.form.formGroup.controls.pipeRoughness.value == null) {
      this.form.formGroup.controls.pipeRoughness.patchValue(this._defaultPipeRoughness, { emitEvent: false });
    }
    
    let defaultFluid = this.fluids ? this.fluids.find(x => x.id === this.annularFluidId) : null;
    let controls = this.form.formGroup.controls;
    if (!controls.drillingFluid.value) { this.form.formGroup.controls.drillingFluid.setValue(defaultFluid); }
    if (!controls.fluid.value) { this.form.formGroup.controls.fluid.setValue(defaultFluid); }
    if (!controls.slurry.value) { this.form.formGroup.controls.slurry.setValue(this.slurries[0]); }
    if (!controls.leadSlurry.value) { this.form.formGroup.controls.leadSlurry.setValue(this.slurries[0]); }
  }

  private getData(calledFromSignalR: boolean) {
    const sources = [
      this._wellTypeService.getWellType() as Observable<any>,
      this._drillstringService.getDrillstringForTubular()
    ];

    forkJoin(sources).pipe(
      map(([wellType, drillstring]) => {
        this.drillPipe = drillstring['drillPipe'];
        this.drillpipeMissing = !this.drillPipe;

        this.showDrillPipeData = wellType.type == 'Subsea' || this.currentString.type == 'Liner' ? true : null;
        this.showRiser = wellType.type == 'Subsea' && this.currentTubularIndex > 0 ? true : null;
        this.selectedDrillPipe = this.drillPipe ?
          `Landing String: OD: ${this.drillPipe.outsideDiameter}", Weight: ${this.drillPipe.weightPerFoot}#, ID: ${this.drillPipe.insideDiameter}"` :
          'Please Select a Landing String';

        if (calledFromSignalR) {
          return;
        }

        if (this.form.formGroup.controls.length.value > 0) {
          this.onTailTrue(null);
        } else {
          this.onTailFalse(null);
        }

        this.cementLength = +(this.currentString.shoeMd - this.currentString.topOfCementMd).toFixed(2);
        this.form.formGroup.controls.length.setValidators([Validators.required, Validators.min(0), Validators.max(this.cementLength)]);

        this.form.formGroup.controls.inletTemperature
          .setValue((this.form.formGroup.value.inletTemperature ?? this.udtProfile[0].temperature), { emitEvent: false });
        this.form.formGroup.controls.slurryInletTemperature
          .setValue((this.form.formGroup.value.slurryInletTemperature ?? this.udtProfile[0].temperature), { emitEvent: false });
        // this.formGroupControls.leadSpacerInletTemperature
        //   .setValue((this.formGroupValues.leadSpacerInletTemperature ?? res.udt.surfaceAmbientTemperature), {emitEvent: false});
        // this.formGroupControls.displacementInletTemperature
        //   .setValue((this.formGroupValues.displacementInletTemperature ?? res.udt.surfaceAmbientTemperature), {emitEvent: false});
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public onTailChange(e) {
    if (e['srcElement']['checked']) {
      this.onTailTrue(true);
    } else {
      this.onTailFalse(false);
    }
  }

  onTailTrue(checked: boolean) {
    this.hasTailSlurry = false;
    this.form.formGroup.controls.length.enable({ emitEvent: false });
    if (!this.form.formGroup.controls.length.value && checked && checked !== null) {
      this.form.formGroup.controls.length.setValue(this.userUnits.longLengths === 'ft' ? 100 : 30);
    }
  }

  onTailFalse(checked: boolean) {
    this.hasTailSlurry = true;
    this.form.formGroup.controls.length.disable({ emitEvent: false });
    if (!checked && checked !== null) {
      this.form.formGroup.controls.length.setValue(0);
    }
  }

  ngOnDestroy(): void {
      this.signalRfunc = null;
  }
}
