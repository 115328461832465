import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subscription, lastValueFrom } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { WellheadService } from '../../services/wellhead.service';
import { WellheadModel } from './wellhead.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetForceValueFromLbf, GetShortLengthValueFromInches } from 'src/app/perivis/shared/helpers/units.helper';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-wellhead',
  templateUrl: './wellhead.component.html',
  styleUrls: ['./wellhead.component.scss']
})
export class WellheadComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  public wellheadForm: FormGroup;
  public wellheadDisabled: boolean;
  public currentStringName: string;
  public innerDiameterUnit: string;
  public forceUnit: string;
  public isLoading: boolean;
  public configMissing: boolean;

  // State
  @Input()
  private componentId: string;

  constructor(
    private _store: StoreService,
    private _formBuilder: FormBuilder,
    private _signalRService: SignalRService,
    private _wellheadService: WellheadService,
    private _wellConfigService: WellConfigService
  ) {
    this._subscriptions = new Subscription();

    this.wellheadForm = this._formBuilder.group({
      tubularStringId: new FormControl(null),
      wellheadInnerDiameter: new FormControl({ value: null, disabled: this.wellheadDisabled }, Validators.required),
      hangerLockdownRating: new FormControl({ value: null, disabled: this.wellheadDisabled }, Validators.required)
    });
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const units = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.innerDiameterUnit = units.shortLengths;
    this.forceUnit = Units.lib[units.force].symbol;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getData();

    const debouncedOnChange = this.wellheadForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged());
    this._subscriptions.add(debouncedOnChange.subscribe(v => this.onFormChange(v)));
  }

  signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.isLoading = true;
      this.getData();
    }
  }

  private getData() {
    this._wellConfigService.getSelectedTubularId().subscribe({
      next: (currentString) => {
        if (currentString?.id !== undefined) {
          const sources: Observable<any>[] = [
            this._wellheadService.getWellheadForTubular(),
            this._wellConfigService.getTubulars()
          ];

          forkJoin(sources).pipe(
            map(([wellhead, tubulars]) => {
              this.wellheadForm.controls.tubularStringId.setValue(currentString.id, { emitEvent: false });
              const stringIndex = tubulars.findIndex(x => x.id === currentString.id);
              const unitLabel = this.innerDiameterUnit === 'in' ? '"' : this.innerDiameterUnit;
              const tubular = tubulars[stringIndex];
              this.currentStringName = `Current string: ${tubular.stringSections[0].pipe.outsideDiameter}${unitLabel} ${tubular.type}`;

              this.wellheadDisabled = stringIndex === 0 || tubulars[stringIndex].type === 'Liner';

              if (this.wellheadDisabled) {
                this.wellheadForm.controls.wellheadInnerDiameter.disable();
                this.wellheadForm.controls.hangerLockdownRating.disable();
              } else {
                this.wellheadForm.controls.wellheadInnerDiameter.enable();
                this.wellheadForm.controls.hangerLockdownRating.enable();
              }

              if (!wellhead.id) {
                const wellheadDefault = wellhead.currentStringOd < GetShortLengthValueFromInches(18.625, this.innerDiameterUnit) ?
                  GetShortLengthValueFromInches(18.75, this.innerDiameterUnit) : GetShortLengthValueFromInches(28, this.innerDiameterUnit);

                this.wellheadForm.controls.wellheadInnerDiameter.patchValue(wellheadDefault);
                this.wellheadForm.controls.hangerLockdownRating.patchValue(GetForceValueFromLbf(500000, this.forceUnit));
                this.wellheadForm.controls.hangerLockdownRating.markAsDirty();
                this.onFormChange(this.wellheadForm.value, true);
              } else {
                this.wellheadForm.patchValue(wellhead);
              }

              this.isLoading = false;
            }),
            catchError(err => {
              this.isLoading = false;
              return err;
            })
          ).subscribe();
        } else {
          this.configMissing = true;
          this.wellheadDisabled = false;
          this.isLoading = false;
          return;
        }
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  private async onFormChange(formData: WellheadModel, isOnInit?: boolean) {
    if (this.wellheadForm.controls.hangerLockdownRating.pristine && this.wellheadForm.controls.wellheadInnerDiameter.pristine) {
      return;
    }
    if (this.wellheadForm.valid && (!this.isLoading || isOnInit)) {
      await lastValueFrom(this._wellheadService.updateWellheadForTubular(formData));
    }
  }

  get isDialogVisible(): boolean {
    return this.wellheadDisabled || this.configMissing;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this.signalRfunc = null;
  }

}
