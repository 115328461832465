<div class="ng-table-results">
  <p-table
    #dataTable
    responsiveLayout="stack"
    [scrollable]="true"
    [scrollHeight]="tableHeight"
    styleClass="p-datatable-gridlines"
    [columns]="columnDefinitions"
    [reorderableColumns]="true"
    [exportFilename]="tableName"
    selectionMode="multiple"
    [value]="tableData"
    range-selection
    (rangeChanged)="onRangeChanged($event)"
    [virtualScroll]="virtualScroll"
    [virtualScrollItemSize]="20"
  >
    <ng-template pTemplate="header" let-columns>
      <!-- <tr>
          <th class="row-index">
          </th>
          <th [attr.colspan]='columnDefinitions.length'>
            <span>{{tableName}}</span>
          </th>
        </tr> -->
      <tr>
        <th class="row-index align-content-center">
          <div>
            <p-splitButton
              label=""
              pTooltip="Copy Table"
              icon="pi pi-copy"
              (onClick)="copyTable(columns)"
              [model]="menuItems"
              styleClass="p-button-secondary p-button-sm"
              appendTo="body"
            ></p-splitButton>
            <div class="inputSwitch">
              <p-inputSwitch
                [ngModel]="true"
                pTooltip="Include headers when copying"
                (onChange)="headers($event)"
                binary="true"
              ></p-inputSwitch>
            </div>
          </div>
        </th>
        <th
          (click)="selectColumn(col, columns)"
          *ngFor="let col of columns"
          pReorderableColumn
          class="align-items-center"
        >
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-rowData
      let-rIdx="rowIndex"
      let-columns="columns"
    >
      <tr (click)="clearColumnData()">
        <td class="row-index">{{ rIdx + 1 }}</td>
        <td *ngFor="let col of columnDefinitions">
          <!-- Connection -->
          <div *ngIf="tableName == 'Connection Safety Factor Results'">
            <div
              [ngClass]="{
                belowDesignFactor:
                    ((col.field == 'burstLeak' || selectedOperation['value'] == 'burstLeak') && col.field != 'measuredDepth' &&
                    col.field != 'trueVerticalDepth' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['burstLeak'] - 0.000001) ||

                    ((col.field == 'collapse' || selectedOperation == 'collapse') && col.field != 'measuredDepth' &&
                    col.field != 'trueVerticalDepth' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['collapse'] - 0.000001) ||

                    ((col.field == 'axial' || selectedOperation['value'] == 'axial') && col.field != 'measuredDepth' &&
                    col.field != 'trueVerticalDepth' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        (designFactors['tension'] < designFactors['compression']
                          ? designFactors['tension']
                          : designFactors['compression']) -
                          0.000001)
                        }"
            >
              {{ col.valueFormatter(rowData[col.field]) }}
            </div>
          </div>

          <!-- Pipe -->
          <div
            *ngIf="
              tableName != 'Thermal Results - Temperatures' &&
              tableName != 'Thermal Results - Pressures' &&
              tableName != 'Connection Safety Factor Results' &&
              tableName != 'Annular Results'
            ">
            <div
              [ngClass]="{
                belowDesignFactor:
                  (!rowData[col.field]?.toString().includes('B') && col.field == 'barlowBurst' &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      designFactors['barlowBurst'] - 0.000001) ||

                  (!rowData[col.field]?.toString().includes('B') && col.field == 'pureAxial' &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      (designFactors['pureTension'] <
                      designFactors['pureCompression']
                        ? designFactors['pureTension']
                        : designFactors['pureCompression']) -
                        0.000001) ||

                  (!rowData[col.field]?.toString().includes('B') && col.field == apiCollapseType &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      designFactors['apiCollapse'] - 0.000001) ||

                  (!rowData[col.field]?.toString().includes('B') && col.field == 'vonMisesEquivalent' &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      designFactors['vonMisesCollapse'] - 0.000001) ||

                    (!rowData[col.field]?.toString().includes('B') && col.field != 'measuredDepth' &&
                      col.field != 'trueVerticalDepth' &&
                      selectedOperation == 'barlowBurst' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['barlowBurst'] - 0.000001) ||

                    (!rowData[col.field]?.toString().includes('B') && col.field != 'measuredDepth' &&
                      col.field != 'trueVerticalDepth' &&
                      selectedOperation == 'apiCollapse' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['apiCollapse'] - 0.000001) ||
                        
                    (!rowData[col.field]?.toString().includes('B') && col.field != 'measuredDepth' &&
                      col.field != 'trueVerticalDepth' &&
                      selectedOperation == 'pureAxial' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        (designFactors['pureTension'] <
                        designFactors['pureCompression']
                          ? designFactors['pureTension']
                          : designFactors['pureCompression']) -
                          0.000001) ||

                    (!rowData[col.field]?.toString().includes('B') && col.field != 'measuredDepth' &&
                      col.field != 'trueVerticalDepth' &&
                      selectedOperation == 'vonMisesEquivalent' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['vonMisesCollapse'] - 0.000001) ||

                  (col.field == 'hangerLiftoffForce' &&
                    rowData[col.field] * -1 > hangerLiftoffForce)
              }"
              *ngIf="col.valueFormatter"
            >
              {{ col.valueFormatter(rowData[col.field]) }}
            </div>
          </div>

          <!-- Ann results -->
          <div 
            *ngIf="
            tableName === 'Annular Results' ||
            tableName === 'Thermal Results - Pressures'">
            <div>
              <div *ngIf="col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
              <div *ngIf="!col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
            </div>
          </div>

          <!-- Thermal -->
          <div *ngIf="tableName === 'Thermal Results - Temperatures'">
            <div
              [ngStyle]="{
                color: getColorForTemperature(rowData[col.field], col.field)
              }"
            >
              <div *ngIf="col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
              <div *ngIf="!col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
