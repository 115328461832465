import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { Fluid } from 'src/app/perical/models/fluid.model';
import {
  CementingOperation,
  CirculationOperation,
  DrillingOperation,
  GasLiftOperation,
  InjectionOperation,
  MudPit,
  OperationType,
  ProductionOperation,
  RunCasingAndCirculate,
  ShutInOperation,
  SingleOperation,
  TripPipeAndCirculate
} from 'src/app/perical/models/thermal-operation.model';
import { FluidsService } from 'src/app/perical/services/fluids.service';
import { ThermalOperationsService } from 'src/app/perical/services/thermal-operations.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { Perforation } from 'src/app/perical/models/perforation.model';
import { UdtService } from 'src/app/shared/services/udt.service';
import { User } from 'src/app/core/components/user-admin-page/user-model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

export interface SingleOperationForm {
  type: OperationType;
  productionOperation: ProductionOperation;
  injectionOperation: InjectionOperation;
  shutInOperation: ShutInOperation;
  circulationOperation: CirculationOperation;
  gasLiftOperation: GasLiftOperation;
  drillingOperation: DrillingOperation;
  cementingOperation: CementingOperation;
  runCasingAndCirculate: RunCasingAndCirculate;
  tripPipeAndCirculate: TripPipeAndCirculate;
  mudPit: MudPit;
}

@Component({
  selector: 'app-single-operation',
  templateUrl: './single-operation.component.html',
  styleUrls: ['./single-operation.component.scss'],
  providers: subformComponentProviders(SingleOperationComponent)
})
export class SingleOperationComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private _fluidsService: FluidsService,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService,
    private _thermalOperationsService: ThermalOperationsService,
    private _udtService: UdtService
  ) {
    this.setOperationTypes();
  }

  public operationTypes: any[];
  public OperationType: typeof OperationType = OperationType;
  public isLoading: boolean;
  public fluids$: Fluid[];
  public annularFluidId: string;
  public mudPitsEnabled: boolean;
  public perforations: Perforation[];
  public surfaceTemperature: number;
  public currentString: any;
  public udtProfile: any;
  public currentTubularIndex: number;
  public tubulars: any;
  public tubingShoe: number;

  @Input()
  public isReadOnly: boolean;

  @Input()
  public user: User;

  @Input()
  public units: UserUnitsModel;

  @Input() set typeInput(typeInput: any) {
    if (typeInput.source === 'annulus' && typeInput.type.value === 'circulation') {
      switch (this.tabType) {
        case 'inside': {
          this.form.formGroup.controls.type.setValue(OperationType.CIRCULATION, { emitEvent: false });
        }
        case 'annulus': {
          this.form.formGroup.controls.type.setValue(null, { emitEvent: false });
        }
      }
    } else if (typeInput.source === 'inside' && typeInput.type.value === 'circulation') {
      if (this.tabType === 'annulus') {
        this.form.formGroup.controls.type.setValue(null, { emitEvent: false });
      }
    } else if (!this.form.formGroup?.value.type && (typeInput.type?.value === 'production' || typeInput.type?.value === 'injection' || typeInput.type?.value === 'shutIn')) {
      if (typeInput.source === 'inside' && this.tabType === 'annulus') {
        this.form.formGroup.controls.type.setValue(OperationType.SHUTIN, { emitEvent: false });
      } else if (typeInput.source === 'annulus' && this.tabType === 'inside') {
        this.form.formGroup.controls.type.setValue(OperationType.SHUTIN, { emitEvent: false });
      }
    }

    // if (this.tabType == 'annulus' && !this.operationTypes.some(x => x.value === "gasLift")) {
    //   this.operationTypes.push({ name: 'Gas Lift', value: 'gasLift' });
    // }
  }

  @Input() tabType: string;
  @Input() hideShutIn: boolean;
  @Output() typeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() mudPitsUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() drillingDepthRangeUpdate = new EventEmitter<{ startDepth: number, endDepth: number }>();

  public form = createForm<SingleOperation, SingleOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null, [Validators.required]),
      productionOperation: new UntypedFormControl(null),
      injectionOperation: new UntypedFormControl(null),
      shutInOperation: new UntypedFormControl(null),
      circulationOperation: new UntypedFormControl(null),
      gasLiftOperation: new UntypedFormControl(null),
      drillingOperation: new UntypedFormControl(null),
      cementingOperation: new UntypedFormControl(null),
      runCasingAndCirculate: new UntypedFormControl(null),
      tripPipeAndCirculate: new UntypedFormControl(null),
      mudPit: new UntypedFormControl(null)
    },
    toFormGroup: (obj: SingleOperation | null): SingleOperationForm | null => {
      if (!obj) {
        return null;
      }

      const { type, ...commonValues } = obj;

      if (type === 'drilling') {
        this.drillingDepthRangeUpdate.emit({ startDepth: obj['startDepth'], endDepth: obj['endDepth'] });
      }

      return {
        productionOperation: obj.type === OperationType.PRODUCTION ? obj : null,
        injectionOperation: obj.type === OperationType.INJECTION ? obj : null,
        shutInOperation: obj.type === OperationType.SHUTIN ? obj : null,
        circulationOperation: obj.type === OperationType.CIRCULATION ? obj : null,
        gasLiftOperation: obj.type === OperationType.GASLIFT ? obj : null,
        drillingOperation: obj.type === OperationType.DRILLING ? obj : null,
        cementingOperation: obj.type === OperationType.CEMENTING ? obj : null,
        runCasingAndCirculate: obj.type === OperationType.RUNCASINGANDCIRCULATE ? obj : null,
        tripPipeAndCirculate: obj.type === OperationType.TRIPPIPEANDCIRCULATE ? obj : null,
        mudPit: obj.type === OperationType.MUDPIT ? obj : null,
        type: obj.type
      };
    },
    fromFormGroup: (formValue: SingleOperationForm): SingleOperation | null => {
      const { productionOperation, injectionOperation, shutInOperation, circulationOperation, gasLiftOperation, drillingOperation, mudPit, cementingOperation, runCasingAndCirculate, tripPipeAndCirculate, type, ...commonValues } = formValue;

      switch (type) {
        case OperationType.PRODUCTION:
          return productionOperation ? { type, ...productionOperation, ...commonValues } : null;
        case OperationType.INJECTION:
          return injectionOperation ? { type, ...injectionOperation, ...commonValues } : null;
        case OperationType.SHUTIN:
          return shutInOperation ? { type, ...shutInOperation, ...commonValues } : null;
        case OperationType.CIRCULATION:
          return circulationOperation ? { type, ...circulationOperation, ...commonValues } : null;
        case OperationType.GASLIFT:
          return gasLiftOperation ? { type, ...gasLiftOperation, ...commonValues } : null;
        case OperationType.DRILLING:
          return drillingOperation ? { type, ...drillingOperation, ...commonValues } : null;
        case OperationType.CEMENTING:
          return cementingOperation ? { type, ...cementingOperation, ...commonValues } : null;
        case OperationType.RUNCASINGANDCIRCULATE:
          return runCasingAndCirculate ? { type, ...runCasingAndCirculate, ...commonValues } : null;
        case OperationType.TRIPPIPEANDCIRCULATE:
          return tripPipeAndCirculate ? { type, ...tripPipeAndCirculate, ...commonValues } : null;
        case OperationType.MUDPIT:
          return mudPit ? { type, ...mudPit, ...commonValues } : null;
        case null:
          return null;
        default:
        // throw new UnreachableCase(type);
      }
    }
  });

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.filterOperations();
      
    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  private signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_FLUIDS) {
      this._fluidsService.getFluids().subscribe(fluids => {
        this.fluids$ = fluids;
      });
    }
    if (data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_PERFORATIONS) {
      this.filterOperations();
    }
  }

  private setOperationTypes() {
    this.operationTypes = [
      { name: 'Production', value: 'production' },
      { name: 'Injection', value: 'injection' },
      { name: 'Circulation', value: 'circulation' },
      { name: 'Trip Pipe & Circulate', value: 'tripPipeAndCirculate' },
      { name: 'Run Casing & Circulate', value: 'runCasingAndCirculate' },
      { name: 'Drilling', value: 'drilling' },
      { name: 'Cementing', value: 'cementing' },
      { name: 'Shut In', value: 'shutIn' }
    ];
  }

  onDrillingDepthRangeChange(depthRange) {
    this.drillingDepthRangeUpdate.emit({ startDepth: depthRange.startDepth, endDepth: depthRange.endDepth });
  }

  private filterOperations() {
    this.setOperationTypes();
    const sources: Observable<any>[] = [
      this._thermalOperationsService.getPerforations(),
      this._wellConfigService.getTubulars() as Observable<any>,
      this._wellConfigService.getSelectedTubularId() as Observable<any>,
      this._udtService.getUndisturbedTemperaturePoints(),
      this._fluidsService.getFluids() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([perforations, tubulars, selectedString, udt, fluids]) => {
        this.currentString = tubulars.find(x => x.id === selectedString.id);
        this.annularFluidId = this.currentString['annularFluidId'];

        this.tubingShoe = tubulars[tubulars.length - 1].shoeMd;
  
        this.fluids$ = fluids;
        this.tubulars = tubulars;
        this.currentTubularIndex = tubulars.findIndex(x => x.id === this.currentString.id);
        const removeCount = tubulars.length - this.currentTubularIndex - 1;
        tubulars.splice(this.currentTubularIndex + 1, removeCount);
        const deepestShoe = Math.max(...tubulars.map(x => x.shoeMd));
        this.perforations = perforations.filter(x => x.measuredDepth <= deepestShoe).sort((a, b) => (a.measuredDepth < b.measuredDepth ? -1 : 1));
  
        this.udtProfile = udt;
        this.surfaceTemperature = udt[0].temperature;
  
        if (this.currentString['hasCement']) {
          this.operationTypes = this.operationTypes.filter(x => x.value != 'circulation');
        } else {
          this.operationTypes = this.operationTypes.filter(x => x.value != 'cementing');
        }
  
        if (this.currentString['type'] == 'Tubing') {
          this.operationTypes = this.operationTypes.filter(x => x.value != 'drilling');
          this.operationTypes = this.operationTypes.filter(x => x.value != 'tripPipeAndCirculate');
          this.operationTypes = this.operationTypes.filter(x => x.value != 'runCasingAndCirculate');
        }
  
        if (this.currentString['type'] == 'Tieback') {
          this.operationTypes = this.operationTypes.filter(x => x.value != 'cementing');
        }
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hideShutIn'] && changes['hideShutIn'].currentValue) {
      this.operationTypes = this.operationTypes.filter(x => x.value != 'shutIn');
    } else {
      if (!this.operationTypes.find(x => x.value == 'shutIn')) {
        this.operationTypes.push({ name: 'Shut In', value: 'shutIn' });
      }
    }
  }

  onTypeChanged(e) {
    this.typeChanged.emit({
      type: this.form.formGroup.controls.type,
      source: this.tabType
    });
  }

  enableDisableMudPits(e) {
    this.mudPitsUpdate.emit(e.checked);
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }  
}
