<p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div class="fluids">
      <p-confirmDialog
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
      ></p-confirmDialog>

      <p-dialog
        modal="true"
        [(visible)]="phaseEnvelopeCalculating"
        [draggable]="false"
        [closable]="false"
      >
        <i class="pi pi-exclamation-circle"></i> Please wait while Phase
        Envelope is being calculated <br />
        <br />
      </p-dialog>
      <p-button
        pTooltip="{{
          fluidsStore.state?.drawerOpen ? 'Collapse List' : 'Expand List'
        }}"
        class="collapseButton"
        icon="{{
          fluidsStore.state?.drawerOpen
            ? 'pi pi-angle-double-left'
            : 'pi pi-angle-double-right'
        }}"
        (onClick)="drawerToggle()"
      ></p-button>
      <p-dropdown
        *ngIf="!fluidsStore.state?.drawerOpen"
        [options]="fluids"
        [(ngModel)]="fluidsStore.state.selectedFluid"
        dataKey="id"
        appendTo="body"
        (onChange)="onFluidSelect($event)"
      >
        <ng-template pTemplate="selectedItem">
          {{ fluidsStore.state?.selectedFluid.state.name }}
        </ng-template>
        <ng-template let-fluid pTemplate="item">
          {{ fluid.state.name }}
        </ng-template>
      </p-dropdown>
      <div class="grid">
        <div class="flex">
          <div class="drawer" *ngIf="fluidsStore.state?.drawerOpen">
            <div class="col-3">
              <p-listbox
                #listbox
                [options]="selectedFluids"
                [(ngModel)]="fluidsStore.state.selectedFluid"
                [group]="true"
                optionGroupChildren="fluids"
                (onChange)="onFluidSelect($event)"
              >
                <ng-template let-group pTemplate="group">
                  <div class="grid grid-nogutter flex align-items-center">
                    <div class="col-10">{{ findDisplayName(group.type) }}</div>
                    <div class="col-1">
                      <button
                        class="pi pi-plus p-button p-button-success p-button-text p-button-sm addBtn"
                        pTooltip="Add {{ findDisplayName(group.type) }}"
                        (click)="addFluid(group.type)"
                      ></button>
                    </div>
                    <div class="col-1">
                      <P-button
                        class="{{
                          group.fluids.length > 0
                            ? 'pi pi-angle-double-down collapseBtn'
                            : 'pi pi-angle-double-left collapseBtn'
                        }}"
                        pTooltip="{{
                          group.fluids.length > 0 ? 'Collapse' : 'Expand'
                        }} {{ findDisplayName(group.type) }}"
                        (click)="expandGroup(group.type)"
                      ></P-button>
                    </div>
                  </div>
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  <div class="grid grid-nogutter fluid-item align-items-center">
                    <div class="col-8">{{ fluid.state.name }}</div>
                    <div class="col-4" [style]="{ 'text-align': 'right' }">
                      <button
                        [disabled]="hideGergCalc"
                        *ngIf="fluid.state.co2withImpurities"
                        class="pi pi-play p-button p-button-text p-button-sm buttons"
                        pTooltip="Calculate Phase Envelope"
                        (click)="calcGerg(fluid)"
                      ></button>
                      <button
                        class="pi pi-copy p-button p-button-text p-button-sm p-button-info buttons"
                        pTooltip="Clone Fluid"
                        (click)="cloneFluid(fluid)"
                      ></button>
                      <button
                        *ngIf="!fluid?.isInUse && !checkDefaultFluid(fluid.state.name)"
                        class="pi pi-trash p-button p-button-text p-button-sm p-button-danger buttons"
                        pTooltip="Delete Fluid"
                        (click)="deleteFluid(fluid)"
                      ></button>
                      <button
                        [title]="checkDefaultFluid(fluid.state.name) ? 'Fluid cannot be deleted' : 'Fluid is used in the design'"
                        *ngIf="fluid?.isInUse || checkDefaultFluid(fluid.state.name)"
                        class="pi pi-lock p-button p-button-text p-button-sm p-button-danger buttons"
                      ></button>
                    </div>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>
          <div class="content">
            <div class="col">
              <div class="fluid">
                <app-fluid-data *ngIf="user"
                  [isReadOnly]="user?.roles?.readOnly"
                  [fluid]="fluidsStore.state?.selectedFluid"
                  (fluidUpdate)="updateOrCreateFluid($event)"
                  [disabled]="fluidsStore.state?.selectedFluid?.isInUse || (isDefaultFluid && fluidsStore.state?.selectedFluid?.state.name !== 'Seawater')"
                  [fluids]="fluids"
                  [phaseEnvelopeCalculated]="phaseEnvelopeCalculated"
                  [userUnits]="units"
                  [fluidsComponentHeight]="componentHeight"
                  [isAltus]="user?.profile.organization === 'Altus'"
                ></app-fluid-data>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-tool-loader>
</p-scrollPanel>
