import { Component, Input, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Flow } from 'src/app/perical/models/flow.model';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { OperationType, TripPipeAndCirculate } from 'src/app/perical/models/thermal-operation.model';
import { GetShortLengthValueFromInches } from 'src/app/perivis/shared/helpers/units.helper';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UndisturbedTemperature } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';
import { TubularString } from 'src/app/wellbore-inputs/models/well-schematic.model';
import { DrillstringService } from 'src/app/wellbore-inputs/services/drillstring.service';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';

export interface TripPipeAndCirculateForm {
  type: OperationType;
  fluid: Fluid;
  standardFluidFlow: Flow;
  tripIntoHole: number;
  circulationTime: number;
  outsideDiameter: number;
  insideDiameter: number;
  inletTemperature: number;
  pipeRoughness: number;
  isPullOutOfHole: boolean;
  isNextStringInPlace: boolean;
  pullOutOfHoleTime: number;
  circulationDepth: number;
  totalFlowArea: number;
  annulusIndex: number;
}

@Component({
  selector: 'app-trip-pipe-and-circulate-details',
  templateUrl: './trip-pipe-and-circulate-details.component.html',
  styleUrls: ['./trip-pipe-and-circulate-details.component.scss'],
  providers: subformComponentProviders(TripPipeAndCirculateDetailsComponent)
})
export class TripPipeAndCirculateDetailsComponent implements OnChanges, OnDestroy, AfterViewInit {

  constructor(
    private _wellTypeService: WellTypeService,
    private _drillstringService: DrillstringService,
    private _signalRService: SignalRService
  ) { }

  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[]) {
    this.fluids = fluids?.filter(f => f.state.type === FluidType.BRINE ||
      f.state.type === FluidType.STANDARDMUD ||
      f.state.type === FluidType.ADVANCEDMUD)
      .sort((a, b) => a.state.name.localeCompare(b.state.name));
  }

  public annularFluidId: string;
  @Input('annularFluidId') set _annularFluidId(annularFluidId: string) {
    this.annularFluidId = annularFluidId ?? null;
  }

  @Input()
  public isReadOnly: boolean;

  @Input()
  private udtProfile: UndisturbedTemperature;

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public currentString: TubularString;

  @Input()
  public currentTubularIndex: number;

  public operationType: string = 'tripPipeAndCirculate';
  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;
  public showDrillString: boolean;
  public drillStringMissing: boolean;
  public selectedHwdp: string;
  public hasHwdp: boolean;
  public hasCollar: boolean;
  public selectedCollar: string;
  public selectedDrillPipe: string;
  public poohDisabled: boolean;
  public showRiser: boolean;
  public currentShoe: number;
  public drillPipe: any;
  public hwdp: any;
  public collar: any;
  public tempUnit: string;
  public shortLengthUnit: string;
  public pipeRoughnessUnit: string;
  public weigthUnit: string;
  public temperatureValidation: { min: number, max: number };
  public pipeRoughnessValidation: { min: number, max: number };
  public areaUnit: string;
  public totalFlowAreaValidation: { min: number, max: number };
  
  private _defaultPipeRoughness: number;

  public form = createForm<TripPipeAndCirculate, TripPipeAndCirculateForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.TRIPPIPEANDCIRCULATE),
      fluid: new UntypedFormControl(null, [Validators.required]),
      standardFluidFlow: new UntypedFormControl(null, [Validators.required]),
      tripIntoHole: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100000)]),
      outsideDiameter: new UntypedFormControl(null, Validators.required),
      insideDiameter: new UntypedFormControl(null, Validators.required),
      pipeRoughness: new UntypedFormControl(null, [Validators.required]),
      circulationTime: new UntypedFormControl(null, [Validators.required]),
      isPullOutOfHole: new UntypedFormControl(false, [Validators.required]),
      inletTemperature: new UntypedFormControl(null),
      isNextStringInPlace: new UntypedFormControl(false, [Validators.required]),
      circulationDepth: new UntypedFormControl(null, [Validators.required]),
      pullOutOfHoleTime: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100000)]),
      totalFlowArea: new UntypedFormControl(null, Validators.required),
      annulusIndex: new UntypedFormControl(null)
    },
    toFormGroup: (obj: TripPipeAndCirculate | null): TripPipeAndCirculateForm | null => {
      if (!obj) {
        return null;
      }

      const { fluid, tripIntoHole, flow, circulationTime, pipeRoughness, riser, inletTemperature, isPullOutOfHole, isNextStringInPlace, pullOutOfHoleTime, totalFlowArea, ...commonValues } = obj;

      obj.fluid = this.fluids?.find(f => f.id === obj.fluid.id);

      this.poohChange(isPullOutOfHole, false, pullOutOfHoleTime > 0);

      return {
        type: obj.type,
        circulationTime,
        isNextStringInPlace,
        isPullOutOfHole,
        tripIntoHole,
        pipeRoughness,
        outsideDiameter: riser.outsideDiameter,
        insideDiameter: riser.insideDiameter,
        circulationDepth: obj.circulationDepth,
        pullOutOfHoleTime,
        totalFlowArea,
        fluid: obj.fluid,
        inletTemperature,
        standardFluidFlow: obj.flow,
        annulusIndex: obj.annulusIndex
      };
    },
    fromFormGroup: (formValue: TripPipeAndCirculateForm): TripPipeAndCirculate | null => {
      const { fluid, tripIntoHole, standardFluidFlow, pipeRoughness, outsideDiameter, insideDiameter, circulationTime, circulationDepth, inletTemperature, isPullOutOfHole, isNextStringInPlace, pullOutOfHoleTime, totalFlowArea, annulusIndex, ...commonValues } = formValue;

      return {
        type: OperationType.TRIPPIPEANDCIRCULATE,
        flow: standardFluidFlow,
        fluid,
        tripIntoHole,
        circulationTime,
        inletTemperature,
        pipeRoughness,
        isPullOutOfHole,
        riser: {
          outsideDiameter,
          insideDiameter
        },
        isNextStringInPlace,
        pullOutOfHoleTime,
        circulationDepth,
        totalFlowArea,
        annulusIndex
      };
    }
  });

  async ngOnChanges(): Promise<void> {
    this.isLoading = true;

    this.tempUnit = this.userUnits.temperature;
    this.shortLengthUnit = this.userUnits.shortLengths == 'in' ? '"' : this.userUnits.shortLengths;
    this.weigthUnit = this.userUnits.linearDensity == 'lb/ft' ? '#' : this.userUnits.linearDensity;
    this.areaUnit = this.userUnits.area;
    this.pipeRoughnessUnit = this.userUnits.shortLengths;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 500 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.77, max: 260 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255.37, max: 533.15 };
        break;
    }
    this.form.formGroup.controls.inletTemperature.setValidators([Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max), Validators.required]);

    this._defaultPipeRoughness = 0.001968;
    if (this.userUnits.shortLengths == 'in') {
      this.pipeRoughnessValidation = { min: 0, max: 0.25 };
    } else if (this.userUnits.shortLengths == 'cm') {
      this._defaultPipeRoughness = 0.00499;
      this.pipeRoughnessValidation = { min: 0, max: 0.6 };
    } else {
      this._defaultPipeRoughness = 0.0499;
      this.pipeRoughnessValidation = { min: 0, max: 6.3 };
    }
    this.form.formGroup.controls.pipeRoughness.setValidators([Validators.min(this.pipeRoughnessValidation.min), Validators.max(this.pipeRoughnessValidation.max), Validators.required]);

    if (this.areaUnit == 'in²') {
      this.totalFlowAreaValidation = { min: 0, max: 100 };
    } else {
      this.totalFlowAreaValidation = { min: 0, max: 254 };
    }
    this.form.formGroup.controls.totalFlowArea.setValidators([Validators.min(this.totalFlowAreaValidation.min), Validators.max(this.totalFlowAreaValidation.max), Validators.required]);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getTripPipeData();
  }

  signalRfunc(data: any){
    if ((data.tubularStringId && data.tubularStringId !== this.currentString.id)) {
      return;
    }
    if (data.action === PeriforOnChangeMessages.REFRESH_DRILL_STRING || data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.getTripPipeData();
    }
  }

  ngAfterViewInit(): void {
    if (this.form.formGroup.controls.pipeRoughness.value == null) {
      this.form.formGroup.controls.pipeRoughness.patchValue(this._defaultPipeRoughness, { emitEvent: false });
    }
    let defaultFluid = this.fluids ? this.fluids.find(x => x.id === this.annularFluidId) : null;
    if (!this.form.formGroup.controls.fluid.value) {
      this.form.formGroup.controls.fluid.setValue(defaultFluid);
    }
  }

  private getTripPipeData() {
    const sources: Observable<any>[] = [
      this._drillstringService.getDrillstringForTubular() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([drillstring, wellType]) => {
        this.drillPipe = drillstring['drillPipe'];
        this.hwdp = drillstring['heavyWeightDrillPipe'];
        this.collar = drillstring['collar'];
        this.drillStringMissing = !this.drillPipe && (drillstring.hwdpLength ?? 0) == 0 && (drillstring.collarLength ?? 0) == 0;

        this.currentShoe = this.currentString.shoeMd;

        let drillPipeLength = +((this.form.formGroup.controls.circulationDepth.value ?? this.currentShoe) - drillstring.collarLength - drillstring.hwdpLength).toFixed(2);
        this.selectedDrillPipe = this.drillPipe ?
          `Drill String: OD: ${this.drillPipe.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.drillPipe.weightPerFoot}${this.weigthUnit}, ID: ${this.drillPipe.insideDiameter}${this.shortLengthUnit}, Length: ${drillPipeLength} ${this.userUnits.longLengths}` : null;
        this.selectedHwdp = this.hwdp ? `HWDP: OD: ${this.hwdp.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.hwdp.weightPerFoot}${this.weigthUnit}, ID: ${this.hwdp.insideDiameter}${this.shortLengthUnit}, Length: ${drillstring.hwdpLength} ${this.userUnits.longLengths}` : null;
        this.selectedCollar = this.collar ? `Collar: OD: ${this.collar.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.collar.weightPerFoot}${this.weigthUnit}, ID: ${this.collar.insideDiameter}${this.shortLengthUnit}, Length: ${drillstring.collarLength} ${this.userUnits.longLengths}` : null;
        this.hasHwdp = drillstring.hwdpLength > 0;
        this.hasCollar = drillstring.collarLength > 0;

        if (!this.hasCollar && !this.hasHwdp && !this.drillPipe) {
          this.selectedDrillPipe = 'Please specify a drill string';
        }

        this.showRiser = wellType.type == 'Subsea' && this.currentTubularIndex > 0 ? true : null;


        this.form.formGroup.controls.circulationDepth
          .setValue((this.form.formGroup.value.circulationDepth ?? this.currentShoe), { emitEvent: false });

        this.form.formGroup.controls.circulationDepth.setValidators([Validators.max(this.currentShoe)]);

        this.form.formGroup.controls.inletTemperature
          .setValue((this.form.formGroup.value.inletTemperature ?? this.udtProfile[0].temperature), { emitEvent: false });

        this.form.formGroup.controls.totalFlowArea
          .setValue((this.form.formGroup.value.totalFlowArea ? this.form.formGroup.value.totalFlowArea : this.areaUnit == 'in²' ? 1 : 6.45), { emitEvent: false });

        this.form.formGroup.controls.outsideDiameter
        .setValue((this.form.formGroup.value.outsideDiameter ?? GetShortLengthValueFromInches(21, this.userUnits.shortLengths)), { emitEvent: false });

        this.form.formGroup.controls.insideDiameter
          .setValue((this.form.formGroup.value.insideDiameter ?? GetShortLengthValueFromInches(19.75, this.userUnits.shortLengths)), { emitEvent: false });

        this.poohChange(this.form.formGroup.controls.isPullOutOfHole, false, this.form.formGroup.controls.pullOutOfHoleTime.value > 0);
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public poohChange(e, checkboxChange, greaterThanZero?) {
    if (checkboxChange || (!checkboxChange && (e?.checked ?? e?.value ?? false)) || greaterThanZero) {
      this.poohDisabled = false;
    } else {
      this.poohDisabled = true;
    }

    if (checkboxChange && !e?.checked) {
      this.form.formGroup.controls.pullOutOfHoleTime.setValue(0);
    } else if (checkboxChange && e?.checked) {
      this.form.formGroup.controls.pullOutOfHoleTime.setValue(10);
    }
  }

  ngOnDestroy(): void {
      this.signalRfunc = null
  }

}
