import { Component, OnInit } from '@angular/core';
import { UserUnitsModel } from './user-units.model';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { lastValueFrom } from 'rxjs';
import { Units } from '../../services/unit-library';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users-service';
import { StorageKeys, StoreService } from '../../services/store.service';

@Component({
  selector: 'app-user-units',
  templateUrl: './user-units.component.html',
  styleUrls: ['./user-units.component.scss'],
  providers: [UsersService]
})
export class UserUnitsComponent implements OnInit {
  public units: UserUnitsModel;
  public readonly shortLengthUnitOptions = Units.getSelectList(Units.shortLengths());
  public readonly longLengthUnitOptions = Units.getSelectList(Units.longLengths());
  public readonly dlsUnitOptions = Units.getSelectList(Units.doglegSeverity());
  public readonly tempGradientOptions = Units.getSelectList(Units.temperatureGradient());
  public readonly linearDensityUnitOptions = Units.getSelectList(Units.linearDensity());
  public readonly temperatureUnitOptions = Units.getSelectList(Units.temperature());
  public readonly pressureUnitOptions = Units.getSelectList(Units.pressure());
  public readonly stressPressureUnitOptions = Units.getSelectList(Units.stressPressure());
  public readonly forceUnitOptions = Units.getSelectList(Units.force());
  public readonly speedUnitOptions = Units.getSelectList(Units.speed());
  public readonly densityUnitOptions = Units.getSelectList(Units.density());
  public readonly standardFluidFlowUnitOptions = Units.getSelectList(Units.standardFluidFlow());
  public readonly hydrocarbonFlowUnitOptions = Units.getSelectList(Units.hydrocarbonFlow());
  public readonly volumeUnitOptions = Units.getSelectList(Units.volume());
  public readonly areaUnitOptions = Units.getSelectList(Units.area());
  public readonly thermalConductivityUnitOptions = Units.getSelectList(Units.thermalConductivity());
  public readonly thermalExpansionCoeffUnitOptions = Units.getSelectList(Units.coefficientOfThermalExpansion());
  public readonly gasInjectionRateUnitOptions = Units.getSelectList(Units.gasInjectionRate());
  public readonly solidsDensityUnitOptions = Units.getSelectList(Units.solidsDensity());
  public readonly massFlowOptions = Units.getSelectList(Units.massFlow());
  public readonly specificHeatCapacityUnitOptions = Units.getSelectList(Units.specificHeatCapacity());
  public readonly slurryFlowRateOptions = Units.getSelectList(Units.slurryFlow());
  public readonly gasGradientOptions = Units.getSelectList(Units.gasGradient());
  public readonly enthalpyOptions = Units.getSelectList(Units.enthalpy());
  public readonly heatOptions = Units.getSelectList(Units.heat());
  public readonly torqueOptions = Units.getSelectList(Units.torque());

  constructor(
    private _usersService: UsersService,
    private _toaster: AppNotificationService,
    private _storeService: StoreService,
    private _router: Router
  ) { }

  async ngOnInit() : Promise<void> {
    this.units = await this._storeService.get(StorageKeys.UNITS) || new UserUnitsModel();
  }

  async onUserMetadataFormSubmit() {
    let updated = await lastValueFrom(this._usersService.updateUserUnits(this.units));
    if (updated) {
      await this._storeService.set(StorageKeys.UNITS, this.units);
      this._toaster.showSuccess("Units updated");
      this._router.navigateByUrl('/designs');
    }
  }

  async onCancel() {
    this._router.navigateByUrl('/designs');
  }

  public resetToApi() {
    this.units = {
      longLengths: 'ft',
      shortLengths: 'in',
      doglegSeverity: 'd-ft',
      temperatureGradient: '∆°F/ft',
      linearDensity: 'lb/ft',
      temperature: '°F',
      pressure: 'psi',
      stressPressure: 'psi',
      force: 'lbf',
      speed: 'ft/s',
      density: 'ppg (U.S.)',
      standardFluidFlow: 'GPM',
      hydrocarbonFlow: 'bbl/d',
      volume: 'bbl',
      area: 'in²',
      thermalConductivity: 'BTU/h·ft·°F',
      coefficientOfThermalExpansion: '1/°F',
      gasInjectionRate: 'scfh',
      solidsDensity: 'lbm/ft³',
      massFlow: 'lb/h',
      specificHeatCapacity: 'BTU/ft³·°F',
      slurryFlow: 'bbl/min',
      gasGradient: 'psi/ft',
      enthalpy: 'BTU/lbm',
      heat: 'BTU',
      torque: 'lbf·ft'
    };
  }

  public resetToSi() {
    this.units = {
      longLengths: 'm',
      shortLengths: 'in',
      doglegSeverity: 'd-m',
      temperatureGradient: '∆°C/m',
      linearDensity: 'kg/m',
      temperature: '°C',
      pressure: 'bar',
      stressPressure: 'KPa',
      force: 'tf',
      speed: 'm/s',
      density: 'kg/m³',
      standardFluidFlow: 'm³/min',
      hydrocarbonFlow: 'm³/d',
      volume: 'm³',
      area: 'cm²',
      thermalConductivity: 'W/m·K',
      coefficientOfThermalExpansion: '1/°C',
      gasInjectionRate: 'Sm³/h',
      solidsDensity: 'kg/m³',
      massFlow: 't/d',
      specificHeatCapacity: 'J/m³·°C',
      slurryFlow: 'm³/d',
      gasGradient: 'kPa/m',
      enthalpy: 'J/kg',
      heat: 'J',
      torque: 'N·m'
    };
  }
}
