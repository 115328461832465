import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { StressResultsService } from '../../services/stress-results.service';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Subscription, catchError, forkJoin, map } from 'rxjs';
import { DesignFactorsService } from '../../services/design-factors.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { ConnectionSafetyFactorResultsUi } from '../../models/perivis-results-state.model';
import { Store } from '@ngneat/elf';
import { isEqual } from "lodash";

@Component({
  selector: 'app-single-load-connection-safety-factors',
  templateUrl: './single-load-connection-safety-factors.component.html',
  styleUrls: ['./single-load-connection-safety-factors.component.scss']
})
export class SingleLoadConnectionSafetyFactorsComponent implements OnInit, OnDestroy {
  private minimumResultType: string = 'summary';
  private previousView: string;
  private _connectionSafetyFactorResults: any[];
  private _subscriptions: Subscription;

  public plotName: string = 'connectionSafetyFactorsPlot';
  public axialResults: any[];
  public loadCases: any[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public modes: SelectItem[] = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' },
    { label: 'Minimum', value: 'minimum' }
  ];
  public errorMsg: string;
  public results: any[];
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public isLoading: boolean;
  public yAxisTitle: string = '';
  public xAxisTitle: string = 'Connection Safety Factor';
  public resultsBackup: any[];
  public designFactors: any;
  public userUnits: UserUnitsModel;
  public depthUnit: string;
  public tableHeight: string;
  public componentHeight: number;

  public types = [
    { label: 'Burst', value: 'burstLeak' },
    { label: 'Collapse', value: 'collapse' },
    { label: 'Axial', value: 'axial' }
  ]

  public columnDefs: Array<{ header: string, field: string }>;
  public columnDefinitions = this.setDepths();
  public signalRChange: boolean;

  @ViewChild('toolbarDiv', { static: false }) toolbarDiv: ElementRef;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.connectionSafetyFactorResultsStore = this._storeService.createStore(this.componentId, new ConnectionSafetyFactorResultsUi);
  }
  get componentId(): string {
    return this._componentId;
  }
  public connectionSafetyFactorResultsStore: Store;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _designFactorsService: DesignFactorsService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Connection Safety Factor Results")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 65;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.depthUnit = this.userUnits.longLengths;
    this.yAxisTitle = `MD (${this.userUnits.longLengths})`;

    this.refreshResults(true, false);

    if (!this.previousView && this.connectionSafetyFactorResultsStore.state.selectedMode) {
      this.previousView = this.connectionSafetyFactorResultsStore.state?.selectedMode;
    } else {
      this.previousView = 'single'
    }

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      //TODO Does this need to reset to single?
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: null }));
      this.refreshResults(true, true);
    }
  }

  public async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode?: boolean): Promise<void> {
    this.errorMsg = null;
    this.isLoading = isLoading;

    if ((!this._connectionSafetyFactorResults || calledFromSignalR) && !calledFromMode) {
      const sources = [
        this._stressResultsService.getAxialResults(),
        this._stressResultsService.getConnectionSafetyFactorResults(),
        this._designFactorsService.getDesignFactors()
      ];

      await forkJoin(sources).pipe(
        map(([axialResults, safetyFactorResults, designFactors]) => {
          let connectionSafetyFactorResults = safetyFactorResults;
          this.resultsBackup = safetyFactorResults;
          this.designFactors = designFactors.connection;
          this.axialResults = axialResults;
          if (connectionSafetyFactorResults.length === 0 || connectionSafetyFactorResults[0].loadCaseName === '') {
            this.errorMsg = "Please calculate Perivis to see results";
            this.columnDefs = [];
            this.results = [];
            this.isLoading = false;
            return;
          }
          this._connectionSafetyFactorResults = safetyFactorResults;

          this.remapData();
        }),
        catchError(err => {
          this.errorMsg = "Well Configuration Missing: Please specify at least one string";
          this.isLoading = false;
          return err;
        })).subscribe();
    } else {
      this.remapData();
    }
  }

  private remapData() {
    if (!this._connectionSafetyFactorResults) {
      return;
    }
    this.minimumResultType = this.connectionSafetyFactorResultsStore.state.selectedLoadCase?.value || this.connectionSafetyFactorResultsStore.state.selectedLoadCase;
    if (this.connectionSafetyFactorResultsStore.state.selectedMode == "single") {
      this.handleSingleSafetyFactorLoad(this._connectionSafetyFactorResults, true);
    } else if (this.connectionSafetyFactorResultsStore.state.selectedMode == "multiple") {
      this.handleMultiSafetyFactorLoad(this._connectionSafetyFactorResults, this.designFactors)
    } else if (this.connectionSafetyFactorResultsStore.state.selectedMode == 'minimum' && this.minimumResultType !== 'burstLeak' &&
      this.minimumResultType !== 'axial' && this.minimumResultType !== 'collapse') {
      this.handleMinimumSummary(this._connectionSafetyFactorResults, this.designFactors);
    } else {
      this.handleMinimum(this._connectionSafetyFactorResults, this.designFactors, this.minimumResultType);
    }
    this.signalRChange = true;
    this.isLoading = false;
  }

  setLoadCase(e): void {
    this.minimumResultType = e.value;
    if (this.connectionSafetyFactorResultsStore.state.selectedMode === "single") {
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.results = e.value.connectionSafetyFactorResults;
    } else {
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.refreshResults(e.value, false);
    }

    if (this.connectionSafetyFactorResultsStore.state.selectedMode === 'minimum' && e.value != 'summary') {
      this.handleMinimum(this.resultsBackup, this.designFactors, e.value);
    }

    if (this.connectionSafetyFactorResultsStore.state.selectedMode === 'minimum' && e.value == 'summary') {
      this.handleMinimumSummary(this.resultsBackup, this.designFactors);
    }
  }

  public onSelectedResultDisplayUpdated(e): void {
    this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  public depthViewToggle(e) {
    this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  public modeToggle(e) {
    if (e.value !== "minimum" && this.previousView == 'minimum' || this.previousView == 'summary') {
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: 'plot' }));
    }

    if (e.value == "multiple") {
      // this.selectedLoadCase = "burstLeak";
    } else {
      this.xAxisTitle = 'Connection Safety Factor';
    }
    this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedMode: e.value }));
    if (e.value === 'minimum' && this.previousView !== 'multiple') {
      this.handleMinimumSummary(this.resultsBackup, this.designFactors);
    } else {
      this.refreshResults(true, false, true);
    }
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private handleSingleSafetyFactorLoad(connectionSafetyFactorResults: Array<any>, calledFromSignalR: boolean) {
    if (calledFromSignalR) {
      //TODO Might need to fix this
      // this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.minimumResultType = 'summary';
    this.columnDefs = [
      { header: 'Burst', field: 'burst' },
      { header: 'Collapse', field: 'collapse' },
      { header: 'Axial', field: 'axial' }
    ];

    this.columnDefinitions = this.setDepths();
    this.columnDefinitions.push(
      {
        header: 'Burst', field: 'burst',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Collapse', field: 'collapse',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Axial', field: 'axial',
        valueFormatter: (params: any) => params
      }
    );
    this.results = [];

    this.loadCases = connectionSafetyFactorResults?.map(lc => {
      lc.connectionSafetyFactorResults.forEach(element => {
        // Burst
        let designFactorsBurstCheck = this.connectionSafetyFactorResultsStore.state.includeCharacters && element['burst'] < this.getDesignFactor(this.designFactors, 'burstLeak') - 0.000001 ? ' *' : '';
        if (element['burst'].toString().includes('*')) {
          let burst = element['burst'].toString().substring(0, element['burst'].toString().indexOf(' '));
          element['burst'] = this.roundValue(burst, 3) + designFactorsBurstCheck;
        } else {
          element['burst'] = this.roundValue(element['burst'], 3);
        }

        // Collapse
        let designFactorsCollapseCheck = this.connectionSafetyFactorResultsStore.state.includeCharacters && element['collapse'] < this.getDesignFactor(this.designFactors, 'collapse') - 0.000001 ? ' *' : '';
        if (element['collapse'].toString().includes('*')) {
          let collapse = element['collapse'].toString().substring(0, element['collapse'].toString().indexOf(' '));
          element['collapse'] = this.roundValue(collapse, 3) + designFactorsCollapseCheck;
        } else {
          element['collapse'] = this.roundValue(element['collapse'], 3);
        }

        // Axial
        let designFactorsAxialCheck = this.connectionSafetyFactorResultsStore.state.includeCharacters && element['axial'] < this.getDesignFactor(this.designFactors, 'axial') - 0.000001 ? ' *' : '';
        if (element['axial'].toString().includes('*')) {
          let axial = element['axial'].toString().substring(0, element['axial'].toString().indexOf(' '));
          element['axial'] = this.roundValue(axial, 3) + designFactorsCollapseCheck;
        } else {
          element['axial'] = this.roundValue(element['axial'], 3);
        }

      });
      return { label: lc.loadCaseName, value: lc };
    }) ?? [];
    if (!this.connectionSafetyFactorResultsStore.state.selectedLoadCase || this.previousView != 'single' || !this.connectionSafetyFactorResultsStore.state.selectedLoadCase.loadCaseName) {
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.previousView = 'single';
    this.results = this.loadCases.find(x => x.label == (this.connectionSafetyFactorResultsStore.state.selectedLoadCase?.loadCaseName ?? this.connectionSafetyFactorResultsStore.state.selectedLoadCase?.label)).value?.connectionSafetyFactorResults || [];
  }

  private handleMultiSafetyFactorLoad(connectionSafetyFactorResults: Array<any>, connectionDesignFactors) {
    if (this.previousView !== 'multiple') {
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: { label: 'Burst Leak', value: 'burstLeak' } }));
    }
    this.previousView = 'multiple';
    this.minimumResultType = 'summary';
    this.columnDefinitions = this.setDepths();
    this.columnDefs = [];
    this.results = [];
    let i = 0;
    this.loadCases = this.types;

    connectionSafetyFactorResults.forEach(load => {
      const loadName: string = load.loadCaseName;
      const loadNameCamel = toCamelCaseString(loadName);
      let selectedType = this.connectionSafetyFactorResultsStore.state.selectedLoadCase.value ?? this.connectionSafetyFactorResultsStore.state.selectedLoadCase;

      this.setAxisNames(selectedType);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      this.columnDefinitions.push({
        header: loadName, field: loadNameCamel,
        valueFormatter: (params: any) => params
      }
      );
      let backupArray = [];
      load.connectionSafetyFactorResults.forEach(loadResult => {
        if (selectedType == 'burstLeak') {
          selectedType = 'burst'
        }
        let result = {
          [loadNameCamel]: this.connectionSafetyFactorResultsStore.state.includeCharacters ? this.roundValue(loadResult[selectedType], 3) + (loadResult[selectedType] < (this.getDesignFactor(connectionDesignFactors, selectedType) - 0.000001) ? ' *' : '') : this.roundValue(loadResult[selectedType], 3),
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (i === 0) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        let otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });

      if (i === connectionSafetyFactorResults.length - 1) {
        this.addDesignFactor(selectedType, connectionDesignFactors);
      }
      i++;
    });
    this.isLoading = false;
  }

  private handleMinimumSummary(connectionSafetyFactorResults: Array<any>, designFactors) {
    // if (this.previousView !== 'summary') {
    //   this.selectedLoadCase = 'summary';
    // }
    this.previousView = 'summary';
    this.columnDefinitions = [];
    this.columnDefs = [];
    this.results = [];
    this.loadCases = this.types;
    this.loadCases = this.loadCases.filter(x => x.label !== 'Summary');
    this.loadCases.unshift({ label: 'Summary', value: 'summary' });

    this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: 'grid' }));

    this.columnDefinitions = [
      {
        header: 'Load Name', field: 'loadName',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Burst', field: 'burstLeak',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Collapse', field: 'collapse',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Axial', field: 'axial',
        valueFormatter: (params: any) => params
      },
    ];

    connectionSafetyFactorResults.forEach(load => {
      let smallestBurst = 10000;
      let smallestAxial = 10000;
      let smallestCollapse = 10000;
      let firstDepth = load.connectionSafetyFactorResults[0].measuredDepth;
      let depthBurst = firstDepth;
      let depthAxial = firstDepth;
      let depthCollapse = firstDepth;
      load.connectionSafetyFactorResults.forEach(element => {
        if (element['burst'] < smallestBurst) {
          smallestBurst = element['burst'];
          depthBurst = element.measuredDepth.toFixed(3);
        }

        if (element['axial'] < smallestAxial) {
          smallestAxial = element['axial'];
          depthAxial = element.measuredDepth.toFixed(3);
        }

        if (element['collapse'] < smallestCollapse) {
          smallestCollapse = element['collapse'];
          depthCollapse = element.measuredDepth.toFixed(3);
        }
      });

      if (this.results.length < 1) {
        this.results.push({
          loadName: 'Design Factor',
          burstLeak: designFactors['burstLeak'],
          collapse: designFactors['collapse'],
          axial: Math.min(designFactors['tension'], designFactors['compression']),
        });
      }
      let loadIndex = this.axialResults.findIndex(x => x.loadCaseName == load.loadCaseName);
      let depthIndex = this.axialResults[loadIndex].axialResults.findIndex(x => Math.abs(x.measuredDepth - depthAxial) < 0.00001);
      let axialForceValue = this.axialResults[loadIndex].axialResults[depthIndex].axialLoad;
      let tensionCompression = axialForceValue < 0 ? ' - Compression' : '';
      let depthUnit = this.userUnits.longLengths + "MD";

      this.results.push({
        loadName: load.loadCaseName,
        burstLeak: this.connectionSafetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestBurst, 3) + (smallestBurst < this.getDesignFactor(designFactors, 'burstLeak') - 0.000001 ? ' *' : '') + ' - @ - ' + depthBurst + ` ${depthUnit}` : this.roundValue(smallestBurst, 3),
        collapse: this.connectionSafetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestCollapse, 3) + (smallestCollapse < this.getDesignFactor(designFactors, 'collapse') - 0.000001 ? ' *' : '') + ' - @ - ' + depthCollapse + ` ${depthUnit}` : this.roundValue(smallestCollapse, 3),
        axial: this.connectionSafetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestAxial, 3) + (smallestAxial < this.getDesignFactor(designFactors, 'axial') - 0.000001 ? ' *' : '') + ' - @ - ' + depthAxial + ` ${depthUnit}` + tensionCompression : this.roundValue(smallestAxial, 3)
      });
    });
    if (!this.connectionSafetyFactorResultsStore.state.selectedLoadCase || !this.loadCases.find(l => isEqual(l, this.connectionSafetyFactorResultsStore.state.selectedLoadCase))) {
      this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.signalRChange = !this.signalRChange;
    this.isLoading = false;
  }

  private getDesignFactor(designFactors: any, selectedType) {
    let df = selectedType;
    if (df === 'axial') {
      df = designFactors['tension'] < designFactors['compression'] ? designFactors['tension'] : designFactors['compression'];
    } else if (df === 'collapse') {
      df = designFactors['collapse'];
    } else if (df === 'burst') {
      df = designFactors['burstLeak'];
    }

    return df;
  }

  private handleMinimum(safetyFactorResults: Array<any>, designFactors, type) {
    this.previousView = 'minimum';
    this.columnDefinitions = this.setDepths();
    this.columnDefs = [];
    this.results = [];
    this.loadCases = this.types;
    this.loadCases = this.loadCases.filter(x => x.label !== 'Summary');
    this.loadCases.unshift({ label: 'Summary', value: 'summary' });

    if (type == 'burstLeak') {
      type = 'burst';
    }
    let selectedType = type.value ?? type;
    this.setAxisNames(selectedType);

    this.connectionSafetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: 'grid' }));

    safetyFactorResults.forEach(load => {
      const loadName: string = load.loadCaseName;
      const loadNameCamel = toCamelCaseString(loadName);
      let l = 0;

      this.columnDefinitions.push({
        header: loadName, field: loadNameCamel,
        valueFormatter: (params: any) => params
      }
      );

      let smallestValue = 10000;
      let depthMd = 0;
      let depthTvd = 0;
      load.connectionSafetyFactorResults.forEach(element => {
        if (element[selectedType] < smallestValue) {
          smallestValue = element[selectedType];
          depthMd = element.measuredDepth;
          depthTvd = element.trueVerticalDepth;
        }
      });

      let designFactorCheck = this.connectionSafetyFactorResultsStore.state.includeCharacters && smallestValue < this.getDesignFactor(designFactors, selectedType) - 0.000001 ? ' *' : '';

      if (this.results.findIndex(x => x.measuredDepth == depthMd) < 0) {
        this.results.push({
          loadName: loadNameCamel,
          measuredDepth: depthMd,
          trueVerticalDepth: depthTvd,
          [loadNameCamel]: this.roundValue(smallestValue, 3) + designFactorCheck
        });
      } else {
        this.results[this.results.findIndex(x => x.measuredDepth == depthMd)][loadNameCamel] = this.roundValue(smallestValue, 3) + designFactorCheck;
      }

      l++;
    });
    this.results.sort((a, b) => (a.measuredDepth < b.measuredDepth ? -1 : 1));
    this.addDesignFactor(selectedType, designFactors);

    this.isLoading = false;
  }

  private setDepths() {
    return [
      {
        header: `MD (${this.depthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.depthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
    ]
  }

  private setAxisNames(selectedType: any) {
    switch (selectedType) {
      case 'burstLeak': {
        this.xAxisTitle = 'Burst Safety Factor';
        break;
      }
      case 'collapse': {
        this.xAxisTitle = 'Collapse Safety Factor';
        break;
      }
      case 'axial': {
        this.xAxisTitle = 'Axial Safety Factor';
        break;
      }
      default: {
        break;
      }
    }
  }

  private roundValue(value, decimalPoints) {
    let fixed = decimalPoints || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(value * fixed) / fixed;
  }

  private addDesignFactor(selectedType, connectionDesignFactors) {
    let gridDesignFactorName = '';
    let df = selectedType;
    if (df === 'axial') {
      df = this.designFactors['tension'] < this.designFactors['compression'] ? 'tension' : 'compression';
      gridDesignFactorName = 'Axial DF';
    } else if (df === 'collapse') {
      gridDesignFactorName = 'Collapse DF';
      df = 'collapse';
    } else if (df.startsWith('burst')) {
      gridDesignFactorName = 'Burst DF';
      df = 'burstLeak';
    }

    this.columnDefs.push({ header: gridDesignFactorName, field: selectedType });
    this.columnDefinitions.push(
      {
        header: gridDesignFactorName, field: selectedType,
        valueFormatter: (params: any) => this.formatDecimal(params, 1000)
      }
    );
    this.results = this.results.map(x => {
      let designFactor = {
        [selectedType]: connectionDesignFactors[df],
        measuredDepth: x.measuredDepth,
        trueVerticalDepth: x.trueVerticalDepth
      }
      return { ...x, ...designFactor }
    });
  }

  public toggleCharacterView(e) {
    this.connectionSafetyFactorResultsStore.update(state => ({ ...state, includeCharacters: e.checked }));

    if (this.connectionSafetyFactorResultsStore.state.selectedMode == "single") {
      this.handleSingleSafetyFactorLoad(this.resultsBackup, false);
    } else if (this.connectionSafetyFactorResultsStore.state.selectedMode == 'multiple') {
      this.handleMultiSafetyFactorLoad(this.resultsBackup, this.designFactors)
    } else if (this.connectionSafetyFactorResultsStore.state.selectedMode == 'minimum' && this.minimumResultType !== 'burstLeak' && !this.minimumResultType.startsWith('collapse') &&
      this.minimumResultType !== 'axial') {
      this.handleMinimumSummary(this.resultsBackup, this.designFactors);
    }

    if (this.connectionSafetyFactorResultsStore.state.selectedMode == 'minimum' && this.minimumResultType == 'burstLeak') {
      this.handleMinimum(this.resultsBackup, this.designFactors, this.connectionSafetyFactorResultsStore.state.selectedLoadCase);
    }
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
