import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { LinearInterpSingle } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { InternalPressureProfileType, LostReturnsFluidDropIPP } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { lastValueFrom } from 'rxjs';

interface LostReturnsFluidDropIppForm {
  type: InternalPressureProfileType.FLUIDDROP;
  lossDepth: UomQuantityInput;
  porePressure: UomQuantityInput;
  fluidDensity: UomQuantityInput;
  marginOfError: UomQuantityInput;
  isPressureEnteredAsPpg: boolean;
}

@Component({
  selector: 'app-fluid-drop-ipp',
  templateUrl: './fluid-drop-ipp.component.html',
  providers: subformComponentProviders(FluidDropIppComponent)
})
export class FluidDropIppComponent implements AfterViewInit, OnChanges {

  @Input() currentString: any;
  @Input() nextShoeDepth: number;
  @Input() nextStringMudWeight: number;
  @Input() porePressures: PoreFrac[];
  @Input() userUnits: UserUnitsModel;

  private _defaultEmw: number;

  public validators: any;
  public defaultEmw: number;
  public densityLabel: string;
  public densityValidation: { min: number, max: number };
  public marginOfErrorValidation: { min: number, max: number };
  public porePressureValidation: { min: number, max: number };

  public form = createForm<LostReturnsFluidDropIPP, LostReturnsFluidDropIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.FLUIDDROP),
      lossDepth: new UntypedFormControl(null),
      porePressure: new UntypedFormControl(null),
      fluidDensity: new UntypedFormControl(null),
      marginOfError: new UntypedFormControl(null),
      isPressureEnteredAsPpg: new UntypedFormControl(true)
    },
    toFormGroup: (obj: LostReturnsFluidDropIPP | null): LostReturnsFluidDropIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        lossDepth: { control: obj.lossDepth },
        porePressure: { control: obj.porePressure },
        fluidDensity: { control: obj.fluidDensity },
        marginOfError: { control: obj.marginOfError },
        isPressureEnteredAsPpg: obj.isPressureEnteredAsPpg
      }
    },
    fromFormGroup: (formValue: LostReturnsFluidDropIppForm): LostReturnsFluidDropIPP | null => {
      const { lossDepth, porePressure, fluidDensity, marginOfError, ...commonValues } = formValue;
      return {
        lossDepth: +lossDepth?.control,
        porePressure: +porePressure?.control,
        fluidDensity: +fluidDensity?.control,
        marginOfError: +marginOfError?.control,
        ...commonValues
      }
    }
  });

  constructor(private _trajectoryService: TrajectoryService) { }

  ngAfterViewInit(): void {
    this.getEmwDefaultPore(this.nextShoeDepth);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.densityLabel = Units.lib[this.userUnits.density].symbol;

    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 0.01, max: 25 };
        this.marginOfErrorValidation = { min: 0, max: 10 };
        this.porePressureValidation = { min: 0, max: 25 };
        this._defaultEmw = 10.5;
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        this.marginOfErrorValidation = { min: 0, max: 1198 };
        this.porePressureValidation = { min: 0, max: 2995 };
        this._defaultEmw = 1258;
        break;
      case 'g/cm³':
      case 'kg/l':
        this.densityValidation = { min: 0.00119, max: 3 };
        this.marginOfErrorValidation = { min: 0, max: 1.2 };
        this.porePressureValidation = { min: 0, max: 3 };
        this._defaultEmw = 1.26;
        break;
    }

    this.validators = {
      lossDepth: [Validators.min(this.currentString.hangerMd), Validators.max(Number.MAX_VALUE), Validators.required],
      porePressure: [Validators.min(this.porePressureValidation.min), Validators.max(this.porePressureValidation.max), Validators.required],
      fluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required],
      marginOfError: [Validators.min(this.marginOfErrorValidation.min), Validators.max(this.marginOfErrorValidation.max), Validators.required]
    };
    if (changes.nextShoeDepth) {
      this.getEmwDefaultPore(this.nextShoeDepth);
    }
  }

  getEmwDefaultPore(depth): void {
    if (depth) {
      let tvds = lastValueFrom(this._trajectoryService.getTvdsFromMds([depth], true));
      if (this.porePressures?.length > 1) {
        if (depth < this.currentString?.hangerMd) {
          return;
        }
        this.defaultEmw = +LinearInterpSingle(tvds[0], this.porePressures, this.userUnits).toFixed(2);
      } else {
        this.defaultEmw = this._defaultEmw;
      }
      if (!this.form.formGroup.controls.porePressure.value?.control) {
        this.form.formGroup.controls.porePressure.setValue({ control: this.defaultEmw });
      }
    }
  }
  
}
