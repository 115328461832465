import { AfterContentInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { GeneralEPP } from '../../models/external-pressure-profile.model';
import { isNumber } from 'lodash';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Units } from 'src/app/core/services/unit-library';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';
import { lastValueFrom } from 'rxjs';

interface GeneralEPPForm {
  fluidDensity: number;
  hangerPressure: number;
}

@Component({
  selector: 'app-general-epp',
  templateUrl: './general-epp.component.html',
  styleUrls: ['./general-epp.component.scss'],
  providers: subformComponentProviders(GeneralEppComponent)
})
export class GeneralEppComponent implements OnInit, AfterContentInit, OnChanges {

  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public topPressureLabel: string;

  private defaultHangerPressure: number;

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  public densityLabel: string;
  public pressureLabel: string;
  public pressureValidation: { min: number, max: number };
  public densityValidation: { min: number, max: number };

  public form = createForm<GeneralEPP, GeneralEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      fluidDensity: new UntypedFormControl(null),
      hangerPressure: new UntypedFormControl(null)
    },
    toFormGroup: (obj: GeneralEPP): GeneralEPPForm => {
      return obj;
    },
    fromFormGroup: (formValue: GeneralEPPForm): GeneralEPP => {
      return formValue;
    }
  });

  constructor(private _trajectoryService: TrajectoryService) { }

  ngOnInit(): void {
    this.topPressureLabel = this.currentString['type'] === 'Casing' ? 'Surface Pressure' : 'Hanger Pressure';
    this.pressureLabel = this.userUnits.pressure;
    this.densityLabel = Units.lib[this.userUnits.density].symbol;
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.hangerPressure.value && isNumber(this.defaultHangerPressure)) {
        this.form.formGroup.controls.hangerPressure.setValue(this.defaultHangerPressure);
      }
      if (!this.form.formGroup.controls.fluidDensity.value) {
        this.form.formGroup.controls.fluidDensity.setValue(this.currentString?.annularFluid.state.nominalDensity);
      }
    }, 0);
  }

  async ngOnChanges() {
    // if (changes.currentString.firstChange || changes.currentString.currentValue !== changes.currentString.previousValue) {
      switch (this.userUnits.pressure) {
        case 'psi':
          this.pressureValidation = { min: 0, max: 30000 };
          break;
        case 'bar':
          this.pressureValidation = { min: 0, max: 2068 };
          break;
        case 'KPa':
          this.pressureValidation = { min: 0, max: 206800 };
          break;
        case 'atm':
          this.pressureValidation = { min: 0, max: 2041 };
          break;
      }
      this.form.formGroup.controls.hangerPressure.setValidators([Validators.required, Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max)]);

  
      switch (this.userUnits.density) {
        case 'ppg (U.S.)':
          this.densityValidation = { min: 0.01, max: 25 };
          break;
        case 'kg/m³':
        case 'g/L':
          this.densityValidation = { min: 1.19, max: 2995 };
          break;
        case 'g/cm³':
        case 'kg/l':
          this.densityValidation = { min: 0.00119, max: 3 };
          break;
      }
      this.form.formGroup.controls.fluidDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
      
      let tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([this.currentString.hangerMd], true));
      this.defaultHangerPressure = GetMudHydrostaticPressure(tvds[0], this.currentString?.annularFluid.state.nominalDensity, this.userUnits);
      if (!this.form.formGroup.controls.hangerPressure.value && isNumber(this.defaultHangerPressure)) {
        this.form.formGroup.controls.hangerPressure.setValue(this.defaultHangerPressure);
      }
    // }
  }

  onInputFocus($event) {
    $event.target.select();
  }
}
