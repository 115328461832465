<form [formGroup]="form.formGroup">
  <div class="field">
    <label>Packer Name</label>
    <div class="p-inputgroup">
      <input
        type="text"
        pInputText
        [formControlName]="form.formControlNames.name"
        id="name"
      />
    </div>
  </div>
<p-tabView>
  <p-tabPanel>
    <ng-template pTemplate="header"> Packer Data </ng-template>
      <div class="field">
        <label>Setting Type</label>
        <p-dropdown
          (onChange)="typeChange()"
          [formControlName]="form.formControlNames.type"
          optionValue="value"
          optionLabel="label"
          [options]="packerTypes"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="field">
        <label>Packer Depth</label>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.measuredDepth"
          />
          <span class="p-inputgroup-addon">{{ userUnits.longLengths }} MD</span>
        </div>
        <p *ngIf=" form.formGroup.controls.measuredDepth.invalid && form.formGroup.value.measuredDepth" class="error">
            Allowed range: {{ packerMinDepth }} - {{ packerDefaultDepth }} {{ userUnits.longLengths }}
      </div>

      <div [style.display]="form.formGroup.controls.type.value == 'Hydraulic' ? 'block' : 'none'">
        <div class="field">
          <label>Initial Set Pressure</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.initialSetPressure"
            />
            <span class="p-inputgroup-addon">{{ pressureUnit }}</span>
          </div>
        </div>

        <div class="field">
          <label>Plug Depth</label>
          <div class="p-inputgroup">
            <input
              pInputText
              [formControlName]="form.formControlNames.plugDepth"
            />
            <span class="p-inputgroup-addon"
              >{{ userUnits.longLengths }} MD</span
            >
          </div>
          <p
            *ngIf="
              form.formGroup.controls.plugDepth.invalid &&
              form.formGroup.controls.plugDepth.value &&
              form.formGroup.controls.type.value == 'Hydraulic'
            "
            class="error"
          >
            Allowed range:
            {{ form.formGroup.controls.measuredDepth.value }} -
              {{ packerDefaultDepth }} {{ userUnits.longLengths }}
          </p>
        </div>
      </div>

      <div class="field">
        <label>Pickup/Slackoff </label>
        <span>
          <i
            title="Pickup (+)/Slackoff (-)"
            class="pi pi-info-circle pickupSlackoffClass"
          ></i>
        </span>
        <div class="p-inputgroup">
          <input
            pInputText
            [formControlName]="form.formControlNames.pickupForce"
          />
          <span class="p-inputgroup-addon">{{ forceUnit }}</span>
        </div>
      </div>
    <div class="col-12">
      <label>Anchor/ASV:</label>
      <hr class="rounded" style="height: 2px; margin-bottom: 10px" />
      <div class="field" style="margin-bottom: 0px">
        <input
          style="height: 13px; width: 20px"
          type="checkbox"
          binary="true"
          [formControlName]="form.formControlNames.isPressureBarrier"
        />
        <label style="font-size: 14px; padding-left: 5px">Annulus pressure barrier</label>
        <span>
          <i
            title="Uncheck if Anchor or Open ASV"
            class="pi pi-info-circle pickupSlackoffClass"
          ></i>
        </span>
      </div>
    </div>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header"> Expansion Joint / PBR </ng-template>
    <p-fieldset>
      <ng-template pTemplate="header">
        <input
          style="height: 13px; width: 20px"
          type="checkbox"
          binary="true"
          [formControlName]="form.formControlNames.hasExpansionJoint"
        />
        <label class="expansionJointLabel" style="font-size: 11px; padding-left: 5px">Expansion Joint / PBR</label>
      </ng-template>

      <app-expansion-joint
        [formControlName]="form.formControlNames.expansionJoint"
        [packerDepth]="form.formGroup.controls.measuredDepth.value"
        [hasExpansionJoint]="form.formGroup.controls.hasExpansionJoint.value"
        [userUnits]="userUnits"
      ></app-expansion-joint>
    </p-fieldset>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header"> Packer Envelope </ng-template>

    <div class="mr-2">
      <p-inputSwitch (onChange)="tablePlotToggle($event)" binary="true"></p-inputSwitch>
      <label class="plotLabel">Table/Plot</label>
    </div>

    <app-xy-line-plot
      *ngIf="selectedResultDisplay"
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="'Packers'"
      [componentHeight]="packersComponentHeight"
      [reverseAutoRange]="true"
    ></app-xy-line-plot>

    <div [style.display]="!selectedResultDisplay ? 'block' : 'none'">
      <br>
        <ng-table-grid-cmp
          [tableName]="'Packer Envelope'"
          [tableData]="packerEnvelope"
          [columnDefinitions]="columnDefinitions"
          [newRowFormGroup]="newDataRow"
          [inputFields]="inputFields"
          (dataChange)="onPackerEnvelopeChange($event)"
          [isDynamicLoaded]="true"
          [enableVirtualScroll]="false"
          [tableHeight]="tableHeight"
        ></ng-table-grid-cmp>
    </div>
  </p-tabPanel>
</p-tabView>
</form>
<br>