import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DesignLimitsPlotComponent } from '../perivis/components/design-limits-plot/design-limits-plot.component';
import { SingleLoadBurstComponent } from '../perivis/components/single-load-burst/single-load-burst.component';
import { DesignFactorsComponent } from '../perivis/components/design-factors/design-factors.component';
import { PostInstallLoadsComponent } from '../perivis/components/post-install-loads/post-install-loads.component';
import { InternalPressureProfilesComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/internal-pressure-profiles.component';
import { ExternalPressureProfilesComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/external-pressure-profiles.component';
import { TemperatureProfilesComponent } from '../perivis/components/post-install-loads/post-install-load-details/temperature-profiles/temperature-profiles.component';
import { SingleLoadSafetyFactorsComponent } from '../perivis/components/single-load-safety-factors/single-load-safety-factors.component';
import { SingleLoadConnectionSafetyFactorsComponent } from '../perivis/components/single-load-connection-safety-factors/single-load-connection-safety-factors.component';
import { SingleLoadPlotComponent } from '../perivis/shared/components/single-load-plot/single-load-plot.component';
import { SingleLoadCollapseComponent } from '../perivis/components/single-load-collapse/single-load-collapse.component';
import { SingleLoadAxialComponent } from '../perivis/components/single-load-axial/single-load-axial.component';
import { SingleLoadGridComponent } from '../perivis/shared/components/single-load-grid/single-load-grid.component';
import { SingleLoadResultsComponent } from '../perivis/shared/components/single-load-results/single-load-results.component';
import { StressOptionsComponent } from '../perivis/components/stress-options/stress-options.component';
import { PostInstallLoadDetailsComponent } from '../perivis/components/post-install-loads/post-install-load-details/post-install-load-details.component';
import { PoreAndFracComponent } from '../perivis/components/pore-and-frac/pore-and-frac.component';
import { PpfgPlotComponent } from '../perivis/components/ppfg-plot/ppfg-plot.component';

import { SharedModule } from './shared.module';
import { UomQuantityInputComponent } from '../perivis/components/post-install-loads/post-install-load-details/uom-quantity-input/uom-quantity-input-component';
import { PorePressureComponent } from '../perivis/components/pore-pressure/pore-pressure.component';
import { FracturePressureComponent } from '../perivis/components/fracture-pressure/fracture-pressure.component';
import { LiftoffResultsComponent } from '../perivis/components/liftoff-results/liftoff-results.component';
import { PressuresTemperaturesComponent } from '../perivis/components/pressures-temperatures/pressures-temperatures.component';
import { PreInstallLoadsComponent } from '../perivis/components/pre-install-loads/pre-install-loads.component';
import { PreInstallDataComponent } from '../perivis/components/pre-install-loads/pre-install-data/pre-install-data.component';
import { OverpullDetailsComponent } from '../perivis/components/pre-install-loads/pre-install-data/overpull-details/overpull-details.component';
import { RunInHoleDetailsComponent } from '../perivis/components/pre-install-loads/pre-install-data/run-in-hole-details/run-in-hole-details.component';
import { SinglePreInstallLoadComponent } from '../perivis/components/pre-install-loads/pre-install-data/single-pre-install-load/single-pre-install-load.component';
import { DisplacementResultsComponent } from '../perivis/components/displacement-results/displacement-results.component';
import { DlsOverridesComponent } from '../wellbore-inputs/components/dls-overrides/dls-overrides.component';
import { GreenCementTestDetailsComponent } from '../perivis/components/pre-install-loads/pre-install-data/green-cement-test-details/green-cement-test-details.component';
import { PackerEnvelopeResultsComponent } from '../perivis/components/packer-envelope-results/packer-envelope-results.component';
import { PackerTableResultsComponent } from '../perivis/components/packer-table-results/packer-table-results.component';
import { PostInstallLoadsPlotComponent } from '../perivis/components/post-install-loads/post-install-loads-plot/post-install-loads-plot.component';
import { CustomEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/custom-epp/custom-epp.component';
import { PericalLinkEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/perical-link-epp/perical-link-epp.component';
import { GeneralEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/general-epp/general-epp.component';
import { FluidToShoeEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/fluid-to-shoe-epp/fluid-to-shoe-epp.component';
import { MudAndWetCementEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/mud-and-wet-cement-epp/mud-and-wet-cement-epp.component';
import { MudAndCementMixWaterEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/mud-and-cement-mix-water-epp/mud-and-cement-mix-water-epp.component';
import { InterpolationToPorePressureEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/interpolation-to-pore-pressure-epp/interpolation-to-pore-pressure-epp.component';
import { MudAndPorePressureEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/mud-and-pore-pressure-epp/mud-and-pore-pressure-epp.component';
import { PorePressureEppComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/pore-pressure-epp/pore-pressure-epp.component';
import { CustomIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/custom-ipp/custom-ipp.component';
import { PericalLinkIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/perical-link-ipp/perical-link-ipp.component';
import { DisplacementFluidIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/displacement-fluid-ipp/displacement-fluid-ipp.component';
import { PressureTestIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/pressure-test-ipp/pressure-test-ipp.component';
import { GeneralFluidIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/general-fluid-ipp/general-fluid-ipp.component';
import { PartialEvacByMdIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/partial-evac-by-md-ipp/partial-evac-by-md-ipp.component';
import { WcdIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/wcd-ipp/wcd-ipp.component';
import { FluidDropIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/fluid-drop-ipp/fluid-drop-ipp.component';
import { LostReturnsWaterIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/lost-returns-water-ipp/lost-returns-water-ipp.component';
import { CirculatedKickGasIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/circulated-kick-gas-ipp/circulated-kick-gas-ipp.component';
import { CirculatedKickLiquidIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/circulated-kick-liquid-ipp/circulated-kick-liquid-ipp.component';
import { FullEvacIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/full-evac-ipp/full-evac-ipp.component';
import { BhctTempProfileComponent } from '../perivis/components/post-install-loads/post-install-load-details/temperature-profiles/bhct-temp-profile/bhct-temp-profile.component';
import { CustomTempProfileComponent } from '../perivis/components/post-install-loads/post-install-load-details/temperature-profiles/custom-temp-profile/custom-temp-profile.component';
import { UndisturbedTempProfileComponent } from '../perivis/components/post-install-loads/post-install-load-details/temperature-profiles/undisturbed-temp-profile/undisturbed-temp-profile.component';
import { PericalLinkTempProfileComponent } from '../perivis/components/post-install-loads/post-install-load-details/temperature-profiles/perical-link-temp-profile/perical-link-temp-profile.component';
import { SssvIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/sssv-ipp/sssv-ipp.component';
import { NewPostInstallLoadDialogComponent } from '../perivis/components/post-install-loads/new-post-install-load-dialog/new-post-install-load-dialog.component';
import { CasingFullOfGasIppComponent } from '../perivis/components/post-install-loads/post-install-load-details/internal-pressure-profiles/casing-full-of-gas-ipp/casing-full-of-gas-ipp.component';
import { PressureResultsComponent } from '../perivis/components/pressure-results/pressure-results.component';
import { WellheadSlumpComponent } from '../perivis/components/wellhead-slump/wellhead-slump.component';
import { GasCapComponent } from '../perivis/components/post-install-loads/post-install-load-details/external-pressure-profiles/gas-cap/gas-cap.component';



@NgModule({
  declarations: [
    UomQuantityInputComponent,
    DesignFactorsComponent,
    PostInstallLoadsComponent,
    PostInstallLoadDetailsComponent,
    InternalPressureProfilesComponent,
    ExternalPressureProfilesComponent,
    TemperatureProfilesComponent,
    SingleLoadSafetyFactorsComponent,
    SingleLoadConnectionSafetyFactorsComponent,
    SingleLoadPlotComponent,
    SingleLoadCollapseComponent,
    SingleLoadAxialComponent,
    PoreAndFracComponent,
    PorePressureComponent,
    FracturePressureComponent,
    PpfgPlotComponent,
    SingleLoadGridComponent,
    SingleLoadResultsComponent,
    StressOptionsComponent,
    DesignLimitsPlotComponent,
    SingleLoadBurstComponent,
    LiftoffResultsComponent,
    PressuresTemperaturesComponent,
    PreInstallLoadsComponent,
    OverpullDetailsComponent,
    PreInstallDataComponent,
    SinglePreInstallLoadComponent,
    RunInHoleDetailsComponent,
    PackerEnvelopeResultsComponent,
    DisplacementResultsComponent,
    GreenCementTestDetailsComponent,
    PackerTableResultsComponent,
    DlsOverridesComponent,
    PostInstallLoadsPlotComponent,
    CustomEppComponent,
    PericalLinkEppComponent,
    GeneralEppComponent,
    FluidToShoeEppComponent,
    MudAndWetCementEppComponent,
    MudAndCementMixWaterEppComponent,
    InterpolationToPorePressureEppComponent,
    MudAndPorePressureEppComponent,
    PorePressureEppComponent,
    CustomIppComponent,
    PericalLinkIppComponent,
    DisplacementFluidIppComponent,
    PressureTestIppComponent,
    GeneralFluidIppComponent,
    PartialEvacByMdIppComponent,
    WcdIppComponent,
    FluidDropIppComponent,
    LostReturnsWaterIppComponent,
    CirculatedKickGasIppComponent,
    CirculatedKickLiquidIppComponent,
    FullEvacIppComponent,
    BhctTempProfileComponent,
    CustomTempProfileComponent,
    UndisturbedTempProfileComponent,
    PericalLinkTempProfileComponent,
    SssvIppComponent,
    NewPostInstallLoadDialogComponent,
    CasingFullOfGasIppComponent,
    PressureResultsComponent,
    WellheadSlumpComponent,
    GasCapComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    UomQuantityInputComponent,
    DesignFactorsComponent,
    PostInstallLoadsComponent,
    PostInstallLoadDetailsComponent,
    InternalPressureProfilesComponent,
    ExternalPressureProfilesComponent,
    TemperatureProfilesComponent,
    SingleLoadSafetyFactorsComponent,
    SingleLoadConnectionSafetyFactorsComponent,
    SingleLoadPlotComponent,
    SingleLoadCollapseComponent,
    SingleLoadAxialComponent,
    PoreAndFracComponent,
    PorePressureComponent,
    PpfgPlotComponent,
    SingleLoadGridComponent,
    SingleLoadResultsComponent,
    StressOptionsComponent,
    DesignLimitsPlotComponent,
    SingleLoadBurstComponent,
    FracturePressureComponent,
    OverpullDetailsComponent,
    RunInHoleDetailsComponent,
    PackerEnvelopeResultsComponent,
    GreenCementTestDetailsComponent,
    PackerTableResultsComponent
  ]
})
export class PerivisModule { }
